import React from "react";
import { addNewDoc } from "../Firebase/Firebase.utils";
import InputBox from "../components/InputBox";
import UseGetAllDoc from "../Hooks/UseGetAllDoc";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

export default function LandlordRepRequirementForm({ handleAddMode }) {
  const currentUser = useSelector((state) => state.userObject);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedBuilding, setSelectedBuilding] = React.useState("");
  const { data: buildings, isLoading } = UseGetAllDoc({
    collection: "masterlist",
  });
  const [formData, setFormData] = React.useState({});
  const [isProcessing, setIsProcessing] = React.useState(false);

  if (isLoading) {
    return (
      <div className="small-form flex items-center justify-center">
        Loading...
      </div>
    );
  }

  console.log(buildings);

  const buildingOptions = buildings.map((building) => ({
    label: building.buildingName,
    value: building.id,
  }));

  console.log(buildingOptions);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelect = (building) => {
    setFormData({ ...formData, building: building.value });
    setSelectedBuilding(building.label);
    setSearchTerm("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    if (formData.building === undefined) {
      setIsProcessing(false);
      toast.error("Please select a building");
      return;
    }

    const docObject = {
      ...formData,
      buildingName: selectedBuilding,
      team_lead: currentUser.email,
      team_members: [currentUser.email],
      createdBy: currentUser.email,
      createdAt: new Date().toLocaleString(),
      updatedAt: new Date().toLocaleString(),
      lastUpdatedBy: currentUser.email,
      lastUpdatedBy: currentUser.email,
      status: "active",
      pipeline_status: "10%",
    };
    try {
      await addNewDoc("landlordRepRequirements", docObject);
      setIsProcessing(false);
      handleAddMode();
      toast.success("Landlord Rep Requirement Added");
    } catch (error) {
      console.log(error);
      setIsProcessing(false);
      toast.error("An error occurred. Please try again");
    }
  };

  return (
    <div className="small-form relative">
      <button className="close-button" onClick={handleAddMode}>
        Cancel
      </button>
      <div className="header pt-8">Add a Landlord Rep Pipeline</div>
      <div className="mt-8 w-full">
        <form action="" className="w-full">
          <div className="space-y-4">
            <InputBox
              name="tenantName"
              label="Tenant Name"
              type="text"
              placeholder="Enter Tenant Name"
              required
              onChange={handleChange}
            />
            <InputBox
              name="requirementSize"
              label="Requirement Size"
              type="text"
              placeholder="Enter Requirement Size"
              required
              onChange={handleChange}
            />

            <InputBox
              name="requirementType"
              label="Requirement Type"
              type="text"
              placeholder="e.g. Office, Retail, etc"
              required
              onChange={handleChange}
            />

            <InputBox
              name="building"
              label={"Search for Building Here"}
              placeholder={"Search for Building"}
              type="search"
              onChange={handleSearch}
              value={searchTerm}
            />
          </div>
          {searchTerm.length > 0 && (
            <div className="max-h-36 p-2 shadow-md rounded-lg w-[350px] overflow-hidden absolute bg-white border border-gray-100 ">
              {buildingOptions
                .filter((building) => {
                  return building.label
                    ?.toString()
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase());
                })
                .map((building) => {
                  return (
                    <div
                      className="overflow-x-hidden text-clip border-b border-gray-100 p-2 cursor-pointer hover:bg-gray-100 capitalize"
                      key={building.value}
                      onClick={() => handleSelect(building)}
                    >
                      {building.label}
                    </div>
                  );
                })}
            </div>
          )}

          <div>
            <div className="text-sm font-semibold  mt-4">Selected Building</div>
            <div>{selectedBuilding || "No building Selected"}</div>
          </div>

          <div className="w-full">
            <button className="blue-button mt-8" onClick={handleSubmit}>
              {isProcessing ? "Processing..." : "Add Requirement"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
