import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getDocData, queryAllDocsByField } from "../../Firebase/Firebase.utils";
import { Photo } from "@mui/icons-material";
import PhotoCarousel from "./PhotoCarousel";
import UserAvatar from "../../components/UserAvatar";

function Flyer() {
  const { id } = useParams();
  const [listing, setListing] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [photos, setPhotos] = React.useState([]);

  useEffect(() => {
    const fetchPhotos = async () => {
      const data = await queryAllDocsByField(
        "photos",
        "listingId",
        "==",
        id,
        (data) => {
          const photos = data.map((photo) => {
            return photo.url;
          });
          setPhotos(photos);
          setIsLoading(false);
        }
      );
    };
    fetchPhotos();
  }, []);

  useEffect(() => {
    const fetchListing = async () => {
      const data = await getDocData("listings", id);
      setListing(data);
      setIsLoading(false);
    };
    fetchListing();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const amountConverter = (amount) => {
    return parseFloat(amount).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <div>
      <div className="bubble">
        <div className="font-bold text-leechiuBlue">
          {listing.website_title || listing.property_name}
        </div>
        <div>{listing.listing_description}</div>
      </div>

      <div className="flex items-start justify-center gap-2 flex-col xl:flex-row w-full bubble mt-4">
        <div className="p-2 mx-auto">
          <PhotoCarousel listing={listing} id={id} photos={photos} />
        </div>
        <div className="bubble mt-4 flex flex-col w-full">
          <div className="font-bold text-leechiuBlue ">Property Details</div>
          <div>{listing.listing_type}</div>
          <div>{listing.address}</div>
          <div>Pipeline Status: {listing.pipeline_status}</div>
          <div>Lot Area: {listing.lot_area} sqm</div>
          <div>
            Selling Price: Php {amountConverter(listing.selling_price)} per sqm
          </div>
          <div>Total Price: Php {amountConverter(listing.total_price)}</div>
        </div>
      </div>

      <div className="bubble mt-4">
        <div className="text-leechiuBlue font-bold mb-4">Agents</div>
        <div className="flex items-center gap-4">
          {listing.team_members &&
            listing.team_members.map((member) => (
              <div key={member}>
                <UserAvatar email={member} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default Flyer;
