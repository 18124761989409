import React, { useEffect } from "react";
import InputBox from "../components/InputBox";
import ReactSelectBox from "../components/ReactSelectBox";
import {
  addNewDoc,
  createNewDocument,
  getDocDataSnap,
  setDocData,
} from "../Firebase/Firebase.utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

export default function ResearchBilling({ data }) {
  const [formData, setFormData] = React.useState(data || {});
  const [isProcessing, setIsProcessing] = React.useState(data ? true : false);
  const [approver, setApprover] = React.useState(null);
  const [milestone, setMilestone] = React.useState(null);
  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const id = searchParams.get("id") || "";

  console.log(id);

  useEffect(() => {
    if (id !== "") {
      const fetchBilling = async () => {
        await getDocDataSnap("billing", id, (data) => {
          setFormData(data);
        });
      };
      fetchBilling();
    }
  }, []);

  const approvers = [
    { value: "roy.golez@leechiu.com", label: "Roy Golez" },
    { value: "henry.cabrera@leechiu.com", label: "Henry Cabrera" },
  ];

  const milestones = [
    "Mobilization",
    "First Presentation",
    "Second Presentation",
    "Final Billing",
    "Out of Pocket Expenses",
    "Others",
  ];

  const milestonesOptions = milestones.map((milestone) => ({
    value: milestone,
    label: milestone,
  }));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectChange = (selectedOption, name) => {
    setFormData((prev) => ({
      ...prev,
      [name]: selectedOption.value,
    }));
  };

  const handleSaveDraft = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const billingDoc = {
      ...formData,
      created_at: new Date(),
      created_by: currentUser.email,
      status: "draft",
      billing_type: "research",
    };

    if (id !== "") {
      const updateBilling = async () => {
        try {
          await setDocData("billing", id, billingDoc);
          setIsProcessing(false);
          navigate(`/billinglist/mybillings`);
        } catch (error) {
          alert(error.message);
          setIsProcessing(false);
        }
      };
      updateBilling();
      return;
    }

    const saveDraft = async () => {
      try {
        await createNewDocument("billing", billingDoc);
        setIsProcessing(false);

        navigate(`/dashboard`);
      } catch (error) {
        alert(error.message);
        setIsProcessing(false);
      }
    };
    saveDraft();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);

    if (formData.approver === "" || null || undefined) {
      toast.error("Please select an approver");
      setIsProcessing(false);
      return;
    }

    const billingDoc = {
      ...formData,
      billing_type: "research",
      created_at: new Date(),
      created_by: currentUser.email,
      status: "draft",
      files: {},
    };

    const submitBilling = async () => {
      try {
        const res = await createNewDocument("billing", billingDoc);
        const id = res.id;
        setIsProcessing(false);

        navigate(`/bill-support/${id}`);
      } catch (error) {
        alert(error.message);
        setIsProcessing(false);
      }
    };

    if (id !== "") {
      const updateBilling = async () => {
        try {
          await setDocData("billing", id, billingDoc);
          setIsProcessing(false);
          navigate(`/bill-support/${id}`);
        } catch (error) {
          alert(error.message);
          setIsProcessing(false);
        }
      };
      updateBilling();
      return;
    }

    if (window.confirm("Are you sure you want to submit this billing?")) {
      submitBilling();
      setIsProcessing(false);
    } else {
      setIsProcessing(false);
      return;
    }
  };

  return (
    <div>
      <div className="header">Billing for Research Study</div>

      <div>
        <form action="" onSubmit={handleSubmit}>
          <ReactSelectBox
            options={approvers}
            label="Select Approver"
            required={true}
            disabled={isProcessing}
            onChange={(selectedOption) =>
              handleSelectChange(selectedOption, "approver")
            }
            value={
              approvers.find(
                (approver) => approver.value === formData.approver
              ) || ""
            }
          />
          <div>
            <div className="mt-4 header">Client Information</div>
            <div className="flex flex-wrap gap-4">
              <InputBox
                label={"Client Name"}
                placeholder={"Company or Person to be billed"}
                name="client_name"
                onChange={handleChange}
                required
                value={formData.client_name || ""}
                disabled={isProcessing}
              />
              <InputBox
                label={"Client Address"}
                placeholder={"Enter Client Address"}
                name="client_address"
                onChange={handleChange}
                required
                value={formData.client_address || ""}
                disabled={isProcessing}
              />
              <InputBox
                label={"Contact Person"}
                placeholder={"Enter Contact Person"}
                name="contact_person"
                onChange={handleChange}
                required
                value={formData.contact_person || ""}
                disabled={isProcessing}
              />
              <InputBox
                label={"Position of Contact Person"}
                placeholder={"Enter Position of Contact Person"}
                name={"contact_position"}
                onChange={handleChange}
                required
                value={formData.contact_position || ""}
                disabled={isProcessing}
              />
              <InputBox
                label={"Email Address of Contact Person"}
                placeholder={"Enter Email Address"}
                type="email"
                name="contact_email"
                onChange={handleChange}
                required
                value={formData.contact_email || ""}
                disabled={isProcessing}
              />
              <InputBox
                label={"Mobile Number of Contact Person"}
                placeholder={"Enter mobile number"}
                name="contact_number"
                onChange={handleChange}
                required
                value={formData.contact_number || ""}
                disabled={isProcessing}
              />
            </div>
          </div>

          <div>
            <div className="mt-4 font-bold header">Billing Details</div>
            <div className="flex flex-wrap gap-4">
              <InputBox
                label="Fee Amount"
                placeholder="Enter Amount without VAT"
                type="number"
                name="amount"
                onChange={handleChange}
                required
                value={formData.amount || ""}
                disabled={isProcessing}
              />

              <InputBox
                label="VAT"
                placeholder="Enter VAT"
                type="number"
                name="vat"
                onChange={handleChange}
                required
                value={formData.vat || ""}
                disabled={isProcessing}
              />

              <InputBox
                label="Enter Short Description of Transaction"
                placeholder="e.g. Research Study for ABC Company"
                type="text"
                name="description"
                onChange={handleChange}
                required
                value={formData.description || ""}
                disabled={isProcessing}
              />
            </div>

            <div className="mt-4 font-bold header">Transaction Information</div>
            <div className="flex flex-wrap gap-4">
              <ReactSelectBox
                options={milestonesOptions}
                label="Select Milestone"
                required={true}
                disabled={isProcessing}
                onChange={(selectedOption) =>
                  handleSelectChange(selectedOption, "milestone")
                }
                value={
                  milestonesOptions.find(
                    (milestone) => milestone.value === formData.milestone
                  ) || ""
                }
              />

              <InputBox
                label={"Transaction Date"}
                placeholder={"Enter Transaction Date"}
                type="date"
                name="transaction_date"
                onChange={handleChange}
                required
                value={formData.transaction_date || ""}
                disabled={isProcessing}
              />

              <InputBox
                label={"Total Contract Fee"}
                placeholder={"Enter Total Contract Fee"}
                type="text"
                name="total_contract_fee"
                onChange={handleChange}
                required
                value={formData.total_contract_fee || ""}
                disabled={isProcessing}
              />

              <InputBox
                label={"Billing Percentage"}
                placeholder={"e.g. 10% Upon Mobilization"}
                type="text"
                name="billing_percentage"
                onChange={handleChange}
                required
                value={formData.billing_percentage || ""}
                disabled={isProcessing}
              />
            </div>
          </div>

          {data ? null : (
            <div className="mt-4 flex items-center gap-4">
              <button
                className="blue-button"
                disabled={isProcessing}
                type="submit"
              >
                {isProcessing ? "Processing..." : "Proceed to step 2"}
              </button>

              <button
                className="border border-gray-700 rounded-lg px-2 py-1 "
                onClick={handleSaveDraft}
                disabled={isProcessing}
                type="button"
              >
                Save As Draft
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
