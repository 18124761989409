import React from "react";
import { motion } from "framer-motion";

function Modal({ children }) {
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-20">
      <motion.div
        initial={{ y: "10vh", opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ type: "tween", stiffness: 100 }}
        className="bg-white p-4 rounded-lg"
      >
        {children}
      </motion.div>
    </div>
  );
}

export default Modal;
