import React from "react";
import ProgressBar from "./ProgressBar";
import { useState } from "react";
import InputBox from "./InputBox";

function UploadFileInput({ name, placeholder, id, value, disabled, collName }) {
  const [file, setFile] = useState(null);
  const [inProcess, setInProcess] = useState(false);
  const [label, setLabel] = useState(value);
 
  

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setInProcess(true);
    } else {
      setFile(null);
      setInProcess(false);
    }
  };

  

  const handleLabel = (e) => {
    setLabel(e.target.value);
  };

  return (
    <>
      <form className="mt-4 ml-4 flex items-end">
        <div className="flex flex-col w-80 justify-end">
          <InputBox
            label={label}
            type="text"
            placeholder={placeholder}
            onChange={handleLabel}
            value={value}
            disabled={disabled || false}
            required
          />
          <input type="file" onChange={handleChange} name={name} 
          disabled={label?.length > 0 ? false : true}
          
          />
          {inProcess && (
            <ProgressBar
              file={file}
              setInProcess={setInProcess}
              collName={collName}
              id={id}
              fileLabel={label}
            />
          )}
        </div>
      </form>
    </>
  );
}

export default UploadFileInput;
