import React from "react";
import ProfilePhotoOnly from "../../components/ProfilePhotoOnly";
import { deleteDocById } from "../../Firebase/Firebase.utils";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

function TeamNoteCard({ note }) {
  const currentUser = useSelector((state) => state.userObject);
  const convertDate = (date) => {
    //convert with day, month, day, year and time
    const d = new Date(date);
    return `${d.toLocaleString("default", {
      month: "short",
    })} ${d.getDate()}, ${d.getFullYear()} ${d.toLocaleTimeString()}`;
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this note?")) {
      await deleteDocById("teamNotes", note.id);
      toast.success("Note deleted successfully");
    }
  };

  return (
    <div className="bubble relative">
      {note.createdBy === currentUser.email ? (
        <button
          className="text-xs text-gray-600 underline absolute top-2 right-2"
          onClick={handleDelete}
        >
          Delete
        </button>
      ) : null}

      <div>
        <ProfilePhotoOnly email={note.createdBy} />
        <div className="text-xs mt-2">{note.note}</div>
        <div className="text-gray-600 text-xs mt-4">
          {convertDate(note.createdAt)}
        </div>
      </div>
    </div>
  );
}

export default TeamNoteCard;
