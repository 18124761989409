import React from "react";
import InputBox from "../components/InputBox";
import { addNewDoc } from "../Firebase/Firebase.utils";

import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function CreateNewStudyForm() {
  const [formData, setFormData] = React.useState({});
  const [isProcessing, setIsProcessing] = React.useState(false);
  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const docObject = {
      ...formData,
      createdAt: new Date().toLocaleString(),
      createdBy: currentUser.email,
      pipeline_status: "10%",
      teamMembers: [currentUser.email],
      teamLead: currentUser.email,
    };

    await addNewDoc("researchStudies", docObject);
    toast.success("Study Created Successfully");
    navigate("/research");

    setIsProcessing(false);
  };

  const handleCancel = () => {
    // Redirect to the previous page
    window.history.back();
  };

  return (
    <div>
      <div>
        <form onSubmit={handleSubmit}>
          <InputBox
            label="Study Name"
            name="studyName"
            placeholder={"Enter Study Name"}
            type="text"
            onChange={handleChange}
          />
          <InputBox
            label="Client Name"
            name="clientName"
            placeholder={"Enter Client Name"}
            type="text"
            onChange={handleChange}
          />
          <InputBox
            label="Contact Number"
            name="contactNumber"
            placeholder={"Enter Contact Number"}
            type="text"
            onChange={handleChange}
          />
          <InputBox
            label="Client Email"
            name="clientEmail"
            placeholder={"Enter Client Email"}
            type="email"
            onChange={handleChange}
          />
          <div className="flex items-center gap-2 mt-4">
            <button className="blue-button" type="submit">
              {isProcessing ? "Processing..." : "Create Study"}
            </button>
            <button
              className="white-button"
              onClick={handleCancel}
              type="button"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
