import React from "react";
import { getDocData, setDocData } from "../Firebase/Firebase.utils";

function HomePage() {
  const [formData, setFormData] = React.useState({});
  const [homepageData, setHomepageData] = React.useState({});
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [editMode, setEditMode] = React.useState(false);

  React.useEffect(() => {
    getDocData("website", "homepage").then((data) => {
      setHomepageData(data);
      setFormData(data);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const createDataObject = (data) => {
    return {
      tagline: data.tagline,
      featuredProperties: data.featuredProperties,
      videoDescription: data.videoDescription,
    };
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setEditMode(!editMode);
    if (editMode) {
      if (window.confirm("Are you sure you want to save changes?")) {
        setIsSubmitting(true);
        const data = createDataObject(formData);
        await setDocData("website", "homepage", data);
        setHomepageData(formData);
        setIsSubmitting(false);
      } else {
        setFormData(homepageData);
      }
    }
  };

  return (
    <div className="text-sm bg-white p-2 rounded-xl mt-4">
      <form className="flex flex-col gap-2" onSubmit={handleSave}>
        <div className="flex items-center gap-2">
          <div className="w-32 md:w-80 ">Tag Line</div>
          <input
            type="text"
            placeholder="input tag line"
            className="px-4 py-2 rounded border w-full text-sm"
            name="tagline"
            onChange={handleChange}
            required
            value={formData.tagline}
            disabled={!editMode}
          />
        </div>

        <div className="flex items-center gap-2">
          <div className="w-32 md:w-80">Featured Properties</div>
          <textarea
            placeholder="input featured properties description line"
            className="px-4 py-2 rounded border w-full text-sm"
            name="featuredProperties"
            onChange={handleChange}
            required
            value={formData.featuredProperties}
            disabled={!editMode}
            rows={6}
          />
        </div>

        <div className="flex items-center gap-2">
          <div className="w-32 md:w-80">Video Description</div>
          <textarea
            placeholder="input video description line"
            className="px-4 py-2 rounded border w-full h-fit-content text-sm "
            name="videoDescription"
            onChange={handleChange}
            required
            value={formData.videoDescription}
            disabled={!editMode}
            rows="6"
          />
        </div>
        <div className="flex items-center gap-2">
          <button
            className="bg-leechiuBlue text-white px-4 py-2 rounded disabled:opacity-50 border border-leechiuBlue"
            disabled={isSubmitting}
          >
            {editMode ? "Save" : "Edit"}
          </button>
          {editMode && (
            <button
              className="border border-black px-4 py-2 rounded"
              onClick={() => {
                setEditMode(!editMode);
                setFormData(homepageData);
              }}
            >
              Cancel
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

export default HomePage;
