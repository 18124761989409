import React from "react";
import { setDocData } from "../Firebase/Firebase.utils";
import toast from "react-hot-toast";

function AddCardForm({
  page,
  setAddMode,
  cards,
  editCard,
  setEditCard,
  editIndex,
}) {
  const [formData, setFormData] = React.useState(
    editCard ? cards[editIndex] : {}
  );

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (editCard) {
      const newCards = [...cards];
      newCards[editIndex] = formData;
      const data = {
        cards: newCards,
      };
      await setDocData("website", page, data);
      setEditCard(false);
      setAddMode(false);
      toast.success("Card Updated Successfully");
      setFormData({});
      setIsSubmitting(false);
      return;
    }

    const data = {
      cards: cards ? [...cards, formData] : [formData],
    };
    await setDocData("website", page, data);
    setFormData({});
    setIsSubmitting(false);
    setAddMode(false);
    toast.success("Card Added Successfully");
  };

  return (
    <div className="relative p-4">
      <button
        onClick={() => {
          setEditCard(false);
          setAddMode(false);
        }}
        className="underline absolute top-0 right-2 text-sm"
      >
        Close
      </button>
      <div>
        <div className="uppercase text-leechiuBlue font-bold text-sm">
          Enter Card Details
        </div>
        <form onSubmit={handleSubmit} className="mt-4 space-y-2">
          <div className="flex items-start justify-center flex-col gap-4">
            <div className="flex flex-col">
              <label className="font-bold text-xs capitalize">title</label>
              <input
                className="border px-2 py-1 w-96 rounded-xl"
                type="text"
                placeholder="Enter Title of Card"
                onChange={handleChange}
                name="title"
                required
                value={formData.title}
              />
            </div>

            <div className="flex flex-col">
              <label className="font-bold text-xs capitalize">
                Description
              </label>
              <textarea
                className="border px-2 py-1 w-96 rounded-xl"
                rows={4}
                type="text"
                placeholder="Enter Description of Card"
                onChange={handleChange}
                name="description"
                required
                value={formData.description}
              />
            </div>
          </div>
          <div>
            <button
              className="bg-leechiuBlue px-4 py-2 rounded-lg border border-leechiuBlue text-white"
              type="submit"
            >
              {isSubmitting
                ? "Submitting..."
                : editCard
                ? "Update Card"
                : "Add Card"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddCardForm;
