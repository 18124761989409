import React from "react";

function Pipeline() {
  return (
    <div>
      <div className="header">Pipeline</div>
    </div>
  );
}

export default Pipeline;
