import React from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { getDocDataSnap } from "../Firebase/Firebase.utils";
import { useSelector } from "react-redux";

function AvatarName({ displayName }) {
  const [nameInitials, setNameInitials] = useState("");
  const [profilePhoto, setProfilePhoto] = useState("");

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const getUserPhoto = async () => {
      const res = await getDocDataSnap("users", currentUser.uid, (data) => {
        setProfilePhoto(data.profilePhoto);
      });
    };
    getUserPhoto();
  }, []);

  useEffect(() => {
    const createNameInitials = (name) => {
      const nameSplit = name.split(" ");

      if (nameSplit.length === 1) {
        return nameSplit[0][0];
      } else {
        const firstName = nameSplit[0];
        const lastName = nameSplit[1];

        return firstName[0] + lastName[0];
      }
    };
    setNameInitials(createNameInitials(displayName));
  }, []);

  return (
    <div>
      <Stack direction="row" spacing={2}>
        {profilePhoto ? (
          <Avatar
            sx={{ bgcolor: deepOrange[500] }}
            alt={displayName}
            src={profilePhoto}
          />
        ) : (
          <Avatar sx={{ bgcolor: deepOrange[500] }}>{nameInitials}</Avatar>
        )}
      </Stack>
    </div>
  );
}

export default AvatarName;
