import React, { useState, useEffect } from "react";
import { setDocData, getAllDocsSnap } from "../../Firebase/Firebase.utils";
import ProfilePhotoOnly from "../../components/ProfilePhotoOnly";

import toast from "react-hot-toast";

function TeamMembersForm({ listing, setEditMode }) {
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState(""); // State to hold user input
  const [filteredMembers, setFilteredMembers] = useState([]); // State for filtered team members
  const [formData, setFormData] = useState(listing); // State to hold form data
  const [selectedMember, setSelectedMember] = useState(null);
  const [teamLeader, setTeamLeader] = useState(listing.team_lead);

  useEffect(() => {
    const fetchUsers = async () => {
      const users = await getAllDocsSnap("users", (users) => {
        setTeamMembers(users);
        setLoading(false);
      });
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    // Filter team members based on the search term
    const filtered = teamMembers.filter((member) =>
      member.displayName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredMembers(filtered);
  }, [searchTerm, teamMembers]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleMemberSelect = (member) => {
    setSelectedMember(member);

    //check if member is already a member
    if (formData.team_members.includes(member.email)) {
      toast.error("Member is already part of the team");
      return;
    }

    setFormData({
      ...formData,
      team_members: [...formData.team_members, member.email],
    });

    // Optionally clear the search term after selection
    setSearchTerm("");
  };

  const handleMembersUpdate = () => {
    setDocData("listings", listing.id, formData);
    toast.success("Team members updated successfully");
    setEditMode(false);
  };

  const removeMember = (member) => {
    setFormData({
      ...formData,
      team_members: formData.team_members.filter((m) => m !== member),
    });
  };

  if (loading) {
    return <div>Loading</div>;
  }

  const handleTeamLeaderUpdate = (member) => {
    setTeamLeader(member);
    setFormData({
      ...formData,
      team_lead: member,
    });
  };

  return (
    <div className="relative w-[350px] min-h-[500px] overflow-hidden">
      <div
        className="absolute top-0 right-0 underline text-xs cursor-pointer"
        onClick={() => setEditMode(false)}
      >
        Close
      </div>
      <div className="pt-8 font-bold text-gray-600 text-sm mb-4 text-center">
        Set Team Members
      </div>
      <div className="relative">
        <form onSubmit={(e) => e.preventDefault()}>
          <input
            className="w-full p-2 border border-gray-300 rounded-md"
            type="text"
            placeholder="Search for team members"
            onChange={handleSearchChange}
          />
        </form>
        {searchTerm && filteredMembers.length > 0 && (
          <ul className="mt-2 border border-gray-300 rounded-md absolute z-10 bg-white top-12 w-full shadow-md">
            {filteredMembers.map((member) => (
              <li
                key={member.id} // Assuming each member has a unique id
                className="p-2 cursor-pointer hover:bg-gray-200"
                onClick={() => handleMemberSelect(member)}
              >
                {member.displayName}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div>
        {formData.team_members.map((member) => {
          return (
            <div
              key={member.id}
              className="flex items-center justify-between border-b border-gray-300 p-2"
            >
              <div>
                <div>{<ProfilePhotoOnly email={member} /> || member}</div>
                <button
                  className={`${
                    member === teamLeader
                      ? "text-xs text-leechiuOrange font-bold mt-2"
                      : "underline text-xs text-gray-600 mt-2"
                  }`}
                  onClick={() => handleTeamLeaderUpdate(member)}
                >
                  {member === teamLeader ? "Team Leader" : "Set as Team Leader"}
                </button>
              </div>
              <div
                className="text-xs text-red-600 cursor-pointer"
                onClick={() => removeMember(member)}
              >
                Remove
              </div>
            </div>
          );
        })}
      </div>
      <div className="mt-auto">
        <button
          className="px-4 py-2 bg-leechiuBlue rounded-lg text-white w-full mt-auto"
          onClick={handleMembersUpdate}
        >
          Update Team Members
        </button>
      </div>
    </div>
  );
}

export default TeamMembersForm;
