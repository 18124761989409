import React from "react";
import { CgWebsite } from "react-icons/cg";
import { FaNewspaper } from "react-icons/fa";
import { FaBlogger } from "react-icons/fa";
import { MdOutlineEventRepeat } from "react-icons/md";
import { RiGalleryView2 } from "react-icons/ri";
import { BiSolidVideos } from "react-icons/bi";
import { IoDocumentSharp } from "react-icons/io5";
import { IoIosSettings } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { MdInsights } from "react-icons/md";

function SideNavBar() {
  const [activeLink, setActiveLink] = React.useState("website-sections");
  const navigate = useNavigate();

  const navitems = [
    {
      name: "Website Sections",
      icon: <CgWebsite />,
      link: "website-sections",
    },
    {
      name: "Insights",
      icon: <MdInsights />,
      link: "insights",
    },
    {
      name: "News Articles",
      icon: <FaNewspaper />,
      link: "news",
    },
    {
      name: "Blogs",
      icon: <FaBlogger />,
      link: "blogs",
    },
    {
      name: "Events",
      icon: <MdOutlineEventRepeat />,
      link: "events",
    },
    {
      name: "Gallery",
      icon: <RiGalleryView2 />,
      link: "gallery",
    },
    {
      name: "Photos and Videos",
      icon: <BiSolidVideos />,
      link: "photos-and-videos",
    },
    {
      name: "Documents",
      icon: <IoDocumentSharp />,
      link: "documents",
    },
    {
      name: "Settings",
      icon: <IoIosSettings />,
      link: "settings",
    },
  ];

  return (
    <div className="space-y-4">
      <div
        className="
      w-fit 
      md:w-72  lg:w-96 bg-white p-2 rounded-xl"
      >
        <div
          className="
        hidden 
        md:flex
        text-leechiuOrange font-bold "
        >
          Choose from Options Below
        </div>
        <div>
          {navitems.map((item, index) => (
            <Link
              title={item.name}
              to={`/${item.link}`}
              key={index}
              className={`flex items-center gap-2 p-2 hover:bg-gray-200 cursor-pointer ${
                activeLink === item.name
                  ? "font-bold text-leechiuBlue"
                  : "text-gray-500"
              } `}
              onClick={() => setActiveLink(item.name)}
            >
              <div>{item.icon}</div>
              <div
                className="hidden 
              text-xs md:text-sm lg:text-base
              md:flex"
              >
                {item.name}
              </div>
            </Link>
          ))}
        </div>
      </div>

      <div
        className="
      w-fit hidden
    md:w-72 lg:w-96 md:flex bg-white p-2 rounded-xl h-80"
      >
        <div
          className="
       
        "
        >
          Another way to navigate
        </div>
      </div>
    </div>
  );
}

export default SideNavBar;
