import React from "react";
import { getDocDataSnap } from "../Firebase/Firebase.utils";

export default function useGetDocData({ collection, id }) {
  const [data, setData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const doc = await getDocDataSnap(collection, id, (doc) => {
        setData(doc);
        setIsLoading(false);
      });
    };
    fetchData();
  }, [collection, id]);

  return { data, isLoading };
}
