import React from "react";
import useFirestore from "../../Hooks/UseFireStore";
import { motion } from "framer-motion";
import Button from "../../components/Button";
import { useState } from "react";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  deleteDocById,
  deleteObjectByUrl,
  setDocData,
  getDocDataSnap,
} from "../../Firebase/Firebase.utils";
import { useEffect } from "react";

function PhotoGrid({ id, setSelectedImg }) {
  const { docs } = useFirestore(id);
  const [editMode, setEditMode] = useState(false);
  const [itemForDeletion, setItemForDeletion] = useState(null);
  const [primaryPhoto, setPrimaryPhoto] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPrimaryPhoto = async () => {
      try {
        const res = await getDocDataSnap("listings", id, (doc) => {
          setPrimaryPhoto(doc.primaryPhoto);
          setLoading(false);
        });
      } catch (error) {}
    };
    fetchPrimaryPhoto();
  }, []);

  const handleEdit = (e) => {
    e.preventDefault();
    setEditMode(!editMode);
  };

  const deletePhoto = async (id) => {
    const res = await deleteDocById("photos", id);
    return res;
  };

  const deleteObject = async (url) => {
    const res = await deleteObjectByUrl(url);
    return res;
  };

  const handleDeleteItem = (id, url) => {
    setItemForDeletion(id);
    if (window.confirm("Are you sure your want to delete this photo")) {
      deletePhoto(id);
      deleteObject(url);
    } else {
      return;
    }
  };

  const handleSetPrimaryPhoto = (doc) => {
    setDocData("listings", id, {
      primaryPhoto: doc.id,
      primaryPhotoUrl: doc.url,
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div>
        <Button label={editMode ? "Save" : "Edit"} onClick={handleEdit} />
        {/* photo area */}
        <div className="img-grid grid grid-cols-3 gap-4 mt-4 mb-4 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          {docs &&
            docs.map((doc) => (
              <div className="relative mt-4" key={doc.id}>
                <motion.div
                  layout
                  className=" img-wrap overflow-hidden h-0  px-0 relative opacity-0.75 border-2 border-gray-500 rounded-lg "
                  style={{ padding: "50%" }}
                  key={doc.id}
                  onClick={() => setSelectedImg(doc.url)}
                >
                  <img
                    className={`min-w-full min-h-full max-w-full absolute top-0 left-0 object-cover ${
                      editMode ? "animate-wiggle" : ""
                    }`}
                    src={doc.url}
                    alt="uploaded pic"
                  />
                </motion.div>
                {editMode && (
                  <button className="absolute -top-5 -right-5">
                    <RemoveCircleIcon
                      className="text-red-500 cursor-pointer"
                      onClick={() => handleDeleteItem(doc.id, doc.url)}
                    />
                  </button>
                )}

                <button onClick={() => handleSetPrimaryPhoto(doc)}>
                  {primaryPhoto === doc.id ? "" : "Set as primary photo"}
                </button>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default PhotoGrid;
