import React, { useEffect } from "react";
import {
  addNewDoc,
  storage,
  setDocData,
  getAllDocsSnap,
  deleteDocById,
} from "../Firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import InputBox from "../components/InputBox";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { FaCircleCheck } from "react-icons/fa6";
import { BiSolidErrorCircle } from "react-icons/bi";
import NoImage from "../assets/No_Image_Available.jpg";

function Insights() {
  const [addMode, setAddMode] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [isUrlPresent, setIsUrlPresent] = React.useState(false);
  const [url, setUrl] = React.useState("");
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [activeUpload, setActiveUpload] = React.useState("");
  const [assignedId, setAssignedId] = React.useState(v4());
  const [insights, setInsights] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [editMode, setEditMode] = React.useState(false);
  const [editId, setEditId] = React.useState("");

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const fetchInsights = async () => {
      const insights = await getAllDocsSnap("insights", (data) => {
        setInsights(data);
        setIsLoading(false);
      });
    };
    fetchInsights();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpload = async (e, fileName, id) => {
    const file = e.target.files[0];
    setIsSubmitting(true);
    const storageRef = ref(storage, `insights/${id}/${fileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    setActiveUpload(fileName);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        toast.error("Error uploading file");
        setIsSubmitting(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          setUrl(downloadURL);
          setIsSubmitting(false);
          setFormData((prev) => ({ ...prev, [fileName]: downloadURL }));
          console.log("Download URL", downloadURL);
          toast.success(`${fileName} Uploaded Successfully`);
        });
      }
    );
    setIsSubmitting(false);
  };

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    if (editMode) {
      const docObject = {
        ...formData,
        updatedAt: new Date().toISOString(),
        lastUpdatedBy: currentUser.email,
      };
      await setDocData("insights", editId, docObject);
      console.log("Edit ID", editId);
      console.log("Doc Object", docObject);

      setEditMode(false);
      setEditId("");
      setFormData({});
      setIsProcessing(false);
      toast.success("Insight Updated Successfully");
      return;
    }

    const docObject = {
      ...formData,
      published: false,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      user: currentUser.email,
    };
    await addNewDoc("insights", docObject);
    setIsProcessing(false);
    setAddMode(false);
    setFormData({});
    toast.success("Insights Added Successfully");
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleEditMode = (e, insight) => {
    if (editMode) {
      setEditMode(false);
      setEditId("");
      setFormData({});
      return;
    }
    setFormData(insight);
    setEditId(insight.id);
    setEditMode(!editMode);
  };

  const handlePublish = async (e, insight) => {
    const docObject = {
      ...insight,
      published: !insight.published,
      updatedAt: new Date().toISOString(),
      lastUpdatedBy: currentUser.email,
    };
    await setDocData("insights", insight.id, docObject);
    setEditId("");
    toast.success("Insight Published Successfully");
  };

  const handleDelete = async (e, id) => {
    if (window.confirm("Are you sure you want to delete this insight?")) {
      await deleteDocById("insights", id);
      toast.error("Insight Deleted Successfully");
    }
  };

  return (
    <div className="flex flex-col items-start justify-normal gap-4">
      <div className="bubble text-leechiuBlue font-bold">
        Insights Management
      </div>

      <div>
        <button
          className="bg-leechiuBlue text-white px-4 py-2 rounded border border-leechiuBlue"
          onClick={handleAddMode}
        >
          Add an Insights Report
        </button>
      </div>

      {addMode && (
        <div className="bubble">
          <form onSubmit={handleSubmit}>
            <InputBox
              label={"Title"}
              type={"text"}
              placeholder={"Enter Title"}
              required={true}
              onChange={handleChange}
              value={formData.title || ""}
              name={"title"}
            />

            <InputBox
              type={"date"}
              label={"Date"}
              name={"date"}
              onChange={handleChange}
              required={true}
              value={formData.date || ""}
            />

            <label className="text-leechiuBlue font-bold">Description</label>
            <textarea
              name="description"
              rows={"6"}
              className="border p-2 w-full rounded-xl"
              onChange={handleChange}
              value={formData.description || ""}
            />

            <button
              onClick={handleSubmit}
              className="bg-leechiuBlue text-white px-4 py-2 rounded border border-leechiuBlue mt-8"
            >
              {isProcessing ? "Processing..." : "Submit"}
            </button>
          </form>
        </div>
      )}

      <div className="bubble">
        <div className="text-leechiuBlue text-center font-bold mb-8">
          Insights Data
        </div>

        <div className="flex items-start justify-normal gap-4 flex-wrap">
          {insights.map((insight, index) => (
            <div
              className="w-full min-h-[500px] text-xs bg-gray-100 p-2 rounded-xl relative"
              key={index}
            >
              <div
                className={
                  insight?.published === true
                    ? "absolute top-0 left-0 p-2 rounded-xl bg-green-500 text-white"
                    : "absolute top-0 left-0 p-2 rounded-xl bg-red-500 text-white"
                }
              >
                {insight?.published === true ? "Published" : "Unpublished"}
              </div>
              <div className="text-right">
                <button
                  className="underline text-xs"
                  onClick={(e) => handleEditMode(e, insight)}
                >
                  {editId === insight.id ? "Close" : "Edit"}
                </button>

                <button
                  className="underline text-xs ml-2"
                  onClick={(e) => handleDelete(e, insight.id)}
                >
                  Delete
                </button>
              </div>

              <div className="text-leechiuBlue text-sm font-bold mt-8">
                <input
                  type="text"
                  className="w-full"
                  value={editId === insight.id ? formData.title : insight.title}
                  disabled={editId !== insight.id}
                  name="title"
                  onChange={handleChange}
                />
              </div>
              <input
                type="date"
                className="w-full mt-4"
                value={editId === insight.id ? formData.date : insight.date}
                disabled={editId !== insight.id}
                name="date"
                onChange={handleChange}
              />
              <textarea
                rows="6"
                className="w-full mt-4"
                value={
                  editId === insight.id
                    ? formData.description
                    : insight.description
                }
                disabled={editId !== insight.id}
                name="description"
                onChange={handleChange}
              />
              <div className="">
                <div className="flex items-center gap-4 text-sm font-bold mt-4">
                  {insight.pdf ? (
                    <FaCircleCheck className="text-green-500" />
                  ) : (
                    <BiSolidErrorCircle className="text-red-500" />
                  )}
                  <a href={insight.pdf} target="_blank">
                    PDF report
                  </a>
                </div>
              </div>
              <div className="w-[300px] mt-4">
                <img src={insight.image || NoImage} alt="" />
              </div>
              {editId === insight.id && (
                <div>
                  <div className="flex flex-col mt-4">
                    <label
                      htmlFor=""
                      className="text-xs font-bold text-gray-600"
                    >
                      Image
                    </label>
                    <input
                      type="file"
                      name="image"
                      onChange={(e) => handleUpload(e, "image", insight.id)}
                    />
                  </div>

                  <div className="flex flex-col mt-4">
                    <label
                      htmlFor=""
                      className="text-xs font-bold text-gray-600"
                    >
                      PDF Report
                    </label>
                    <input
                      type="file"
                      name="pdf"
                      onChange={(e) => handleUpload(e, "pdf", insight.id)}
                    />
                  </div>
                  <div className="space-x-2">
                    <button
                      className=" bg-leechiuBlue text-white px-4 py-2 rounded border border-leechiuBlue mt-4"
                      onClick={handleSubmit}
                    >
                      {isProcessing ? "Processing..." : "Save"}
                    </button>
                    <button
                      className=" bg-leechiuBlue text-white px-4 py-2 rounded border border-leechiuBlue mt-4"
                      onClick={(e) => handlePublish(e, insight)}
                    >
                      {insight.published === true ? "Unpublish" : "Publish"}
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        <div></div>
      </div>
    </div>
  );
}

export default Insights;
