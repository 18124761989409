import React, { useEffect } from "react";
import Button from "../../components/Button";
import InputBox from "../../components/InputBox";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  addNewDoc,
  getAllBuildingSpaces,
  setDocData,
  deleteDocById,
} from "../../Firebase/Firebase.utils";
import { toast } from "react-toastify";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export default function Inventory() {
  const [formData, setFormData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [inventory, setInventory] = useState([]);
  const [totalSpace, setTotalSpace] = useState(0);
  const [editMode, setEditMode] = useState(false);

  const id = useParams();
  const currentUser = useSelector((state) => state.userObject);

  // get all inventory
  useEffect(() => {
    getAllBuildingSpaces("inventory", id.id, (data) => {
      // arrange data in ascending order of floor number
      data.sort((a, b) => a.floorNumber - b.floorNumber);
      setInventory(data);
    });
  }, []);

  // get total space of all units
  useEffect(() => {
    const totalSpaceOfUnits = inventory.reduce((acc, item) => {
      // convert area to number
      const area = parseFloat(item.area);
      return acc + area;
    }, 0);
    setTotalSpace(totalSpaceOfUnits);
    setDocData("masterlist", id.id, { totalAvailableSpace: totalSpaceOfUnits });
  }, [inventory]);

  // create object to be added to firebase
  const createObject = (data) => {
    return {
      ...data,
      buildingId: id.id,
      createdBy: currentUser.email,
      createdOn: new Date(),
      updatedBy: currentUser.email,
      updatedOn: new Date(),
    };
  };

  const handleAddInventory = (e) => {
    e.preventDefault();
    if (editMode) {
      handleUpdateInventory();
      setEditMode(false);
    } else {
      handleCreateInventory();
    }
    setFormData({});
  };

  const handleCreateInventory = () => {
    setIsProcessing(true);
    const data = createObject(formData);
    addNewDoc("inventory", data)
      .then((res) => {
        toast.success("Inventory added successfully");
        setShowForm(false);
        setIsProcessing(false);
      })
      .catch((err) => {
        toast.error(err.message);
        setIsProcessing(false);
      });
  };

  const handleUpdateInventory = () => {
    setIsProcessing(true);
    const data = createObject(formData);
    setDocData("inventory", formData.id, data)
      .then((res) => {
        toast.success("Inventory updated successfully");
        setShowForm(false);
        setIsProcessing(false);
      })
      .catch((err) => {
        toast.error(err.message);
        setIsProcessing(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleShowForm = () => {
    setShowForm(!showForm);
  };

  const handleEdit = (e, item) => {
    setEditMode(true);
    setFormData(item);
    setShowForm(true);
  };

  const handleDelete = (e, item) => {
    if (window.confirm("Are you sure you want to delete this inventory?")) {
      deleteDocById("inventory", item.id)
        .then((res) => {
          toast.success("Inventory deleted successfully");
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  };

  // headers and data for form and table
  const tableHeadersAndData = [
    {
      header: "Floor",
      data: "floorNumber",
      required: true,
      placeholder: "e.g. 6",
    },
    {
      header: "Unit",
      data: "unitNumber",
      required: true,
      placeholder: "e.g. 601",
    },
    {
      header: "Area in sqm",
      data: "area",
      required: true,
      placeholder: "e.g. 1000",
      type: "number",
      formatStringToFloat: true,
    },
    {
      header: "Parking Allocation",
      data: "parkingAllocation",
      required: true,
      placeholder: "e.g. 1:100",
    },
    {
      header: "Quoted Rent",
      data: "quotedRent",
      required: true,
      type: "number",
      placeholder: "e.g. 1000",
    },
    {
      header: "Building Dues",
      data: "buildingDues",
      required: true,
      placeholder: "e.g. 160",
      type: "text",
    },
    {
      header: "Handover Condition",
      data: "handoverCondition",
      required: true,
      placeholder: "e.g. As is",
    },
    {
      header: "AC Charge",
      data: "acCharge",
      required: true,
      placeholder: "e.g. 150",
      type: "text",
    },
    {
      header: "Parking Rate /month",
      data: "parkingRate",
      required: false,
      placeholder: "e.g. 5000",
      type: "number",
    },
    {
      header: "Availability",
      data: "availabilityDate",
      required: true,
      placeholder: "e.g. 2024",
    },
    {
      header: "Remarks",
      data: "remarks",
      required: false,
      placeholder: "e.g. Good for BPO company",
    },
  ];

  // fields that need to be converted to float
  const fieldsForConversion = ["area", "parkingRate", "quotedRent"];

  return (
    <div className="flex flex-col">
      <div className="header mb-4">Inventory Information</div>
      <div className="">
        <Button
          className="blue-button"
          label={showForm ? "Hide Form" : "Add Available Space"}
          onClick={handleShowForm}
        />

        {/* form component */}
        {showForm && (
          <form
            className="flex flex-col gap-4 flex-wrap max-w-5xl mt-4 p-2 bg-white rounded-lg shadow-lg"
            onSubmit={handleAddInventory}
          >
            <h4>Add an Available Space/Inventory</h4>
            <div className="flex items-center gap-4 flex-wrap ">
              {
                // form inputs
                tableHeadersAndData.map((item) => (
                  <InputBox
                    key={item.header}
                    label={item.header}
                    name={item.data}
                    value={formData[item.data] || ""}
                    onChange={handleChange}
                    required={item.required}
                    placeholder={item.placeholder}
                    type={item.type}
                  />
                ))
              }
            </div>
            <Button
              label={isProcessing ? "Processing..." : "Add"}
              type="submit"
              disabled={isProcessing}
            />
          </form>
        )}
      </div>

      <div className="mt-4 bg-white p-2 rounded-lg shadow-md">
        <div className="uppercase font-bold text-gray-500 mb-4 text-sm">
          Available Spaces
        </div>

        {/* table component */}
        <div className="">
          <table className="w-full bg-white border border-gray-300 text-xs">
            <thead>
              <tr>
                {
                  // table headers
                  tableHeadersAndData.map((item) => (
                    <th
                      key={item.header}
                      className="border border-gray-300 px-2 py-1 bg-indigo-800 text-white"
                    >
                      {item.header}
                    </th>
                  ))
                }
              </tr>
            </thead>
            <tbody className="text-center">
              {inventory.map((item, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-100" : "bg-white"
                  } hover:bg-gray-200 hover:cursor-pointer group`}
                >
                  {
                    // table data
                    tableHeadersAndData.map((header) => (
                      <td
                        key={header.header}
                        className="border border-gray-300 px-2 py-1"
                      >
                        {item[header.data] === undefined
                          ? item[header.data]
                          : fieldsForConversion.includes(header.data)
                          ? parseFloat(
                              item[header.data].replace(/,/g, "")
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : item[header.data]}
                      </td>
                    ))
                  }
                  <td className="border border-gray-300 px-2 py-1">
                    <div className="flex justify-center gap-2">
                      <EditIcon
                        className="cursor-pointer text-blue-400"
                        onClick={(e) => handleEdit(e, item)}
                      />
                      <DeleteIcon
                        className="cursor-pointer text-red-400"
                        onClick={(e) => handleDelete(e, item)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="font-bold mt-4 text-gray-600 text-sm">
            Total Available - {totalSpace.toLocaleString()} square meters
          </div>
        </div>
      </div>
    </div>
  );
}
