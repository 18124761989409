import React from "react";
import { getDocData, getDocDataSnap } from "../../Firebase/Firebase.utils";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import UserAvatar from "../../components/UserAvatar";
import { loadUser } from "../../store/userSlice";
import { useSelector } from "react-redux";
import { setDocData } from "../../Firebase/Firebase.utils";
import { current } from "@reduxjs/toolkit";
import { v4 } from "uuid";
import { useSearchParams } from "react-router-dom";

function DiscussionDetails() {
  const [requirement, setRequirement] = useState({});
  const [loading, setLoading] = useState(true);
  const id = useParams();
  const [comment, setComment] = useState("");
  const [filteredComments, setFilteredComments] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const activeSearchParam = searchParams.get("link");

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const getRequirement = async () => {
      const res = await getDocDataSnap(activeSearchParam, id.id, (data) => {
        setRequirement(data);
        setFilteredComments(data.comments);
        setLoading(false);
      });
    };
    getRequirement();
  }, []);

  const createCommentObject = () => {
    const commentObject = {
      comment: comment,
      createdBy: currentUser.email,
      createdAt: new Date(),
      commentId: v4(),
    };
    return commentObject;
  };

  const handleChange = (e) => {
    setComment(e.target.value);
  };

  const handlePost = (e) => {
    const addCommentToRequirement = async () => {
      try {
        setIsProcessing(true);
        const res = await setDocData(activeSearchParam, id.id, {
          comments: [...requirement.comments, createCommentObject()],
        });
        if (res) {
          setComment("");
          setIsProcessing(false);
        }
      } catch (error) {
        setIsProcessing(false);
      }
    };
    addCommentToRequirement();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleDelete = (e, commentId) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete this comment?")) {
      const filteredComments = requirement.comments.filter(
        (comment) => comment.commentId !== commentId
      );
      setFilteredComments(filteredComments);
      setDocData(activeSearchParam, id.id, { comments: filteredComments });
    } else {
      return;
    }
  };

  const renderComment = (comment, index) => {
    const parts = comment.comment.split(/(https?:\/\/[^\s]+)/);

    return (
      <div
        key={index}
        className="flex items-start gap-2 text-sm text-gray-700 border-l-2 rounded-bl-2xl border-orange-500 px-2 w-full max-w-5xl ml-4"
      >
        <div>
          <UserAvatar email={comment.createdBy} />
        </div>
        <div className="flex flex-col items-start">
          <div className="bg-gray-200 rounded-xl p-2">
            {parts.map((part, i) => {
              if (i % 2 === 0) {
                return part;
              } else {
                return (
                  <a
                    key={i}
                    href={part}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {part}
                  </a>
                );
              }
            })}
          </div>
          {currentUser.email === comment.createdBy && (
            <button
              className="text-xs text-gray-500 px-2 font-semibold"
              onClick={(e) => {
                handleDelete(e, comment.commentId);
              }}
            >
              Delete
            </button>
          )}
        </div>
      </div>
    );
  };

  const handleBack = () => {
    window.history.back();
  };

  return (
    <div className="discussion">
      <div className="mb-4 ">
        <button className="blue-button" onClick={handleBack}>
          Back
        </button>
      </div>
      <div className="flex flex-col gap-4 items-start justify-start bg-white rounded-lg shadow p-4 max-w-5xl">
        <div className="flex items-center gap-2 text-sm text-gray-700">
          {" "}
          <UserAvatar email={requirement.createdBy} />
          <div className="font-semibold">{requirement?.description}</div>
        </div>
        <div className="text-sm">Comments</div>
        {filteredComments.map((comment, index) =>
          renderComment(comment, index)
        )}
        <button className="text-xs text-gray-600">Comment</button>
        <div className="w-full flex items-center gap-4">
          <textarea
            className="w-full max-w-5xl h-12 p-3 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
            type="text"
            placeholder="Post your comment here"
            name="comment"
            onChange={handleChange}
            value={comment}
          ></textarea>
          <button className="text-xs" onClick={handlePost}>
            {isProcessing ? "Posting..." : "Post"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default DiscussionDetails;
