import React from "react";

function BuildingDetailsTable({ formData, tableFields }) {
  const convertFirebaseTimeStamp = (timestamp) => {
    //convert with date and time
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleString();
  };
  return (
    <div className="max-w-5xl text-sm">
      <div className="bubble space-y-2">
        {tableFields.map((field, index) => (
          <div
            key={index}
            className="flex items-start justify-normal gap-4 flex-wrap w-[350px] lg:w-[1080px] border-b border-gray-200 py-2"
          >
            <div className="w-60 text-gray-600 font-semibold">
              {field.label}
            </div>
            <div className="w-96 overflow-hidden text-ellipsis">
              {formData[field.value]}
            </div>
          </div>
        ))}
      </div>

      <div className="">
        <div className="mt-4">Last Updated By:</div>
        <div className="">
          {formData.lastUpdatedBy && (
            <div className="text-xs text-gray-600">
              <div className="text-sm text-gray-500">
                {formData.lastUpdatedBy || formData.createdBy}
              </div>
              {formData.lastUpdatedOn && (
                <div className="text-xs text-gray-400">
                  {formData.lastUpdatedOn}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BuildingDetailsTable;
