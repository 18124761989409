import React from "react";

function InputBox({ label, required, placeholder, type, ...otherprops }) {
  return (
    <div className="flex flex-col w-80 text-sm">
      <label
        className="text-xs font-bold capitalize text-gray-600"
        {...otherprops}
      >
        {label}
      </label>
      <input
        className="disabled:text-gray-800 disabled:bg-gray-200 w-80 px-4 py-2 border rounded-md border-gray-400 focus:outline-none focus:border-blue-500"
        type={type}
        placeholder={placeholder}
        required={required}
        {...otherprops}
      />
    </div>
  );
}

export default InputBox;
