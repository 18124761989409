import React from "react";
import app, { getAllDocsSnap, setDocData } from "../../Firebase/Firebase.utils";
import { useEffect, useState } from "react";
import { Select } from "@mui/material";
import InputBoxSelect from "../../components/InputBoxSelect";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";

function FinanceApproval() {
  const [financeApproval, setFinanceApproval] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredList, setFilteredList] = useState([]);
  const [activePill, setActivePill] = useState("all");
  const [originalList, setOriginalList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchAllBillings = async () => {
      const res = await getAllDocsSnap("billing", (data) => {
        //arrange by date approved
        data.sort((a, b) => {
          return (
            new Date(b.created_at.toDate()) - new Date(a.created_at.toDate())
          );
        });

        //filter only approved status
        const approved = data.filter((item) => item.status === "approved");
        setFinanceApproval(approved);
        setOriginalList(data);
        setFilteredList(data);
        setLoading(true);
      });
    };
    fetchAllBillings();
  }, []);

  const pillStyle =
    "px-4 py-1 rounded-full text-xs border border-leechiuOrange";
  const activePillStyle =
    "bg-orange-500 rounded-full border px-4 py-1 text-white text-xs";

  const handleFilterPill = (status) => {
    setActivePill(status);
    if (status === "all") {
      setFilteredList(originalList);
    } else if (status === "all pending") {
      const filtered = financeApproval.filter(
        (item) =>
          item.finance_status === null ||
          item.finance_status === "" ||
          item.finance_status === undefined ||
          item.finance_status === "for fixing" ||
          item.finance_status === "finance checking"
      );
      setFilteredList(filtered);
    } else {
      const filtered = financeApproval.filter(
        (item) => item.finance_status === status
      );
      setFilteredList(filtered);
    }
  };

  const financeOptions = [
    { value: "preparing billing", label: "preparing billing" },
    { value: "bill prepared", label: "bill prepared" },
    { value: "billing sent", label: "billing sent" },
    { value: "collected", label: "collected" },
  ];

  const handleUpdateStatus = (e) => {};

  const handleSelectStatus = (item, e) => {
    const updateBillStatus = async () => {
      const res = await setDocData("billing", item.id, {
        financeStatus: e.value,
      });
    };
    updateBillStatus();
    setActivePill("approved");
  };

  const handleNavigate = (id) => {
    navigate(`/financeView/${id}`);
  };

  return (
    <div>
      <div className="mb-8 font-bold">Billing List with Status</div>
      <div className="flex items-center gap-2">
        <button
          className={activePill === "all" ? activePillStyle : pillStyle}
          onClick={() => handleFilterPill("all")}
        >
          All
        </button>
        <button
          onClick={() => handleFilterPill("all pending")}
          className={activePill === "all pending" ? activePillStyle : pillStyle}
        >
          Finance Pending
        </button>
        <button
          onClick={() => handleFilterPill("billing preparation")}
          className={
            activePill === "billing preparation" ? activePillStyle : pillStyle
          }
        >
          Bill Preparing
        </button>
        <button
          onClick={() => handleFilterPill("billing ready")}
          className={
            activePill === "billing ready" ? activePillStyle : pillStyle
          }
        >
          Bill Ready
        </button>

        <button
          onClick={() => handleFilterPill("billing sent")}
          className={
            activePill === "billing sent" ? activePillStyle : pillStyle
          }
        >
          Bill Sent
        </button>

        <button
          onClick={() => handleFilterPill("collected")}
          className={activePill === "collected" ? activePillStyle : pillStyle}
        >
          Collected
        </button>
      </div>

      <div>
        {filteredList.map((item) => (
          <div
            className="mt-4 text-xs bg-white p-2 rounded-xl shadow-lg 
            hover: cursor-pointer hover:shadow-xl transition duration-300 ease-in-out hover:bg-gray-100
            "
            onClick={() => handleNavigate(item.id)}
          >
            <div className="flex items-center gap-2">
              <div className="text-sm font-semibold">{item.client_name}</div>
              <div>{item.created_at.toDate().toLocaleString()}</div>
              <div
                className={
                  item.billing_type === "lease"
                    ? "bg-green-200 text-gray-800 px-2 py-1 rounded-full"
                    : "bg-blue-200 text-gray-800 px-2 py-1 rounded-full"
                }
              >
                {item.billing_type}
              </div>
            </div>
            <div>Approver Status: {item.status}</div>

            <div>Finance Status: {item.finance_status}</div>
            <div>Php {parseFloat(item.amount).toLocaleString()}</div>
            <div>Requested by: {item.created_by}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FinanceApproval;
