import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userObject: [],
  // listingObject: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    /*     increment: (state) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.value += 1
      },
      decrement: (state) => {
        state.value -= 1
      }, */
    loadUser: (state, action) => {
      state.userObject = action.payload;
    },
    // loadListing: (state, action) => {
    //   state.listingObject = action.payload;
    // },
  },
});

export const { loadUser, loadListing } = userSlice.actions;

export default userSlice.reducer;
