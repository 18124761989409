import React from "react";
import {  useState } from "react";
import { makatiBuildings } from "../../data/MakatiBuildings";
import { uploadBatchDocs } from "../../Firebase/Firebase.utils";
import InputBox from "../../components/InputBox";

import Button from "../../components/Button";
import * as XLSX from "xlsx";


function Upload() {
  const [file, setFile] = useState(null);
  const [jsonData, setJsonData] = useState("");
  const [collection, setCollection] = useState("");
  const [message, setMessage] = useState("");
  const [conversionSuccess, setConversionSuccess] = useState(false);

  const handleConvert = (e) => {
    e.preventDefault();
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);

        const res = JSON.stringify(json, null, 2);
        setJsonData(res);
        setConversionSuccess(true);
        setMessage("Conversion Successful, do you want to upload the data?");
      };
      reader.readAsBinaryString(file);
    }
  };

  const handleUpload = () => {
    if (jsonData) {
      uploadBatchDocs(collection, JSON.parse(jsonData));
      setMessage("Data uploaded successfully.");
    }
  };

  return (
    <div className="">
      <div>
        <input
          className="mb-8"
          type="file"
          accept=".xls,.xlsx"
          onChange={(e) => setFile(e.target.files[0])}
        />
        <form className="flex flex-col gap-2">
          <Button label="Convert" onClick={handleConvert} />
        </form>
        <pre>{message}</pre>

        {
          <InputBox
            label="Select Collection"
            options={makatiBuildings}
            onChange={(e) => setCollection(e.target.value)}
          />
        }

        {conversionSuccess && (
          <Button label="Upload Data" onClick={handleUpload}>
            Upload
          </Button>
        )}
      </div>
    </div>
  );
}

export default Upload;
