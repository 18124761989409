import React from "react";
import ReactDOM from "react-dom";
import { motion } from "framer-motion";

function PhotoViewModal({ selectedImg, setSelectedImg }) {
  const handleClick = (e) => {
    if (e.target.classList.contains("modal-bg")) {
      setSelectedImg(null);
    }
  };

  return ReactDOM.createPortal(
    <div
      className="modal-bg fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"
      onClick={handleClick}
    >
      <motion.div
        initial={{ y: "100vh" }}
        animate={{ y: 0 }}
        className=" modal bg-white lg:w-1/2 w-screen p-4 flex justify-center items-center rounded-lg "
      >
        <img src={selectedImg} alt="enlarged pic" />
      </motion.div>
    </div>,
    document.body
  );
}

export default PhotoViewModal;
