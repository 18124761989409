import React, { useEffect } from "react";
import { getAllDocs } from "../Firebase/Firebase.utils";
import ProfilePhotoOnly from "../components/ProfilePhotoOnly";
import { toast } from "react-hot-toast";
import { setDocData } from "../Firebase/Firebase.utils";
import { useSelector } from "react-redux";

function AgentAssignmentForm({
  id,
  teamMembers,
  setTeamMembers,
  handleAddMode,
  handleSave,
}) {
  const [users, setUsers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [teamMemberArray, setTeamMemberArray] = React.useState(
    teamMembers || []
  );
  const currentUser = useSelector((state) => state.userObject);
  const [isProcessing, setIsProcessing] = React.useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setIsLoading(true);
        const users = await getAllDocs("users");

        //filter users where role includes agent

        setUsers(users);
        setIsLoading(false);
      } catch (error) {}
    };
    fetchUsers();
  }, []);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectUser = (user) => {
    //check if user is already in the team
    if (teamMemberArray.includes(user.email)) {
      toast.error("User is already in the team");
      return;
    }

    setTeamMemberArray([...teamMemberArray, user.email]);

    setSearchTerm("");
  };

  if (isLoading) {
    return <div className="small-form">Loading...</div>;
  }

  const handleSaveMembers = async () => {
    setIsProcessing(true);
    const docObject = {
      teamMembers: teamMemberArray,
      lastUpdatedBy: currentUser.email,
      lastUpdatedOn: new Date().toLocaleString(),
    };

    try {
      await setDocData("masterlist", id, docObject);
      setIsProcessing(false);
      handleAddMode();
      toast.success("Team members updated");
    } catch (error) {
      toast.error(error.message);
      setIsProcessing(false);
    }
    setIsProcessing(false);
  };

  return (
    <div className="small-form relative z-1000">
      <div className="header">Team Assignment</div>
      <button className="close-button" onClick={handleAddMode}>
        Cancel
      </button>

      <div className="flex flex-col">
        <label className="text-gray-600 font-bold text-xs">
          Search of an Agent
        </label>
        <div className="relative">
          <input
            className="border border-gray-300 p-2 rounded"
            type="search"
            placeholder="Search name"
            onChange={handleChange}
            value={searchTerm}
          />
          {searchTerm.length > 0 && (
            <div className="absolute bg-white w-full border border-gray-300">
              {users
                .filter((user) =>
                  user.displayName
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                )
                .map((user) => (
                  <div
                    key={user.id}
                    className="p-2 cursor-pointer hover:bg-gray-100 capitalize"
                    onClick={() => handleSelectUser(user)}
                  >
                    {user.displayName.toLowerCase()}
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-4 mt-8">
        {teamMemberArray.map((member) => (
          <div key={member} className="flex items-center gap-4">
            <div>
              <ProfilePhotoOnly email={member} />
            </div>
            <button
              className="underline text-xs text-red-500"
              onClick={() =>
                setTeamMemberArray(
                  teamMemberArray.filter((item) => item !== member)
                )
              }
            >
              Remove
            </button>
          </div>
        ))}
      </div>

      <div className="absolute w-full bottom-0">
        <button
          className="blue-button disabled:opacity-50"
          onClick={handleSaveMembers}
          disabled={isProcessing}
        >
          {isProcessing ? "Processing..." : "Save"}
        </button>
      </div>
    </div>
  );
}

export default AgentAssignmentForm;
