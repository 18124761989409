import React from "react";

function FlyerViewer({ listing }) {
  const file = listing.files["Flyer"];

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <object
        data={file}
        type="application/pdf"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <embed
          src={file}
          type="application/pdf"
          style={{ width: "100%", height: "100%" }}
        />
      </object>
    </div>
  );
}

export default FlyerViewer;
