import React, { useState } from "react";
import { deleteDocById } from "../../Firebase/Firebase.utils";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

function DeleteListingForm({ id, setShowModal }) {
  const [keyword, setKeyword] = useState("");
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setKeyword(value);
  };

  const handleDelete = async () => {
    setProcessing(true);
    if (keyword === "DELETE") {
      try {
        await deleteDocById("listings", id);
        toast.success("Listing deleted successfully");
        setProcessing(false);
        navigate("/all-listings");
      } catch (error) {
        setProcessing(false);
      }
    } else {
      toast.error("Keyword does not match");
    }
    setProcessing(false);
  };

  const handleClose = () => {
    setShowModal(false);
  };
  return (
    <div className="min-w-[350px]">
      <div className="text-right">
        <button className="underline text-sm text-right" onClick={handleClose}>
          Close
        </button>
      </div>
      <div className="mt-8 font-semibold">
        Are you sure you want to delete this listing?
      </div>
      <div>Enter the keyword below to continue </div>
      <div className="mt-8">
        <input
          className="border border-gray-300 p-2 w-full  rounded-lg"
          type="text"
          placeholder='Enter the word "DELETE" to continue'
          name="keyword"
          onChange={handleChange}
        />
      </div>

      <button
        className="bg-red-500 text-white p-2 rounded-lg mt-2 w-full"
        onClick={handleDelete}
      >
        {processing ? "Processing..." : "Delete Listing"}
      </button>
    </div>
  );
}

export default DeleteListingForm;
