import React from "react";

function Modal({ children }) {
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-20">
      <div className="bg-white rounded-xl p-2">{children}</div>
    </div>
  );
}

export default Modal;
