import React from "react";
import InputBox from "../components/InputBox";

function BuildingDetailsForm({
  formData,
  handleChange,
  isEditing,
  formFields,
}) {
  return (
    <div className="max-w-5xl">
      {isEditing && (
        <form>
          <div className="flex items-center gap-2 flex-wrap">
            {formFields.map((field, index) => (
              <InputBox
                key={index}
                label={field.label}
                placeholder={field.placeholder}
                name={field.value}
                onChange={handleChange}
                required={field.required}
                value={formData[field.value] || ""}
                disabled={field.disabled}
                type={field.inputType}
              />
            ))}
          </div>
        </form>
      )}
    </div>
  );
}

export default BuildingDetailsForm;
