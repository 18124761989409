import React from "react";
import Divider from "@mui/material/Divider";
import { Link, NavLink } from "react-router-dom";

function SidebarOption({ link, label, children }) {
  return (
    <div className="px-4 py-1">
      <div className="sidebar-option flex items-center justify-normal">
        <NavLink to={link} className="flex items-center gap-4 text-sm">
          {children}
          {label}
        </NavLink>
      </div>
    </div>
  );
}

export default SidebarOption;
