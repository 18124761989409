import React, { useEffect } from "react";
import emptyPicture from "../assets/emptydisplay.png";
import { useSelector } from "react-redux";
import InputBox from "../components/InputBox";
import Button from "../components/Button";
import { setDocData, getDocDataSnap } from "../Firebase/Firebase.utils";
import { useRef } from "react";
import { useState } from "react";
import ProfilePhotoProgress from "../components/ProfilePhotoProgress";

function MyProfile({ userProp }) {
  const currentUser = useSelector((state) => state.userObject);
  const [isLoading, setIsLoading] = React.useState(true);
  const [formData, setFormData] = React.useState({});
  const [editMode, setEditMode] = React.useState(false);
  const [file, setFile] = useState(null);
  const [inProcess, setInProcess] = useState(false);
  const [error, setError] = useState(null);

  const inputref = useRef();

  useEffect(() => {
    const getUser = async () => {
      const res = getDocDataSnap("users", currentUser.uid, (data) => {
        setFormData(data);
        setIsLoading(false);
      });
    };
    getUser();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleEditMode = (e) => {
    e.preventDefault();
    setEditMode(!editMode);
    if (editMode) {
      const processData = async () => {
        const res = setDocData("users", currentUser.uid, formData);
      };
      processData();
    }
  };

  const types = ["image/png", "image/jpeg"];

  const handleUpload = (e) => {
    let selected = e.target.files[0];

    if (selected && types.includes(selected.type)) {
      setFile(selected);
      setInProcess(true);
      setError("");
    } else {
      setFile(null);
      setInProcess(false);
      setError("Please select an image file (png or jpg)");
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col bubble">
      <div style={{ display: "none" }}>
        <input
          type="file"
          ref={inputref}
          onChange={handleUpload}
          accept="image/png, image/jpeg"
        />
      </div>
      <div className="w-full flex flex-wrap items-center h-40 gap-2">
        <div className="h-full w-40 relative">
          <img
            className=" h-full w-full rounded-full object-cover cursor-pointer"
            src={formData.profilePhoto || emptyPicture}
            alt="click to upload"
            onClick={() => inputref.current.click()}
          />
          {!formData.profilePhoto && (
            <div
              onClick={() => inputref.current.click()}
              className="absolute top-0 left-0 h-40 w-40 bg-black bg-opacity-50 rounded-full flex items-center justify-center"
            >
              <span className="text-white">Click to upload</span>
            </div>
          )}
          {inProcess && (
            <ProfilePhotoProgress
              file={file}
              id={currentUser.uid}
              setInProcess={setInProcess}
            />
          )}
        </div>
        <div>
          <div className="h-full">{formData.displayName}</div>
          <div>
            <input
              type="text"
              value={formData.position}
              disabled={!editMode}
              className={
                editMode ? "border px-2 py-1 rounded-lg" : "disabled:bg-white"
              }
              onChange={handleChange}
              name="position"
            />
          </div>
          <div className="h-full">{formData.department}</div>
          <div className="flex flex-col items-start mt-4 text-xs">
            <label className="font-bold">Status Message: </label>
            <textarea
              rows={3}
              className={
                editMode
                  ? "border px-2 py-1 rounded-lg w-96 h-18 resize-none"
                  : "disabled:bg-white w-96 h-18 resize-none"
              }
              type="text"
              value={formData.statusMessage}
              disabled={!editMode}
              onChange={handleChange}
              name="statusMessage"
            />
          </div>
        </div>
      </div>

      <div className="mt-8">
        <form>
          <div className="flex flex-wrap items-start justify-start gap-2 ">
            <InputBox
              label="Full Name"
              value={formData.displayName || ""}
              disabled={true}
            />

            <InputBox
              label="Department"
              value={formData.department || ""}
              disabled={true}
            />

            <InputBox
              label="Position"
              value={formData.position || ""}
              disabled={!editMode}
              name="position"
              onChange={handleChange}
            />

            <InputBox
              label="REBL/SP License Number"
              value={formData.rebl || ""}
              disabled={!editMode}
              name="rebl"
              onChange={handleChange}
            />

            <InputBox
              label="Expiration Date"
              value={formData.reblExpiry || ""}
              disabled={!editMode}
              name="reblExpiry"
              onChange={handleChange}
              type={"date"}
            />

            <InputBox
              label="Contact Number"
              value={formData.contactNumber || ""}
              disabled={!editMode}
              name="contactNumber"
              onChange={handleChange}
              type={"text"}
            />
          </div>
          <textarea
            className=" w-full border md:h-36 sm:h-36 lg:h-60 lg:col-span-1 p-4 max-w-5xl mt-4"
            placeholder="Enter short description about yourself"
            onChange={handleChange}
            name="bio"
            required
            value={formData.bio}
            disabled={!editMode}
          ></textarea>
          <div className="mt-8">
            <Button
              label={editMode ? "Save" : "Edit"}
              onClick={handleEditMode}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default MyProfile;
