import React from "react";
import ImagePlaceholder from "../../assets/No_Image_Available.jpg";
import { CiLocationOn } from "react-icons/ci";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { IoMdGlobe } from "react-icons/io";
import { TbRulerMeasure } from "react-icons/tb";
import { TbCurrencyPeso } from "react-icons/tb";

function ListingCard({ listing }) {
  const currentUser = useSelector((state) => state.userObject);
  return (
    <div className="relative w-80 sm:w-72 h-96 bg-white rounded-xl border border-white shadow-lg overflow-hidden">
      <div className="absolute bottom-0 right-0 text-xs px-2 underline text-gray-600"></div>
      <div
        className={`absolute top-0 right-0 text-white text-xs rounded-bl-xl p-2 ${
          listing.listing_type === "For Sale"
            ? "bg-leechiuBlue"
            : "bg-leechiuOrange"
        }`}
      >
        {listing.listing_type}
      </div>
      <div className="w-full">
        <img
          className="object-cover h-48 w-full rounded-t-xl"
          src={listing.primaryPhotoUrl || ImagePlaceholder}
          alt=""
        />
      </div>

      <div className="text-sm text-gray-600 font-semibold p-2 bg-gray-200 text-center mb-2">
        {listing.property_name}
      </div>
      <div className="flex items-center gap-2 text-xs text-gray-600 px-2 font-bold">
        <IoMdGlobe />
        {listing.website_title || "Not Provided"}
      </div>

      <div className="text-xs text-gray-600 px-2 flex items-center gap-1">
        <div>
          <CiLocationOn className="inline" />
        </div>
        <div>{listing.address || "No Address Provided"}</div>
      </div>

      <div className="text-xs text-gray-600 px-2 flex items-center gap-1">
        <div>
          <TbRulerMeasure className="inline" />
        </div>
        <div>{parseFloat(listing.lot_area).toLocaleString()} sqms</div>
      </div>

      <div className="text-xs text-gray-600 px-2 flex items-center gap-1">
        <div>
          <TbCurrencyPeso className="inline" />
        </div>
        <div>{parseFloat(listing.total_price).toLocaleString()}</div>
      </div>
      <div className=" text-xs  px-2 absolute bottom-0 w-fit bg-leechiuOrange text-white font-bold">
        Status {listing.pipeline_status}
      </div>
    </div>
  );
}

export default ListingCard;
