import React from "react";
import { useEffect, useState } from "react";
import { getAllDocsSnap } from "../../Firebase/Firebase.utils";
import UserAvatar from "../../components/UserAvatar";
import { useSelector } from "react-redux";

export default function AgentsListing() {
  const [listings, setListings] = useState([]);
  const [agents, setAgents] = useState([]);
  const [totalPotentialFee, setTotalPotentialFee] = useState(0);
  const [filteredListings, setFilteredListings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const getListings = async () => {
      const res = getAllDocsSnap("listings", (data) => {
        //  sort by property_name
        data.sort((a, b) =>
          a.property_name?.toLocaleString().localeCompare(b.property_name)
        );
        setListings(data);
        setFilteredListings(data);
        setIsLoading(false);
      });
    };
    getListings();
  }, []);

  useEffect(() => {
    // map listings per team member
    const teamMembers = listings.map((listing) => {
      return listing.team_members;
    });
    // create a set of unique team members
    const uniqueTeamMembers = new Set();
    teamMembers?.forEach((member) => {
      member?.forEach((item) => {
        uniqueTeamMembers.add(item);
      });
    });
    // convert set to array
    const uniqueTeamMembersArray = Array.from(uniqueTeamMembers);
    setAgents(uniqueTeamMembersArray);
  }, [listings]);

  useEffect(() => {
    // compute for total fee
    let totalFee = 0;
    listings.forEach((listing) => {
      const listingTotal = parseFloat(listing.potential_fee);

      totalFee += listingTotal;
    });

    setTotalPotentialFee(totalFee);
  }, [listings]);

  const averageListingPerAgent = () => {
    const average = listings.length / 12;
    return average;
  };

  const pillActive = "bg-orange-600 text-white";
  const pillInactive = "bg-gray-300 text-gray-700";

  const authorizedList = ["admin", "approver"];

  if (
    !currentUser.roles.some((role) => authorizedList.includes(role)) &&
    currentUser.department !== "Investment Sales"
  ) {
    return (
      <div>
        <h1>Unauthorized</h1>
        <p>Sorry, you are not authorized to view this page.</p>
        <p>Only Investment Sales Department or Approvers can view</p>
      </div>
    );
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col">
      <div className="font-bold">
        <div>Total Agents: 12</div>
        <div>Total Active Listings: {listings.length}</div>
        <div>
          Total Potential Fee: Php{" "}
          {parseFloat(totalPotentialFee.toFixed(2)).toLocaleString()}
        </div>
        <div>
          Average Listing per agent: {averageListingPerAgent().toFixed(2)}
        </div>
      </div>

      <div>
        <div>Arrange by</div>
        <div className="flex items-center gap-2">
          <button>Fee</button>
          <button>Creation Date</button>
        </div>
      </div>

      <div>
        {filteredListings.map((listing) => (
          <div
            className="flex items-center gap-2 mt-2 bg-white
          border shadow-md p-2 rounded-lg
          justify-between           "
            key={listing.id}
          >
            <div className="text-sm">
              <div className="font-bold text-gray-700">
                {listing.property_name}
              </div>
              <div>
                Php{" "}
                {parseFloat(
                  listing?.potential_fee?.toFixed(2)
                ).toLocaleString()}
              </div>
              <div>Lead: {listing.team_lead}</div>
            </div>
            <div className="flex items-center gap-2">
              {listing?.team_members?.map((member) => (
                <div className="">
                  <UserAvatar email={member} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
