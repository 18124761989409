import React from "react";
import { Link } from "react-router-dom";

export default function ArticleCard({ article }) {
  return (
    <div className="bubble cursor-pointer text-xs text-gray-600">
      <Link to={`/article/${article.id}`}>
        <div className="uppercase text-xs font-bold">{article.title}</div>
        <div>{article.author}</div>
        <div>{article.date}</div>
      </Link>
    </div>
  );
}
