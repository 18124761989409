import React from "react";
import { useSelector } from "react-redux";

import UserAvatar from "../../components/UserAvatar";

function DiscussionCard({
  discussion,
  handleContribute,
  currentUser,
  handleDelete,
}) {
  const r = discussion;
  return (
    <div>
      <div
        key={r.id}
        className="bg-white border mt-4 rounded-xl px-4 py-2 w-full max-w-5xl relative "
      >
        <div className="text-xs flex gap-2 items-end">
          <UserAvatar email={r.createdBy} />
        </div>
        <div className="text-xs text-gray-600 my-2 ">{r.description}</div>

        <div className="text-xs text-gray-400">
          {r.comments.length} comments
        </div>

        <div className="text-xs text-gray-400 mt-2">
          {r.createdAt.toDate().toLocaleString("en-US", { hour12: true })}
        </div>
        {/* button group */}
        <div className="flex items-center gap-2 absolute bottom-0 right-2">
          <button
            className="text-xs underline text-gray-700 "
            onClick={(e) => {
              handleContribute(e, r.id);
            }}
          >
            Contribute to this topic
          </button>
          {r.createdBy === currentUser.email && (
            <button
              className="text-xs font-semibold text-gray-700"
              onClick={(e) => {
                handleDelete(e, r);
              }}
            >
              Delete
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default DiscussionCard;
