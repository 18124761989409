import React, { useEffect } from "react";
import { addNewDoc, queryAllDocsByField } from "../Firebase/Firebase.utils";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import TeamNoteCard from "./TeamNoteCard";

function TeamNotes() {
  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notes, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const fetchNotes = async () => {
      const notes = await queryAllDocsByField(
        "teamNotes",
        "accountId",
        "==",
        id,
        (data) => {
          //arrange by createdAt
          data.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });

          setNotes(data);
          setIsLoading(false);
        }
      );
    };
    fetchNotes();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const { note } = formData;
    const data = {
      note,
      createdBy: currentUser.email,
      createdAt: new Date().toLocaleString(),
      accountId: id,
    };
    setFormData({ note: "" });
    await addNewDoc("teamNotes", data);
    setIsSubmitting(false);
    toast.success("Note added successfully");
  };

  if (isLoading) {
    return <div>Loading</div>;
  }

  return (
    <div>
      <div className="header">Team Notes</div>
      <textarea
        name="note"
        placeholder="Type your notes here"
        className="w-full rounded-xl p-2 resize-y"
        onChange={handleChange}
        value={formData.note}
      ></textarea>
      <button className="blue-button" onClick={handleSubmit}>
        {isSubmitting ? "Adding note..." : "Add Note"}
      </button>

      <div className="mt-8 space-y-2">
        {notes.map((note, index) => (
          <TeamNoteCard key={index} note={note} />
        ))}
      </div>
    </div>
  );
}

export default TeamNotes;
