import React from "react";
import InputBox from "../../components/InputBox";
import { useState } from "react";
import { addNewDoc, setDocData } from "../../Firebase/Firebase.utils";
import { useSelector } from "react-redux";

function AddNewCompanyForm({
  setAddMode,
  companyEditMode,
  setCompanyEditMode,
  companyToEdit,
}) {
  const [formData, setFormData] = useState(
    companyEditMode ? companyToEdit : {}
  );
  const [loading, setLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const currentUser = useSelector((state) => state.userObject);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (companyEditMode) {
      setIsProcessing(true);

      await setDocData("companies", companyToEdit.id, {
        ...formData,
        last_update: new Date().toISOString(),
        last_updated_by: currentUser.email,
      });
      setIsProcessing(false);
      setAddMode(false);
      setCompanyEditMode(false);
      return;
    }

    setIsProcessing(true);
    await addNewDoc("companies", formData);
    setIsProcessing(false);
    setAddMode(false);
    setCompanyEditMode(false);
  };

  return (
    <div className="relative">
      <div className="text-sm font-bold text-leechiuBlue text-center pt-8">
        Add a new company
      </div>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-2 max-w-[400px] mx-auto text-sm"
      >
        <InputBox
          placeholder={"Company Name"}
          required={true}
          name="company_name"
          onChange={handleChange}
          value={formData.company_name}
        />
        <InputBox
          placeholder={"Company Website"}
          name="company_website_link"
          onChange={handleChange}
          value={formData.company_website_link}
        />

        <InputBox
          placeholder={"Company Email Address"}
          type={"email"}
          name="company_email_address"
          onChange={handleChange}
          value={formData.company_email_address}
        />

        <InputBox
          placeholder={"Company Address"}
          type={"text"}
          name="company_address"
          onChange={handleChange}
          value={formData.company_address}
        />

        <InputBox
          placeholder={"Company Phone Number"}
          type={"tel"}
          name="telephone_number"
          onChange={handleChange}
          value={formData.telephone_number}
        />
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          {isProcessing ? "Please wait..." : "Add Company"}
        </button>
      </form>

      <div>
        <button
          onClick={() => setAddMode(false)}
          className="absolute top-0 right-0 underline text-leechiuBlue text-xs"
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default AddNewCompanyForm;
