import React from "react";
import ProfilePhotoOnly from "../components/ProfilePhotoOnly";
import { deleteDocById, setDocData } from "../Firebase/Firebase.utils";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import EditTaskForm from "./EditTaskForm";

function TaskCard({ task, handleEditMode }) {
  const currentUser = useSelector((state) => state.userObject);

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this task?")) {
      await deleteDocById("tasks", task.id);
    }
    toast.success("Task deleted successfully");
  };

  const handleEdit = () => {
    handleEditMode(task);
  };

  const toggleCompleted = async () => {
    const docObject = {
      ...task,
      status: task.status === "Open" ? "Completed" : "Open",
    };
    await setDocData("tasks", task.id, docObject);
    toast.success("Task status updated successfully");
  };

  return (
    <div className="bubble relative">
      <div className="close-button space-x-2">
        <button className="underline" onClick={handleEdit}>
          Edit
        </button>
        {task.createdBy === currentUser.email && (
          <button className="underline" onClick={handleDelete}>
            Delete
          </button>
        )}
      </div>

      <div className="uppercase font-bold text-sm text-gray-600">
        {task.task_name}
      </div>
      <div className="capitalize-first text-xs text-gray-600">
        {task.description}

        <div>
          {"Due on: "}
          {task.due_date}
        </div>

        <div>Status:{task.status}</div>
      </div>

      <div className="flex items-center gap-2 mt-2">
        {task.assignee_emails.map((email, index) => (
          <ProfilePhotoOnly key={index} email={email} />
        ))}
      </div>

      <div>
        <button
          onClick={toggleCompleted}
          className="blue-button absolute bottom-1 right-1"
        >
          {task.status === "Open" ? "Mark as Completed" : "Reopen Task"}
        </button>
      </div>
    </div>
  );
}

export default TaskCard;
