import React from "react";
import InputBox from "../../components/InputBox";
import Button from "../../components/Button";
import { useSelector } from "react-redux";
import { useState } from "react";

function LeaseBillingFormComponent({
  formData,
  handleChange,
  handleSubmit,
  handleDraft,
  isProcessing,
  setisProcessing,
  disabled,
}) {
  const currentUser = useSelector((state) => state.userObject);

  const approvers = [
    {
      name: "Select Approver",
      email: "",
    },
    {
      name: "Henry Cabrera",
      email: "henry.cabrera@leechiu.com",
    },
    {
      name: "Lylah Ledonio",
      email: "lylah.ledonio@leechiu.com",
    },
    {
      name: "Phillip Anonuevo",
      email: "phillip.anonuevo@leechiu.com",
    },
    {
      name: "Roy Golez",
      email: "roy.golez@leechiu.com",
    },
  ];

  return (
    <div>
      <div className="flex items-center gap-2">
        <h1>Billing Request for Leasing Transactions</h1>
        <div className="rounded-full px-4 py-2 bg-orange-300 capitalize">
          {formData.status || "Draft"}
        </div>
      </div>
      <form
        action=""
        onSubmit={handleSubmit}
        className="max-w-5xl flex gap-2 flex-col"
      >
        <h4 className="text-orange-600">Step 1: Billing Information</h4>
        <select
          className="disabled:text-gray-800 disabled:bg-gray-200 w-80 px-4 py-2 mt-2 border rounded-md border-gray-400 focus:outline-none focus:border-blue-500"
          onChange={handleChange}
          name="approver"
          value={formData.approver || ""}
          required
          disabled={disabled}
        >
          {approvers.map((approver, index) => (
            <option key={index} value={approver.email}>
              {approver.name}
            </option>
          ))}
        </select>

        <h4>Transaction Information</h4>
        <div className="flex flex-wrap gap-2 items-end">
          <InputBox
            label="Building Name"
            type="text"
            placeholder="Enter Building Name"
            name="building"
            value={formData.building || ""}
            onChange={handleChange}
            required
            disabled={disabled}
          />
          <InputBox
            label="Unit No. "
            type="text"
            placeholder="Enter Unit No."
            name="building_unit"
            value={formData.building_unit || ""}
            onChange={handleChange}
            required
            disabled={disabled}
          />
          <InputBox
            label="Lease Start Date"
            type="date"
            placeholder=""
            name="lease_start_date"
            value={formData.lease_start_date || ""}
            onChange={handleChange}
            required
            disabled={disabled}
          />
          <InputBox
            label="Lease End Date"
            type="date"
            placeholder=""
            name="lease_end_date"
            value={formData.lease_end_date || ""}
            onChange={handleChange}
            required
            disabled={disabled}
          />
          <InputBox
            label="Total Area Leased in sqms"
            type="number"
            placeholder="e.g. 2500"
            name="total_area_leased"
            value={formData.total_area_leased || ""}
            onChange={handleChange}
            required
            disabled={disabled}
          />

          <InputBox
            label="Base Rent per sqm per month"
            type="number"
            placeholder="e.g. 800"
            name="base_rent"
            value={formData.base_rent || ""}
            onChange={handleChange}
            required
            disabled={disabled}
          />

          <InputBox
            label="With Parking "
            type="text"
            placeholder="Enter Yes or No"
            name="with_parking"
            value={formData.with_parking || ""}
            onChange={handleChange}
            required
            disabled={disabled}
          />

          <InputBox
            label="Number of Parking Slots"
            type="number"
            placeholder="e.g. 20"
            name="parking_slots"
            value={formData.parking_slots || ""}
            onChange={handleChange}
            required
            disabled={disabled}
          />

          <InputBox
            label="Parking Rate per slot per month"
            type="number"
            placeholder="e.g. 5000"
            name="parking_rate"
            value={formData.parking_rate || ""}
            onChange={handleChange}
            required
            disabled={disabled}
          />
          <InputBox
            label="Use of Space"
            type="text"
            placeholder="e.g. Residential, BPO, Office, POGO, Industrial, etc. "
            name="use_of_space"
            value={formData.use_of_space || ""}
            onChange={handleChange}
            required
            disabled={disabled}
          />

          <InputBox
            label="Settled required PDCs/Advance Deposit/Security Deposit?"
            type="text"
            placeholder="e.g. Yes or No or Not Applicable"
            name="pdcs"
            value={formData.pdcs || ""}
            onChange={handleChange}
            required
            disabled={disabled}
          />
        </div>

        <h4>Landlord and Tenant Information</h4>
        <div className="flex flex-wrap gap-2">
          <InputBox
            label="Landlord Name"
            type="text"
            placeholder="e.g. Ayala Land Inc. "
            name="landlord"
            value={formData.landlord || ""}
            onChange={handleChange}
            required
            disabled={disabled}
          />
          <InputBox
            label="Tenant Name"
            type="text"
            placeholder="e.g. Japan Tobacco Inc. "
            name="tenant"
            value={formData.tenant || ""}
            onChange={handleChange}
            required
            disabled={disabled}
          />
          <InputBox
            label="Name of CEO, CFO, and COO of Tenant:"
            type="text"
            placeholder="e.g. John Doe, Jane Doe, and Juan Dela Cruz"
            name="tenant_executive"
            value={formData.tenant_executive || ""}
            onChange={handleChange}
            required
            disabled={disabled}
          />
        </div>

        <h4>Billing Particulars</h4>
        <div className="flex flex-wrap gap-2 mb-4">
          <InputBox
            label="Entity to be billed"
            type="text"
            placeholder="e.g. Ayala Land Inc. "
            name="client_name"
            value={formData.client_name || ""}
            onChange={handleChange}
            required
            disabled={disabled}
          />

          <InputBox
            label="Billing Address"
            type="text"
            placeholder="Enter Billing Address"
            name="client_address"
            value={formData.client_address || ""}
            onChange={handleChange}
            required
            disabled={disabled}
          />

          <InputBox
            label="Contact Person"
            type="text"
            placeholder="Enter Contact Person"
            name="contact_person"
            value={formData.contact_person || ""}
            onChange={handleChange}
            required
            disabled={disabled}
          />

          <InputBox
            label=" Position"
            type="text"
            placeholder="Enter Position of Contact Person"
            name="contact_position"
            value={formData.contact_position || ""}
            onChange={handleChange}
            required
            disabled={disabled}
          />
          <InputBox
            label="Contact Email"
            type="email"
            placeholder="Enter email address of contact person"
            name="contact_email"
            value={formData.contact_email || ""}
            onChange={handleChange}
            required
            disabled={disabled}
          />
          <InputBox
            label="Contact Number"
            type="text"
            placeholder="Enter Contact Number"
            name="contact_number"
            value={formData.contact_number || ""}
            onChange={handleChange}
            required
            disabled={disabled}
          />

          <InputBox
            label="Number of months commission"
            type="text"
            placeholder="e.g. 4"
            name="months_commission"
            value={formData.months_commission || ""}
            onChange={handleChange}
            required
            disabled={disabled}
          />

          <InputBox
            label="Billing Amount exclusive of VAT"
            type="number"
            placeholder="Enter Amount to be billed"
            name="amount"
            value={formData.amount || ""}
            onChange={handleChange}
            required
            disabled={disabled}
          />

          <InputBox
            label="VAT"
            type="number"
            placeholder="Enter VAT amount"
            name="vat"
            value={formData.vat || ""}
            onChange={handleChange}
            required
            disabled={disabled}
          />

          <InputBox
            label="Total Billing Amount"
            type="number"
            placeholder="Enter Total Billing Amount"
            name="total_amount"
            value={formData.total_amount || ""}
            onChange={handleChange}
            required
            disabled={disabled}
          />
        </div>
        <h4>External Broker, if any</h4>
        <div className="flex flex-wrap gap-4">
          <InputBox
            label={"Broker Name"}
            placeholder={"Enter Broker Name"}
            type="text"
            name="broker_name"
            onChange={handleChange}
            value={formData.broker_name || ""}
            disabled={disabled}
          />

          <InputBox
            label={"Percentage Share"}
            placeholder={"Percentage Share"}
            type="text"
            name="percentage_share"
            onChange={handleChange}
            value={formData.percentage_share || ""}
            disabled={disabled}
          />

          <InputBox
            label={"Brokerage Fee"}
            placeholder={"Enter Brokerage Fee"}
            type="text"
            name="brokerage_fee"
            onChange={handleChange}
            value={formData.brokerage_fee || ""}
            disabled={disabled}
          />
        </div>

        {currentUser.roles.includes("finance") ? null : (
          <div className="flex gap-2 ">
            <Button
              type="submit"
              label={isProcessing ? "Submitting..." : "Proceed to Step 2"}
              disabled={isProcessing}
            />
            <Button
              label={isProcessing ? "Saving Draft..." : "Save Draft"}
              disabled={isProcessing}
              onClick={handleDraft}
            />
          </div>
        )}
      </form>
    </div>
  );
}

export default LeaseBillingFormComponent;
