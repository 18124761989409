import React from "react";
import { addNewDoc } from "../../Firebase/Firebase.utils";
import InputBox from "../../components/InputBox";
import { useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

function AddContactForm({ handleAddMode, id }) {
  const [formData, setFormData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currentUser = useSelector((state) => state.userObject);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //check if phone or email is not empty
    if (!formData.contactPhone && !formData.contactEmail) {
      toast.error("Please enter either phone or email");
      return;
    }
    const docObject = {
      ...formData,
      accountId: id,
      createdAt: new Date().toISOString(),
      createdBy: currentUser.email,
    };
    setIsSubmitting(true);
    await addNewDoc("contacts", docObject);
    setIsSubmitting(false);
    toast.success("Contact added successfully");
    handleAddMode();
  };

  return (
    <div className="small-form relative">
      <button
        className="underline text-xs uppercase absolute top-2 right-2"
        onClick={handleAddMode}
      >
        Close
      </button>
      <div className="header pt-8">Add Contact</div>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4 mt-4">
        <InputBox
          name="contactName"
          label="Contact Name"
          type="text"
          value={formData.contactName}
          placeholder={"Enter contact name"}
          onChange={handleChange}
          required
        />
        <InputBox
          name="contactPosition"
          label="Position"
          type="text"
          value={formData.contactPosition}
          placeholder={"Enter contact position"}
          onChange={handleChange}
          required
        />
        <InputBox
          name="contactEmail"
          label="Email"
          type="email"
          value={formData.contactEmail}
          placeholder={"Enter contact email"}
          onChange={handleChange}
        />
        <InputBox
          name="contactPhone"
          label="Phone"
          type="text"
          value={formData.contactPhone}
          placeholder={"Enter contact phone"}
          onChange={handleChange}
        />
        <button className="blue-button" type="submit">
          {isSubmitting ? "Adding contact..." : "Add Contact"}
        </button>
      </form>
    </div>
  );
}

export default AddContactForm;
