import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  getAllDocsSnap,
  getAllDocs,
  uploadBatchDocs,
} from "../../Firebase/Firebase.utils";
import BgcBuildings from "../../data/BgcBuildings";
import BuildingCard from "../../OfficeLeasing/BuildingCard";

function Masterlist() {
  const [buildings, setBuildings] = useState([]);
  const [activeMenu, setActiveMenu] = useState(0);

  const [activeList, setActiveList] = useState([]);
  const [listingCount, setListingCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const getBuildings = async () => {
      const bldg = await getAllDocsSnap("masterlist", (bldg) => {
        //arrange by buildingName
        bldg.sort((a, b) => (a.buildingName > b.buildingName ? 1 : -1));
        setBuildings(bldg);

        setActiveList(bldg);
        setLoading(false);
      });
    };
    getBuildings();
  }, []);

  const menuItems = ["All", "Exclusive", "Non Exclusive"];

  const activateMenu = (index) => {
    setActiveMenu(index);
    if (index === 0) {
      setActiveList(buildings);
    } else if (index === 1) {
      const filtered = buildings.filter(
        (building) => building.exclusive === "Yes"
      );

      setActiveList(filtered);
    } else {
      const filtered = buildings.filter(
        (building) => building.exclusive !== "Yes"
      );

      setActiveList(filtered);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const filteredBuildings = activeList.filter((building) => {
    //check if theres building name
    if (building.buildingName) {
      return building.buildingName
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
    } else {
      return;
    }
  });

  return (
    <div className="flex flex-col mt-4">
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-4 flex-wrap [&>*]:text-xs hover:cursor-pointer">
          {menuItems.map((item, index) => (
            <div
              key={index}
              className={activeMenu === index ? "active-pill" : "inactive-pill"}
              onClick={() => activateMenu(index)}
            >
              {item}
            </div>
          ))}
        </div>

        <input
          type="search"
          placeholder="Search here"
          className="border p-2 rounded-md max-w-5xl"
          onChange={handleSearch}
        />
        <div>Total Result: {filteredBuildings.length}</div>

        <div className="flex items-center justify-normal gap-2 flex-wrap">
          {filteredBuildings.map((building, index) => (
            <BuildingCard key={index} building={building} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Masterlist;
