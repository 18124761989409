import React from "react";
import InputBox from "../components/InputBox";
import { addNewDoc } from "../Firebase/Firebase.utils";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

export default function NewContactForm({ requirementData, handleAddMode }) {
  const [formData, setFormData] = React.useState({});
  const [isProcessing, setIsProcessing] = React.useState(false);
  const currentUser = useSelector((state) => state.userObject);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    if (!formData.contactPhone && !formData.contactEmail) {
      toast.error("Please enter either phone or email");
      setIsProcessing(false);
      return;
    }

    const docObject = {
      ...formData,
      createdAt: new Date().toLocaleString(),
      createdBy: currentUser.email,
      updatedAt: new Date().toLocaleString(),
      lastUpdatedBy: currentUser.email,
      accountId: requirementData.id,
    };

    await addNewDoc("contacts", docObject);
    setIsProcessing(false);
    handleAddMode();
    toast.success("Contact added successfully");
  };
  return (
    <div className="relative small-form">
      <div className="header pt-8">New Contact Form</div>
      <button className="close-button" onClick={handleAddMode}>
        Cancel
      </button>

      <div>
        <form onSubmit={handleSubmit}>
          <InputBox
            name="contactName"
            label="Contact Name"
            type="text"
            placeholder="Enter contact name"
            required={true}
            onChange={handleChange}
          />

          <InputBox
            name="contactPosition"
            label="Position"
            type="text"
            placeholder="Enter contact position"
            required={true}
            onChange={handleChange}
          />

          <InputBox
            name="contactEmail"
            label="Email"
            type="email"
            placeholder="Enter contact email"
            onChange={handleChange}
          />

          <InputBox
            name="contactPhone"
            label="Phone"
            type="text"
            placeholder="Enter contact phone"
            onChange={handleChange}
          />

          <button className="blue-button" type="submit">
            {isProcessing ? "Processing" : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
}
