import React, { useEffect } from "react";
import emptyPicture from "../assets/emptydisplay.png";
import { useSelector } from "react-redux";
import InputBox from "../components/InputBox";
import Button from "../components/Button";
import { setDocData, getDocDataSnap } from "../Firebase/Firebase.utils";
import { useRef } from "react";
import { useState } from "react";
import ProfilePhotoProgress from "../components/ProfilePhotoProgress";
import { useParams } from "react-router-dom";

function UserProfile({ userProp }) {
  const { id } = useParams();
  const [formData, setFormData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const getUser = async () => {
      const res = getDocDataSnap("users", id, (data) => {
        setFormData(data);
        setIsLoading(false);
      });
    };
    getUser();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col">
      <div className="w-full flex flex-wrap items-center h-40 gap-2">
        <div className="h-full flex items-start gap-4 flex-wrap">
          <img
            className=" h-full w-40 rounded-full object-cover border border-gray-600"
            src={formData.profilePhoto || emptyPicture}
            alt="click to upload"
            // onClick={() => inputref.current.click()}
          />
          <div className="">
            <div className="h-full text-lg text-gray-600 font-bold capitalize">
              {formData.displayName.toLowerCase()}
            </div>
            <div className="h-full text-sm">{formData.department}</div>
            <div className="text-sm ">{formData.email}</div>
            <div className="mt-4 text-gray-400 text-xs">{formData.bio}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
