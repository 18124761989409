import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ImgPlaceHolder from "../../assets/No_Image_Available.jpg";

function ListingCardComponent({ listing, handleNavigate }) {
  const [viewType, setViewType] = useState("list");

  return (
    <div
      key={listing.id}
      className={`
      hover:cursor-pointer hover:bg-gray-100
      ${
        viewType === "list"
          ? "relative flex items-center gap-2 border bg-white rounded-xl shadow-lg p-2 mt-2 text-sm"
          : "relative w-[300px] h-[300px] flex flex-col items-start gap-2 border bg-white rounded-xl shadow-lg p-2 mt-2 text-sm"
      }`}
      onClick={() => handleNavigate(listing.id)}
    >
      <img
        className={
          viewType === "list"
            ? "h-36 w-36 rounded-xl object-cover"
            : "h-36 w-full rounded-xl object-cover"
        }
        src={listing?.primaryPhotoUrl || ImgPlaceHolder}
        alt=""
      />
      <div className="">
        <div className="flex items-center gap-2">
          <div className="font-semibold">{listing.property_name}</div>
          <div className="rounded-full bg-orange-200 px-1 text-xs">
            {listing.listing_type}
          </div>
        </div>
        <a
          className="text-xs text-blue-600"
          href={listing.google_maps_pin}
          target="_blank"
        >
          Google Maps Pin
        </a>
        <div className="text-xs">{listing.address}</div>
        <div className="text-xs">
          {parseFloat(listing.lot_area).toLocaleString()} square meters
        </div>
        <div className="text-xs">
          Php {parseFloat(listing.selling_price).toLocaleString()} per sqm
        </div>
        <div className="text-xs">
          Php {parseFloat(listing.total_price).toLocaleString()}
        </div>
        <div className="text-xs">
          Fee: Php {parseFloat(listing.potential_fee).toLocaleString()}
        </div>

        <div className="text-xs">
          Pipeline Status: {listing.pipeline_status}
        </div>
      </div>
    </div>
  );
}

export default ListingCardComponent;
