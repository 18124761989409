import React from "react";
import InputBox from "../../components/InputBox";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

function ISBillingFormComponent({
  handleChange,
  handleSubmit,
  handleSaveDraft,
  isProcessing,
  formData,
  disabled,
}) {
  const currentUser = useSelector((state) => state.userObject);

  const approvers = [
    {
      name: "Select Approver",
      email: "",
    },
    {
      name: "Henry Cabrera",
      email: "henry.cabrera@leechiu.com",
    },
    {
      name: "Lylah Ledonio",
      email: "lylah.ledonio@leechiu.com",
    },
    {
      name: "Phillip Anonuevo",
      email: "phillip.anonuevo@leechiu.com",
    },
    {
      name: "Roy Golez",
      email: "roy.golez@leechiu.com",
    },
  ];

  return (
    <div>
      <div>
        <form
          action="POST"
          onSubmit={handleSubmit}
          className="flex flex-wrap gap-4 max-w-5xl"
          id="is-sale-billing"
        >
          <h1>Billing Request - Sale Transaction</h1>
          <div className="rounded-full px-4 py-2 bg-orange-300 capitalize">
            {formData.status || "Draft"}
          </div>
          <div className="flex  flex-col gap-4 flex-wrap">
            <h4 className="text-orange-600 uppercase">
              Step 1: Billing Information
            </h4>

            <div className="flex flex-col w-80">
              <label className="text-sm font-semibold text-gray-600">
                Select Approver
              </label>
              <select
                className="disabled:text-gray-800 disabled:bg-gray-200 w-80 px-4 py-2 mt-2 border rounded-md border-gray-400 focus:outline-none focus:border-blue-500"
                onChange={handleChange}
                name="approver"
                value={formData.approver || ""}
                required
                disabled={disabled}
              >
                {approvers.map((approver, index) => (
                  <option key={index} value={approver.email}>
                    {approver.name}
                  </option>
                ))}
              </select>
            </div>
            <h4>Client Information</h4>
            <div className="flex flex-wrap gap-4">
              <InputBox
                label={"Client Name"}
                placeholder={"Company or Person to be billed"}
                name="client_name"
                onChange={handleChange}
                required
                value={formData.client_name || ""}
                disabled={disabled}
              />

              <InputBox
                label={"Client Address"}
                placeholder={"Enter Client Address"}
                name="client_address"
                onChange={handleChange}
                required
                value={formData.client_address || ""}
                disabled={disabled}
              />

              <InputBox
                label={"Contact Person"}
                placeholder={"Enter Contact Person"}
                name="contact_person"
                onChange={handleChange}
                required
                value={formData.contact_person || ""}
                disabled={disabled}
              />

              <InputBox
                label={"Position of Contact Person"}
                placeholder={"Enter Position of Contact Person"}
                name={"contact_position"}
                onChange={handleChange}
                required
                value={formData.contact_position || ""}
                disabled={disabled}
              />

              <InputBox
                label={"Email Address of Contact Person"}
                placeholder={"Enter Email Address"}
                type="email"
                name="contact_email"
                onChange={handleChange}
                required
                value={formData.contact_email || ""}
                disabled={disabled}
              />

              <InputBox
                label={"Mobile Number of Contact Person"}
                placeholder={"Enter mobile number"}
                name="contact_number"
                onChange={handleChange}
                required
                value={formData.contact_number || ""}
                disabled={disabled}
              />
            </div>

            <h4>Billing Details</h4>
            <div className="flex flex-wrap gap-4">
              <InputBox
                label="Fee Amount"
                placeholder="Enter Amount without VAT"
                type="number"
                name="amount"
                onChange={handleChange}
                required
                value={formData.amount || ""}
                disabled={disabled}
              />

              <InputBox
                label="VAT"
                placeholder="Enter VAT"
                type="number"
                name="vat"
                onChange={handleChange}
                required
                value={formData.vat || ""}
                disabled={disabled}
              />

              <InputBox
                label="Enter Short Description of Transaction"
                placeholder="e.g. Lease of 100 sqm. office space in W. Tower, BGC"
                type="text"
                name="description"
                onChange={handleChange}
                required
                value={formData.description || ""}
                disabled={disabled}
              />
            </div>

            <h4>Transaction Information</h4>
            <div className="flex flex-wrap gap-4">
              <InputBox
                label={"Transaction Date"}
                placeholder={"Enter Transaction Date"}
                type="date"
                name="transaction_date"
                onChange={handleChange}
                required
                value={formData.transaction_date || ""}
                disabled={disabled}
              />

              <InputBox
                label={"Seller"}
                placeholder={"Enter Seller's Name"}
                type="text"
                name="seller"
                onChange={handleChange}
                required
                value={formData.seller || ""}
                disabled={disabled}
              />

              <InputBox
                label={"Buyer"}
                placeholder={"Enter Buyer's Name"}
                type="text"
                name="buyer"
                onChange={handleChange}
                required
                value={formData.buyer || ""}
                disabled={disabled}
              />

              <InputBox
                label="Enter Property Address"
                placeholder="e.g. 123 Main St., BGC, Taguig City"
                type="text"
                name="property_address"
                onChange={handleChange}
                required
                value={formData.property_address || ""}
                disabled={disabled}
              />

              <InputBox
                label="Enter Lot Area in sqms, if applicable"
                placeholder="e.g. 2500"
                type="text"
                name="lot_area"
                onChange={handleChange}
                required
                value={formData.lot_area || ""}
                disabled={disabled}
              />

              <InputBox
                label="Enter Floor Area in sqms, if applicable"
                placeholder="e.g. 800"
                type="text"
                name="floor_area"
                onChange={handleChange}
                required
                value={formData.floor_area || ""}
                disabled={disabled}
              />

              <InputBox
                label={"Transaction Value"}
                placeholder={"Enter Transaction Value"}
                type="number"
                name="transaction_value"
                onChange={handleChange}
                required
                value={formData.transaction_value || ""}
                disabled={disabled}
              />

              <InputBox
                label={"Commission Rate"}
                placeholder={"Enter Commission Rate"}
                type="text"
                name="commission_rate"
                onChange={handleChange}
                required
                value={formData.commission_rate || ""}
                disabled={disabled}
              />
            </div>

            <h4>External Broker, if any</h4>
            <div className="flex flex-wrap gap-4">
              <InputBox
                label={"Broker Name"}
                placeholder={"Enter Broker Name"}
                type="text"
                name="broker_name"
                onChange={handleChange}
                value={formData.broker_name || ""}
                disabled={disabled}
              />

              <InputBox
                label={"Percentage Share"}
                placeholder={"Percentage Share"}
                type="text"
                name="percentage_share"
                onChange={handleChange}
                value={formData.percentage_share || ""}
                disabled={disabled}
              />

              <InputBox
                label={"Brokerage Fee"}
                placeholder={"Enter Brokerage Fee"}
                type="text"
                name="brokerage_fee"
                onChange={handleChange}
                value={formData.brokerage_fee || ""}
                disabled={disabled}
              />
            </div>
          </div>
          {currentUser.roles.includes("finance") ? null : (
            <>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#1E3A8A",
                  color: "#fff",
                  width: "max-content",
                  alignSelf: "center",
                  "&:hover": {
                    backgroundColor: "#1E3A8A",
                  },
                }}
                disabled={isProcessing}
                type="submit"
              >
                {isProcessing ? "Processing..." : "Proceed to Step 2"}
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#60798b",
                  color: "#fff",
                  width: "max-content",
                  alignSelf: "center",
                  "&:hover": {
                    backgroundColor: "#60798b",
                  },
                }}
                disabled={isProcessing}
                onClick={handleSaveDraft}
              >
                {isProcessing ? "Processing..." : "Save as Draft"}
              </Button>
            </>
          )}
        </form>
      </div>
    </div>
  );
}

export default ISBillingFormComponent;
