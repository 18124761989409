import React from "react";
import Logo from "../../assets/leechiu-blue.png";
import LoginForm from "./LoginForm";
import { Link } from "react-router-dom";
import SignupForm from "./SignupForm";

function Signup() {
  return (
    <div className="h-screen flex bg-[url('https://images.unsplash.com/photo-1497366754035-f200968a6e72?auto=format&fit=crop&q=80&w=2301&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')] bg-cover bg-center w-screen items-center justify-center flex-col overflow-hidden">
      <div className="bg-black bg-opacity-30 w-screen h-screen flex items-center justify-center">
        <div className="h-3/4 w flex items-center justify-center flex-col bg-gray-50 rounded-lg">
          <img src={Logo} alt="Leechiu Logo" className="h-20" />
          <SignupForm />
          <div className="flex flex-col justify-center items-center mb-8">
            <p>
              Already have an account? Login{" "}
              <Link className="underline" to="/login">
                Here
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
