import React from "react";
import { useState } from "react";
import { addNewDoc, setDocData } from "../../Firebase/Firebase.utils";
import { useSelector } from "react-redux";
import InputBox from "../../components/InputBox";
import toast from "react-hot-toast";

function AddInteractionForm({
  interactionDataToEdit,
  editMode,
  setEditMode,
  contact,
  setAddMode,
}) {
  const [formData, setFormData] = useState(
    editMode ? interactionDataToEdit : {}
  );
  const [loading, setLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const currentUser = useSelector((state) => state.userObject);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (editMode) {
      setIsProcessing(true);

      await setDocData("interactions", interactionDataToEdit.id, {
        ...formData,
        last_update: new Date().toISOString(),
        last_updated_by: currentUser.email,
      });
      setIsProcessing(false);
      toast.success("Interaction updated successfully");
      setAddMode(false);
      setEditMode(false);
      return;
    }

    setIsProcessing(true);
    await addNewDoc("interactions", {
      ...formData,
      touch_point_ref: contact.id,
      date_created: new Date().toISOString(),
      created_by: currentUser.email,
    });
    setIsProcessing(false);
    setAddMode(false);
    setEditMode(false);
    toast.success("Interaction logged successfully");
  };

  return (
    <div className="relative min-w-[350px]">
      <button
        onClick={() => {
          setAddMode(false);
          setEditMode(false);
        }}
        className="absolute top-2 right-2 underline text-xs text-leechiuBlue"
      >
        Close
      </button>
      <div className="pt-8 uppercase font-bold text-leechiuBlue text-sm text-center">
        Log Client Interaction
      </div>
      <form onSubmit={handleSubmit} className="space-y-4">
        <InputBox
          name="type"
          label="Interaction Type"
          placeholder="Call, Email, Meeting, etc."
          required
          onChange={handleChange}
          value={formData.type}
        />
        <InputBox
          name="date"
          label="Date of Interaction"
          type="date"
          required
          onChange={handleChange}
          value={formData.date}
        />
        <textarea
          name="notes"
          placeholder="Notes"
          className="w-full p-2 border border-zinc-500 rounded-md"
          onChange={handleChange}
          value={formData.notes}
        />

        <button className="mt-4 w-full bg-leechiuOrange text-white p-2 shadow-sm text-xs">
          {isProcessing ? "Please wait..." : "Log Interaction"}
        </button>
      </form>
    </div>
  );
}

export default AddInteractionForm;
