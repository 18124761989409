import React, { useEffect } from "react";
import { queryAllDocsByField } from "../Firebase/Firebase.utils";
import { useSelector } from "react-redux";

export default function useSearchUserDocs({ collection }) {
  const currentUser = useSelector((state) => state.userObject);
  const [data, seData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const docs = await queryAllDocsByField(
        collection,
        "team_members",
        "array-contains",
        currentUser.email,
        (docs) => {
          seData(docs);
          setIsLoading(false);
        }
      );
    };
    fetchData();
  }, []);

  return { data, isLoading };
}
