import React from "react";

export default function SummaryReport({ setViewMode, data }) {
  const accounts = data;

  const activeAccounts = accounts.filter(
    (account) => account.status === "active"
  );

  const inactiveAccounts = accounts.filter(
    (account) => account.status === "inactive"
  );

  const accountsWithoutStatus =
    accounts.length - activeAccounts - inactiveAccounts;

  const actsWithoutStatus = accounts.filter(
    (act) => act.status === undefined || null
  );

  const tenPercenters = activeAccounts.filter(
    (item) => item.pipeline_status === "10%"
  );

  const thirtyPercenters = activeAccounts.filter(
    (item) => item.pipeline_status === "30%"
  );
  const fiftyPercenters = activeAccounts.filter(
    (item) => item.pipeline_status === "50%"
  );
  const ninetyPercenters = activeAccounts.filter(
    (item) => item.pipeline_status === "90%"
  );
  const hundredPercenters = activeAccounts.filter(
    (item) => item.pipeline_status === "100%"
  );

  const handleSum = (acc, curr) => {
    if (!curr.potentialFee) {
      return acc + 0;
    } else {
      const value = parseFloat(curr.potentialFee.replace(/,/g, ""));
      return acc + value;
    }
  };
  const tenPercentersFee = tenPercenters.reduce(handleSum, 0);
  const thirtyPercentersFee = thirtyPercenters.reduce(handleSum, 0);
  const fiftyPercentersFee = fiftyPercenters.reduce(handleSum, 0);
  const ninetyPercentersFee = ninetyPercenters.reduce(handleSum, 0);
  const hundredPercentersFee = hundredPercenters.reduce(handleSum, 0);
  const totalFee = accounts.reduce(handleSum, 0);

  console.log(tenPercentersFee);

  console.log(
    accounts.filter((account) => account.status === undefined || null)
  );

  return (
    <div className="page relative text-gray-600 text-xs">
      <button className="close-button" onClick={() => setViewMode(false)}>
        Close
      </button>
      <div>
        <div className="header">Summary Report - Commercial Leasing Team</div>

        <div>
          <div className="flex items-center justify-between w-64">
            <div>Active Accounts</div>
            <div>{activeAccounts.length}</div>
          </div>
          <div className="flex items-center justify-between w-64">
            <div>Inactive Accounts</div>
            <div>{inactiveAccounts.length}</div>
          </div>
          <div className="flex items-center justify-between w-64 font-bold">
            <div>Total Accounts</div>
            <div>{accounts.length}</div>
          </div>
        </div>

        <div className="mt-4">
          <div className="header">Summary per Status and Fee</div>
          <div className="w-64 flex items-center justify-between">
            <div>10%</div>
            <div>{tenPercenters.length}</div>
            <div>
              {tenPercentersFee.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>

          <div className="w-64 flex items-center justify-between">
            <div>30%</div>
            <div>{thirtyPercenters.length}</div>
            <div>
              {thirtyPercentersFee.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>

          <div className="w-64 flex items-center justify-between">
            <div>50%</div>
            <div>{fiftyPercenters.length}</div>
            <div>
              {fiftyPercentersFee.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>

          <div className="w-64 flex items-center justify-between">
            <div>90%</div>
            <div>{ninetyPercenters.length}</div>
            <div>
              {ninetyPercentersFee.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>

          <div className="w-64 flex items-center justify-between">
            <div>100%</div>
            <div>{hundredPercenters.length}</div>
            <div>
              {hundredPercentersFee.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>

          <div className="w-64 flex items-center justify-between font-bold">
            <div>Total</div>
            <div>{activeAccounts.length}</div>
            <div>
              {totalFee.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>

          <div className="flex items-center justify-between w-64">
            <div>Total of 30% to 90%</div>
            <div>
              {thirtyPercenters.length +
                fiftyPercenters.length +
                ninetyPercenters.length}
            </div>
            <div>
              {(
                thirtyPercentersFee +
                fiftyPercentersFee +
                ninetyPercentersFee
              ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
