import React from "react";

function CompanyProfile({ requirementData }) {
  const tableFields = [
    { label: " Name", value: "tenantName" },
    {
      label: " Industry",
      value: "tenantIndustry",
    },
    {
      label: " Contact Person",
      value: "tenantContactPerson",
    },
    {
      label: " Contact Number",
      value: "tenantContactNumber",
    },
    {
      label: " Email Address",
      value: "tenantEmail",
    },
  ];
  return (
    
    <div className="bubble">
      <div className="header mb-4">Company Profile</div>
      {
        <div className="flex items-center flex-wrap max-w-5xl text-sm">
          <table className="w-full bg-white border border-gray-300">
            <tbody>
              {tableFields.map((field, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
                >
                  <td className="border border-gray-300 px-2 py-1">
                    {field.label}
                  </td>
                  <td className="border border-gray-300 px-2 py-1">
                    {requirementData[field.value]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      }
    </div>
  );
}

export default CompanyProfile;
