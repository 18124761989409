import React from "react";
import { useState, useEffect } from "react";
import {
  getAllDocsSnap,
  countDocsByStatusAndEmail,
  getAllDocsByEmailAndStatus,
} from "../../Firebase/Firebase.utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function RequestToBill() {
  const [pendingBillings, setPendingBillings] = React.useState(0);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [billingForApproval, setBillingForApproval] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchApprovalList = async () => {
      try {
        const myApprovalList = await getAllDocsByEmailAndStatus(
          "billing",
          currentUser.email,
          "pending approval",
          "approver"
        );

        setBillingForApproval(myApprovalList);
      } catch (error) {}
    };
    fetchApprovalList();
  }, []);

  const handleClick = (id) => {
    navigate(`/billingview/${id}`);
  };

  return (
    <div>
      <div className="mb-8">Billings for your approval</div>
      {billingForApproval.map((item) => (
        <div
          key={item.id}
          className="p-2 border bg-white rounded-lg hover:cursor-pointer hover:bg-gray-100"
          onClick={() => handleClick(item.id)}
        >
          <div>{item.client_name}</div>
          <div>{parseFloat(item.amount).toLocaleString()}</div>
          <div>{item.description}</div>
          <div>Requested by: {item.created_by}</div>
        </div>
      ))}
    </div>
  );
}

export default RequestToBill;
