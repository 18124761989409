import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  signInWithEmail,
  findUserDocbyId,
} from "../../Firebase/Firebase.utils";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loadUser } from "../../store/userSlice";

function LoginForm() {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };
  const [isLoading, setIsLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const Navigate = useNavigate();
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    const signIn = async () => {
      try {
        setIsLoading(true);
        const res = await signInWithEmail(formData.email, formData.password);
        if (res) {
          const { email, uid } = res.user;

          const { displayName, roles, department } = await findUserDocbyId(
            res.user.uid
          );

          dispatch(loadUser({ email, displayName, uid, roles, department }));
          Navigate("/dashboard");
        }
        setIsLoading(false);
      } catch (error) {}
    };
    signIn();
  };

  return (
    <div className="flex flex-col h-96 w-96 justify-center p-8 gap-y-5 text-gray-600">
      <div className="font-bold text-leechiuBlue text-center text-lg">
        Welcome Back
      </div>
      <form
        action="POST"
        onSubmit={handleSubmit}
        className="flex flex-col gap-y-5"
      >
        <TextField
          id="outlined-basic"
          label="Email Address"
          variant="outlined"
          type="email"
          name="email"
          onChange={handleChange}
        />

        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label=" Enter Password"
            name="password"
            onChange={handleChange}
          />
        </FormControl>
        <button
          type="submit"
          disabled={isLoading}
          className="bg-leechiuBlue text-white px-4 py-2 rounded-lg"
        >
          {isLoading ? "Logging in, Please wait" : "Login"}
        </button>
      </form>
    </div>
  );
}

export default LoginForm;
