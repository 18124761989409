import React, { useEffect } from "react";
import { deleteDocById } from "../Firebase/Firebase.utils";
import toast from "react-hot-toast";
import NoPhoto from "../assets/No_Image_Available.jpg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function BuildingCard({ building }) {
  const [photos, setPhotos] = React.useState([]);

  const currentUser = useSelector((state) => state.userObject);

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this building?")) {
      await deleteDocById("masterlist", building.id);
      toast.success("Building has been deleted");
    }
  };

  return (
    <Link to={`/building/${building.id}`}>
      <div className=" bg-white w-72 h-96 relative p-2 rounded-xl overflow-hidden shadow-md hover:shadow-lg hover:border hover:border-leechiuOrange text-xs text-gray-600">
        <div className="font-bold header pt-2 text-center">
          {building.buildingName}
        </div>
        <div className="">
          <img
            className="object-cover w-72 h-72"
            src={building?.primaryPhotoUrl || NoPhoto}
            alt=""
          />
        </div>{" "}
        <div>{building.address}</div>
      </div>
    </Link>
  );
}

export default BuildingCard;
