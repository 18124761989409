import React from "react";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getDocDataSnap,
  setDocData,
  deleteObjectByUrl,
  deleteDocById,
} from "../Firebase/Firebase.utils";


import Button from "../components/Button";
import BuildingDetailsForm from "./BuildingDetailsForm";
import BuildingDetailsTable from "./BuildingDetailsTable";
import BuildingPhotos from "./BuildingPhotos";
import LeasingInformation from "../pages/Leasing/LeasingInformation";
import { v4 } from "uuid";
import { toast } from "react-toastify";
import NoPhoto from "../assets/No_Image_Available.jpg";
import { storage } from "../Firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useSelector } from "react-redux";
import AssignedBuildingAgent from "./AssignedBuildingAgent";

function BuildingDetails() {
  const id = useParams();
  const [doc, setDoc] = useState({});
  const [formData, setFormData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [activeMenu, setActiveMenu] = useState(1);
  const [floorPlanUpload, setFloorPlanUpload] = useState(null);
  const [url, setUrl] = useState("");
  const [progress, setProgress] = useState(0);

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const getDoc = async () => {
      const doc = await getDocDataSnap("masterlist", id.id, (doc) => {
        setDoc(doc);
      });
    };
    getDoc();
  }, []);

  useEffect(() => {
    setFormData(doc);
  }, [doc]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const tableFields = [
    {
      label: "Building Name",
      value: "buildingName",
      inputType: "text",
    },
    {
      label: "Address",
      value: "address",
      inputType: "text",
    },
    {
      label: "Google Maps Pin",
      value: "googleMapsPin",
      inputType: "text",
    },
    {
      label: "Business District",
      value: "businessDistrict",
      inputType: "text",
    },
    {
      label: "City",
      value: "city",
      inputType: "text",
    },
    {
      label: "Exclusive",
      value: "exclusive",
      inputType: "text",
    },

    {
      label: "Developer",
      value: "developer",
      inputType: "text",
    },
    {
      label: "Year Constructed",
      value: "yearConstructed",
      inputType: "text",
    },
    {
      label: "Certifications",
      value: "certifications",
      inputType: "text",
    },
    {
      label: "Total Floors",
      value: "totalFloors",
      inputType: "number",
    },
    {
      label: "Density",
      value: "density",
      inputType: "text",
    },

    {
      label: "Floor Efficiency",
      value: "floorEfficiency",
      inputType: "text",
    },

    {
      label: "Available Space in sqms",
      value: "totalAvailableSpace",
      inputType: "number",
    },
    {
      label: "Handover Condition",
      value: "handoverCondition",
      inputType: "text",
    },
    {
      label: "AC System",
      value: "acSystem",
      inputType: "text",
    },
    {
      label: "AC Charges",
      value: "acCharges",
      inputType: "text",
    },
    {
      label: "Quoted Rent",
      value: "quotedRent",
      inputType: "text",
    },
    {
      label: "CUSA per sqm per month",
      value: "cusa",
      inputType: "text",
    },
    {
      label: "Parking Rate per slot per month",
      value: "parkingRate",
      inputType: "text",
    },
    {
      label: "Building Grade",
      value: "buildingGrade",
      inputType: "text",
    },
    {
      label: "Gross Leasable Area",
      value: "grossLeasableArea",
      inputType: "text",
    },
    {
      label: "Floor Plate",
      value: "floorPlate",
      inputType: "text",
    },
    {
      label: "Net Usable Area",
      value: "netUseableArea",
      inputType: "text",
    },
    {
      label: "Backup Power",
      value: "backupPower",
      inputType: "text",
    },
    {
      label: "Elevators",
      value: "elevators",
      inputType: "text",
    },
    {
      label: "Telco Providers",
      value: "telcoProviders",
      inputType: "text",
    },
    {
      label: "Ceiling Height",
      value: "ceilingHeight",
      inputType: "text",
    },
    {
      label: "Parking Allocation",
      value: "parkingAllocation",
      inputType: "text",
    },
    {
      label: "24/7 Operations",
      value: "twentyFourSevenOperations",
      inputType: "text",
      placeholder: "Yes/No",
    },
    {
      label: "Subdivisions",
      value: "subdivisions",
      inputType: "text",
    },
    {
      label: "Retail Tenant in Office Floors",
      value: "retailTenantInOfficeFloors",
      inputType: "text",
      placeholder: "Yes/No",
    },
    {
      label: "BPO",
      value: "bpo",
      inputType: "text",
      placeholder: "Yes/No",
    },
    {
      label: "Online Gaming",
      value: "onlineGaming",
      inputType: "text",
      placeholder: "Yes/No",
    },
    {
      label: "Contact Person",
      value: "contactPerson",
      inputType: "text",
    },
    {
      label: "Contact Email",
      value: "contactEmail",
      inputType: "text",
    },
    {
      label: "Available Space",
      value: "availableSpace",
      inputType: "text",
    },
    {
      label: "Description",
      value: "description",
      inputType: "text",
    },
    {
      label: "Remarks",
      value: "remarks",
      inputType: "text",
    },
  ];

  const menuItems = [
    {
      label: "Detailed Information",
      id: 1,
    },
    {
      label: "Photos",
      id: 2,
    },
    {
      label: "Leasing Information",
      id: 3,
    },
    {
      label: "Team Members",
      id: 4,
    },
  ];

  const handleEdit = (e) => {
    e.preventDefault();
    setIsEditing(!isEditing);
    if (isEditing) {
      const docObject = {
        ...formData,
        lastUpdatedBy: currentUser.email,
        lastUpdatedOn: new Date().toLocaleString(),
      };
      setDocData("masterlist", id.id, docObject);
    }
  };

  const handleFloorPlanUpload = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const storageRef = ref(storage, `floorPlans/${id.id}/${v4()}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setUrl(downloadURL);
          setFormData({ ...formData, floorPlanUrl: downloadURL });
          setDocData("masterlist", id.id, { floorPlanUrl: downloadURL });
          toast.success("Floor Plan uploaded successfully");
          setProgress(0);
        });
      }
    );
    setFloorPlanUpload(null);
  };

  const handleDeleteFloorPlan = async (e) => {
    if (!window.confirm("Are you sure you want to delete this floor plan?")) {
      return;
    } else {
      e.preventDefault();
      setUrl("");
      setFormData({ ...formData, floorPlanUrl: "" });
      await setDocData("masterlist", id.id, { floorPlanUrl: "" });
      await deleteObjectByUrl(formData.floorPlanUrl).then(() => {
        toast.success("Floor Plan deleted successfully");
      });
    }
  };

  const navigate = useNavigate();

  const handleDeleteBuilding = async (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete this building?")) {
      await deleteDocById("masterlist", id.id).then(() => {
        toast.success("Building deleted successfully");
        navigate("/leasing");
      });
    }
  };

  return (
    <div className="flex flex-col gap-4 relative">
      <div className="flex items-start gap-4 flex-wrap bubble">
        <img
          src={formData.primaryPhotoUrl || NoPhoto}
          alt=""
          className="w-60 border border-white"
        />
        <div className="flex flex-col gap-4">
          <div>
            <h1 className="text-xl text-orange-600">{formData.buildingName}</h1>
            {currentUser.roles.includes("admin") && (
              <div className="absolute top-2 right-2 underline px-4 py-2 rounded-lg text-gray-600 text-xs">
                <button onClick={handleDeleteBuilding} className="underline">
                  Delete{" "}
                </button>
              </div>
            )}
            <a
              href={formData.googleMapsPin}
              target="_blank"
              className="text-gray-600 text-xs underline font-bold"
            >
              Click for google maps pin
            </a>
          </div>
          <div className="header">
            Typical Floor Plan
            <div className="relative">
              {formData.floorPlanUrl && (
                <img
                  className="h-60 border border-leechiuOrange"
                  src={formData.floorPlanUrl}
                  alt=""
                />
              )}
              {isEditing && formData.floorPlanUrl && (
                <button
                  className="hover:underline text-xs text-leechiuOrange"
                  onClick={handleDeleteFloorPlan}
                >
                  Delete
                </button>
              )}
              {isEditing && (
                <div className="mt-4">
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={handleFloorPlanUpload}
                  />
                  {progress !== 0 && (
                    <progress value={progress} max="100" className="h-2" />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center gap-4 flex-wrap">
        {menuItems.map((item) => (
          <button
            className={
              activeMenu === item.id ? `active-pill ` : `inactive-pill `
            }
            onClick={() => setActiveMenu(item.id)}
            key={item.id}
          >
            {item.label}
          </button>
        ))}
      </div>

      {activeMenu === 1 && (
        <>
          <div className="">
            <Button label={isEditing ? "Save" : "Edit"} onClick={handleEdit} />
          </div>
          <div>
            {!isEditing && (
              <BuildingDetailsTable
                formData={formData}
                tableFields={tableFields}
              />
            )}
          </div>

          <div>
            {isEditing && (
              <BuildingDetailsForm
                formData={formData}
                handleChange={handleChange}
                isEditing={isEditing}
                formFields={tableFields}
              />
            )}
          </div>
        </>
      )}

      {activeMenu === 2 && (
        <div className="flex flex-col">
          <div className="flex items-center gap-2 ">
            <BuildingPhotos buildingId={id.id} buildingDoc={doc} />
          </div>
        </div>
      )}

      {activeMenu === 3 && (
        <div>
          <LeasingInformation />
        </div>
      )}

      {activeMenu === 4 && (
        <div>
          <AssignedBuildingAgent />
        </div>
      )}
    </div>
  );
}

export default BuildingDetails;
