import React, { useEffect, useState } from "react";
import { getAllDocsSnap } from "../Firebase/Firebase.utils";

export default function UseGetAllDoc({ collection }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      getAllDocsSnap(collection, (data) => {
        setData(data);
        setIsLoading(false);
      });
    };
    fetchData();
  }, []);

  return { data, isLoading };
}
