import { Button } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { createNewDocument } from "../../Firebase/Firebase.utils";
import { useNavigate } from "react-router-dom";
import InputBox from "../../components/InputBox";
import { useState } from "react";
import ISBillingFormComponent from "./ISBillingFormComponent";
import toast from "react-hot-toast";

function ISbilling() {
  const [formData, setFormData] = React.useState({});
  const [isProcessing, setIsProcessing] = React.useState(false);
  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);

    if (formData.approver === "" || null || undefined) {
      toast.error("Please select an approver");
      setIsProcessing(false);
      return;
    }

    const billingDoc = {
      ...formData,
      comments: [],
      billing_type: "sale",
      created_at: new Date(),
      created_by: currentUser.email,
      status: "draft",
      files: {},
    };

    const submitBilling = async () => {
      try {
        const res = await createNewDocument("billing", billingDoc);
        const id = res.id;
        setIsProcessing(false);

        navigate(`/bill-support/${id}`);
      } catch (error) {
        alert(error.message);
        setIsProcessing(false);
      }
    };

    if (window.confirm("Are you sure you want to submit this billing?")) {
      submitBilling();
      setIsProcessing(false);
    } else {
      setIsProcessing(false);
      return;
    }
  };

  const handleSaveDraft = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const billingDoc = {
      ...formData,
      created_at: new Date(),
      created_by: currentUser.email,
      status: "draft",
      billing_type: "sale",
    };

    const saveDraft = async () => {
      try {
        await createNewDocument("billing", billingDoc);
        setIsProcessing(false);

        navigate(`/dashboard`);
      } catch (error) {
        alert(error.message);
        setIsProcessing(false);
      }
    };
    saveDraft();
  };

  return (
    <div>
      <ISBillingFormComponent
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handleSaveDraft={handleSaveDraft}
        isProcessing={isProcessing}
        formData={formData}
      />
    </div>
  );
}

export default ISbilling;
