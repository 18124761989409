import React, { useEffect } from "react";
import { getAllTasks } from "../../Firebase/Firebase.utils";
import AccountTaskCard from "./AccountTaskCard";
import Modal from "../../components/Modal";
import AddTaskForm from "./AddTaskForm";
import { useParams } from "react-router-dom";

function TaskManagement({ listing }) {
  const [tasks, setTasks] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [activeFilter, setActiveFilter] = React.useState("pending");
  const [addMode, setAddMode] = React.useState(false);
  const { id } = useParams();

  useEffect(() => {
    const fetchTasks = async () => {
      const tasks = getAllTasks("tasks", id, (data) => {
        //arrange by due_date in ascending order
        data.sort((a, b) => {
          return new Date(a.due_date) - new Date(b.due_date);
        });
        setTasks(data);
        setIsLoading(false);
      });
    };
    fetchTasks();
  }, []);

  let filteredtasks = [];
  if (activeFilter === "pending") {
    filteredtasks = tasks.filter((task) => task.status === "Open");
  } else {
    filteredtasks = tasks.filter((task) => task.status === "Completed");
  }

  if (isLoading) {
    return (
      <div>
        <div className="header mt-4">All Account Tasks</div>
        <div className="mt-4">Loading...</div>
      </div>
    );
  }

  const handleActiveFilter = (filter) => {
    setActiveFilter(filter);
  };

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  return (
    <div>
      <div>
        <div className="header mt-4">All Account Tasks</div>
        <button
          className="bg-leechiuBlue text-xs text-white px-2 py-1"
          onClick={handleAddMode}
        >
          Add Task
        </button>
        <div className="flex items-center gap-2 mt-4">
          <div
            className={
              activeFilter === "pending" ? "active-pill" : "inactive-pill"
            }
            onClick={() => handleActiveFilter("pending")}
          >
            Pending
          </div>
          <div
            className={
              activeFilter === "completed" ? "active-pill" : "inactive-pill"
            }
            onClick={() => handleActiveFilter("completed")}
          >
            Completed
          </div>
        </div>
        <div>
          {filteredtasks.map((task) => (
            <div key={task.id} className="text-left mt-2">
              <AccountTaskCard task={task} />
            </div>
          ))}
          {filteredtasks.length === 0 && (
            <div className="text-left mt-4 text-sm text-gray-600">
              No tasks found
            </div>
          )}
        </div>
      </div>

      {addMode && (
        <Modal>
          <AddTaskForm listing={listing} handleAddMode={handleAddMode} />
        </Modal>
      )}
    </div>
  );
}

export default TaskManagement;
