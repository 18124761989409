import React from "react";
import { useState } from "react";
import Inventory from "./Inventory";
import Transactions from "./Transactions";

export default function LeasingInformation() {
  const [activeMenu, setActiveMenu] = useState(1);

  const menuItems = [
    { id: 1, label: "Inventory" },
    { id: 2, label: "Transactions" },
  ];

  const activePage = () => {
    switch (activeMenu) {
      case 1:
        return <Inventory />;
      case 2:
        return <Transactions />;
      default:
        return <Inventory />;
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-4">
        {menuItems.map((item) => (
          <button
            className={
              activeMenu === item.id ? `active-pill ` : `inactive-pill `
            }
            onClick={() => setActiveMenu(item.id)}
            key={item.id}
          >
            {item.label}
          </button>
        ))}
      </div>
      <div className="flex flex-col mt-4">{activePage()}</div>
    </div>
  );
}
