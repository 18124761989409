import React from "react";

function MarketingUnderCons() {
  return (
    <div className="bg-white rounded-xl p-2 md:min-w-[700px] w-[300px]">
      <div>This page is being updated.. </div>
    </div>
  );
}

export default MarketingUnderCons;
