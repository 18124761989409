import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

function Closing() {
  const { id } = useParams();

  return (
    <div>
      <Link to={`/listing/${id}`}>Back</Link>
    </div>
  );
}

export default Closing;
