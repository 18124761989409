import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import AddDocForm from "./AddDocForm";
import {
  deleteDocById,
  getAllDocsSnap,
  deleteObjectByUrl,
} from "../../Firebase/Firebase.utils";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

export default function TemplatesLegal() {
  const currentUser = useSelector((state) => state.userObject);
  const [addMode, setAddMode] = useState(false);
  const [docs, setDocs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchDocs = async () => {
      await getAllDocsSnap("legalTemplates", (data) => {
        setDocs(data);
        setIsLoading(false);
      });
    };
    fetchDocs();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleAddDoc = () => {
    setAddMode(!addMode);
  };

  const handleDelete = async (doc) => {
    if (window.confirm("Are you sure you want to delete this file?")) {
      await deleteDocById("legalTemplates", doc.id);
      await deleteObjectByUrl(doc.url);
      toast.success("Document deleted successfully");
    } else return;
  };

  return (
    <div>
      <div className="header">Legal Templates</div>

      {currentUser.roles.includes("admin") && (
        <div className="mt-4">
          <button className="blue-button" onClick={handleAddDoc}>
            Add a new document
          </button>
        </div>
      )}

      <div className="mt-4 space-y-2">
        {docs.map((doc, index) => (
          <div key={index} className="card">
            <div className="flex items-center gap-8">
              <div className="font-bold text-leechiuBluen w-80">
                {doc.fileName}
              </div>
              <div className="w-36">{doc.version}</div>
              <div>{doc.description}</div>
              <a href={doc.url} target="_blank" className="underline">
                Download Link
              </a>
              {currentUser.roles.includes("admin") && (
                <button
                  className="blue-button"
                  onClick={() => handleDelete(doc)}
                >
                  Delete
                </button>
              )}
            </div>
            <div>{doc.fileDesc}</div>
          </div>
        ))}
      </div>

      {addMode && (
        <Modal>
          <div className="small-form">
            <AddDocForm setAddMode={setAddMode} />
          </div>
        </Modal>
      )}
    </div>
  );
}
