import React from "react";
import UserAvatar from "../../components/UserAvatar";

function TenantCards({ tenant }) {
  return (
    <div className="relative">
      <div className="px-4 py-2 rounded-xl shadow-lg bg-white hover:bg-gray-200 hover:cursor-pointer">
        <div className="font-bold">{tenant.tenantName}</div>
        <div className="text-xs">
          <div>Size: {tenant.requirementSize} square meters</div>
          <div>Requirement Type:{tenant.requirementType}</div>
          <div className="uppercase">{tenant.requirementLocation}</div>
        </div>
        <div className="flex items-center gap-2 absolute top-2 right-2">
          {tenant.team_members?.map((member, index) => (
            <div key={index} className="flex items-center gap-2">
              <UserAvatar email={member} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TenantCards;
