import React from "react";
import InputBox from "../../components/InputBox";
import Button from "../../components/Button";
import { useState } from "react";
import { addNewDoc } from "../../Firebase/Firebase.utils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function NewBuilding() {
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();

  const currentUser = useSelector((state) => state.userObject);

  const formFields = [
    {
      label: "Building Name",
      placeholder: "Enter building name",
      name: "buildingName",
      required: true,
    },
    {
      label: "Address",
      placeholder: "Enter address",
      name: "address",
      required: true,
    },
    {
      label: "Google Maps Pin",
      placeholder: "paste here google maps pin link",
      name: "googleMapsPin",
      required: true,
    },
    {
      label: "Business District",
      placeholder: "Enter business district",
      name: "businessDistrict",
      required: true,
    },
    {
      label: "City",
      placeholder: "e.g. Makati",
      name: "city",
      required: true,
    },

    {
      label: "Exclusive",
      placeholder: "Yes or No",
      name: "exclusive",
      required: true,
    },
  ];

  const addDoc = async (e) => {
    e.preventDefault();
    const docObject = {
      ...formData,
      createdAt: new Date(),
      createdBy: currentUser.email,
    };

    try {
      const doc = await addNewDoc("masterlist", docObject);
      toast.success("New building added");
      navigate(`/building/${doc.id}`);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleAddBuilding = (e) => {
    e.preventDefault();
    addDoc(e);
  };

  const handleChange = (e) => {
    setFormData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center bg-white p-4 rounded shadow-lg border max-w-5xl">
        <div className="mt-8">
          <h4 className="mb-4">Add New Building</h4>
          <form
            className="flex items-end gap-2 flex-wrap"
            onSubmit={handleAddBuilding}
          >
            <div className="flex items-end gap-2 flex-wrap">
              {formFields.map((field, index) => (
                <InputBox
                  key={index}
                  label={field.label}
                  placeholder={field.placeholder}
                  name={field.name}
                  onChange={handleChange}
                  required={field.required}
                />
              ))}
            </div>
            <div className="mt-8">
              <Button label="Submit" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default NewBuilding;
