import React from "react";

function RequirementDetailedInfo({ requirementData }) {
  const tableFields = [
    { label: "Tenant Name", value: "tenantName" },
    { label: "Type", value: "requirementType" },
    { label: "Size", value: "requirementSize" },
    { label: "Location", value: "requirementLocation" },
    { label: "Budget", value: "requirementBudget" },
    { label: "Move-In Date", value: "requirementMoveInDate" },
    { label: "Lease Term", value: "requirementTerm" },
    { label: "Potential Fee", value: "potentialFee" },
    { label: "Source of Lead", value: "leadSource" },
    { label: "Notes", value: "requirementNotes" },
  ];

  return (
    <div className="mt-4">
      <div className="header">Requirement Detailed Information</div>
      {
        <div className="flex items-center flex-wrap max-w-5xl text-sm">
          <table className="w-full bg-white border border-gray-300">
            <tbody>
              {tableFields.map((field, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
                >
                  <td className="border border-gray-300 px-2 py-1">
                    {field.label}
                  </td>
                  {field.value === "requirementSize" ? (
                    <td className="border border-gray-300 px-2 py-1">
                      {parseFloat(
                        requirementData[field.value]
                      ).toLocaleString()}{" "}
                      sqms
                    </td>
                  ) : (
                    <td className="border border-gray-300 px-2 py-1">
                      {requirementData[field.value]}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      }
    </div>
  );
}

export default RequirementDetailedInfo;
