import React from 'react'

function NewAllTasks() {
  return (
    <div>
        <div>All Tasks Assigned to You</div>
    </div>
  )
}

export default NewAllTasks