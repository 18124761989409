import React from "react";
import {
  queryAllDocs,
  addNewDoc,
  getAllDocsSnap,
} from "../../Firebase/Firebase.utils";
import { useState, useEffect } from "react";
import InputBox from "../../components/InputBox";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import Modal from "./Modal";
import AddNewCompanyForm from "./AddNewCompanyForm";

function Top1k() {
  const [companies, setCompanies] = useState([]);
  const [filteredlist, setFilteredlist] = useState([]);
  const [originalList, setOriginalList] = useState([]);
  const [addMode, setAddMode] = useState(false);

  useEffect(() => {
    const getDocs = async () => {
      const docs = await getAllDocsSnap("companies", (data) => {
        setCompanies(data);
      });
    };
    getDocs();
  }, []);

  //arrange alphabetically by company name
  useEffect(() => {
    const sorted = companies.sort((a, b) => {
      if (a.company_name < b.company_name) {
        return -1;
      }
      if (a.company_name > b.company_name) {
        return 1;
      }
      return 0;
    });
    setOriginalList(sorted);
    setFilteredlist(sorted);
  }, [companies]);

  if (companies.length === 0)
    return (
      <div className="">
        <div>Loading...</div>
      </div>
    );

  const onSearch = (e) => {
    const filtered = companies.filter((company) => {
      return company?.company_name
        ?.toString()
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });
    setFilteredlist(filtered);
  };

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  return (
    <div>
      <div className="flex flex-col gap-2">
        <div className="flex flex-col items-start justify-normal gap-4 max-w-[1080px]">
          <button
            onClick={handleAddMode}
            className="bg-leechiuBlue text-xs hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Add a new company
          </button>
          <input
            className="px-4 py-2 border border-gray-300 rounded-lg w-80 sm:w-96 "
            placeholder={"search company here"}
            onChange={onSearch}
            type="search"
          />
        </div>
        {filteredlist.map((company, index) => (
          <div
            key={index}
            className="bg-white rounded-xl p-2 shadow-sm border hover:bg-gray-200 cursor-pointer max-w-5xl text-gray-600"
          >
            <Link to={`/company/${company.id}`} key={company.id}>
              <div>{company.company_name}</div>
              <p>{company.company_address}</p>
            </Link>
          </div>
        ))}
      </div>

      {addMode && (
        <Modal>
          <AddNewCompanyForm setAddMode={setAddMode} />
        </Modal>
      )}
    </div>
  );
}

export default Top1k;
