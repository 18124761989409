import React, { useEffect } from "react";
import InputBox from "../components/InputBox";
import Select from "react-select";
import ProfilePhotoOnly from "../components/ProfilePhotoOnly";
import { useSelector } from "react-redux";
import { addNewDoc } from "../Firebase/Firebase.utils";
import toast from "react-hot-toast";

function NewTaskForm({ requirementData, handleAddMode, id }) {
  const currentUser = useSelector((state) => state.userObject);
  const [formData, setFormData] = React.useState({});
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [teamMembers, setTeamMembers] = React.useState(
    requirementData.team_members
  );

  const options = teamMembers.map((member) => ({
    value: member,
    label: <ProfilePhotoOnly email={member} />,
  }));

  useEffect(() => {}, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelect = (selectedOption) => {
    setFormData({
      ...formData,
      assignee_emails: selectedOption.map((option) => option.value),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const docObject = {
      ...formData,
      listing_id: id,
      type: "landlordRep",
      status: "Open",
      createdAt: new Date().toLocaleString(),
      createdBy: currentUser.email,
    };
    setIsSubmitting(true);
    await addNewDoc("tasks", docObject);
    setIsSubmitting(false);
    setFormData({});
    handleAddMode();
    toast.success("Task added successfully");
  };

  return (
    <div className="small-form relative">
      <div className="header">Add New Task</div>
      <button className="close-button" onClick={handleAddMode}>
        Close
      </button>

      <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
        <InputBox
          label="Task Name"
          placeholder="Enter task name"
          type="text"
          required
          name="task_name"
          onChange={handleChange}
        />

        <InputBox
          label="Task Description"
          placeholder="Enter task description"
          type="text"
          name="description"
          onChange={handleChange}
        />

        <InputBox
          label="Due Date"
          type="date"
          required
          name="due_date"
          onChange={handleChange}
        />

        <div>
          <label
            htmlFor=""
            className="text-sm font-semibold text-gray-600 uppercase"
          >
            Assigned To
          </label>
          <Select
            className="w-80"
            options={options}
            isMulti={true}
            onChange={handleSelect}
            required
          />
        </div>

        <button className="blue-button" type="submit">
          {isSubmitting ? "Adding Task..." : "Add Task"}
        </button>
      </form>
    </div>
  );
}

export default NewTaskForm;
