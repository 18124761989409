import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getDocData, getAllPhotosById } from "../../Firebase/Firebase.utils";
import { Photo } from "@mui/icons-material";
import PhotoCarousel from "./PhotoCarousel";

function WebView() {
  const { id } = useParams();
  const [listing, setListing] = useState({});
  const [photoCarousel, setPhotoCarousel] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getDoc = async () => {
      const doc = await getDocData("listings", id);
      setListing(doc);
      setIsLoading(false);
    };
    getDoc();
  }, []);

  useEffect(() => {
    const getPhotos = async () => {
      const photos = await getAllPhotosById("photos", id);
      const photoArray = photos.map((photo) => {
        return photo.url;
      });
      setPhotoCarousel(photoArray);
    };
    getPhotos();
  }, [listing]);

  const handleClick = (e, index) => {
    e.preventDefault();
    setPhotoIndex(index);
  };

  const activePhotoStyle = "border-4 border-orange-600";

  const photoCarouselItems = photoCarousel.map((photo) => (
    <div
      className={`w-[5rem] cursor-pointer bg-black ${
        photoIndex === photoCarousel.indexOf(photo) ? activePhotoStyle : ""
      }`}
      key={photo.id}
      onClick={(e) => handleClick(e, photoCarousel.indexOf(photo))}
    >
      <img src={photo.url} alt="" className="object-contain" />
    </div>
  ));

  if (isLoading) {
    return <div className="m-auto text-center">Loading...</div>;
  }

  return (
    // container
    <div className="bg-white p-4 rounded-lg shadow-2xl flex flex-col max-w-5xl m-auto">
      {/* title */}
      

      <div className="flex items-center border-l-8 border-orange-600 gap-4 mb-4">
        <div className="h-12"></div>
        <div className="text-indigo-800 font-bold text-xl">
          Property {listing.listing_type} - {listing.address}
        </div>
      </div>

      {/* photos */}
      <PhotoCarousel photos={photoCarousel} />

      {/* property particulars */}
      <div className="mt-8">
        <div className="text-sm text-gray-600 font-bold">
          Property Particulars
        </div>

        {/* table for the information */}
        <div className="mt-4">
          <table className="">
            <tbody className="text-xs">
              <tr>
                <td className="border border-gray-300 px-4 py-2">Lot Area</td>
                <td className="border border-gray-300 px-4 py-2">
                  {parseFloat(listing.lot_area).toLocaleString()} square meters
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2">Address</td>
                <td className="border border-gray-300 px-4 py-2">
                  {listing.address}
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2">
                  Asking Price per sqm
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  Php {parseFloat(listing.selling_price).toLocaleString()} per
                  square meter
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2">
                  Total Price
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  Php {parseFloat(listing.total_price).toLocaleString()} + VAT
                  if applicable
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2">
                  Description
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {listing.listing_description}
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2">Zoning</td>
                <td className="border border-gray-300 px-4 py-2">
                  {listing.zoning}
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2">Frontage</td>
                <td className="border border-gray-300 px-4 py-2">
                  {listing.frontage}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default WebView;
