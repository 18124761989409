import React, { useEffect } from "react";
import InputBox from "../components/InputBox";
import Select from "react-select";
import { useState } from "react";
import Button from "../components/Button";

import { motion } from "framer-motion";
import { setDocData } from "../Firebase/Firebase.utils";
import { useSelector } from "react-redux";
import { v4 } from "uuid";
import { getDocDataSnap } from "../Firebase/Firebase.utils";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";

function MyClients() {
  const [formData, setFormData] = useState({});
  const [industry, setIndustry] = useState([]);
  const [showNewContact, setShowNewContact] = useState(false);
  const [phonebook, setPhonebook] = useState([]);
  const [filteredPhonebook, setFilteredPhonebook] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [openSubMenuId, setOpenSubMenuId] = useState(null);
  const currentUser = useSelector((state) => state.userObject);
  const [sortedPhonebook, setSortedPhonebook] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const getDocument = async () => {
      try {
        const res = await getDocDataSnap("users", currentUser.uid, (data) => {
          if (data.phonebook === undefined) {
            setPhonebook([]);
            setFilteredPhonebook([]);
          } else {
            // Sort the phonebook array alphabetically by name
            const sorted = data.phonebook
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name));

            setPhonebook(sorted);
            setFilteredPhonebook(sorted);
            setSortedPhonebook(sorted);
          }
        });
      } catch (error) {}
    };
    getDocument();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpenSubMenuId(null);
    let contactObject = {};

    if (
      formData.contactId === undefined ||
      formData.contactId === null ||
      formData.contactId === ""
    ) {
      const contactId = v4();
      contactObject = {
        phonebook: [
          ...phonebook,
          {
            contactId: contactId,
            ...formData,
            industry: industry,
            createdAt: new Date(),
            createdBy: currentUser.email,
          },
        ],
      };
    } else {
      contactObject = {
        phonebook: [
          ...phonebook,
          {
            ...formData,
            industry: industry,
            createdAt: new Date(),
            createdBy: currentUser.email,
          },
        ],
      };
    }

    const setDocument = async () => {
      try {
        const res = await setDocData("users", currentUser.uid, contactObject);
      } catch (error) {}
    };

    setDocument();
    setFormData({});
    setIndustry([]);
    setShowNewContact(false);
    setEditMode(false);
  };

  const handleSelect = (e) => {
    let industry = [];
    e.map((item) => {
      industry.push(item.value);
    });
    setIndustry(industry);
    setFormData({ ...formData, industry: industry });
  };

  const options = [
    { value: "Real Estate", label: "Real Estate" },
    { value: "Hospitality", label: "Hospitality" },
    { value: "Finance", label: "Finance" },
    { value: "IT-BPM", label: "IT-BPM" },
    { value: "Residential", label: "Residential" },
    { value: "Industrial", label: "Industrial" },
    { value: "Retail", label: "Retail" },
    { value: "Mixed-use", label: "Mixed-use" },
    { value: "Others", label: "Others" },
  ];

  const handleShowNewContact = () => {
    setShowNewContact(!showNewContact);
    setOpenSubMenuId(null);
  };

  const handleDelete = (e, contactId) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete this contact?")) {
      const filteredPhonebook = phonebook.filter((item) => {
        return item.contactId !== contactId;
      });
      const contactObject = {
        phonebook: filteredPhonebook,
      };

      const setDocument = async () => {
        try {
          const res = await setDocData("users", currentUser.uid, contactObject);
        } catch (error) {}
      };
      setDocument();
      setPhonebook(filteredPhonebook);
    } else {
      return;
    }
  };
  const handleSearch = (e) => {
    const search = e.target.value.toLowerCase();

    const filteredPhonebook = phonebook.filter((item) => {
      // Convert both the item's name and company name to lowercase
      const itemNameLower = item.name.toLowerCase();
      const companyLower = item.company.toLowerCase();

      // Check if either the name or the company name includes the search value
      return itemNameLower.includes(search) || companyLower.includes(search);
    });

    setFilteredPhonebook(filteredPhonebook);
  };

  const handleEdit = (e, contact) => {
    e.preventDefault();
    setEditMode(true);
    setShowNewContact(true);
    setFormData(contact);
    setIndustry(contact.industry);

    const filteredPhonebook = phonebook.filter((item) => {
      return item.contactId !== contact.contactId;
    });
    setFilteredPhonebook(filteredPhonebook);
    setPhonebook(filteredPhonebook);
  };

  const handleSubmenu = (e, elementId) => {
    e.preventDefault();

    if (openSubMenuId === elementId) {
      setOpenSubMenuId(null);
      return;
    }
    setOpenSubMenuId(elementId);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setFormData({});
    setIndustry([]);
    setShowNewContact(false);
    setEditMode(false);
    setPhonebook(sortedPhonebook);
    setFilteredPhonebook(sortedPhonebook);
    setOpenSubMenuId(null);
  };

  return (
    <motion.div
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      className=""
    >
      <div className="mb-4">
        <Button
          label={showNewContact ? "Hide" : "Add"}
          onClick={handleShowNewContact}
        />
      </div>
      <div>
        {showNewContact && (
          <motion.form
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            onSubmit={handleSubmit}
            className="bg-gray-100 p-4 rounded-md mb-4 border shadow-md "
          >
            <h4 className="mb-2">Add a new Client Contact</h4>
            <div className="flex flex-wrap gap-2">
              <InputBox
                label="Client Name"
                type="text"
                placeholder="Enter Client Name"
                name="name"
                required
                onChange={handleChange}
                value={formData.name}
              />
              <InputBox
                label="Client Company"
                type="text"
                placeholder="Enter none if not applicable"
                name="company"
                onChange={handleChange}
                value={formData.company}
                required
              />
              <InputBox
                label="Client Email"
                type="email"
                placeholder="Enter Client Email"
                name="email"
                onChange={handleChange}
                value={formData.email}
              />
              <InputBox
                label="Client Phone"
                type="text"
                placeholder="Enter Client Phone"
                name="phone"
                onChange={handleChange}
                value={formData.phone}
              />
              <InputBox
                label="Position"
                type="text"
                placeholder="Enter Position"
                name="position"
                onChange={handleChange}
                value={formData.position}
              />

              <div className="flex flex-col w-80 mb-4">
                <label
                  className="text-sm font-semibold text-gray-600 mb-2"
                  htmlFor=""
                >
                  Select Industry
                </label>
                <Select
                  type="text"
                  placeholder="Select Industry"
                  isMulti
                  options={options}
                  name="industry"
                  onChange={handleSelect}
                  value={options.filter((item) => {
                    return industry.includes(item.value);
                  })}
                />
              </div>
            </div>
            <div className="flex gap-x-4">
              <Button label="Submit" type="submit" />
              <Button label="Cancel" type="reset" onClick={handleCancel} />
            </div>
          </motion.form>
        )}
      </div>

      {!showNewContact && (
        <div className="mb-4">
          <InputBox placeholder={"Search"} onChange={handleSearch} />
        </div>
      )}

      {filteredPhonebook &&
        filteredPhonebook.map((item) => {
          return (
            <div
              className="bg-white shadow border p-5 flex items-center  rounded-md relative mb-4 max-w-5xl"
              key={item.contactId}
            >
              <button
                className="absolute bottom-0 right-0 cursor-pointer"
                onClick={(e) => {
                  handleSubmenu(e, item.contactId);
                }}
              >
                <MoreVertIcon />
              </button>

              <div className="">
                <div className="lg:flex lg:gap-x-4 [&>*]:text-xs [&>*]:text-gray-600">
                  <div className="capitalized font-bold">{item.name}</div>
                  <div>{item.company}</div>
                  <div className="flex flex-col lg:flex-row lg:gap-x-4">
                    <a className="mb-2 lg:mb-0" href={`mailto:${item.email}`}>
                      {item.email}
                    </a>
                    <a href={`tel:${item.phone}`}>{item.phone}</a>
                  </div>
                </div>

                {item.industry && (
                  <div className="flex flex-wrap gap-x-2 absolute top-0 right-0">
                    {item.industry.map((item) => {
                      return (
                        <div className="">
                          <span className="bg-gray-200 text-xs px-2 py-1 rounded-md capitalize">
                            {item}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}

                {openSubMenuId === item.contactId ? (
                  <div
                    className=" btn-group absolute -bottom-12 right-0 flex  gap-x-2 z-10 bg-gray-600 bg-opacity-50 rounded-md p-2  text-center"
                    id={item.contactId}
                  >
                    <button
                      disabled={editMode}
                      onClick={(e) => {
                        handleEdit(e, item);
                      }}
                      className="bg-blue-500 text-white px-4 py-2 rounded-md
                    disabled:opacity-50 disabled:cursor-not-allowed
                         "
                    >
                      Edit
                    </button>
                    <button
                      disabled={editMode}
                      className="bg-red-500 text-white px-4 py-2 rounded-md
                disabled:opacity-50 disabled:cursor-not-allowed
                "
                      onClick={(e) => {
                        handleDelete(e, item.contactId);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
    </motion.div>
  );
}

export default MyClients;
