import React from "react";
import UserAvatar from "../../components/UserAvatar";
import { setDocData, deleteDocById } from "../../Firebase/Firebase.utils";
import toast from "react-hot-toast";

function AccountTaskCard({ task }) {
  const handleClick = async () => {
    let newStatus = task.status === "Open" ? "Completed" : "Open";
    let newTask = { ...task, status: newStatus };
    await setDocData("tasks", task.id, newTask);
    toast.success("Task status updated successfully");
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this task?")) {
      await deleteDocById("tasks", task.id);
      toast.success("Task deleted successfully");
    }
  };
  return (
    <div className="bubble text-xs text-gray-600 relative">
      <div className="absolute  text-xs text-gray-600 top-1 right-1 flex items-center gap-2">
        <button className="underline" onClick={handleDelete}>
          Delete
        </button>
        <div onClick={handleClick}>
          {task.status === "Open" ? "Mark as Completed" : "Reopen Task"}
        </div>
      </div>

      <div className="font-bold capitalize-first mt-4">{task.task_name}</div>
      <div>{task.status}</div>
      <div>{task.due_date}</div>
      <div className="mt-2 flex items-center gap-2 flex-wrap">
        {task.assignee_emails?.map((email, index) => (
          <div>
            <UserAvatar email={email} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default AccountTaskCard;
