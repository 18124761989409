import React from "react";
import SideNavBar from "./SideNavBar";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { loadUser } from "../store/userSlice";
import { useDispatch } from "react-redux";

function MarketingDashboard() {
  const currentUser = useSelector((state) => state.userObject);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(loadUser({}));
  };

  return (
    <div className=" bg-gray-200 min-h-screen">
      <div
        className="
      text-xs md:text-sm
      flex items-center justify-between gap-2 bg-leechiuBlue  text-white p-2 sticky top-0 z-10"
      >
        <div> CMS for Leechiu Website</div>
        <input
          type="text"
          className="border px-2 py-1 w-96 rounded-xl"
          placeholder="search here"
        />
        <button
          onClick={handleLogout}
          className="bg-leechiuBlue text-white px-4 py-2 rounded border border-leechiuBlue"
        >
          Log Out
        </button>
      </div>
      <div className="flex items-start justify-center gap-4 mt-4 px-2">
        <div className="">
          <SideNavBar />
        </div>
        <div className="w-fit md:w-[700px] rounded-xl">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default MarketingDashboard;
