import React, { useEffect } from "react";
import InputBox from "../components/InputBox";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { addNewDoc, getAllDocsSnap } from "../Firebase/Firebase.utils";
import AddNewsForm from "./AddNewsForm";
import { Article } from "@mui/icons-material";
import ArticleCard from "./ArticleCard";

export default function News() {
  const [formData, setFormData] = React.useState({});
  const [isProcessing, setIsProcessing] = React.useState(false);
  const currentUser = useSelector((state) => state.userObject);
  const [addMode, setAddMode] = React.useState(false);
  const [newsArticles, setNewsArticles] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const fetchNews = async () => {
      await getAllDocsSnap("news", (data) => {
        setNewsArticles(data);
        setIsLoading(false);
      });
    };

    fetchNews();
  }, []);

  if (isLoading) {
    return (
      <div className="bubble">
        <div className="header">News Articles</div>
        <div>Loading...</div>
      </div>
    );
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);

    const docObject = {
      ...formData,
      type: "news",
      status: "unpublished",
      created_at: new Date().toLocaleString(),
      createdBy: currentUser.email,
    };

    setIsProcessing(true);
    await addNewDoc("news", docObject);
    toast.success("Article saved successfully");
    setFormData({});
    setIsProcessing(false);
    setAddMode(false);
  };

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  return (
    <div className="news-articles">
      <div className="bubble">
        <div className="header">News Articles</div>
        <div>
          <div>
            <button className="blue-button" onClick={handleAddMode}>
              {addMode ? "Close" : "Add New Article"}
            </button>
          </div>
          {addMode && (
            <AddNewsForm
              formData={formData}
              setFormData={setFormData}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              isProcessing={isProcessing}
            />
          )}
        </div>
      </div>

      <div className="space-y-4 mt-4">
        <div className="header bubble">Your Articles</div>
        <div className="space-y-4">
          {newsArticles.map((article, index) => (
            <div key={index}>
              <ArticleCard article={article} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
