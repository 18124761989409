import React from "react";
import { logOutUser } from "../../Firebase/Firebase.utils";

function NoAccessRoles() {
  const logout = () => {
    logOutUser();
  };
  return (
    <div>
      Your access is being activated. Please wait for the admin to approve your
      access.
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={() => {
          logout();
        }}
      >
        Log Out
      </button>
    </div>
  );
}

export default NoAccessRoles;
