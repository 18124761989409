import React from "react";
import { getAllDocsSnap, updateDocById } from "../../Firebase/Firebase.utils";
import { useState, useEffect } from "react";
import Select from "react-select";
import InputBox from "../../components/InputBox";

function UserAccess() {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUserForEdit, setSelectedUserForEdit] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const getUsers = async () => {
      const data = await getAllDocsSnap("users", (users) => {
        users.sort((a, b) => {
          if (a.displayName < b.displayName) {
            return -1;
          }
          if (a.displayName > b.displayName) {
            return 1;
          }
          return 0;
        });

        setUsers(users);

        setIsLoading(false);
      });
      //arrange users by name
    };
    getUsers();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const options = [
    { value: "user", label: "User" },
    { value: "agent", label: "Agent" },
    { value: "analyst", label: "Analyst" },
    { value: "admin", label: "Admin" },
    { value: "approver", label: "Approver" },
    { value: "finance", label: "Finance" },
    { value: "marketing", label: "Marketing" },
    { value: "restricted", label: "Restricted" },
    { value: "cl admin", label: "CL Admin" },
    { value: "is admin", label: "IS Admin" },
  ];

  const selectUserForEditing = (id) => {
    setSelectedUserForEdit(id);

    if (selectedUserForEdit === id) {
      setSelectedUserForEdit(null);
    }
  };

  const handleSelect = (userid, selectedOption) => {
    let roles = [];
    selectedOption.map((option) => {
      roles.push(option.value);
    });
    updateDocById("users", userid, { roles: roles });
  };

  const handleSelectDepartment = (userid, selectedOption) => {
    updateDocById("users", userid, { department: selectedOption.value });
  };

  const departmentOptions = [
    { value: "Investment Sales", label: "Investment Sales" },
    { value: "Commercial Leasing", label: "Commercial Leasing" },
    { value: "Residential", label: "Residential" },
    { value: "Hospitality", label: "Hospitality" },
    { value: "marketing", label: "Marketing" },
    { value: "finance", label: "Finance" },
    { value: "admin", label: "Admin" },
    { value: "research", label: "Research and valuation" },
  ];

  const filteredUsers = users.filter((user) => {
    return user.displayName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div>
      <div>
        <div className="mb-4">
          <InputBox
            label="Search User"
            onChange={handleSearch}
            placeholder={"Search User"}
            type={"search"}
          />
        </div>
        <table>
          <tr className="[&>*]:p-2 [&>*]:bg-gray-200">
            <th>Full Name</th>
            <th>Email</th>
            <th className="w-72">Department</th>
            <th className="w-72">Roles</th>
          </tr>
          {filteredUsers.map((user) => (
            <tr className="[&>*]:p-2 [&>*]:text-sm" key={user.id}>
              <td>{user.displayName}</td>
              <td>{user.email}</td>
              <td>
                {selectedUserForEdit === user.id ? (
                  <Select
                    onChange={(selectedOption) =>
                      handleSelectDepartment(user.id, selectedOption)
                    }
                    options={departmentOptions}
                    name="department"
                    value={departmentOptions.filter((item) => {
                      return user.department === item.value;
                    })}
                  />
                ) : (
                  user.department
                )}
              </td>
              <td>
                {selectedUserForEdit === user.id ? (
                  <Select
                    onChange={(selectedOption) =>
                      handleSelect(user.id, selectedOption)
                    }
                    options={options}
                    isMulti
                    name="roles"
                    value={options.filter((item) => {
                      return user.roles?.includes(item.value);
                    })}
                  />
                ) : (
                  <div>
                    {user.roles?.map((role) => (
                      <span
                        className="bg-gray-200 p-2 rounded-md mr-2"
                        key={role}
                      >
                        {role}
                      </span>
                    ))}
                  </div>
                )}
              </td>
              <td>
                <button
                  className="bg-blue-500 text-white p-2 rounded-md"
                  onClick={() => selectUserForEditing(user.id)}
                >
                  {selectedUserForEdit === user.id ? "Update" : "Edit"}
                </button>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
}

export default UserAccess;
