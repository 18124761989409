import React from "react";
import { Link, Outlet } from "react-router-dom";
import { IoAppsSharp } from "react-icons/io5";
import { IoList } from "react-icons/io5";
import { BsBuildingCheck } from "react-icons/bs";
import { PiHouseLineLight } from "react-icons/pi";
import { HiMiniBuildingOffice2 } from "react-icons/hi2";
import { MdForum } from "react-icons/md";
import { MdOutlineRequestPage } from "react-icons/md";
import { RiBillLine } from "react-icons/ri";
import { FaPeopleArrows } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function NewDashboard() {
  const [activeLink, setActiveLink] = React.useState("Dashboard");
  const navigate = useNavigate();

  const userLinks = [
    {
      name: "Dashboard",
      link: "newDashboard",
      icon: <IoAppsSharp />,
    },
    {
      name: "All Tasks",
      link: "all-tasks",
      icon: <IoList />,
    },
    {
      name: "All Listings",
      link: "all-listings",
      icon: <BsBuildingCheck />,
    },
    {
      name: "Residential",
      link: "residential",
      icon: <PiHouseLineLight />,
    },
    {
      name: "Office Leasing",
      link: "office-leasing",
      icon: <HiMiniBuildingOffice2 />,
    },
    {
      name: "Discussion Board",
      link: "discussion-board",
      icon: <MdForum />,
    },
    {
      name: "Request to Bill",
      link: "request-to-bill",
      icon: <MdOutlineRequestPage />,
    },
    {
      name: "All Billings",
      link: "all-billings",
      icon: <RiBillLine />,
    },
    {
      name: "Client Database",
      link: "client-database",
      icon: <FaPeopleArrows />,
    },
    {
      name: "My Profile",
      link: "my-profile",
      icon: <FaRegUser />,
    },
  ];

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="bg-leechiuBlue h-12 w-full flex items-center justify-between text-white p-2">
        <div>Logo</div>
        <div className="flex items-center gap-2">
          <div>User</div>
          <div>Notifications</div>
          <div>Log Out</div>
        </div>
      </div>

      <div className="flex items-start justify-center gap-4 mt-4 p-2">
        <div className="w-32 md:w-96 flex flex-col items-start justify-start gap-2 bg-white p-2 rounded-xl">
          {userLinks.map((link, index) => (
            <Link
              to={`/${link.link}`}
              key={index}
              className={`flex items-center gap-2 cursor-pointer
                ${
                  activeLink === link.name
                    ? "font-bold text-leechiuOrange"
                    : "text-gray-500"
                }
                `}
              onClick={() => setActiveLink(link.name)}
            >
              <div>{link.icon}</div>
              <div>{link.name}</div>
            </Link>
          ))}
        </div>
        <div className="w-full md:w-[800px] ">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default NewDashboard;
