import React from "react";
import Logo from "../../assets/leechiu-blue.png";
import LoginForm from "./LoginForm";
import { Link } from "react-router-dom";

function Login() {
  return (
    <div className="text-gray-600 h-screen flex bg-[url('https://images.unsplash.com/photo-1497366754035-f200968a6e72?auto=format&fit=crop&q=80&w=2301&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')] bg-cover bg-center w-screen items-center justify-center flex-col overflow-hidden">
      <div className="bg-black bg-opacity-30 w-screen h-screen flex items-center justify-center">
        <div className="flex items-center justify-center flex-col bg-gray-50 rounded-lg max-h-3xl">
          <img
            src={
              "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721711625/leechiuweb3/newlogo_leechiu_sm3mgv_1_mop8e5.png"
            }
            alt="Leechiu Logo"
            className="h-12 mt-4"
          />
          <LoginForm />
          <div className="flex flex-col justify-center text-sm items-center mb-8">
            <p className="text-sm font-bold">
              No Account Yet? Sign Up{" "}
              <Link className="underline" to="/signup">
                Here
              </Link>
            </p>
            <Link className="underline mt-8 text-sm" to="/resetpassword">
              Forgot My Password
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
