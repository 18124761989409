import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getDocDataSnap } from "../../Firebase/Firebase.utils";
import { useState } from "react";
import UploadFileInput from "../../components/UploadFileInput";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { doc } from "firebase/firestore";
import { loadListing } from "../../store/userSlice";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

import AccordionComponent from "../../components/AccordionComponent";


function DueDiligence() {
  const { id } = useParams();
  const [listing, setListing] = useState({});
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const fetchListing = async () => {
      try {
        const res = await getDocDataSnap("listings", id, (data) => {
          setListing(data);
          setIsLoading(false);
        });
      } catch (error) {}
    };
    fetchListing();
  }, []);

  const myObject = listing.files;

  const requiredFiles = [
    "Agency Agreement",
    "Copy of Title",
    "CTC of Title",
    "Copy of Tax Declaration",
    "CTC of Tax Declaration",
    "Opinion of Value",
    "Lot Plan",
    "Vicinity Map",
    "RPT Receipts",
    "Tax Clearance",
    "Zoning Certification",
    "Flyer",
    "Map Draw",
    "Appraisal Report",
    "Floor Plan",
    "Lease Summary Sheet",
    "Lease Contracts",
    "Articles of Incorporation",
    "By Laws",
    "Board Resolution",
    "Secretary's Certificate",
    "Special Power of Attorney",
    "Master Deed",
  ];

  const handleShowUploadButton = (file) => {
    document.getElementById(file).style.display = "block";
    document.getElementById(`upload-${file}`).style.display = "none";
  };

  const handleHideUploadButton = (file) => {
    document.getElementById(file).style.display = "none";
    document.getElementById(`upload-${file}`).style.display = "block";
  };

  const ForSaleDD = () => {
    return (
      <div>
        {requiredFiles.map((file) => (
          <div
            className="mb-4 shadow-md border border-gray-200 rounded "
            key={file}
          >
            <AccordionComponent
              label={
                <div className="flex gap-2">
                  {myObject && myObject[file] ? (
                    <CheckCircleIcon className="text-green-500" />
                  ) : (
                    <>
                      <ErrorIcon className="text-red-500" />
                    </>
                  )}
                  <a
                    href={myObject[file]}
                    target="_blank"
                    className="text-gray-700 font-normal"
                  >
                    {file}
                  </a>
                </div>
              }
            >
              <div>
                <UploadFileInput
                  name={file}
                  placeholder={file}
                  value={file}
                  collName={"listings"}
                  id={id}
                  disabled={true}
                />
              </div>
            </AccordionComponent>
          </div>
        ))}
      </div>
    );
  };

  return (
    <motion.div
      className="mt-4"
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
    >
      <div className="mb-4">Due Diligence Documents </div>

      {listing.listing_type === "For Sale" ? <ForSaleDD /> : null}
    </motion.div>
  );
}

export default DueDiligence;
