import React from "react";
import HomePage from "./HomePage";
import Datapage from "./Datapage";

function WebsiteSections() {
  const [activeLink, setActiveLink] = React.useState("Home Page");

  const navLinks = [
    {
      name: "Home Page",
      link: "home-page",
    },
    {
      name: "Tenant Representation",
      link: "tenant-representation",
    },
    {
      name: "Landlord Representation",
      link: "landlord-representation",
    },
    {
      name: "Investment Sales",
      link: "investment-sales",
    },
    {
      name: "Research and Consultancy",
      link: "research-and-consultancy",
    },
    {
      name: "Valuation and Property Appraisal",
      link: "valuation-and-property-appraisal",
    },
    {
      name: "Residential Sales and Leasing",
      link: "residential-sales-and-leasing",
    },
    {
      name: "Hotels, Tourism and Leisure",
      link: "hotels-tourism-and-leisure",
    },
  ];

  return (
    <div className="">
      <div className="bg-white p-2 rounded-xl">
        <div className="font-bold text-zinc-800 mb-4">
          Edit Specific Website Sections
        </div>
        <div className="flex items-center gap-2 flex-wrap">
          {navLinks.map((item, index) => (
            <div
              key={index}
              className={`flex items-center gap-2 px-2 py-1 bg-gray-100 rounded-xl border text-xs cursor-pointer
                ${
                  activeLink === item.name
                    ? "font-bold bg-leechiuOrange text-white"
                    : "text-gray-500"
                }
                `}
              onClick={() => setActiveLink(item.name)}
            >
              <div>{item.name}</div>
            </div>
          ))}
        </div>
      </div>

      <div>
        {activeLink === "Home Page" && <HomePage />}
        {activeLink === "Tenant Representation" && (
          <Datapage pageName={activeLink} pageId={"tenantRep"} />
        )}
        {activeLink === "Landlord Representation" && (
          <Datapage pageName={activeLink} pageId={"landlordRep"} />
        )}
        {activeLink === "Investment Sales" && (
          <Datapage pageName={activeLink} pageId={"investmentSales"} />
        )}
        {activeLink === "Research and Consultancy" && (
          <Datapage pageName={activeLink} pageId={"researchConsultantcy"} />
        )}
        {activeLink === "Valuation and Property Appraisal" && (
          <Datapage pageName={activeLink} pageId={"valuationAppraisal"} />
        )}
        {activeLink === "Residential Sales and Leasing" && (
          <Datapage pageName={activeLink} pageId={"residentialSales"} />
        )}
        {activeLink === "Hotels, Tourism and Leisure" && (
          <Datapage pageName={activeLink} pageId={"hotelsTourism"} />
        )}
      </div>
    </div>
  );
}

export default WebsiteSections;
