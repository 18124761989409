import React from "react";
import InputBoxSelect from "../../components/InputBoxSelect";
import { useState } from "react";
import { setDocData } from "../../Firebase/Firebase.utils";
import UserAvatar from "../../components/UserAvatar";

function FinanceStatusUpdateComponent({ id, billingData, currentUser }) {
  const [editMode, setEditMode] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [financeStatus, setFinanceStatus] = useState(
    billingData.finance_status || null
  );

  const handleEditMode = () => {
    setEditMode(!editMode);
    if (editMode) {
      setDocData("billing", id, {
        finance_status: financeStatus,
        comments: [
          ...billingData.comments,
          {
            comment: newComment,
            created_at: new Date(),
            commenter: currentUser.email,
          },
        ],
      });
    }
    setNewComment("");
  };

  const financeStatusOptions = [
    { value: "finance checking", label: "finance checking" },
    { value: "for fixing", label: "for fixing (see comments)" },
    { value: "billing preparation", label: "billing preparation" },
    { value: "billing ready", label: "billing ready" },
    { value: "billing sent", label: "billing sent" },
    { value: "collected", label: "collected" },
  ];

  const handleSelect = (e) => {
    setFinanceStatus(e.value);
  };

  const handleChange = (e) => {
    setNewComment(e.target.value);
  };

  return (
    <div>
      <div>
        <div className="font-semibold">Comments</div>
        {billingData?.comments?.map((comment, index) =>
          comment === undefined ||
          comment === null ||
          comment.comment === "" ? null : (
            <div
              key={index}
              className="flex items-center gap-2 bg-white rounded-xl mt-2 px-2 py-1 w-fit shadow-lg"
            >
              <div>
                <UserAvatar email={comment?.commenter || ""} />
              </div>
              <div>{comment?.comment}</div>
            </div>
          )
        )}
      </div>
      <div className="mt-4">
        <div className="font-semibold bg-orange-300 px-2 py-1 rounded-xl w-fit">
          Finance Approval Status: {billingData.finance_status}
        </div>
        <InputBoxSelect
          placeholder={"Select Status"}
          disabled={!editMode || billingData.status !== "approved"}
          options={financeStatusOptions}
          onChange={handleSelect}
          value={
            financeStatusOptions.find(
              (option) => option.value === financeStatus
            ) || ""
          }
        />

        <textarea
          placeholder="Add Comment..."
          disabled={!editMode}
          className="w-full border border-gray-200 rounded p-2"
          name=""
          id=""
          cols="30"
          rows="3"
          onChange={handleChange}
          value={newComment}
        ></textarea>
        <button
          className="blue-button disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={handleEditMode}
          disabled={billingData.status !== "approved"}
        >
          {editMode ? "Update" : "Edit Finance Status"}
        </button>
      </div>
    </div>
  );
}

export default FinanceStatusUpdateComponent;
