import React, { useEffect, useState } from "react";
import ProfilePhotoOnly from "../components/ProfilePhotoOnly";
import {
  setDocData,
  deleteDocById,
  addNewDoc,
  getDocData,
  getDocDataSnap,
} from "../Firebase/Firebase.utils";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { setDoc } from "firebase/firestore";
import DisplayNameOnly from "../components/DisplayNameOnly";

export default function PipelineCard({ account }) {
  const navigate = useNavigate();
  const [activePercentage, setActivePercentage] = React.useState(0);
  const currentUser = useSelector((state) => state.userObject);
  const [notes, setNotes] = React.useState("");
  const [comments, setComments] = React.useState(account.comments || []);
  const [accountData, setAccountData] = useState(account);

  useEffect(() => {
    const fetchComments = async () => {
      await getDocDataSnap("tenantRep", account.id, (data) => {
        setComments(data.comments || []);
        setAccountData(data);
      });
    };
    fetchComments();
  }, [account.id]);

  const updateStatus = async () => {
    const newStatus = account.status === "active" ? "inactive" : "active";
    const docObject = {
      status: newStatus,
      updatedBy: currentUser.email,
      updatedAt: new Date().toLocaleString(),
      updateType: "status",
    };

    await setDocData("tenantRep", account.id, docObject);
    toast.success("Status updated successfully ");
  };

  const handleNavigate = () => {
    navigate(`/leasing/tenantRep/req/${account.id}?link=Requirement+Details`);
  };

  const statusPercentages = ["10%", "30%", "50%", "90%", "100%"];

  const handleSetPercentage = async (e) => {
    setActivePercentage(e.target.innerText);
    const docObject = {
      pipeline_status: e.target.innerText,
      updatedBy: currentUser.email,
      updatedAt: new Date().toLocaleString(),
      updateType: "pipeline_status",
    };

    await setDocData("tenantRep", account.id, docObject);
    toast.success("Pipeline status updated");
  };

  const handleDelete = async () => {
    if (currentUser.roles.includes("admin")) {
      if (window.confirm("Are you sure you want to delete this account?")) {
        await deleteDocById("tenantRep", account.id);
        toast.success("Account deleted successfully");
      }
    } else {
      toast.error("You are not authorized to delete this account");
    }
  };

  const handleAddNotes = async (id) => {
    const docObject = {
      notes: notes,
      createdBy: currentUser.email,
      createdAt: new Date().toLocaleString(),
      accountId: id,
      updateType: "weekly update notes",
    };
    setNotes("");
    await setDocData("tenantRep", account.id, {
      comments: [...comments, docObject],
    });

    toast.success("Notes added successfully");
  };

  const latestComment = comments[comments.length - 1];

  return (
    <div className="relative bubble text-xs text-gray-600  rounded-xl">
      <div
        className="text-xs underline absolute bottom-2 right-2 cursor-pointer"
        onClick={handleNavigate}
      >
        See Details
      </div>

      {/* {currentUser.roles.includes("admin") && (
        <div className="absolute top-1 right-16">
          <button className="blue-button" onClick={handleDelete}>
            delete
          </button>
        </div>
      )} */}

      <div
        className={`absolute top-1 right-1 rounded-lg text-white text-xs px-2 py-1 cursor-pointer ${
          account.status === "active" ? "bg-leechiuOrange" : "bg-gray-500"
        }`}
        onClick={updateStatus}
      >
        {account.status || "active"}
      </div>

      <div className="flex items-center gap-2 mb-2">
        <div className="font-bold text-sm">{account.tenantName}</div>

        <div className="text-xs text-gray-600 px-2 py-1 bg-green-100 rounded-full">
          {account.requirementLocation}
        </div>
        <div className="text-xs text-gray-600  px-2 py-1 bg-green-100 rounded-full">
          {account.requirementType}
        </div>
        <div className="text-xs text-gray-600  px-2 py-1 bg-green-100 rounded-full">
          {account.requirementSize} sqms
        </div>

        <div className="uppercase font-bold">
          Fee:{" "}
          {account.potentialFee
            ? parseFloat(account.potentialFee.replace(/,/g, "")).toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )
            : "Not Indicated"}
        </div>
      </div>

      <div className="flex items-start justify-normal gap-4 ">
        <div>
          <div>
            Set Pipeline % :{" "}
            {account.pipeline_status === undefined
              ? "Set pipeline % status"
              : null}
          </div>
          <div>
            <div className="flex items-center gap-4">
              {statusPercentages.map((percentage, index) => (
                <div
                  key={index}
                  className={
                    account.pipeline_status === percentage
                      ? "active-pill"
                      : "inactive-pill"
                  }
                  onClick={handleSetPercentage}
                >
                  {percentage}
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col items-start mt-2">
            {account.team_members?.map((member, index) => {
              return (
                <div key={index}>
                  <DisplayNameOnly email={member} />
                </div>
              );
            })}
          </div>
        </div>

        <div className="w-full">
          <div className="font-bold mb-2">Latest Update</div>
          <div className="comment">
            <div>{latestComment?.notes || "No updates available"}</div>
            <div>{latestComment?.createdAt}</div>
          </div>
          <textarea
            name=""
            rows={2}
            className="border p-2 w-full rounded-lg mt-2"
            placeholder="Enter updates here"
            id=""
            onChange={(e) => setNotes(e.target.value)}
            value={notes}
          ></textarea>
          <button
            className="blue-button"
            onClick={() => handleAddNotes(account.id)}
          >
            Save Update
          </button>
        </div>
      </div>
    </div>
  );
}
