import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { setDocData, getDocData } from "../../Firebase/Firebase.utils";
import InputBox from "../../components/InputBox";
import Button from "../../components/Button";
import UserSearch from "../../components/UserSearch";
import { motion } from "framer-motion";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InputBoxSelect from "../../components/InputBoxSelect";
import toast from "react-hot-toast";
import ProfilePhotoOnly from "../../components/ProfilePhotoOnly";
import TeamMembers from "./TeamMembers";
import LeaseListing from "./LeaseListing";
import LeaseListingSummary from "./LeaseListingSummary";

function SummaryComponent({ listing }) {
  const { id } = useParams();
  const [formData, setFormData] = useState(listing);
  const [editMode, setEditMode] = useState(false); // Add state for edit mode
  const [teamMembers, setTeamMembers] = useState(listing.team_members);

  const { property_name, address, google_maps_pin, listing_type } = formData;
  const options = teamMembers.map((member) => ({
    value: member,
    label: <ProfilePhotoOnly email={member} />,
  }));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value === "" ? "" : value,
    }));
  };

  const setDocDataMerge = async (collName, docId, docObject) => {
    try {
      await setDocData(collName, docId, docObject, { merge: true });
    } catch (error) {}
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setEditMode(!editMode);
    if (editMode) {
      const docObject = {
        ...formData,
      };
      setDocDataMerge("listings", id, docObject);
      setEditMode(false);
    }
  };

  const handleClick = (e, user) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      team_members: [...prevFormData.team_members, user.email],
    }));
  };

  const handleDelete = (e, member) => {
    const filteredTeam = formData.team_members.filter((teamMember) => {
      return teamMember !== member;
    });
    setFormData((prevFormData) => ({
      ...prevFormData,
      team_members: filteredTeam,
    }));
  };

  const handleCopy = () => {
    // Get the text content of the main div and its children
    const textToCopy = mainDivRef.current.innerText;

    // Create a temporary textarea element to copy the text
    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;

    // Append the textarea to the document
    document.body.appendChild(textarea);

    // Select the text in the textarea
    textarea.select();

    // Execute the copy command
    document.execCommand("copy");

    // Remove the temporary textarea
    document.body.removeChild(textarea);

    // Alert the copied text
    toast.success("Copied to clipboard");
  };

  const mainDivRef = React.useRef(null);

  const handleSelectTeamLead = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      team_lead: e.value,
    }));
  };

  const SummaryInfoComp = () => {
    return (
      <>
        {!editMode && (
          <div className="">
            <h4 className=" text-gray-600 text-sm">Summary Information</h4>
            <div className="text-sm font-bold text-gray-600 mb-4">
              Pipeline Status: {listing.pipeline_status}
            </div>
            <div
              className="relative text-xs border rounded border-gray-300 shadow-md p-2 "
              ref={mainDivRef}
            >
              <div className="font-bold text-gray-500">Property for Sale</div>
              <div>Address: {formData.address}</div>
              <div>
                Lot Area: {parseFloat(formData.lot_area).toLocaleString()}{" "}
                square meters
              </div>
              <div>
                Asking Price: Php{" "}
                {parseFloat(formData.selling_price).toLocaleString()} per sqm
              </div>
              <div>
                Total Asking Price: Php{" "}
                {parseFloat(formData.total_price).toLocaleString()}{" "}
                {formData.vatable === "Yes" ? " + VAT" : ""}
              </div>
              <div>Zoning: {formData.zoning}</div>
              <div>Frontage: {formData.frontage}</div>
              <div>Current Use: {formData?.current_use}</div>
              <button className="absolute top-2 right-2" onClick={handleCopy}>
                <ContentCopyIcon />
              </button>
            </div>
          </div>
        )}
      </>
    );
  };

  const statusOptions = [
    { label: "Due Diligence", value: "10%" },
    { label: "Ready to Market", value: "30%" },
    { label: "Signed Offer", value: "50%" },
    { label: "Closed and For Billing", value: "90%" },
    { label: "Billed and Collected", value: "100%" },
  ];

  const handleSelect = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      pipeline_status: e.value,
    }));
  };

  return (
    <motion.div initial={{ opacity: 0, x: 100 }} animate={{ opacity: 1, x: 0 }}>
      <div className="bubble">
        <div className="text-xs">
          <Button
            type="submit"
            label={editMode ? "Save" : "Edit"}
            onClick={handleSubmit}
          />
        </div>

        <div className="edit-info-section mt-8">
          {/*  show this only in edit mode */}
          {editMode && (
            <form action="">
              <div>
                <h4 className="mt-4">Pipeline Status</h4>
                <div>
                  <InputBoxSelect
                    label="Select the applicable pipeline status"
                    options={statusOptions}
                    placeholder="Select Status"
                    name="pipeline_status"
                    onChange={handleSelect}
                    disabled={!editMode}
                    value={statusOptions.map((option) => {
                      if (option.value === formData.pipeline_status) {
                        return option;
                      }
                    })}
                  />
                </div>

                <h4 className="mt-4">Edit Property Information</h4>
                <div className="flex flex-wrap gap-4 ">
                  <InputBox
                    label="Property Name"
                    type="text"
                    placeholder="Enter Property Name"
                    name="property_name"
                    onChange={handleChange}
                    disabled={!editMode}
                    value={formData.property_name || ""}
                  />
                  <InputBox
                    label="Address"
                    type="text"
                    placeholder="Enter Address"
                    name="address"
                    onChange={handleChange}
                    disabled={!editMode}
                    value={formData.address || ""}
                  />
                  <InputBox
                    label="Lot Area in square meters"
                    type="number"
                    placeholder="e.g. 2500"
                    name="lot_area"
                    onChange={handleChange}
                    disabled={!editMode}
                    value={formData.lot_area || ""}
                  />
                  <InputBox
                    label="Selling Price per sqm"
                    type="number"
                    placeholder="e.g. 30000"
                    name="selling_price"
                    onChange={handleChange}
                    disabled={!editMode}
                    value={formData.selling_price || ""}
                  />
                  <InputBox
                    label="Total Asking Price"
                    placeholder="e.g. 30000"
                    name="total_price"
                    onChange={handleChange}
                    value={formData.total_price || ""}

                    /*  value={
                        parseFloat(formData.total_price).toLocaleString() || ""
                      } */
                  />
                  <InputBox
                    label="Is VAT applicable?"
                    placeholder="Yes or No"
                    name="vatable"
                    onChange={handleChange}
                    value={formData.vatable || ""}

                    /*  value={
                        parseFloat(formData.total_price).toLocaleString() || ""
                      } */
                  />
                  <InputBox
                    label="Zoning Classification"
                    type="text"
                    placeholder="e.g. Commercial"
                    name="zoning"
                    onChange={handleChange}
                    disabled={!editMode}
                    value={formData.zoning || ""}
                  />
                  <InputBox
                    label="Floor Area Ratio"
                    placeholder="e.g. 3"
                    name="far"
                    onChange={handleChange}
                    disabled={!editMode}
                    value={formData.far || ""}
                  />
                  <InputBox
                    label="Height Limit"
                    type="text"
                    placeholder="e.g. 30 meters"
                    name="height_limit"
                    onChange={handleChange}
                    disabled={!editMode}
                    value={formData.height_limit || ""}
                  />
                  <InputBox
                    label="Zonal Value per sqm"
                    type="number"
                    placeholder="e.g. 25000"
                    name="zonal"
                    onChange={handleChange}
                    disabled={!editMode}
                    value={formData.zonal || ""}
                  />
                  <InputBox
                    label="Current Use"
                    type="text"
                    placeholder="current use of the property"
                    name="current_use"
                    onChange={handleChange}
                    disabled={!editMode}
                    value={formData.current_use || ""}
                  />
                  <InputBox
                    label="Frontage"
                    type="text"
                    placeholder="Frontage of the property"
                    name="frontage"
                    onChange={handleChange}
                    disabled={!editMode}
                    value={formData.frontage || ""}
                  />
                </div>
              </div>
            </form>
          )}
        </div>
        {listing.listing_type === "For Sale" && <SummaryInfoComp />}
        {listing.listing_type === "For Lease" && (
          <LeaseListingSummary handleCopy={handleCopy} listing={listing} />
        )}
      </div>
      <TeamMembers listing={listing} />
    </motion.div>
  );
}

export default SummaryComponent;
