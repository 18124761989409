import React from "react";
import Button from "../../components/Button";
import { Link } from "react-router-dom";

function LeaseBillingCard({ billing }) {
  return (
    <Link to={`/leaseBillingView/${billing.id}`}>
      <div
        className=" mt-4 text-gray-600 relative hover:border transform hover:border-leechiuOrange
        transition duration-800 ease-in-out bubble"
      >
        <div className="flex justify-between lg:items-center">
          <div className="text-sm font-bold">{billing.client_name}</div>
          <p className="text-xs uppercase font-bold text-leechiuOrange">
            <div className="rounded-full py-1 px-4 bg-leechiuOrange text-white ">
              {billing.finance_status || "Pending"}
            </div>
          </p>
        </div>
        <div className=" flex flex-col text-xs text-gray-600 flex-wrap ml-8 lg:ml-0">
          <div className="text-gray-600 mr-4 capitalize">
            Landlord: {billing.landlord}
          </div>
          <div className="text-gray-600 mr-4">Tenant: {billing.tenant}</div>
          <div className="text-gray-600 mr-4">
            Description: {billing.description}
          </div>
          <div className="text-xs text-orange-600 font-bold">
            Fee: Php {parseFloat(billing.amount).toLocaleString()}
          </div>
        </div>
        <div className="flex flex-row flex-wrap lg:gap-4 text-gray-500 my-2">
          <span className="text-xs">Requested by: {billing.created_by}</span>
          <span className="text-xs ">Billing ID: {billing.id}</span>
          <span className="text-xs">
            Date Created: {billing.created_at?.toDate().toString()}
          </span>
          {/*   {
            approved_on && <span className="text-xs">
            Date Approved: {approved_on?.toDate().toString()}
          </span>
         } */}
        </div>
      </div>
    </Link>
  );
}

export default LeaseBillingCard;
