import React from "react";
import { setDocData } from "../Firebase/Firebase.utils";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";

export default function RequirementHeader({ requirement }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const activeLinkParam = searchParams.get("link");

  const toggleActiveStatus = async () => {
    const newStatus = requirement.status === "active" ? "inactive" : "active";
    await setDocData("landlordRepRequirements", requirement.id, {
      status: newStatus,
    });
    toast.success("Status updated");
  };
  const menuItems = [
    "Requirement Details",
    "Company Profile",
    "Team Notes",
    "Options",
    "Tasks",
  ];

  const handleLink = (e) => {
    setSearchParams({ link: e.target.innerText });
  };

  return (
    <div className="relative">
      <button
        onClick={toggleActiveStatus}
        className={`capitalize text-xs px-2 py-1 rounded-lg absolute top-2 right-1 ${
          requirement.status === "active"
            ? "bg-green-500 text-gray-600"
            : "bg-gray-500 text-white"
        }`}
      >
        {requirement.status}
      </button>
      <div className="bubble">
        <div className="font-bold capitalize">{requirement.tenantName}</div>
        <div className="text-xs">
          <div className="text-gray-600 capitalize">{requirement.requirementType}</div>
          <div className="font-bold text-gray-600 uppercase">
            Pipeline Status: {requirement.pipeline_status}
          </div>
        </div>
        <div className="flex items-center gap-4 mt-4">
          {menuItems.map((menu, index) => (
            <div
              key={index}
              className={
                activeLinkParam === menu ? "active-pill" : "inactive-pill"
              }
              onClick={handleLink}
            >
              {menu}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
