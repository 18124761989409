import React from "react";
import { useSelector } from "react-redux";
import Modal from "../../components/Modal";
import LandlordRepRequirementForm from "../../LandlordRep/LandlordRepRequirementForm";

import UseGetAllDoc from "../../Hooks/UseGetAllDoc";
import LandlordRepREqCard from "../../LandlordRep/LandlordRepREqCard";
import useSearchUserDocs from "../../Hooks/useSearchUserDocs";

function LandlordRep() {
  const currentUser = useSelector((state) => state.userObject);
  const links = ["Buildings", "Office Space"];
  const [addMode, setAddMode] = React.useState(false);

  const { data: requirements, isLoading } = useSearchUserDocs({
    collection: "landlordRepRequirements",
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  //timestamp createdAt is in local string already arrange sort requiremnets by createdAt
  requirements.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  return (
    <div className="text-gray-600">
      <div className="header mt-8">Landlord Representation</div>
      <button className="blue-button" onClick={handleAddMode}>
        Add an Account
      </button>

      <div className="text-sm mt-4">Total Accounts {requirements.length}</div>

      <div className="mt-8 space-y-4">
        {requirements.map((requirement, index) => (
          <div key={index}>
            <LandlordRepREqCard requirement={requirement} />
          </div>
        ))}
      </div>

      <div>
        {addMode && (
          <Modal>
            <LandlordRepRequirementForm handleAddMode={handleAddMode} />
          </Modal>
        )}
      </div>
    </div>
  );
}
export default LandlordRep;
