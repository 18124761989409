import React, { useEffect } from "react";
import useStorage from "../Hooks/UseStorage";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function ProgressBar({ file, collName, id, fileLabel, setInProcess }) {
  const { url, progress } = useStorage(file, collName, id, fileLabel);

  

  return (
    <div>
      <>
        <div className="bg-gray-200 rounded-full mt-4 flex items-center">
          <div
            className="bg-green-500 rounded-full h-2"
            style={{ width: progress + "%" }}
          ></div>
          {url && <CheckCircleIcon className="text-green-500" />}
        </div>
      </>
    </div>
  );
}


export default ProgressBar;
