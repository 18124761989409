import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "../../components/Modal";
import AddContactForm from "./AddContactForm";
import { queryAllDocsByField } from "../../Firebase/Firebase.utils";
import ContactCard from "./ContactCard";

function ContactList({ requirementData, id }) {
  const [addMode, setAddMode] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchContacts = async () => {
      const contacts = await queryAllDocsByField(
        "contacts",
        "accountId",
        "==",
        id,
        (data) => {
          //arrange by name
          data.sort((a, b) => {
            return a.contactName.localeCompare(b.contactName);
          });

          setContacts(data);
          setIsLoading(false);
        }
      );
    };
    fetchContacts();
  }, []);

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  if (isLoading) {
    return <div>Loading</div>;
  }

  return (
    <div className="mt-4">
      <div className="header">Contact Persons</div>
      <div onClick={handleAddMode}>
        <button className="blue-button">Add Additional Contact</button>
      </div>

      {addMode && (
        <Modal>
          <AddContactForm id={id} handleAddMode={handleAddMode} />
        </Modal>
      )}

      <div className="space-y-2 mt-4">
        {contacts.map((contact, index) => (
          <ContactCard key={index} contact={contact} />
        ))}
      </div>
    </div>
  );
}

export default ContactList;
