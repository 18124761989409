import React, { useEffect } from "react";
import { useState } from "react";
import { v4 } from "uuid";
import { useSelector } from "react-redux";
import {
  addNewDoc,
  getAllDocsSnap,
  deleteDocById,
} from "../../Firebase/Firebase.utils";
import UserAvatar from "../../components/UserAvatar";
import { Link, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import DiscussionCard from "./DiscussionCard";

function DiscussionBoard() {
  const [requirements, setRequirements] = useState([]);
  const [newRequirement, setNewRequirement] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeLink, setActiveLink] = useState("requirements");
  const [generalDiscussions, setGeneralDiscussions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const activeSearchParam = searchParams.get("link");

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const getGeneralDiscussions = async () => {
      try {
        const res = getAllDocsSnap("general", (data) => {
          const general = data.sort((a, b) => {
            return b.createdAt - a.createdAt;
          });
          setGeneralDiscussions(general);

          console.log(general);
        });
      } catch (error) {}
    };
    getGeneralDiscussions();
  }, []);

  useEffect(() => {
    const getRequirements = async () => {
      try {
        const res = getAllDocsSnap("requirements", (data) => {
          const sortRequirements = data.sort((a, b) => {
            return b.createdAt - a.createdAt;
          });
          setRequirements(sortRequirements);
          setIsLoading(false);
        });
      } catch (error) {}
    };
    getRequirements();
  }, []);

  const handleChange = (e) => {
    setNewRequirement(e.target.value);
  };

  const handleSubmit = (e, activeSearchParam) => {
    e.preventDefault();
    const docObject = {
      description: newRequirement,
      type: activeSearchParam,
      id: v4(),
      complete: false,
      comments: [],
      likes: 0,
      createdAt: new Date(),
      createdBy: currentUser.email,
      displayName: currentUser.displayName,
    };

    addNewDoc(activeSearchParam, docObject);
    setNewRequirement("");
  };

  const handleDelete = (e, post) => {
    e.preventDefault();

    if (post.createdBy !== currentUser.email) {
      return;
    }

    if (window.confirm("Are you sure you want to delete this requirement?")) {
      deleteDocById(activeSearchParam, post.id);
    } else {
      return;
    }
  };

  const handleComment = (e, id) => {
    e.preventDefault();
    alert("sorry comments are not available yet");
  };

  const navigate = useNavigate();

  const handleContribute = (e, id) => {
    navigate(`${id}?link=${activeSearchParam}`);
  };

  const pillstyle = "bg-white text-black  py-1 px-2 rounded-xl text-xs";
  const activePill = "bg-orange-600 text-white  py-1 px-2 rounded-xl text-xs";

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredRequirements = requirements.filter((r) => {
    return r.description?.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const filteredGeneralDiscussions = generalDiscussions.filter((r) => {
    return r.description?.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleActiveLink = (link) => {
    setSearchParams({ link: link });
    setActiveLink(link);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="flex flex-col gap-2 max-w-5xl">
        <div className="text-right">
          <input
            className="p-2 rounded-lg"
            type="search"
            placeholder="Search Here"
            onChange={handleSearch}
          />
        </div>
        <textarea
          className="w-full px-2 py-1 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
          type="text"
          placeholder="Post a topic here"
          onChange={handleChange}
          value={newRequirement}
        ></textarea>
        <div className="text-right">
          <button
            onClick={(e) => handleSubmit(e, activeSearchParam)}
            className="bg-leechiuBlue text-white px-2 py-1 rounded-lg text-xs"
          >
            Post
          </button>
        </div>
      </div>

      <div className="text-sm mt-4 px-2 py-1 header">Categories</div>
      <div className="flex items-center gap-2 [&>*]:cursor-pointer">
        <span
          className={
            activeSearchParam === "requirements" ? activePill : pillstyle
          }
          onClick={() => handleActiveLink("requirements")}
        >
          Requirements
        </span>
        <span
          className={activeSearchParam === "general" ? activePill : pillstyle}
          onClick={() => handleActiveLink("general")}
        >
          General Topics
        </span>
      </div>

      <div className="text-xs">
        {activeSearchParam === "requirements" &&
          filteredRequirements.map((r) => (
            <DiscussionCard
              key={r.id}
              discussion={r}
              handleContribute={handleContribute}
              currentUser={currentUser}
              handleDelete={handleDelete}
            />
          ))}

        {activeSearchParam === "general" && (
          <div>
            {filteredGeneralDiscussions.map((r) => (
              <DiscussionCard
                key={r.id}
                discussion={r}
                handleContribute={handleContribute}
                currentUser={currentUser}
                handleDelete={handleDelete}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default DiscussionBoard;
