import React, { useEffect } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import { useSelector } from "react-redux";
import Masterlist from "../pages/Leasing/Masterlist";
import LandlordRep from "../pages/Leasing/LandlordRep";
import TenantRep from "../pages/Leasing/TenantRep";
import Pipeline from "../pages/Leasing/Pipeline";
import { useSearchParams } from "react-router-dom";

function Leasing() {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.userObject);
  const [searchParams, setSearchParams] = useSearchParams();
  const activeLink = searchParams.get("link");

  const links = [
    "Masterlist",
    "Tenant Representation",
    "Landlord Representation",
  ];

  const handleNewBuilding = () => {
    navigate("/leasing/newbuilding");
  };

  const authorizedList = ["admin", "approver"];

  if (
    !currentUser.roles.some((role) => authorizedList.includes(role)) &&
    currentUser.department !== "Commercial Leasing"
  ) {
    return (
      <div>
        <h1>Unauthorized</h1>
        <p>Sorry, you are not authorized to view this page.</p>
        <p>Only Commercial Leasing Department or Approvers can view</p>
      </div>
    );
  }

  const handleActiveLink = (link) => {
    navigate(`/leasing/${link.toLowerCase().replace(" ", "")}?link=${link}`);
  };

  return (
    <div className="leasing flex flex-col">
      <div className="lg:mb-8 mb-4">
        <Button label="Add new building" onClick={handleNewBuilding} />
      </div>
      <div className="flex items-center justify-between flex-wrap">
        <div className="flex items-center justify-normal gap-2">
          {links.map((link) => (
            <div
              className={activeLink === link ? "active-pill" : "inactive-pill"}
              key={link}
              onClick={() => handleActiveLink(link)}
            >
              {link}
            </div>
          ))}
        </div>
      </div>
      <div className="">
        {activeLink === "Masterlist" ? <Masterlist /> : null}
        {activeLink === "Landlord Representation" ? <LandlordRep /> : null}
        {activeLink === "Tenant Representation" ? <TenantRep /> : null}
        {activeLink === "Pipeline" ? <Pipeline /> : null}
      </div>
    </div>
  );
}

export default Leasing;
