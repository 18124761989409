import React from "react";
import { FaPeopleArrows } from "react-icons/fa";
import { Link, Outlet } from "react-router-dom";

function ResearchValuation() {
  const [activeLink, setActiveLink] = React.useState("research-study");

  const links = [
    {
      name: "Research Study",
      link: "research-study",
      icon: <FaPeopleArrows />,
    },
    {
      name: "Valuation Study",
      link: "valuation-study",
      icon: <FaPeopleArrows />,
    },
    {
      name: "Resources",
      link: "resources",
      icon: <FaPeopleArrows />,
    },
  ];

  return (
    <div className="space-y-8">
      <div className="header">Research and Valuation</div>

      <div className="flex items-center gap-4">
        {links.map((link, index) => (
          <div
            key={index}
            className={
              activeLink === link.link ? "active-pill" : "inactive-pill"
            }
            onClick={() => setActiveLink(link.link)}
          >
            <Link to={link.link}>{link.name}</Link>
          </div>
        ))}
      </div>

      <div>
        <Outlet />
      </div>
    </div>
  );
}

export default ResearchValuation;
