import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Modal from "../components/Modal";
import AgentAssignmentForm from "./AgentAssignmentForm";
import { setDocData, getDocDataSnap } from "../Firebase/Firebase.utils";
import ProfilePhotoOnly from "../components/ProfilePhotoOnly";

function AssignedBuildingAgent() {
  const currentUser = useSelector((state) => state.userObject);
  const { id } = useParams();
  const [teamMembers, setTeamMembers] = React.useState([]);
  const [addMode, setAddMode] = React.useState(false);
  const [data, setData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    const fetchBuildingData = async () => {
      try {
        setIsLoading(true);
        const buildingData = await getDocDataSnap("masterlist", id, (data) => {
          setData(data);
          setTeamMembers(data.teamMembers);
          setIsLoading(false);
          console.log(data.teamMembers);
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchBuildingData();
  }, []);

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  const handleSave = async () => {
    console.log(teamMembers);
    try {
      const docObject = {
        teamMembers: teamMembers,
        lastUpdatedBy: currentUser.email,
        lastUpdatedOn: new Date(),
      };
      await setDocData("masterlist", id, docObject);
      handleAddMode();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="header">Assign Agents</div>
      <button className="blue-button" onClick={handleAddMode}>
        Add / Remove
      </button>

      {addMode && (
        <Modal>
          <AgentAssignmentForm
            teamMembers={teamMembers}
            setTeamMembers={setTeamMembers}
            handleAddMode={handleAddMode}
            handleSave={handleSave}
            id={id}
          />
        </Modal>
      )}

      <div className="mt-8 bubble">
        <div className="text-leechiuBlue text-sm font-bold">
          Assigned Agents
        </div>
        <div className="flex items-center justify-normal gap-4">
          {teamMembers?.map((member, index) => (
            <div key={index} className="flex items-center">
              <ProfilePhotoOnly email={member} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AssignedBuildingAgent;
