import React from "react";
import Logo from "../assets/leechiu-blue.png";

function Maintenance() {
  return (
    <div className="flex flex-col items-center justify-center h-screen text-lg font-bold text-orange-600">
      <img src={Logo} className="h-20 mb-8" alt="" />
      <div>The page is under maintenance</div>
     
    </div>
  );
}

export default Maintenance;
