import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import { Link } from "react-router-dom";
import "../../styles/otherStyles.css";
import { loadUser } from "../../store/userSlice";
import { useSelector } from "react-redux";

function BillingsList() {
  const currentUser = useSelector((state) => state.userObject);

  const pillStyle = "px-2 py-1 bg-gray-200 rounded-full text-xs";

  return (
    <div className="billing-list ">
      <div className="flex gap-4 text-sm uppercase mb-4 ">
        <NavLink className={pillStyle} to="mybillings">
          My Requests
        </NavLink>

        {currentUser.roles?.includes("approver") && (
          <>
            <NavLink className={pillStyle} to="billingsPending">
              For Approval
            </NavLink>
            <NavLink className={pillStyle} to="billingsApproved">
              Approved
            </NavLink>
          </>
        )}
      </div>
      <div className="bg-gray-700 h-[2px] max-w-5xl"></div>
      <Outlet />
    </div>
  );
}

export default BillingsList;
