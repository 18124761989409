import React from "react";
import { Link } from "react-router-dom";

function OptionBox({link, label}) {
  return (
    <>
      <div className=" hover:bg-slate-700 h-24 w-24 text-sm text-center flex items-center rounded-lg bg-slate-400 shadow-emerald-900 shadow-md text-white">
        <Link to={link}> {label}</Link>
      </div>
    </>
  );
}

export default OptionBox;
