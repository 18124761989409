import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDocDataSnap, setDocData } from "../Firebase/Firebase.utils";
import { useNavigate } from "react-router-dom";
import NoProfilePhoto from "../assets/blankprofile.webp";

function ProfilePicture() {
  const currentUser = useSelector((state) => state.userObject);
  const [user, setUser] = useState({});
  const [editing, setEditing] = useState(true);

  const [profilePicture, setProfilePicture] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getDocDataSnap("users", currentUser.uid, (user) => {
        setProfilePicture(user.profilePhoto);
        setUser(user);
      });
    };
    fetchUser();
  }, []);

  const navigate = useNavigate();

  const handleStatusMessageClick = () => {
    setEditing(!editing);
  };

  const handleBlur = () => {
    setEditing(false);
    setDocData("users", currentUser.uid, user);
  };

  return (
    <div>
      <div
        className="flex items-center justify-center gap-4 cursor-pointer"
        onClick={() => navigate("/myprofile")}
      >
        <img
          className="w-20 h-20 rounded-full"
          style={{ objectFit: "cover" }}
          src={profilePicture || NoProfilePhoto}
          alt=""
        />
        <div className="text-xs text-left flex flex-col flex-wrap ">
          <div className="text-xs font-bold">{user.displayName}</div>
          <div>{currentUser.department}</div>
        </div>
      </div>

      <textarea
        // disabled={editing}
        className="w-full text-wrap text-xs p-2 text-zinc-500 resize-none border rounded-xl disabled:bg-white mt-2"
        value={user.statusMessage || ""}
        placeholder="Set your status message here"
        onClick={handleStatusMessageClick}
        onBlur={handleBlur}
        onChange={(e) => {
          setUser({ ...user, statusMessage: e.target.value });
        }}
      />
    </div>
  );
}

export default ProfilePicture;
