import React, { useEffect } from "react";
import { loadUser } from "../../store/userSlice";
import { useSelector } from "react-redux";
import {
  getAllDocsByEmail,
  getAllDocsByEmailAndStatus,
} from "../../Firebase/Firebase.utils";
import { Link, useNavigate } from "react-router-dom";
import BillingCard from "../../components/BillingCard";
import LeaseBillingCard from "./LeaseBillingCard";

function BillingsApproved() {
  const currentUser = useSelector((state) => state.userObject);
  const [billingList, setBillingList] = React.useState([]);
  const [billingForApproval, setBillingForApproval] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [billingsApproved, setBillingsApproved] = React.useState([]);

  const Navigate = useNavigate();

  useEffect(() => {
    if (!currentUser.email || currentUser.email === null) {
      Navigate("/login");
    }
  }, []);

  useEffect(() => {
    const fetchMyBillings = async () => {
      try {
        setIsLoading(true);
        const myBillings = await getAllDocsByEmail(
          "billing",
          currentUser.email,
          "created_by"
        );

        setBillingList(myBillings);
        setIsLoading(false);
      } catch (error) {}
    };
    fetchMyBillings();
  }, []);

  useEffect(() => {
    const fetchApprovalList = async () => {
      try {
        const myApprovalList = await getAllDocsByEmailAndStatus(
          "billing",
          currentUser.email,
          "pending",
          "approver"
        );

        setBillingForApproval(myApprovalList);
      } catch (error) {}
    };
    fetchApprovalList();
  }, []);

  useEffect(() => {
    const billingsApprovedList = async () => {
      try {
        const myApprovalList = await getAllDocsByEmailAndStatus(
          "billing",
          currentUser.email,
          "approved",
          "approver"
        );

        //arrange by created_at
        myApprovalList.sort((a, b) => {
          return (
            new Date(b.created_at.toDate()) - new Date(a.created_at.toDate())
          );
        });

        setBillingsApproved(myApprovalList);
      } catch (error) {}
    };
    billingsApprovedList();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h4 className="mt-8">
        This following are all the billings you have approved
      </h4>
      {isLoading && <p>Loading...</p>}

      {billingsApproved.length === 0 && (
        <p className="italic">You have not approved any billings yet</p>
      )}
      {billingsApproved.map((billing) => {
        return (
          <div>
            {billing.billing_type === "lease" ? (
              <LeaseBillingCard billing={billing} />
            ) : (
              <BillingCard billing={billing} />
            )}
          </div>
        );
      })}
    </div>
  );
}

export default BillingsApproved;
