import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "./pages/Landing";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import Main from "./pages/Main";
import Billing from "./pages/Billing/Billing";
import ISbillingSale from "./pages/Billing/ISbillingSale";
import MyBillings from "./pages/Billing/MyBillings";
import BillingView from "./pages/Billing/BillingView";
import NewListingOptions from "./pages/Listing/NewListingOptions";
import SaleListing from "./pages/Listing/SaleListing";
import BillingsList from "./pages/Billing/BillingsList";
import BillingPending from "./pages/Billing/BillingPending";
import BillingsApproved from "./pages/Billing/BillingsApproved";
import Signup from "./pages/Login/Signup";
import BillSupport from "./pages/Billing/BillSupport";
import ListingView from "./pages/Listing/ListingView";
import AllListings from "./pages/Listing/AllListings";
import DueDiligence from "./pages/Listing/DueDiligence";
import Agency from "./pages/Listing/Agency";
import LeaseBilling from "./pages/Billing/LeaseBilling";
import Top1k from "./pages/Top1k/Top1k";
import TaskManagement from "./pages/Tasks/TaskManagement";
import Photos from "./pages/Listing/Photos";
import MeetingNotes from "./pages/Listing/MeetingNotes";
import Flyer from "./pages/Listing/Flyer";
import Marketing from "./pages/Listing/Marketing";
import Closing from "./pages/Listing/Closing";
import DetailedInfo from "./pages/Listing/DetailedInfo";
import SummaryComponent from "./pages/Listing/SummaryComponent";
import MyClients from "./pages/MyClients";
import Maintenance from "./pages/Maintenance";
import ResetPassword from "./pages/Login/ResetPassword";
import AllTasks from "./pages/Tasks/AllTasks";
import MyProfile from "./pages/MyProfile";
import RequestToBill from "./pages/Billing/RequestToBill";
import UnderConstruction from "./components/UnderConstruction";
import ContinueDraftISBilling from "./pages/Billing/ContinueDraftISBilling";
import WebView from "./pages/Listing/WebView";
import Leasing from "./OfficeLeasing/Leasing";
import Masterlist from "./pages/Leasing/Masterlist";
import RequirementDetails from "./pages/Requirements/DiscussionDetails";
import LandlordRep from "./pages/Leasing/LandlordRep";
import TenantRep from "./pages/Leasing/TenantRep";
import Pipeline from "./pages/Leasing/Pipeline";
import BuildingDetails from "./OfficeLeasing/BuildingDetails";
import UserProfile from "./components/UserProfile";
import NewBuilding from "./pages/Leasing/NewBuilding";
import AgentsListing from "./pages/Listing/AgentsListing";
import BillView from "./pages/Billing/BillView";
import LeaseBillingView from "./pages/Billing/LeaseBillingView";
import ContinueDraftLeaseBilling from "./pages/Billing/ContinueDraftLeaseBilling";
import UserAccess from "./pages/Admin/UserAccess";
import DiscussionBoard from "./pages/Requirements/DiscussionBoard";
import LeaseListing from "./pages/Listing/LeaseListing";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import IsPipeline from "./pages/Dashboard/IsPipeline";
import AllHospitalityListings from "./pages/Hospitality/AllHospitalityListings";
import FinanceApproval from "./pages/Billing/FinanceApproval";
import FinanceView from "./pages/Billing/FinanceView";
import ResiListings from "./pages/Residential/ResiListings";
import ResiLeaseBilling from "./pages/Residential/ResiLeaseBilling";
import Upload from "./pages/Upload/Upload";
import ResiLeaseBillView from "./pages/Residential/ResiLeaseBillView";
import ContinueDraftResiLeaseBill from "./pages/Residential/ContinueDraftResiLeaseBill";
import LeasingRequirementDetails from "./pages/Leasing/LeasingRequirementDetails";
import CompanyDetails from "./pages/Companies/CompanyDetails";
import MarketingDashboard from "./marketing/MarketingDashboard";
import WebsiteSections from "./marketing/WebsiteSections";
import MarketingUnderCons from "./marketing/MarketingUnderCons";
import NewDashboard from "./NewDashboard/NewDashboard";
import NewAllTasks from "./NewDashboard/NewAllTasks";
import NoAccessRoles from "./pages/Dashboard/NoAccessRoles";
import Insights from "./marketing/Insights";
import ResearchValuation from "./pages/Billing/ResearchValuation";
import CLPipeline from "./OfficeLeasing/CLPipeline";
import { auth } from "./Firebase/Firebase.utils";
import { useDispatch } from "react-redux";
import { loadUser } from "./store/userSlice";
import LandLordRepDetails from "./LandlordRep/LandLordRepDetails";
import ResearchStudy from "./Research/ResearchStudy";
import ValuationStudy from "./Research/ValuationStudy";
import Resources from "./Research/Resources";
import CreateNewStudyForm from "./Research/CreateNewStudyForm";
import ResearchStudyDetails from "./Research/ResearchStudyDetails";
import News from "./marketing/News";
import ArticleDetails from "./marketing/ArticleDetails";
import Blogs from "./marketing/Blogs";
import BlogDetails from "./marketing/BlogDetails";
import ResearchBilling from "./Research/ResearchBilling";
import ResearchBillingSupport from "./Research/ResearchBillingView";
import ResearchBillingView from "./Research/ResearchBillingView";
import NewMain from "./pages/Dashboard/NewMain";
import DashboardTwo from "./pages/Dashboard/DashboardTwo";
import TemplatesLegal from "./pages/Legal/TemplatesLegal";

function App() {
  const currentUser = useSelector((state) => state.userObject);

  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        dispatch(loadUser({}));
      }
    });
    return () => unsubscribe();
  }, []);

  if (
    currentUser === null ||
    !currentUser.email ||
    currentUser.email === null ||
    currentUser.email === "" ||
    currentUser.email === undefined
  ) {
    return (
      <div className="App">
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="resetpassword" element={<ResetPassword />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </Router>
      </div>
    );
  }

  if (currentUser.roles == [] || currentUser.roles === undefined) {
    return (
      <div className="App">
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<NoAccessRoles />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </Router>
      </div>
    );
  }

  if (currentUser.roles.includes("marketing")) {
    return (
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<MarketingDashboard />}>
              <Route index element={<WebsiteSections />} />
              <Route path="dashboard" element={<WebsiteSections />} />
              <Route path="insights" element={<Insights />} />
              <Route path="news" element={<News />} />
              <Route path="blogs" element={<Blogs />} />
              <Route path="blog/:id" element={<BlogDetails />} />
              <Route path="article/:id" element={<ArticleDetails />} />
              <Route path="website-sections" element={<WebsiteSections />} />
              <Route path="*" element={<MarketingUnderCons />} />
            </Route>
            <Route path="/dashboard" element={<MarketingDashboard />} />
          </Routes>
        </Router>
      </div>
    );
  }

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="maintenance" element={<Maintenance />} />
          <Route path="resetpassword" element={<ResetPassword />} />
          <Route path="webview/:id" element={<WebView />} />
          <Route path="newDashBoard" element={<NewDashboard />}>
            <Route index element={<Dashboard />} />
            <Route path="all-tasks" element={<NewAllTasks />} />
          </Route>

          <Route path="/" element={<NewMain />}>
            <Route index element={<Dashboard />} />
            <Route path="dashboard/cl-pipeline" element={<CLPipeline />} />
            <Route path="dashboard" element={<Dashboard />}></Route>
            <Route path="dashboard/is-pipeline" element={<IsPipeline />} />
            <Route path="agents" element={<AgentsListing />} />
            <Route path="agents" element={<AgentsListing />} />
            <Route path="upload" element={<Upload />} />
            <Route path="all-listings" element={<AllListings />} />
            <Route path="research" element={<ResearchValuation />}>
              <Route index element={<ResearchStudy />} />
              <Route path="research-study" element={<ResearchStudy />} />
              <Route
                path="research-study/:id"
                element={<ResearchStudyDetails />}
              />
              <Route
                path="new-research-study"
                element={<CreateNewStudyForm />}
              />
              <Route path="valuation-study" element={<ValuationStudy />} />
              <Route path="resources" element={<Resources />} />
            </Route>
            <Route path="hospitality" element={<AllHospitalityListings />} />
            <Route path="legal-templates" element={<TemplatesLegal />} />
            <Route path="residential" element={<ResiListings />} />
            <Route path="tasks" element={<AllTasks />} />
            <Route path="billing" element={<Billing />} />
            <Route path="myclients" element={<MyClients />} />
            <Route path="clients_db" element={<Top1k />} />
            <Route path="discussion" element={<DiscussionBoard />} />
            <Route path="discussion/:id" element={<RequirementDetails />} />
            <Route path="/profile/:id" element={<UserProfile />} />
            <Route path="myprofile" element={<MyProfile />} />
            <Route path="ISbillingSale" element={<ISbillingSale />} />
            <Route
              path="researchValuationBilling"
              element={<ResearchBilling />}
            />
            <Route path="leaseBilling" element={<LeaseBilling />} />
            <Route path="company/:id" element={<CompanyDetails />} />
            <Route path="flyer/:id" element={<Flyer />} />
            <Route
              path="residentialLeaseBillingView/:billingid"
              element={<ResiLeaseBillView />}
            />
            <Route
              path="residentialLeaseBilling"
              element={<ResiLeaseBilling />}
            />
            <Route path="bill-support/:id" element={<BillSupport />} />
            <Route path="rtbs" element={<RequestToBill />} />
            <Route path="userAccess" element={<UserAccess />} />
            <Route
              path="billinglist/financePending"
              element={<FinanceApproval />}
            />
            <Route path="financeView/:id" element={<FinanceView />} />
            <Route
              path="leaseBillingView/:billingid"
              element={<LeaseBillingView />}
            />
            <Route
              path="/leasing/tenantRep/req/:id"
              element={<LeasingRequirementDetails />}
            />
            <Route
              path="/leasing/landlordrepresentation/:id"
              element={<LandLordRepDetails />}
            />
            <Route path="/leasing/newbuilding" element={<NewBuilding />} />
            <Route path="leasing" element={<Leasing />}>
              <Route index element={<Masterlist />} />
              <Route path="masterlist" element={<Masterlist />} />
              <Route path="landlordrepresentation" element={<LandlordRep />} />
              <Route path="tenantrepresentation" element={<TenantRep />} />
              <Route path="pipeline" element={<Pipeline />} />
            </Route>
            <Route path="/building/:id" element={<BuildingDetails />} />
            <Route path="underconstruction" element={<UnderConstruction />} />
            <Route path="billinglist" element={<BillingsList />}>
              <Route path="mybillings" element={<MyBillings />} />
              <Route path="billingsPending" element={<BillingPending />} />
              <Route path="billingsApproved" element={<BillingsApproved />} />
              <Route path="rejected" element={<MyBillings />} />
            </Route>
            <Route path="newlisting" element={<NewListingOptions />} />
            <Route path="sale-listing" element={<SaleListing />} />
            <Route path="lease-listing" element={<LeaseListing />} />
            <Route path="agency/:id" element={<Agency />} />
            <Route path="billingview/:billingid" element={<BillingView />} />
            <Route
              path="researchBillingView/:billingid"
              element={<ResearchBillingView />}
            />
            <Route
              path="continue-is/:id"
              element={<ContinueDraftISBilling />}
            />{" "}
            <Route
              path="continue-lease/:id"
              element={<ContinueDraftLeaseBilling />}
            />
            <Route
              path="continue-resilease/:id"
              element={<ContinueDraftResiLeaseBill />}
            />
            <Route path="listing/:id" element={<ListingView />}>
              <Route index element={<SummaryComponent />} />
              <Route path="summary" element={<SummaryComponent />} />
              <Route path="detailed" element={<DetailedInfo />} />
              <Route path="duediligence" element={<DueDiligence />} />
              <Route path="photos" element={<Photos />} />
              <Route path="tasks" element={<TaskManagement />} />
              <Route path="notes" element={<MeetingNotes />} />
              <Route path="closing" element={<Closing />} />
              <Route path="marketing" element={<Marketing />} />
              <Route path="webView" element={<WebView />} />
            </Route>
          </Route>

          <Route path="/login" element={<Login />} />

          <Route path="/signup" element={<Signup />} />
          <Route path="*" element={<UnderConstruction />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
