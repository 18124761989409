import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { queryAllDocsByField } from "../Firebase/Firebase.utils";
import { useNavigate } from "react-router-dom";

export default function ISAccountCountCard() {
  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();
  const [accounts, setAccounts] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const getLeasingAccounts = async () => {
      const data = await queryAllDocsByField(
        "listings",
        "team_members",
        "array-contains",
        currentUser.email,
        (data) => {
          setAccounts(data);
          setIsLoading(false);
        }
      );
    };
    getLeasingAccounts();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const potentialFee = accounts.reduce((acc, curr) => {
    return acc + curr.potential_fee;
  }, 0);

  const handleNavigate = () => {
    navigate("/all-listings");
  };

  return (
    <div className="bg-white" onClick={handleNavigate}>
      <div className="font-bold text-leechiuBlue">
        You have {accounts.length} Accounts
      </div>
      <div className="text-gray-600 text-sm">
        Potential Fee: Php{" "}
        {potentialFee.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </div>
    </div>
  );
}
