import React from "react";
import InputBox from "../components/InputBox";

export default function AddBlogsForm({
  formData,
  setFormData,
  handleChange,
  handleSubmit,
  isProcessing,
}) {
  return (
    <div>
      <div className="space-y-4 mt-8">
        <form action="" className="space-y-4" onSubmit={handleSubmit}>
          <div className="header">Add a new article</div>
          <InputBox
            name="title"
            placeholder="Title"
            type="text"
            label={"Title"}
            onChange={handleChange}
            required={true}
            value={formData.title}
          />

          <InputBox
            name="author"
            placeholder="Author Name"
            type="text"
            onChange={handleChange}
            label={"Author"}
            required={true}
            value={formData.author}
          />

          <InputBox
            name="date"
            placeholder="Date of Article"
            type="date"
            onChange={handleChange}
            label={"Date"}
            required={true}
            value={formData.date}
          />

          <div className="space-x-4">
            <button type="submit" className="blue-button">
              {isProcessing ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
