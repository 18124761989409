import React, { useState } from "react";
import UseGetAllDoc from "../Hooks/UseGetAllDoc";
import PipelineCard from "./PipelineCard";
import { useEffect } from "react";
import { getAllDocsSnap } from "../Firebase/Firebase.utils";
import { AnimatePresence, motion } from "framer-motion";
import PageModal from "../components/PageModal";
import SummaryReport from "./SummaryReport";

export default function CLPipeline() {
  const statusPercentages = ["10%", "30%", "50%", "90%", "100%"];
  const filters = ["active", "inactive"];
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeFilter, setActiveFilter] = useState("active");
  const [percentageArrays, setPercentageArrays] = useState(statusPercentages);
  const [searchTerm, setSearchTerm] = useState("");
  const [viewMode, setViewMode] = useState(false);

  useEffect(() => {
    const fetchAccounts = async () => {
      await getAllDocsSnap("tenantRep", (data) => {
        setAccounts(data);
        setIsLoading(false);
      });
    };
    fetchAccounts();
  }, []);

  const [activePercentage, setActivePercentage] = React.useState("All");

  if (isLoading) {
    return <div>Loading...</div>;
  }

  //arrange accounts alphabetically using tenantName
  accounts.sort((a, b) => {
    if (a.tenantName < b.tenantName) {
      return -1;
    }
    if (a.tenantName > b.tenantName) {
      return 1;
    }
    return 0;
  });

  const currentList = accounts.filter(
    (account) => account.status === activeFilter
  );

  const filteredAccounts = currentList.filter(
    (account) =>
      percentageArrays.includes(account.pipeline_status) &&
      account.tenantName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  console.log(filteredAccounts.length)

  const setActiveFiltersInArray = (selected) => {
    if (percentageArrays.includes(selected)) {
      setPercentageArrays(percentageArrays.filter((item) => item !== selected));
    } else {
      setPercentageArrays([...percentageArrays, selected]); // Spread operator to create a new array with selected
    }
  };

  const handleSelectAll = () => {
    if (statusPercentages.every((item) => percentageArrays.includes(item))) {
      setPercentageArrays([]);
      return;
    }
    setPercentageArrays(statusPercentages);
  };

  const handleViewReport = () => {
    setViewMode(!viewMode);
  };

  return (
    <div>
      <div className="text-xs p-2 relative">
        <div className="header">Commercial Leasing Pipeline</div>
        <button className="blue-button" onClick={handleViewReport}>
          See Summary Report
        </button>
        <input
          type="search"
          className="border p-2 rounded-lg border-gray-100 absolute top-0 right-10"
          placeholder="Search here"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="text-sm uppercase font-bold text-gray-600 mt-2">
          Total Accounts: {filteredAccounts.length}
        </div>
        <div className="flex items-start gap-4 lg:flex-row flex-col lg:items-center">
          <div className="mt-2 links-container">
            {filters.map((filter, index) => (
              <div
                key={index}
                className={`capitalize ${
                  activeFilter === filter
                    ? "activeLinkStyle"
                    : "inactiveLinkStyle"
                }`}
                onClick={() => setActiveFilter(filter)}
              >
                {filter}
              </div>
            ))}
          </div>
          <div className="mt-2">
            <div className="flex items-center gap-4">
              <div
                className={
                  statusPercentages.every((item) =>
                    percentageArrays.includes(item)
                  )
                    ? "active-pill"
                    : "inactive-pill"
                }
                onClick={handleSelectAll}
              >
                All
              </div>
              {statusPercentages.map((status, index) => (
                <div
                  key={index}
                  className={
                    percentageArrays.includes(status)
                      ? "active-pill"
                      : "inactive-pill"
                  }
                  onClick={() => setActiveFiltersInArray(status)}
                >
                  {status}
                </div>
              ))}
            </div>
          </div>
        </div>
        <AnimatePresence>
          {filteredAccounts.map((account, index) => (
            <motion.div
              transition={{ duration: 0.3 }}
              key={index}
              className="mt-4"
            >
              <PipelineCard account={account} />
            </motion.div>
          ))}
        </AnimatePresence>
      </div>

      {viewMode && (
        <PageModal>
          <SummaryReport setViewMode={setViewMode} data={accounts} />
        </PageModal>
      )}
    </div>
  );
}
