export const makatiBuildings = [
    {
      "buildingName": "6750 Ayala",
      "businessDistrict": "Makati",
      "address": "6750 Ayala Avenue",
      "developer": "Ayala Land, Inc.",
      "yearConstructed": 1993,
      "certifications": "PEZA",
      "totalFloors": 24,
      "availableSpace": "12F – 233.16 sqm\r\n16F – 875.74 sqm",
      "totalAvailableSpace": 1108.9,
      "density": "1:9",
      "floorEfficiency": 0.82,
      "handoverCondition": "Semi-Fitted",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Regular Hours (7:00 AM to 7:00 PM) Metered + PhP 300\r\nExtension Charge – (7:00 PM to 10:00 PM) PhP 2,800 /hour",
      "quotedRent": "PhP 1,300",
      "cusa": " PhP 292.31",
      "parkingRate": "PhP 10,000",
      "lastUpdated": "Nov. 8, 2023",
      "buildingGrade": "Grade A",
      "grossLeasableArea": "GLA of 22,795 sqm",
      "floorPlate": "1,375 sqm floor plate",
      "netUseableArea": "1,072.5 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "High Rise – 4 Passenger Elevators\r\nLow Rise – 4 Passenger Elevators\r\n2 Executive Elevators\r\n1 Service Elevator\r\n2 Parking Elevators",
      "telcoProviders": "Telco Providers: Eastern Telecom, Globe, PLDT",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "\r\nPhp 2,800.00 / hour (7PM to 10PM)\r\n\r\nNo online gaming allowed\r\n\r\n24 hour operations not allowed"
    },
    {
      "buildingName": "6780 Ayala",
      "businessDistrict": "Makati",
      "address": "6780 Ayala Avenue",
      "developer": "Jaka Properties",
      "yearConstructed": 1970,
      "certifications": "PEZA",
      "totalFloors": 15,
      "availableSpace": "Basement (Office) - 175.10 sqm\r\nGF - 150 sqm\r\n3F – 663.61 sqm\r\n3F – 300.00 sqm\r\n4F - 102.95 sqm\r\n5F – 400.00 sqm\r\n5F – 300.00 sqm\r\n6F – 317.22 sqm\r\n6F – 392.71 sqm\r\n7F – 717.00 sqm\r\n8F – 717.00 sqm\r\n8F – 300.00 sqm\r\n9F – 235.00 sqm\r\n9F – 124.21 sqm\r\n10F – 270.18 sqm\r\n12F – 300.00 sqm\r\nPortion of PH – 300.00 sqm\r\n\r\nAvailable by February 16, 2024 – \r\n10F - 114.67 sqm",
      "totalAvailableSpace": 5444.44,
      "density": "1:4.9",
      "floorEfficiency": 0.77,
      "handoverCondition": "Semi-Fitted",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Metered",
      "quotedRent": "PhP 950\r\nBasement - PhP 800\r\nGF - PhP 1,500",
      "cusa": "PhP 170",
      "parkingRate": "PhP 5,000",
      "lastUpdated": "December 6, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 14,372 sqm",
      "floorPlate": "1,314 sqm floor plate",
      "netUseableArea": "1,011.78 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "8 Passenger Elevators\r\n1 Service Elevator",
      "telcoProviders": "Telco Providers: Eastern Telecom, Bayantel, Globe, PLDT",
      "ceilingHeight": "3 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of Subject to Availability",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Makati Sky Plaza",
      "businessDistrict": "Makati",
      "address": "6788 Ayala Avenue",
      "developer": "Makati Sky Plaza, Inc. ",
      "yearConstructed": 1999,
      "certifications": "PEZA",
      "totalFloors": 22,
      "availableSpace": "2F – 175.15 sqm\r\n3F – 207.53 sqm\r\n3F – 207.53 sqm\r\n3F – 273.52 sqm\r\n4F Unit 1 – 206.19 sqm\r\n4F Unit 2 – 214.86 sqm\r\n4F Unit 6 – 206.19 sqm\r\n4F – 171.79 sqm\r\n5F – 592.84 sqm\r\n5F – 206.19 sqm\r\n6F – 1,185.68 sqm\r\n9F – 124.69 sqm\r\n12F – 589.52 sqm\r\n19F – 589.52 sqm\r\n20F – 589.52 sqm\r\n21F – 170.83 sqm\r\n21F – 205.03 sqm\r\n\r\n(4F Units 1 and 2 are combinable)",
      "totalAvailableSpace": 5916.580000000001,
      "density": "1:6.5",
      "floorEfficiency": 0.92,
      "handoverCondition": "Bare Shell\r\n4F (206.19 sqm), 3F (273.52 sqm), 19F  (589.52 sqm), & 21F (205.03 sqm) – Warm Shell",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,100",
      "cusa": "\r\nRegular hours – PhP 185\r\n24/7 – PhP 247",
      "parkingRate": " PhP 4,000",
      "lastUpdated": "October 18, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 25,527 sqm",
      "floorPlate": "1,180 sqm floor plate",
      "netUseableArea": "1,086 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "8 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Eastern Telecom, Globe, PLDT, Radius, Smart",
      "ceilingHeight": "2.8 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: Yes ",
      "remarks": "Does not allow embassys, co-working/ serviced offices\r\n\r\nAC Extension Charge – Metered"
    },
    {
      "buildingName": "BPI – Philam Life Makati",
      "businessDistrict": "Makati",
      "address": "6811 Ayala Avenue",
      "developer": "Ayala Land, Inc.",
      "yearConstructed": 1998,
      "certifications": "Non-PEZA",
      "totalFloors": 33,
      "availableSpace": "Direct to Unit Owners:\r\n10F – 291 sqm\r\n28F Unit D – 232 sqm\r\n\r\nAyala Floors:\r\n19F Unit C - 373.06 sqm",
      "totalAvailableSpace": 1949.02,
      "density": "1:9",
      "floorEfficiency": 0.88,
      "handoverCondition": "Semi-Fitted",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Regular Hours (7:00AM to 7:00PM) PhP 128 PSM\r\n19F – PhP 453.38 PSM\r\nExtended Charge – (8:00PM to 6:00AM) PhP 3,270 /hour",
      "quotedRent": "PhP 1,000\r\n19F – PhP 1,300\r\n32F – PhP 650",
      "cusa": "PhP 180.02\r\n19F – PhP 387.82",
      "parkingRate": "PhP 5,500\r\n19F – PhP 10,007.88\r\n",
      "lastUpdated": "Ayala – August 17, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 30,294 sqm",
      "floorPlate": "1,300 sqm floor plate",
      "netUseableArea": "1,144 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "9 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT, Eastern Telecom",
      "ceilingHeight": "2.68 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of \r\n17F – 3 slots\r\n22F to 24F – 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: No ",
      "remarks": "Prefers Traditional offices ONLY"
    },
    {
      "buildingName": "S&L Building",
      "businessDistrict": "Makati",
      "address": "101 Esteban, Legazpi Village, Makati, 1229 Metro Manila",
      "availableSpace": "GF - 293.91 sqm",
      "handoverCondition": "As is where is",
      "quotedRent": "PhP 1650",
      "parkingRate": "PhP 4000",
      "lastUpdated": 42583
    },
    {
      "buildingName": "Bankmer",
      "businessDistrict": "Makati",
      "address": "Ayala Avenue,\r\nMakati City ",
      "developer": "To be determined",
      "yearConstructed": "Completion 1970",
      "certifications": "Non-PEZA",
      "totalFloors": 15,
      "availableSpace": "Needs LOI first before they disclose the availability  ",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "To be determined",
      "quotedRent": "To be determined",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "lastUpdated": "X",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of 7,597 sqm",
      "floorPlate": "569 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Ayala North Exchange HQ",
      "businessDistrict": "Makati",
      "address": "6798 Ayala Avenue corner Salcedo St., Legaspi Village",
      "developer": "Ayala Land, Inc.",
      "yearConstructed": "4Q 2018 (October)",
      "certifications": "PEZA, LEED Certified",
      "totalFloors": 12,
      "availableSpace": "6F – 814.18 sqm\r\n11F – 1,639.80 sqm\r\n12F – 1,640.30 sqm\r\n14F – 1,611.81 sqm\r\n15F – 1,640.15 sqm",
      "totalAvailableSpace": 7346.24,
      "density": "1:7",
      "floorEfficiency": "Approx. 80.9%",
      "handoverCondition": "6F - Semi-Fitted \r\nWarm Shell",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,300",
      "cusa": "PhP 216.97",
      "parkingRate": " PhP 6,400",
      "lastUpdated": "December 11, 2023",
      "buildingGrade": "Grade A",
      "grossLeasableArea": "GLA of approx. 19,900 sqm",
      "floorPlate": "Approx. 1,631 sqm floor plate",
      "netUseableArea": "Approx. 1,319  sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "7 Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "2.7  meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:75",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "24/7 operations and BPO allowed\r\n\r\nNo AC extension charges"
    },
    {
      "buildingName": "Ayala North Exchange BPO",
      "businessDistrict": "Makati",
      "address": "6798 Ayala Avenue corner Salcedo St., Legaspi Village, Makati City",
      "developer": "Ayala Land, Inc.",
      "yearConstructed": "Completion 1Q 2019 (January)",
      "certifications": "PEZA Certified",
      "totalFloors": 21,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of 1:5",
      "floorEfficiency": "Approx. 81.7% Efficiency",
      "handoverCondition": "Handover Condition Fully Fitted",
      "acSystem": "Centralized Chilled Water AC System",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,700",
      "cusa": "PhP 215",
      "parkingRate": " PhP 6,000",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade A",
      "grossLeasableArea": "GLA of 36,001 sqm",
      "floorPlate": "Approx. 2,024 sqm floor plate",
      "netUseableArea": "Approx. 1,654 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "10 Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:75\r\n10F, 11F – Subject to availability",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "24 hour operations allowed\r\n\r\nNo AC extension charges"
    },
    {
      "buildingName": "Glorietta 1 Corporate Center",
      "businessDistrict": "Makati",
      "address": "Ayala Center,\r\nMakati City ",
      "developer": "Ayala Land, Inc.",
      "yearConstructed": "Completion 1Q 2019 (January)",
      "certifications": "PEZA Certified",
      "totalFloors": 12,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "84% Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "To be determined",
      "quotedRent": "To be determined",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 18,590.04 sqm",
      "floorPlate": "3,600 sqm floor plate",
      "netUseableArea": "3,024 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "6 Elevators",
      "telcoProviders": "Telco Providers: Globe",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Glorietta 2 Corporate Center",
      "businessDistrict": "Makati",
      "address": "Ayala Center",
      "developer": "Ayala Land, Inc.",
      "yearConstructed": 2012,
      "certifications": "PEZA Certified",
      "totalFloors": 11,
      "availableSpace": "5F – 3,029 sqm\r\n11F – 3,037.23 sqm\r\n\r\nNote: Confirm rates with Ayala \r\n\r\nCan be subdivided: 684.52 sqm, 637.47 sqm, 772.58 sqm, 745.50 sqm",
      "totalAvailableSpace": 6066.23,
      "density": "1:6.5",
      "floorEfficiency": 0.84,
      "handoverCondition": "Semi-Fitted",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,200",
      "cusa": "PhP 282",
      "parkingRate": "PhP 12,000",
      "lastUpdated": "December 11, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 20,071 sqm",
      "floorPlate": "2,888 sqm floor plate",
      "netUseableArea": "2,425.92 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "6 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Globe",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 \r\n11F – 8 slots",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Glorietta 5 Corporate Center",
      "businessDistrict": "Makati",
      "address": "Ayala Center,\r\nMakati City ",
      "developer": "Ayala Land, Inc.",
      "yearConstructed": "Completion 2007",
      "certifications": "PEZA Certified",
      "totalFloors": 6,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "87% Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "To be determined",
      "quotedRent": "To be determined",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 10,766 sqm",
      "floorPlate": "3,500 sqm floor plate",
      "netUseableArea": "3,045 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "2 Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "GT Tower",
      "businessDistrict": "Makati",
      "address": "Ayala Avenue",
      "developer": "Federal Land, Inc.",
      "yearConstructed": 1999,
      "certifications": "PEZA Certified",
      "totalFloors": 47,
      "availableSpace": "DOUBLE CHECK AVAILABILITY WITH FEDERAL LAND\r\n\r\n22F – 1,168 sqm\r\n27F -1,045 sqm\r\n37F – 837 sqm\r\n40F – 1,247 sqm\r\n\r\n\r\n37F can be subdivided",
      "totalAvailableSpace": 4297,
      "density": "1:7",
      "floorEfficiency": 0.88,
      "handoverCondition": "Warm Shell",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Regular Hours (8:30 AM to 5:30 PM) – Metered\r\nExtension Charge – PhP 2,100 /hour ",
      "quotedRent": "PhP 1,350",
      "cusa": "PhP 200",
      "parkingRate": "PhP 4,200",
      "lastUpdated": "Mar 3, 2023",
      "buildingGrade": "Grade A",
      "grossLeasableArea": "GLA of 48,536 sqm",
      "floorPlate": "1,200 sqm floor plate",
      "netUseableArea": "1,056 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "12 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Converge ICT, Globe, PLDT",
      "ceilingHeight": "2.6 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:200",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "Regular Office Hours: 9AM to 7PM\r\n25F – Warm Shell with paritions\r\n\r\n37F – Lease term is until June 15, 2020\r\n\r\n23F was formerly occupied by Crosscoop"
    },
    {
      "buildingName": "Insular Life Building Makati",
      "businessDistrict": "Makati",
      "address": "Ayala Avenue corner Paseo De Roxas",
      "developer": "Insular Life",
      "yearConstructed": "1963 (Renovated 2015)",
      "certifications": "PEZA Certified",
      "totalFloors": 14,
      "availableSpace": "Basement – 774.73 sqm \r\nBasement (storage) -23.57 sqm\r\n2F – 1,744.11 sqm\r\n3F – 1,679.31 sqm\r\n5F – 390.37 sqm\r\n5F – 823.43 sqm\r\n6F – 1,649 sqm (280 seats)",
      "totalAvailableSpace": 7084.5199999999995,
      "density": "1:5.5\r\n1:6.5 (POGO)",
      "floorEfficiency": 0.93,
      "handoverCondition": "Semi-Fitted\r\nBasement, 5F (823.43 sqm) – as is where is\r\n5F (390.37 sqm) – Bare Shell\r\n6F – Fully Fitted",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,200 \r\nBasement – PhP 1,100\r\nBasement (Storage), GF, GF (Storage TBD",
      "cusa": "PhP 200",
      "parkingRate": "PhP6,500 for Podium Parking and PhP 5,500 for open parking",
      "lastUpdated": "December 12, 2023",
      "buildingGrade": "Grade A",
      "grossLeasableArea": "GLA of 20,210 sqm ",
      "floorPlate": "1,623.13 sqm floor plate",
      "netUseableArea": "1,460.82 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "4 Passenger Elevators\r\n1 Service Elevators\r\n2 Parking Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "2.75 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of Subject to Availability",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "May operate 24/7"
    },
    {
      "buildingName": "KPMG Center",
      "businessDistrict": "Makati",
      "address": "Ayala Avenue",
      "developer": " KPMG",
      "yearConstructed": "1990s",
      "certifications": "Non-PEZA",
      "totalFloors": 12,
      "availableSpace": "Basement - 418.81 sqm\r\n8F to 10F – 644.73 sqm /floor\r\n\r\n8F & 10F are under advanced negotiation",
      "totalAvailableSpace": 2353,
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "Semi-Fitted\r\nBasement - Fully Fitted (training room set-up)\r\n10F - Warm Shell\r\n11F - Fully Fitted with workstations\r\n\r\n*9F - executive floor set-up",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Metered",
      "quotedRent": "GF – PhP 1,350\r\n2F to 10F – PhP 1,200",
      "cusa": "PhP 90",
      "parkingRate": " PhP 5,000",
      "lastUpdated": "December 5, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 8,541 sqm",
      "floorPlate": "706 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "3 Passenger Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 2 slots per floor",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "LKG Tower",
      "businessDistrict": "Makati",
      "address": "Ayala Avenue ",
      "developer": "ICEC Corporation",
      "yearConstructed": 1999,
      "certifications": "Non-PEZA",
      "totalFloors": 38,
      "availableSpace": "12B – 410.77 sqm\r\n15F – 1,111.30 sqm\r\n16F – 1,108.63 sqm\r\n17F – 1,105.70 sqm\r\n22F – 1,092.18 sqm\r\n23F – 393.31 sqm\r\n24F – 1,086.68 sqm\r\n25F – 1,083.90 sqm\r\n\r\n24F Cuts:\r\nUnit 1 – 542.28 sqm\r\nUnit 2 – 152.36 sqm\r\nUnit 3 – 392.04 sqm\r\n\r\n(TRADITIONAL OFFICE ONLY)",
      "totalAvailableSpace": 7391.700000000001,
      "density": "1:9",
      "floorEfficiency": 0.87,
      "handoverCondition": "Semi-Fitted",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Regular Hours (8:00 AM to 6:00 PM) – Metered\r\n\r\nExtension Charge – PhP 2,000 /hour",
      "quotedRent": "GF – PhP 1,700\r\nBasement – PhP 950\r\nOther floors – PhP 1,000",
      "cusa": "PhP 240",
      "parkingRate": "Reserved – PhP 5,500\r\nVIP – PhP 5,400",
      "lastUpdated": "Jun 26, 2023",
      "buildingGrade": "Grade A",
      "grossLeasableArea": "GLA of 32,771.47 sqm",
      "floorPlate": "1,252 sqm floor plate",
      "netUseableArea": "1,089.24 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "10 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Bayantel, Eastern Telecom, Globe, PLDT",
      "ceilingHeight": "2.75 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation: No more parking available but lessor can try renting parking slots to nearby area.",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "Saturdays (Regular Hours) – 8AM to 12PM\r\n\r\n37F – CEO Suites (Exclusive)"
    },
    {
      "buildingName": "LV Locsin",
      "businessDistrict": "Makati",
      "address": "6752 Ayala Ave corner Makati Ave, Legazpi Village, Makati City",
      "developer": "Ynatalco Realty Investment Co. Inc.",
      "yearConstructed": "Completion 1985",
      "certifications": "Non-PEZA",
      "totalFloors": 19,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of 1:8",
      "floorEfficiency": "80% Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "Metered",
      "quotedRent": "\r\n4F – PhP 1,100\r\n19F – PhP 1,200",
      "cusa": "PhP 170",
      "parkingRate": " PhP 5,000",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 16,617.17 sqm",
      "floorPlate": "888.25 sqm floor plate",
      "netUseableArea": "710.60 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "5 Passenger Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "2.50 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:100",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "Allocation of 5 parking slots "
    },
    {
      "buildingName": "SSS Makati",
      "businessDistrict": "Makati",
      "address": "Ayala Avenue,\r\nMakati City ",
      "developer": "SSS",
      "yearConstructed": "Completion 1960",
      "certifications": "Non-PEZA",
      "totalFloors": 13,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "89% Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "PhP 197",
      "quotedRent": "PhP 800",
      "cusa": "NA",
      "parkingRate": " PhP 4,500\r\n(may vary per unit -- depends )",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 19,100 sqm",
      "floorPlate": "1,650 sqm floor plate",
      "netUseableArea": "1,468 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "4 Passenger Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "2.50 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "Water – PhP 37 to PhP 40 PSM\r\nGarbage – PhP 5 PSM\r\n\r\nOnly allows month to month leases -- building renovation will happen early next year"
    },
    {
      "buildingName": "Allied Bank Center",
      "businessDistrict": "Makati",
      "address": "Ayala Avenue",
      "developer": "PNB",
      "yearConstructed": "1970s",
      "certifications": "Non-PEZA",
      "totalFloors": 13,
      "availableSpace": "Basement - 368.66 sqm\r\nMezzanine - 886.35 sqm\r\n3F - 1,180.71 sqm\r\n3F – 1,327.67 sqm\r\n5F – 448.04 sqm\r\n5F – 528.14 sqm\r\n7F – 209.48 sqm\r\n7F – 154.28 sqm\r\n7F – 112.36 sqm\r\n10F - 1,745.24 sqm\r\n11F - 1,232.44 sqm\r\n12F – 121.73 sqm\r\n\r\nMarch 2024:\r\n8F – 2,403.49 sqm",
      "totalAvailableSpace": 8315.099999999999,
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "As is Where is\r\n(Semi-fitted but not move in ready)",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,100",
      "cusa": "PhP 220",
      "parkingRate": "PhP 6,500",
      "lastUpdated": "October 18, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 36,677.64 sqm",
      "floorPlate": "2,640.80 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "5 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: PLDT, Globe, Rise, Infinivan",
      "ceilingHeight": "3 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:100",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "building now known as : PNB Center"
    },
    {
      "buildingName": "STI Holdings Center",
      "businessDistrict": "Makati",
      "address": "6764 Ayala Avenue",
      "developer": "PNB",
      "yearConstructed": "To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 10,
      "availableSpace": "10F – 1,032 sqm\r\n\r\nAllows half floor subdivision",
      "totalAvailableSpace": 1032,
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "Fully fitted",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,000",
      "cusa": "Regular Hours – PhP 100; 24/7 – PhP 200",
      "parkingRate": " To be determined",
      "lastUpdated": "October 9, 2023",
      "buildingGrade": "Grade: B",
      "grossLeasableArea": "GLA of  Approx. 10,320 sqm",
      "floorPlate": "Approx. 1,032 sqm floor plate",
      "netUseableArea": "To be determined net usable",
      "backupPower": "To be determined Backup Power ",
      "elevators": "Elevators To be determined",
      "telcoProviders": "To be determined",
      "ceilingHeight": "To be determined",
      "parkingAllocation": "Parking Allocation:To be determined",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Vicente Madrigal",
      "businessDistrict": "Makati",
      "address": "Ayala Avenue,\r\nMakati City ",
      "yearConstructed": "Completion ",
      "certifications": "Non-PEZA",
      "availableSpace": "Fully Leased\r\n\r\n(does not entertain brokers)",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 400",
      "cusa": " PhP 147",
      "parkingRate": " ",
      "lastUpdated": "X",
      "buildingGrade": " ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Makati Stock Exchange",
      "businessDistrict": "Makati",
      "address": "Ayala Avenue, Makati City ",
      "developer": "Ayala Land, Inc.",
      "yearConstructed": 1971,
      "certifications": "PEZA",
      "totalFloors": 8,
      "availableSpace": "5F – 1,200 sqm\r\n8F - 523.31 sqm",
      "totalAvailableSpace": 1723.31,
      "density": "1:9",
      "floorEfficiency": 0.87,
      "handoverCondition": "3F, 5F - Warm Shell\r\n4F (601.34 sqm, 144.62) - Semi fitted\r\n4F (251.68 sqm, 601.34 sqm) - Warm Shell",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "PhP 406\r\nExtension Charge – PhP 2,800/hour",
      "quotedRent": "PhP 1,100",
      "cusa": "PhP 367",
      "parkingRate": "PhP 17,200",
      "lastUpdated": "Oct 4, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 14,000 sqm",
      "floorPlate": "1,959.48 sqm floor plate",
      "netUseableArea": "1,469.61 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "8 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "3.5 meters finished ceiling height (slab to slab)",
      "parkingAllocation": "Parking Allocation of 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "Manila Bank Building",
      "businessDistrict": "Makati",
      "address": "6772 Ayala Avenue, Legazpi Village",
      "developer": "Dona Nicacasia Realty Corp.",
      "yearConstructed": 1967,
      "certifications": "Non-PEZA",
      "totalFloors": 13,
      "availableSpace": "\r\n6F Unit 10 – 83.50 sqm\r\n6F Unit 11C - 16.03 sqm\r\n6F Unit 12A- 61.10 sqm\r\n11F Unit 1 – 150.67 sqm\r\n11F Unit 2B - 84.30 sqm\r\n11F Unit 3 – 212.79 sqm\r\n11F Unit 3A – 108.55 sqm\r\n12F Whole Floor – 1,057.70 sqm\r\nPH Units 6 & 6A – 106.00 sqm\r\nPH Unit 7 - 58 sqm\r\nPH Unit 10 - 62.41 sqm\r\nPH Unit 14 – 40.00 sqm\r\nPH Unit 15 - 30 sqm\r\n\r\n12F Cuts:\r\n12F Unit A – 285.51 sqm\r\n12F Unit B –131.01 sqm\r\n12F Unit C – 641.18 sqm",
      "totalAvailableSpace": 2121.1899999999996,
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "Semi-fitted",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Regular Hours (6:00 AM to 7:00 PM) – Metered\r\n\r\nExtension Charge to be finalized. Tenants may provide their own split-type units if they intend to use the space beyond regular hours.",
      "quotedRent": "PhP 780",
      "cusa": "Included in rent",
      "parkingRate": "No slots available",
      "lastUpdated": "November 22, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 14,456 sqm",
      "floorPlate": "1,112 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "4 Passenger Elevators",
      "telcoProviders": "Telco Providers: Eastern Telecom, Globe, PLDT",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation N/A",
      "twentyFourSevenOperations": "24/7 Operations: Subject for approval"
    },
    {
      "buildingName": "McKinley Exchange",
      "businessDistrict": "Makati",
      "address": "Ayala Avenue,\r\nMakati City ",
      "developer": "Ayala Land, Inc.",
      "yearConstructed": "Completion 2014",
      "certifications": "PEZA Certified",
      "totalFloors": 5,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "89% Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "To be determined",
      "quotedRent": "To be determined",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 11,464 sqm",
      "floorPlate": "2,800 sqm floor plate",
      "netUseableArea": "2,492 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "2 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Multinational Bancorporation Center",
      "businessDistrict": "Makati",
      "address": " 805 Ayala Avenue, Makati City",
      "developer": "Amalgamated Development Corporation",
      "yearConstructed": "Completion 1996",
      "certifications": "PEZA Certified",
      "totalFloors": 23,
      "availableSpace": "Fully Leased\r\n\r\n\r\n",
      "totalAvailableSpace": 0,
      "density": "Density of 1:5",
      "floorEfficiency": "91% Efficiency",
      "handoverCondition": "Handover Condition Warm Shell (with partitions)",
      "acSystem": "Split Type AC",
      "acCharges": "Regular Hours (8:00AM to 5:00PM) – Metered",
      "quotedRent": "PhP 1,000",
      "cusa": "PhP 200",
      "parkingRate": " PhP 6,000",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 18,500 sqm",
      "floorPlate": "860 sqm floor plate",
      "netUseableArea": "782.60 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "3 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Eastern Telecom, Globe, PLDT, Smart, Radius",
      "ceilingHeight": "2.4 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "24 hour operations allowed"
    },
    {
      "buildingName": "Noble Square",
      "businessDistrict": "Makati",
      "address": "6762 Ayala Avenue ",
      "developer": "National Life Insurance",
      "yearConstructed": 1968,
      "certifications": "Non-PEZA",
      "totalFloors": 12,
      "availableSpace": "2F Unit 1 – 24.64 sqm\r\n5F Units 2 – 315.30 sqm\r\n6F Storage – 24.64 sqm\r\n7F Unit 1 – 94.13 sqm\r\n7F Unit 5 – 150.59 sqm\r\n7F Units 6 & 11 – 62.07 sqm\r\n7F Units 7 to 9 – 124.14 sqm\r\n7F Units 14 & 15 – 141.78 sqm\r\n8F Storage – 24.64 sqm\r\n8F – 986.49 sqm (Approx. 200 seats)\r\n9F Storage – 24.64 sqm\r\n9F Unit 3 – 70.72 sqm\r\n9F Unit 15 – 144.71 sqm\r\n10F Storage – 24.64 sqm\r\n10F – Approx. 490 sqm\r\n10F - Approx. 490 sqm\r\n11F Storage – 24.64 sqm\r\n11F – 986.49 sqm\r\n12F – 986.49 sqm\r\n\r\n10F, 11F, 12F can be subdivided into half",
      "totalAvailableSpace": 5511.89,
      "density": "1:5",
      "floorEfficiency": 0.87,
      "handoverCondition": "Warm Shell\r\n8F - Fully Fitted",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Metered",
      "quotedRent": "Warm Shell – PhP 850\r\nFully Fitted – PhP 980",
      "cusa": " PhP 205 ",
      "parkingRate": " PhP 6,000",
      "lastUpdated": "Oct. 12, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of 7,228 sqm",
      "floorPlate": "855 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "70% Backup Power",
      "elevators": "4 Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation First come, first serve",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "Can operate 24/7 – tenant to provide Back up AC\r\nOffice floors for office tenants only"
    },
    {
      "buildingName": "NEX Tower",
      "businessDistrict": "Makati",
      "address": "6786 Ayala Avenue, Brgy. San Lorenzo",
      "developer": "NEX Development Group",
      "yearConstructed": "4Q 2018",
      "certifications": "PEZA Certified, Pre-Certified LEED Platinum",
      "totalFloors": 28,
      "availableSpace": "\r\n14F – 1,467.62 sqm\r\n16F – 1,451.88 sqm\r\n\r\n\r\n16F can be subdivided:\r\nUnit 1 – 366.78 sqm\r\nUnit 2 –  336.03 sqm\r\nUnit 3 – 398.02 sqm\r\nUnit 4 – 351.05 sqm",
      "totalAvailableSpace": 2919.5,
      "density": "Low Zone – 1:8\r\nHigh Zone – 1:9",
      "floorEfficiency": 0.83,
      "handoverCondition": "Warm Shell",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,400",
      "cusa": "PhP 200",
      "parkingRate": "PhP 7,000",
      "lastUpdated": "October 25, 2023",
      "buildingGrade": "Prime",
      "grossLeasableArea": "GLA of 34,392.95 sqm ",
      "floorPlate": "Approx. 1,400 sqm floor plate",
      "netUseableArea": "100% Backup Power",
      "backupPower": "100% Backup Power",
      "elevators": "6 Low-Rise  Passenger Elevators\r\n5 High-Rise Passenger Elevators\r\n1 Service Elevator\r\n2 Parking Elevator",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "3 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: Subject for approval",
      "remarks": "Units come with ceiling T runners and acoustic ceiling tiles (provided for, but to be installed by the tenant)\r\n\r\nNo 24/7 operations, but allows 24/7 access\r\n\r\nNo foreign governments, embassies, POGO, recruitment firms, Vouce-based BPO and high trafffic tenants"
    },
    {
      "buildingName": "PBCOM Tower",
      "businessDistrict": "Makati",
      "address": "Ayala Avenue",
      "developer": "Filinvest Land",
      "yearConstructed": 2000,
      "certifications": "PEZA Certified",
      "totalFloors": 52,
      "availableSpace": "PBCOM FLOORS: \r\n10F – 1,377.81 sqm\r\n11F – 1,377.81 sqm\r\n12F Unit D – 380.49 sqm\r\n12F Unit E – 171.19 sqm\r\n17F – 1,377.81 sqm\r\n18F – 1,377.81 sqm\r\n48F - 1,137.96\r\n\r\nFILINVEST FLOORS (KMC Exclusive): \r\n21F- 1,377.82 sqm\r\n25F – 1,422.71 sqm\r\n26F Unit 1 & 1A – 704.22 sqm\r\n27F Unit 1 – 551.93 sqm\r\n27F Units 3 & 4 – 436.14 sqm\r\n32F – 1,444.76 sqm\r\n36F – 1,444.76 sqm\r\n\r\n27F - Possible to take one unit but lessor prefers 2 units taker. Tenant will shoulder the cost of construction.",
      "totalAvailableSpace": 14583.220000000001,
      "density": "1:5 (based on NUA)",
      "floorEfficiency": 0.89,
      "handoverCondition": "Semi-Fitted",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Metered\r\nExtension Charge PhP 2,000 to 2,500\r\n\r\nFilinvest Floors - Included in CUSA\r\nAC Extension Carges - PhP 1,800/hour/floor",
      "quotedRent": "PBCOM Floors – PhP 1,200\r\nFilinvest Floors – PhP 980\r\n\r\nFilinvest Floors (POGO) – PhP 1,300\r\n8F, 51F – PhP 1,543.50",
      "cusa": "PhP 225",
      "parkingRate": "PBCOM Floors – PhP 6,250\r\nFilinvest Floors – PhP 5,500",
      "lastUpdated": "PBCOM – October 10, 2023\r\nFilinvest – December 6, 2023",
      "buildingGrade": "Grade A",
      "grossLeasableArea": "GLA of 74,158 sqm",
      "floorPlate": "1,440.04 sqm floor plate",
      "netUseableArea": "1,281.63 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "17 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Arsysmedia, Bayantel, Digitel, Eastern Telecom, Globe, PLDT, Smart, Converge, Radius Telecoms, Rise, Infinivan",
      "ceilingHeight": "2.6 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation Subject to Availability",
      "twentyFourSevenOperations": "24/7 Operations: Yes ",
      "remarks": "PhP 1,800/hour for AC charge extenstion\r\n\r\nUnit for sublease parking slot:\r\n8 slots\r\n\r\n22F – Lease term is until November 2019"
    },
    {
      "buildingName": "RCBC Plaza Yuchengco Tower",
      "businessDistrict": "Makati",
      "address": "H.V. Dela Costa Street, Salcedo Village",
      "developer": "RCBC Realty Corporation",
      "yearConstructed": 2000,
      "certifications": "PEZA Certified",
      "totalFloors": 48,
      "availableSpace": "14F – 1,638.27 sqm\r\n15F – 1,637.98 sqm\r\n17F – 1,251.40 sqm\r\n19F – 147.64 sqm\r\n20F – 1,325.15 sqm\r\n23F – 1,697.93 sqm\r\n25F - 619.67 sqm\r\n26F – 241.60 sqm\r\n26F - 339.64 sqm\r\n28F – 1,705.56 sqm\r\n39F – 1,791.55 sqm\r\n\r\nAVAILABLE BY 1Q 2024:\r\n(Not included in vacancy yet)\r\n32F to 38F – 12,382.07 sqm\r\n42F – 1,719.52 sqm",
      "totalAvailableSpace": 12396.39,
      "density": "1:9 (based on net)",
      "floorEfficiency": "Whole floor – 90% Efficiency\r\nMulti-tenanted – 87% ",
      "handoverCondition": "5F, 15F, 20F, 23F, 25F, 26F (251 sqm), 29F, 32F, 34F, 35F, 37F, 38F\r\n – Semi-Fitted\r\n14F, 17F, 19F, 22F, 26F (407 sqm), 28F, 33F, 36F, 39F  – Warm Shell",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Metered + Php 18.00 per Tr per hour\r\nAC extension charge (minimum 2 hours):\r\nSingle tenanted floors – PhP 1.55 /sqm/hour\r\nMulti-tenanted floors – PhP 2,559.50/hour",
      "quotedRent": "PhP 1,550",
      "cusa": "PhP 240",
      "parkingRate": "PhP 5,000 (Single) to PhP 10,800 (Triple)",
      "lastUpdated": "December 6, 2023",
      "buildingGrade": "Prime",
      "grossLeasableArea": "GLA of 73,983 sqm",
      "floorPlate": "1,766 sqm floor plate",
      "netUseableArea": "1,544.08 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "22 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Converge ICT, Eastern Telecom, Globe, Infinivan PLDT. PhilComm, RISE, Smart, Skycable",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "Online gaming tenants not allowed\r\n\r\nAC Extension Charge/s :\r\nSingle-tenanted floors: Php 1.55 per sq.m. per hour \r\n\r\nMulti-tenanted floors: Php 2,559.50 per hour, with a minimum extension of 2 hours. \r\n\r\nChilled Water Charges for tenants operating back-up Fan Coil Units (FCUs): Php 16.00 per ton per hour \r\n"
    },
    {
      "buildingName": "RCBC Plaza Tower II",
      "businessDistrict": "Makati",
      "address": "H.V. Dela Costa Street, Salcedo Village",
      "developer": "RCBC Realty Corporation",
      "yearConstructed": 2000,
      "certifications": "PEZA Certified",
      "totalFloors": 42,
      "availableSpace": "10F – 1,718.17 sqm\r\n11F – 1,718.02 sqm\r\n12F – 1,717.91 sqm\r\n14F – 1,716.77 sqm\r\n15F – 448.87 sqm\r\n15F – 399.09 sqm\r\n16F – 977.79 sqm\r\n16F – 289.43 sqm\r\n17F – 922.67 sqm\r\n17F – 792.76 sqm\r\n18F – 589.20 sqm\r\n18F – 634.38 sqm\r\n19F – 1,675.51 sqm\r\n20F – 1,764.55 sqm\r\n40F – 1,833.55 sqm\r\n41F – 1,833.55 sqm\r\n\r\nAvailable by Q4 2023:\r\n28F to 29F – 1,773 sqm/floor",
      "totalAvailableSpace": 19032.11,
      "density": "1:9 (based on net)",
      "floorEfficiency": "Whole floor – 90% Efficiency\r\nMulti-tenanted – 87% ",
      "handoverCondition": "9F, 11F, 15F (448.87 sqm), 16F (977.79 sqm), 18F – Warm Shell\r\n14F, 15F (399.09 sqm), 16F (289.43 sqm), 17F, 19F, 20F, 38F, 39F – Semi-Fitted",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Metered + Php 18.00 per Tr per hour\r\nAC extension charge (minimum 2 hours):\r\nSingle tenanted floors – PhP 1.55 /sqm/hour\r\nMulti-tenanted floors – PhP 2,559.50/hour",
      "quotedRent": "PhP 1,550",
      "cusa": "PhP 240",
      "parkingRate": "PhP 5,000 (Single) to PhP 10,800 (Triple)",
      "lastUpdated": "December 6, 2023",
      "buildingGrade": "Prime",
      "grossLeasableArea": "GLA of 64,733 sqm",
      "floorPlate": "1,766 sqm floor plate",
      "netUseableArea": "1,544.08 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "17 Passenger Elevators for Tower 2\r\n2 Service Elevators for Tower 2",
      "telcoProviders": "Telco Providers: Converge ICT, Eastern Telecom, Globe, Infinivan PLDT. PhilComm, RISE, Smart, Skycable",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "Online gaming tenants not allowed\r\n\r\nAC Extension Charge/s :\r\nSingle-tenanted floors: Php 1.55 per sq.m. per hour \r\n\r\nMulti-tenanted floors: Php 2,559.50 per hour, with a minimum extension of 2 hours. \r\n\r\nChilled Water Charges for tenants operating back-up Fan Coil Units (FCUs): Php 16.00 per ton per hour \r\n"
    },
    {
      "buildingName": "Robinsons Summit Center",
      "businessDistrict": "Makati",
      "address": "6783 Ayala AvenueAE6:AE15AE6:AE17",
      "developer": "Robinsons Land Corporation",
      "yearConstructed": 2001,
      "certifications": "PEZA Certified",
      "totalFloors": 37,
      "availableSpace": "11F Unit 1 to 5 - 456.10 sqm\r\n12F - 1,014.34 sqm\r\n15F - 1,014.34 sqm\r\n20F - 1,025.18 sqm (chair and table)\r\n23F - 1,058.30 sqm (workstations, half)\r\n28F Units 4,5 & 6 - 496.15 sqm\r\n34F – 1,130 sqm (200 seats, 154)\r\n35F – 1,039.42 sqm (166 workstations only)\r\n\r\n23F - can be subdivided into half",
      "totalAvailableSpace": 9045.72,
      "density": "1:6",
      "floorEfficiency": 0.84,
      "handoverCondition": "10F Unit 6 to 12 - Warm Shell\r\n11F Unit 1 to 5, 15F, 16F Unit 11 to 12 - Semi-Fitted \r\n12F, 20F, 22F, 23F, 28F Units 4,5 & 6, 34F, 35F - Fitted \r\n23F, 35F - with existing fit-out (workstations only), subject for renovation\r\n\r\n",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Regular Hours (8:00AM to 7:00 PM) – Metered\r\nExtension Charge – PhP 21/ton/hour\r\n",
      "quotedRent": "PhP 1,000",
      "cusa": "Regular Hours – PhP 212\r\n24/7 – PhP 222",
      "parkingRate": "PhP 8,000",
      "lastUpdated": "November 20, 2023",
      "buildingGrade": "Grade A",
      "grossLeasableArea": "GLA of 31,003 sqm",
      "floorPlate": "1,058 sqm floor plate",
      "netUseableArea": "888.72 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "9 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "2.6 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "Building Dues for 24/7 operations: \r\nPhP 207 PSM\r\n\r\nAC Charge for the regular business hours (11 hours) is inclusive in the USD 14,500 rate.\r\n\r\nAC Extension Charge: PhP 21/tr/hr"
    },
    {
      "buildingName": "Rufino Pacific Tower",
      "businessDistrict": "Makati",
      "address": "Ayala Avenue corner Rufino Avenue, Makati City",
      "developer": "First Pacific Realty Partners Corp.",
      "yearConstructed": 1993,
      "certifications": "Non-PEZA",
      "totalFloors": 42,
      "availableSpace": "15F - 220sqm\r\n16F – 860 sqm",
      "totalAvailableSpace": 1080,
      "density": "Density of To be determined",
      "floorEfficiency": "90% Efficiency",
      "handoverCondition": "Handover Condition Warm Shell",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "To be determined",
      "quotedRent": "PhP 730",
      "cusa": "PhP 115",
      "parkingRate": " To be determined",
      "lastUpdated": "December 7, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 32,912 sqm",
      "floorPlate": "878 sqm floor plate",
      "netUseableArea": "789 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "8 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT, Eastern Telecom, Bayantel",
      "ceilingHeight": "2.43 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Rufino (VAR) Building",
      "businessDistrict": "Makati",
      "address": "Rufino Avenue, Makati City",
      "developer": "Var Buildings Inc.",
      "yearConstructed": "Completion 1995",
      "certifications": "Non-PEZA",
      "totalFloors": 10,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 550",
      "cusa": "PhP 88",
      "parkingRate": " PhP 2,800",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of Approx. 12,000 sqm",
      "floorPlate": "Approx. 1,400 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "Partial Backup Power",
      "elevators": "4 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Eastern Telecom, Globe, PLDT, Smart",
      "ceilingHeight": "2.6 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:200",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Security Bank Building",
      "businessDistrict": "Makati",
      "address": "Ayala Avenue,\r\nMakati City ",
      "developer": "Keyland",
      "yearConstructed": "Completion 1968",
      "certifications": "Non-PEZA",
      "totalFloors": 22,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "To be determined",
      "quotedRent": "To be determined",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of 34,000 sqm",
      "floorPlate": "1,545 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "SGV I & II",
      "businessDistrict": "Makati",
      "address": "Ayala Avenue,\r\nMakati City ",
      "developer": "To be determined",
      "yearConstructed": "Completion 1970's",
      "certifications": "Non-PEZA",
      "totalFloors": "16 storey per building",
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 700",
      "cusa": "Included in rent",
      "parkingRate": " No slots available",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of 8,320 sqm per building",
      "floorPlate": "569 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "CVG One",
      "businessDistrict": "Makati",
      "address": "6796 Ayala Avenue",
      "developer": "Ayala Land, Inc.",
      "yearConstructed": 2009,
      "certifications": "PEZA Certified",
      "totalFloors": "8 storey per building",
      "availableSpace": "3F – 2,253.10 sqm\r\n4F – 2,232.20 sqm\r\n5F  – 2,342.20 sqm\r\n6F – 2,342.20 sqm\r\n\r\nCan't do 500-600 sqm cuts",
      "totalAvailableSpace": 9169.699999999999,
      "density": "1:5.5",
      "floorEfficiency": "Approx. 90% (Subject for finalization)",
      "handoverCondition": "Bare Shell",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,250",
      "cusa": "PhP 200",
      "parkingRate": "PhP 6,500",
      "lastUpdated": "December 11, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 15,498",
      "floorPlate": "Approx. 2,300 sqm floor plate",
      "netUseableArea": "Approx. 2,070 sqm net usable  (subject for finalization)",
      "backupPower": "100% Backup Power",
      "elevators": "4 Passenger Elevators",
      "telcoProviders": "Telco Providers: Globe",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 74 slots",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Keyland Building",
      "businessDistrict": "Makati",
      "address": "6797 Ayala Avenue corner VA Rufino, Ayala Bel-Air , Makati City",
      "developer": "Security Land",
      "yearConstructed": 2012,
      "certifications": "PEZA",
      "totalFloors": 9,
      "availableSpace": "Basement – 569.30 sqm (under nego)\r\n4F – 676.05\r\n\r\n\r\nMap: Lawson Convenience Store - Keyland Store, G/F Unit D Keyland Bldg, 6797 Ayala Ave, Makati, 1209 Metro Manila",
      "totalAvailableSpace": 1245.35,
      "density": "To be determined",
      "floorEfficiency": 0.86,
      "handoverCondition": "Fully Fitted",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": "Metered",
      "quotedRent": "Basement – PhP 550\r\n4F – PhP 900",
      "cusa": "PhP 200",
      "parkingRate": "PhP 4,500",
      "lastUpdated": "November 14, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 9,125.56 sqm ",
      "floorPlate": "1,593 sqm floor plate",
      "netUseableArea": "1,370.66 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "4 Passenger Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "The Enterprise Center Tower I",
      "businessDistrict": "Makati",
      "address": "Paseo de Roxas corner Ayala Avenue, Legaspi Village",
      "developer": "KSA Realty",
      "yearConstructed": 1999,
      "certifications": "PEZA Certified",
      "totalFloors": 38,
      "availableSpace": "2F Unit 1 – 893.28  sqm\r\nTower 1 11F Unit 1-2 & 3a - 505.74 \r\n12F Whole Floor  – 1,482.54 sqm\r\n14F Unit 5 – 226.64 sqm\r\n15F Unit 4 to 6 – 579.83 sqm\r\n15F 7 & 8 – 389.20 sqm\r\n17F Unit 3 to 6  – 706.23 sqm\r\n18F 1 & 8 – 558.73 sqm\r\n18F Unit 5 – 235.82 sqm\r\n21F Unit 2 & 3 – 245.56 sqm\r\n22F Unit 2 to 4 – 463. 94 sqm\r\n22F Unit 5 – 220.18 sqm\r\n23F Unit 4 to 8 – 983.72 sqm\r\n34F Whole Floor – 1,365.14 sqm \r\n37F Whole Floor – 1,227.93 sqm\r\n\r\nAvailable by May 2024:\r\n22F Unit 1 & 8 - 506.52",
      "totalAvailableSpace": 10084.58,
      "density": "1:10",
      "floorEfficiency": 0.75,
      "handoverCondition": "Warm Shell\r\n12F, 15F Unit 4 to 6 - 22F Unit 5, 34F - As is where is",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Metered\r\nExtension Charge: PhP 1,800 / hour ",
      "quotedRent": "PhP 1,500",
      "cusa": "PhP 205",
      "parkingRate": "Non-reserved – PhP 3,600\r\nReserved – PhP 5,000",
      "lastUpdated": "December 4, 2023",
      "buildingGrade": "Prime",
      "grossLeasableArea": "GLA of 45,480 sqm ",
      "floorPlate": "1,482.54 sqm floor plate",
      "netUseableArea": "1,111.91 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "9 Passenger Elevators\r\n1 Service  Elevators",
      "telcoProviders": "Telco Providers: Bayantel, Eastern Telecom, Globe, Smart, PLDT",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "The Enterprise Center Tower II",
      "businessDistrict": "Makati",
      "address": "Paseo de Roxas corner Ayala Avenue, Legaspi Village",
      "developer": "KSA Realty",
      "yearConstructed": 1999,
      "certifications": "PEZA Certified",
      "totalFloors": 28,
      "availableSpace": "5F – 338.39 sqm\r\n5F – 415.74 sqm\r\n6F to 7F  – 1,482.54 sqm/floor\r\n8F Unit 1 to 4 & 8B – 886.06 sqm\r\n8F Unit 5A – 123.62 sqm\r\n8F Unit 5B-8A – 472.86 sqm\r\n10F Unit 3 – 119.82 sqm\r\n10F Unit 1 to 2, 7 to 8 – 743.61 sqm\r\n11F to 15F – 1,482.54 sqm/floor\r\n16F 1 & 8 – 537.95 sqm\r\n16F Unit 2 to 7 – 944.59 sqm\r\n17F Unit 2 to 4 – 487.73 sqm\r\n18F Unit 2 to 3– 232.43 sqm\r\n19F Units 2 to 5 - 726.74 sqm \r\n20F Unit 1 to 3 – 521.06 sqm\r\n21F – 130.97 sqm\r\n23F Unit 8 – 218.57 sqm",
      "totalAvailableSpace": 15795.409999999998,
      "density": "1:10",
      "floorEfficiency": 0.75,
      "handoverCondition": "Warm Shell",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Metered\r\nExtension Charge: PhP 1,800 / hour +",
      "quotedRent": "PhP 1,500",
      "cusa": "PhP 205",
      "parkingRate": "Non-reserved – PhP 3,600\r\nReserved – PhP 5,000",
      "lastUpdated": "December 4, 2023",
      "buildingGrade": "Prime",
      "grossLeasableArea": "GLA of 31,973 sqm ",
      "floorPlate": "1,444 sqm floor plate",
      "netUseableArea": "1,083 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "6 Passenger Elevators\r\n1 Service Elevators\r\n2 Parking Elevators",
      "telcoProviders": "Telco Providers: Bayantel, Eastern Telecom, Globe, Smart, PLDT",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "AC Extension Charge: PhP 1,800 / hour (regular office hours are 7:30AM to 6:00PM)\r\n\r\n24/7 tenants may provide own back up AC "
    },
    {
      "buildingName": "Tower 6789",
      "businessDistrict": "Makati",
      "address": "6789 Ayala Avenue",
      "developer": "Alphaland Makati Tower, Inc.",
      "yearConstructed": 2013,
      "certifications": "PEZA Certified; LEED Certified",
      "totalFloors": 34,
      "availableSpace": "5F – 1,412.02 sqm\r\n6F – 1,397.90 sqm\r\n7F – 1,391.66 sqm\r\n12F Unit 1 – 421.19 sqm\r\n19F – 1,449.99 sqm\r\n26F Unit 1 to 4 - 651.58\r\n\r\n*****\r\nFloors cannot be subdivided",
      "totalAvailableSpace": 6724.339999999999,
      "density": "1:9.3",
      "floorEfficiency": "82% to 84%",
      "handoverCondition": "Warm Shell",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Metered\r\nExtension Charge: PhP 1,800 / hour from 8PM to 8AM, holidays, and weekends",
      "quotedRent": "PhP 1,200",
      "cusa": " PhP 185",
      "parkingRate": "PhP 5,500",
      "lastUpdated": "November 17, 2023",
      "buildingGrade": "Grade A",
      "grossLeasableArea": "GLA of 44,205.42 sqm",
      "floorPlate": "Approx. 1,300 sqm floor plate",
      "netUseableArea": "Approx. 1,000 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "12 Passenger Elevators\r\n1 Service Elevator",
      "telcoProviders": "Telco Providers: ICT Converge, Globe, PLDT, Eastern Telecom",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Tower One & Exchange Plaza",
      "businessDistrict": "Makati",
      "address": "6767 Ayala Avenue corner Paseo de Roxas",
      "developer": "Ayala Land, Inc.",
      "yearConstructed": 1996,
      "certifications": "Non-PEZA",
      "totalFloors": 35,
      "availableSpace": "PSE Floors:\r\n2F – 1,804.18 sqm\r\n3F – 1,831.50 sqm\r\n(2F can be subdivided – min 160 sqm)\r\n\r\n4F – 179.68 sqm\r\n6F Unit 9 – 99.19 sqm\r\n8F Unit 4 – 96.61 sqm\r\n8F Unit 10 – 78.49 sqm\r\n14F Unit 14 – 95 sqm\r\n15F Unit 16 - 94.39 sqm\r\n16F Unit 14 – 87.14 sqm\r\n22F – 1,188.18 sqm\r\n23F Unit 4 – 86.25 sqm\r\n23F Unit 12 – 120.00 sqm\r\n23F Unit 14 – 115.00 sqm\r\n\r\nAVAILABLE BY 2Q 2024:\r\n4F - 98.43 sqm\r\n19F - 288.68 sqm\r\n19F - 305.29 sqm",
      "totalAvailableSpace": 5875.610000000001,
      "density": "1:10",
      "floorEfficiency": 0.87,
      "handoverCondition": "2F, 3F, 23F – Bare Shell\r\nOthers – Semi-Fitted\r\n16F – To be reinstated to Bare Shell",
      "acSystem": "Centralized Chilled Water\r\nPSE Floors – Variable Refrigerant Flow",
      "acCharges": "Regular Hours – PhP 200 psm\r\nPSE Floors – PhP 154 psm\r\n\r\nAC Extension: PhP 4,200/hr beyond 5PM",
      "quotedRent": "PhP 1,300\r\nPSE Floors – PhP 1,500",
      "cusa": "PhP 200\r\nPSE Floors – PhP 273",
      "parkingRate": "PhP 13,000\r\n2F & 3F – PhP 9,000\r\n15F – PhP 5,000\r\n16F – PhP 6,000",
      "lastUpdated": "Ayala - Nov. 8, 2023",
      "buildingGrade": "Prime",
      "grossLeasableArea": "GLA of 41,973 sqm ",
      "floorPlate": "1,315 sqm floor plate",
      "netUseableArea": "1,144.05 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "4 Low Zone Passenger \r\n3 Mid Zone Passenger\r\n3 High Zone Passenger",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "3.4  meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "Possible Cuts \r\n 2F Office A – 271.94 sqm \r\n2F Office B – 291.47 sqm \r\n2F Office C – 291.47 sqm \r\n2F Office D – 204.65 sqm \r\n2F Office E – 317.59 sqm \r\n2F Office F – 291.27 sqm \r\n2F Office G – 291.47 sqm \r\n2F Office H – 209.73 sqm"
    },
    {
      "buildingName": "League One, Inc. ",
      "businessDistrict": "Makati",
      "address": "Ayala Avenue Extension corner Malugay Street, Makati City",
      "developer": "Alphaland Corporation",
      "yearConstructed": "Completion 4Q 2017",
      "certifications": "Non-PEZA",
      "totalFloors": 33,
      "availableSpace": "Fully Leased",
      "density": "Density 1:5",
      "floorEfficiency": "Approx 82% Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Tenant to provide Variable Refrigerant Flow System",
      "acCharges": "Metered",
      "quotedRent": "\r\n7F – PhP 1,200\r\n23F – PhP 1,400",
      "cusa": "PhP 175",
      "parkingRate": " PhP 5,000",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of Approx. 23,000 sqm",
      "floorPlate": "Approx. 950 sqm floor plate",
      "netUseableArea": "Approx. 779 sqm net usable",
      "backupPower": "100% Backup Power",
      "telcoProviders": "Telco Providers : Globe, PLDT",
      "ceilingHeight": "Finished ceiling height :\r\n7F to 8F – 6.0 meters\r\n9F to 31F – 3.60 meters\r\n32F to 33F – 3.50 meters",
      "parkingAllocation": "Parking Allocation of 1:200",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Bank of Makati",
      "businessDistrict": "Makati",
      "address": "Ayala Avenue Extension, Makati City",
      "developer": "Transnational Properties. Inc.",
      "yearConstructed": "Completion 2Q 2017",
      "certifications": "Under Application for PEZA",
      "totalFloors": 14,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density 1:7",
      "floorEfficiency": "79% Efficiency",
      "handoverCondition": "Handover Condition Warm Shell",
      "acSystem": "Variable Refrigerant Flow System",
      "acCharges": " Regular Hours (8:00 AM to 5:00 PM) – Metered",
      "quotedRent": "PhP 1,100",
      "cusa": "\r\nRegular – PhP 190\r\n24/7 – PhP 290",
      "parkingRate": " PhP 3,500",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 9,007.51 sqm",
      "floorPlate": "915.56 sqm floor plate",
      "netUseableArea": "741.60 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "3 Passenger Elevators\r\n1 Service Elevator",
      "telcoProviders": "Telco Provider : PLDT",
      "ceilingHeight": "2.95 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 (based on NUA)",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "PH floors is prefered to be taken as a whole. \r\n\r\nAll floors can be subdivided into 409 sqm and 505 sqm"
    },
    {
      "buildingName": "Alphaland Corporate Tower",
      "businessDistrict": "Makati",
      "address": "Ayala Avenue Extension",
      "developer": "Alphaland Corporation",
      "yearConstructed": 2017,
      "certifications": "Non-PEZA",
      "totalFloors": 27,
      "availableSpace": "Mezzanine – 1,073.20 sqm\r\n6F – 667 sqm\r\n7F – 1,416 sqm\r\n9F – 950 sqm\r\n12F, 14F , 15F, 16F – 950 sqm/floor\r\n18F & 19F – 950 sqm/floor\r\n22F – 950 sqm\r\n28F, 29F, 30F, 31F* – 950 sqm/floor\r\n33F – 950 sqm\r\n\r\n*31F can be subdivided into quadrants\r\n\r\nFully fitted floors have approx. 150 to 200 seats\r\n",
      "totalAvailableSpace": 15506.2,
      "density": "1:5",
      "floorEfficiency": "7F to 8F – 84%\r\n9F to 33F – 87%",
      "handoverCondition": "Mezzanine, 9F, 12F, 14F, 15F, 22F – Bare Shell\r\nOthers – Fully Fitted\r\n",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": "Metered",
      "quotedRent": "Bare Shell – PhP 1,200\r\nFully Fitted Low Zone – PhP 1,200\r\nFully Fitted High Zone – PhP 1,500",
      "cusa": "PhP 220",
      "parkingRate": "PhP 6,000",
      "lastUpdated": "November 6, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 23,552 sqm",
      "floorPlate": "950 sqm floor plate",
      "netUseableArea": "7F to 8F – 798 sqm\r\n9F to 33F – 826.5 sqm",
      "backupPower": "100% Backup Power",
      "elevators": "3 Passenger\r\n1 Service",
      "telcoProviders": "Telco Provider : Converge, ETPI, Globe, PLDT",
      "ceilingHeight": "6F to 8F – 6 meters finished ceiling height\r\n9F to 33F – 3.6 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:200",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Ayala Triangle Gardens – Tower Two",
      "businessDistrict": "Makati",
      "address": "Paseo de Roxas corner Makati Avenue corner Ayala Avenue",
      "developer": "Ayala Land, Inc.",
      "yearConstructed": "1Q 2021 (March)",
      "certifications": "Under Application for PEZA, Pre-Certified LEED Gold",
      "totalFloors": 39,
      "availableSpace": "7F Unit 2 – 179 sqm\r\n7F Unit 3 – 179 sqm\r\n7F Unit 5 – 333 sqm\r\n7F Unit 6 – 125 sqm\r\n7F Unit 7 – 171 sqm\r\n9F – 516 sqm\r\n11F – 1,947 sqm\r\n12F – 1,947 sqm\r\n19F – 139 sqm\r\n21F – 516.34 sqm\r\n30F – 1,995.57 sqm (ROFR)\r\n31F – 1,491 sqm (ROFR)\r\n32F – 2,087 sqm (ROFR)\r\n\r\nWhole floors may be subdivided into half",
      "totalAvailableSpace": 10924.91,
      "density": "1:9.3",
      "floorEfficiency": "Single-tenanted – 98%\r\nMulti-tenanted – 95%",
      "handoverCondition": "Bare Shell",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "PhP 250 PSM\r\nAC Extension – PhP 4,000/hour",
      "quotedRent": "PhP 1,800",
      "cusa": "PhP 250",
      "parkingRate": "PhP 12,000",
      "lastUpdated": "December 11, 2023",
      "buildingGrade": "Prime",
      "grossLeasableArea": "GLA of 67,308 sqm",
      "floorPlate": "Low Zone (6F to 17F) : 1,966 sqm\r\nMid Zone (18F to 28F): 2,026 sqm\r\nHigh Zone (29F to 39F) : 2,086 sqm",
      "netUseableArea": "Low Zone (6F to 17F) : 1,927 sqm\r\nMid Zone (18F to 28F): 1,985 sqm\r\nHigh Zone (29F to 39F) : 2,044 sqm",
      "backupPower": "100% Backup Power",
      "elevators": "20 Passenger Elevators\r\n1 Service Elevator\r\n2 Parking Elevators",
      "telcoProviders": "Telco Providers : Multiple Providers",
      "ceilingHeight": "3.0 meters finished ceiling height\r\n2.9 meters finished ceiling height w/ raise flooring",
      "parkingAllocation": "Parking Allocation 1:84",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "One Ayala Tower 1",
      "businessDistrict": "Makati",
      "address": "Ayala Avenue corner EDSA",
      "developer": "Ayala Land, Inc.",
      "yearConstructed": "4Q 2021 (December)",
      "certifications": "PEZA",
      "totalFloors": 22,
      "availableSpace": "12F – 2,063.99 sqm\r\n14F – 2,063.99 sqm\r\n\r\nCan be subdivided into quadrants",
      "totalAvailableSpace": 4127.98,
      "density": "1:5",
      "floorEfficiency": 0.94,
      "handoverCondition": "Bare Shell",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,600 - Whole floor\r\nPhP 1,800 - Subdivided floors",
      "cusa": "PhP 203.50",
      "parkingRate": "PhP 8,000",
      "lastUpdated": "December 11, 2023",
      "buildingGrade": "Grade A",
      "grossLeasableArea": "GLA of 32,410 sqm",
      "floorPlate": "2,026 sqm floor plate",
      "netUseableArea": "1,924.70 sqm net usable area",
      "backupPower": "N+1 Backup Power",
      "elevators": "6 High Zone Passenger\r\n6 Low Zone Passenger\r\n1  Service\r\n3 Parking",
      "telcoProviders": "Telco Providers : Globe, PLDT",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:90",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "One Ayala Tower 2",
      "businessDistrict": "Makati",
      "address": "Ayala Avenue corner EDSA",
      "developer": "Ayala Land, Inc.",
      "yearConstructed": "1Q 2022 (March)",
      "certifications": "PEZA",
      "totalFloors": 29,
      "availableSpace": "12F – 464.50 sqm",
      "totalAvailableSpace": 0,
      "density": "1:5",
      "floorEfficiency": 0.94,
      "handoverCondition": "Bare Shell",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,600",
      "cusa": "PhP 203.50",
      "parkingRate": "PhP 8,000",
      "lastUpdated": "December 11, 2023",
      "buildingGrade": "Grade A",
      "grossLeasableArea": "GLA of 42,890 sqm",
      "floorPlate": "1,820 sqm floor plate",
      "netUseableArea": "1,729 sqm net usable area",
      "backupPower": "N+1 Backup Power",
      "elevators": "6 High Zone Passenger\r\n6 Mid Zone Passenger\r\n6 Low Zone Passenger\r\n1  Service\r\n3 Parking",
      "telcoProviders": "Telco Providers : Globe, PLDT",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:90",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "The Gentry Corporate Plaza",
      "businessDistrict": "Makati",
      "address": "130 Valero Street, Salcedo Village, Makati City",
      "developer": "Ayala Land, Inc.",
      "yearConstructed": "Completion 3Q 2024",
      "certifications": "Non-PEZA",
      "totalFloors": 23,
      "availableSpace": "Whole building – 30,349 sqm",
      "totalAvailableSpace": 22400,
      "density": "Density To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "To be determined AC System",
      "acCharges": "To be determined",
      "quotedRent": "To be determined",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "buildingGrade": "Grade A",
      "grossLeasableArea": "GLA of 30,349 sqm",
      "floorPlate": "Approx. 1,600 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable area",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined ceiling height",
      "parkingAllocation": "Parking Allocation: To be determined",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Alveo Financial Tower",
      "businessDistrict": "Makati",
      "address": "6794 Ayala Ave, Legazpi Village",
      "developer": "Alveo Land",
      "yearConstructed": "4Q 2021",
      "certifications": "LEED Certified, Non-PEZA",
      "totalFloors": 55,
      "availableSpace": "21F – 1,147 sqm\r\n22F Unit 1 – 129 sqm\r\n22F Unit 5 – 114 sqm\r\n22F Unit 8 – 115 sqm\r\n22F Unit 9 – 108 sqm\r\n22F Unit 10 – 108 sqm\r\n22F Unit 11 – 108 sqm\r\n(22F Units 1, 8 to 11 are combinable)\r\n\r\n(LPC Char)\r\n26F – Approx. 550 sqm\r\n\r\nColliers:\r\n19F, 27F, 28F, 29F – 1,271 sqm/floor\r\n43F Unit 8 - 108 sqm\r\n43F Unit 9 - 115 sqm",
      "totalAvailableSpace": 6043,
      "density": "To be determined",
      "floorEfficiency": 0.9,
      "handoverCondition": "Bare Shell",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Sub-metered",
      "quotedRent": "PhP 1,200",
      "cusa": "PhP 200",
      "parkingRate": "26F – To be determined\r\n27F, 28F, 29F – PhP 6,000",
      "lastUpdated": "LPC Marco - November 06, 2023\r\nColliers - October 5, 2023",
      "buildingGrade": "Grade A",
      "grossLeasableArea": "GLA of 65,000 sqm",
      "floorPlate": "Approx. 1,100 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable area",
      "backupPower": "100% Backup Power",
      "elevators": "To be determined Passenger Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation: \r\n26F – 5 slots\r\n27F, 28F – 10 slots/floor",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "Stiles Enterprise Plaza",
      "businessDistrict": "Makati",
      "address": "Hippodromo St. corner Theater Dr. Circuit Makati, Makati City",
      "developer": "Alveo Land",
      "yearConstructed": "Completion 1Q 2020",
      "certifications": "LEED Certified, Non-PEZA",
      "totalFloors": "West Tower – 29 \r\nEast Tower – 33 ",
      "availableSpace": "16F Unit 7 – 142 sqm\r\n16F Unit 8 – 81 sqm\r\n\r\nSKF \r\nWest Tower: \r\n15F – 79 sqm\r\n20F – 79 sqm",
      "totalAvailableSpace": 381,
      "density": "Density To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell\r\n15F, 20F  – Warm Shell",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 850\r\nSKF Unit – PhP 550 sqm ",
      "cusa": "PhP 185",
      "parkingRate": "PhP 5,000",
      "lastUpdated": "SKF – July 28, 2023\r\nOthers – December 4, 2023",
      "buildingGrade": "Grade A",
      "grossLeasableArea": "GLA of To be determined",
      "floorPlate": "Approx. 1,300 to 1,400  sqm floor plate",
      "netUseableArea": "To be determined sqm net usable area",
      "backupPower": "100% Backup Power",
      "elevators": "To be determined Passenger Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "To be determined ceiling height",
      "parkingAllocation": "Parking Allocation: To be determined",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "One Ayala Avenue HQ",
      "businessDistrict": "Makati",
      "address": "EDSA corner Ayala Avenue",
      "developer": "Ayala Land",
      "yearConstructed": "4Q 2023",
      "certifications": "PEZA, LEED Certified",
      "totalFloors": "26 (19 Office Floors)",
      "availableSpace": "AVAILABLE BY 4Q 2023:\r\n7F – 838.05 sqm​\r\n8F – 689.70 sqm​\r\n9F to 26F – 698.27 sqm/floor​",
      "totalAvailableSpace": 13230.53,
      "density": "1:9.3",
      "floorEfficiency": "68% to 75%",
      "handoverCondition": "Bare Shell",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,600",
      "cusa": "PhP 203.50",
      "parkingRate": "PhP 8,000",
      "lastUpdated": "Nov 10, 2023",
      "buildingGrade": "Grade A",
      "grossLeasableArea": "GLA of 13,230.53",
      "floorPlate": "Approx. 682 sqm",
      "netUseableArea": "To be finalized",
      "backupPower": "N+1",
      "elevators": "4 Passenger, 2 Shuttle, 1 Service",
      "telcoProviders": "Multiple Providers",
      "ceilingHeight": "2.7 m",
      "parkingAllocation": "Subject to availability",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Marvin Plaza",
      "businessDistrict": "Makati",
      "address": "Chino Roces Avenue corner Rufino Street, Legazpi Village",
      "developer": "Pieceland Corporation",
      "yearConstructed": 1977,
      "certifications": "PEZA Certified",
      "totalFloors": 10,
      "availableSpace": "2F Unit B – 408.00 sqm\r\n4F Unit B – 72.00 sqm\r\n4F Unit E – 99.00 sqm\r\n4F Unit F – 31.00 sqm\r\n4F Unit G – 45.00 sqm\r\n4F Unit H – 46.00 sqm\r\n4F Unit I – 44.00 sqm\r\n4F Unit J – 53.00 sqm\r\n4F Unit K-B – 81.00 sqm\r\n4F Unit M – 130.00 sqm\r\n4F Unit N – 125 sqm\r\n5F – 1,296.40 sqm\r\n6F Unit B – 50.00 sqm\r\n6F Unit F – 49.00 sqm\r\n6F Unit J – 83.00 sqm\r\n6F Unit K – 58.00 sqm\r\n6F Unit L – 240.00 sqm\r\n7F Unit B – 585.00 sqm\r\n8F – 1,269.00 sqm\r\n9F – 1,284.40 sqm\r\nPH Unit B – 515.00 sqm\r\nPH Unit C – 25.00 sqm\r\nPH Unit E – 650.00 sqm",
      "totalAvailableSpace": 7238.799999999999,
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "As is where is",
      "acSystem": "Tenant to provide AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 400",
      "cusa": "PhP 200",
      "parkingRate": "PhP 4,000 to PhP 5,000",
      "lastUpdated": "May 29, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA To be determined",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "To be determined ceiling height",
      "parkingAllocation": "Parking Allocation: Subject to availability",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Banco Filipino Building",
      "businessDistrict": "Makati",
      "address": "101 Paseo De Roxas, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 8,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "To be determined",
      "quotedRent": "\r\nGF – PhP 2,000\r\n2F to 8F – PhP 1,700",
      "cusa": "To be determined",
      "parkingRate": " Included in rent",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 5,113.43 sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "To be determined Passenger Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined ceiling height",
      "parkingAllocation": "Parking Allocation: 1 slot/floor",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "142 Amorsolo Building",
      "businessDistrict": "Makati",
      "address": "142 Amorsolo St., Legaspi Village",
      "developer": "Duralexbrands Innovation Corporation",
      "yearConstructed": 2000,
      "certifications": "Non-PEZA",
      "totalFloors": 11,
      "availableSpace": "4F to 9F – 705 sqm/floor\r\n10F – 381 sqm",
      "totalAvailableSpace": 4611,
      "density": "1:8",
      "floorEfficiency": 0.85,
      "handoverCondition": "As is where is (Bare/Warm)",
      "acSystem": "Split Type ",
      "acCharges": "Metered",
      "quotedRent": "PhP 800 ",
      "cusa": "PhP 130",
      "parkingRate": "PhP 4,500",
      "lastUpdated": "November 14, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 5,559 sqm",
      "floorPlate": "Approx. 705.28 sqm floor plate",
      "netUseableArea": "613.60 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "2 Passenger Elevators",
      "telcoProviders": "PLDT, Globe, Sky Cable, Converge",
      "ceilingHeight": "2.4 m",
      "parkingAllocation": "4 slots per floor",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Valderrama Building",
      "businessDistrict": "Makati",
      "address": "107 Esteban Street, Legazpi Village, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 5,
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Semi-Fitted ",
      "acSystem": "Tenant to Provide AC System",
      "acCharges": "Metered",
      "quotedRent": "PhP 525",
      "cusa": "N/A",
      "parkingRate": " PhP 5,000",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 2,000 sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "To be determined Passenger Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined ceiling height",
      "parkingAllocation": "Parking Allocation: 1 slot",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "111 Paseo de Roxas",
      "businessDistrict": "Makati",
      "address": "Paseo de Roxas, Legazpi Village",
      "developer": "111 Paseo de Roxas Condominium Association, Inc.",
      "yearConstructed": 1990,
      "certifications": "Non-PEZA",
      "totalFloors": 8,
      "availableSpace": "Basement - 600 sqm\r\n6F - 62.70 sqm\r\n8F - 289.56 sqm",
      "totalAvailableSpace": 952.26,
      "density": "To be determined",
      "floorEfficiency": 0.9,
      "handoverCondition": "Semi-Fitted",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "PhP 135",
      "quotedRent": "PhP 800\r\nBasement – PhP 600",
      "cusa": "PhP 100\r\nBasement – PhP 150\r\n",
      "parkingRate": "PhP 3,500",
      "lastUpdated": "November 6, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 8,000 sqm",
      "floorPlate": "1,000 sqm floor plate",
      "netUseableArea": "900 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "3 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT, Eastern Telecom",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of \r\nOther floors – 1:100 \r\nLG – To be determined",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "Angelus Medical Plaza Building",
      "businessDistrict": "Makati",
      "address": "V.A. Rufino Street, Legaspi Village,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion 1990",
      "certifications": "Non-PEZA",
      "totalFloors": 8,
      "availableSpace": "5F – 89 sqm\r\n8F – 365 sqm\r\n\r\n(does not offer broker's fee)",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Semi-Fitted",
      "acSystem": "Tenant to Provide AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 650",
      "cusa": "PhP 65",
      "parkingRate": " PhP 3,000",
      "lastUpdated": "X",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of 4,644 sqm",
      "floorPlate": "580.50 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "2 Passenger Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1 slot/unit",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "Don Pablo Building",
      "businessDistrict": "Makati",
      "address": "114 Amorsolo Street, Legaspi Village",
      "developer": "Trans-World Corp.",
      "yearConstructed": 1972,
      "certifications": "Non-PEZA",
      "totalFloors": 8,
      "availableSpace": "3F – 360 sqm\r\n5F – 45 sqm \r\n8F – 140 sqm",
      "density": "1:8",
      "floorEfficiency": "To be determined",
      "handoverCondition": "Bare Shell",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Included in rent",
      "quotedRent": "PhP 1,000 (VAT inclusive)\r\n3F – PhP 1,200 (VAT inclusive)",
      "cusa": "\r\nIncluded in rent",
      "parkingRate": " PhP 5,000",
      "lastUpdated": "3F - Nov. 29, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined Sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: Eastern Telecom, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation\r\n3F – 2 Slots\r\n5F & 8F – First come first serve",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "The Palisades",
      "businessDistrict": "Makati",
      "address": "107 Perea, Legazpi Village, Makati",
      "developer": "To be determined",
      "yearConstructed": "Completion 2000",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition \r\nGF – As is where is\r\n9F – Fully Fitted",
      "acSystem": "To be determined AC",
      "acCharges": "To be determined",
      "quotedRent": "\r\nGF – PhP 2,000",
      "cusa": "\r\nGF – PhP 80.60",
      "parkingRate": " PhP 4,500",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 3 slots",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "Republic Glass Building",
      "businessDistrict": "Makati",
      "address": "196 Salcedo St., Legazpi Village, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion 1980's",
      "certifications": "Non-PEZA",
      "totalFloors": 7,
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Split Type AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 700",
      "cusa": "PhP 75",
      "parkingRate": " PhP 4,500",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "618 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "2 Passenger Elevators",
      "telcoProviders": "Telco Providers: Converge, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation \r\n2F – 3 slots",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Basic Petroleum Building",
      "businessDistrict": "Makati",
      "address": "104 Don Carlos Palanca, Legazpi Village, Makati, Metro Manila",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "To be determined AC",
      "acCharges": "To be determined",
      "quotedRent": "To be determined",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Corporate Business Centre",
      "businessDistrict": "Makati",
      "address": "151 Paseo de Roxas, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "7F PH – 672.04 sqm",
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "Bare",
      "acSystem": "To be determined",
      "quotedRent": "PhP 500",
      "cusa": "PhP 107.98",
      "parkingRate": " PhP 5,000",
      "lastUpdated": "November 6, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 3 Slots ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Bloomingdale Building",
      "businessDistrict": "Makati",
      "address": "Salcedo Street, Legazpi Village,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 5,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Tenant to Provide AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 700",
      "cusa": "PhP 100",
      "parkingRate": " PhP 3,500",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "2 Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "May operate 24/7\r\nParking: PH – 1 slot allocated"
    },
    {
      "buildingName": "Salcedo One Center",
      "businessDistrict": "Makati",
      "address": "170 Salcedo Street, Legaspi Village",
      "developer": "Palanca Land",
      "yearConstructed": "1970's",
      "certifications": "Non-PEZA",
      "totalFloors": 8,
      "availableSpace": "\r\n2F Unit 3 – 21.51 sqm\r\n3F Unit 2 – 202.50 sqm\r\n5F Unit D – 21.50 sqm\r\n6F Unit 1 – 30.99 sqm\r\n7F Unit 1 – 44.00 sqm\r\n7F Unit 3 – 51.40 sqm\r\n7F Unit 4 – 108.22 sqm\r\n7F Unit G – 21.51 sqm\r\nPenthouse Unit P-1 – 33.67 sqm",
      "totalAvailableSpace": 535.3,
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "3F Unit 2 - Bare Shell\r\nBare Shell, Semi-Fitted\r\n4F Unit C – Fully Fitted",
      "acSystem": "Tenant to Provide",
      "acCharges": "Metered",
      "quotedRent": "PhP 750",
      "cusa": "Regular:  PhP 100\r\n24/7: PhP 200",
      "parkingRate": "PhP 4,000",
      "lastUpdated": "October 23, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of 3,266.16 sqm",
      "floorPlate": "470 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "50% (only for common areas)  Backup Power",
      "elevators": "1 Passenger Elevator",
      "telcoProviders": "Telco Providers: Globe, PLDT, Eastern",
      "ceilingHeight": "2.5 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation: No Available Space",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "Every 100 sqm = 1 parking slot"
    },
    {
      "buildingName": "Cityland III Condominium",
      "businessDistrict": "Makati",
      "address": "V.A. Rufino Street, Legaspi Village,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "To be determined AC",
      "acCharges": "To be determined",
      "quotedRent": "To be determined",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "lastUpdated": "Fully Leased ",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Cypress Garden Condominium Corporation",
      "businessDistrict": "Makati",
      "address": "V.A. Rufino Street, Legaspi Village,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 8,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Split Type AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 40,000 per month",
      "cusa": "PhP 86",
      "parkingRate": " Included in rent (1 slot)",
      "lastUpdated": "Fully Leased ",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined",
      "floorPlate": "To be determined floor plate",
      "netUseableArea": "To be determined",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined finished ceiling height",
      "parkingAllocation": "Parking Allocation of To be determined",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Coherco Corporate Center",
      "businessDistrict": "Makati",
      "address": "V.A. Rufino Street, Legaspi Village,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 7,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Semi-Fitted",
      "acSystem": "Split Type Type AC",
      "acCharges": "Metered",
      "quotedRent": "\r\nGF – PhP 1,500",
      "cusa": "PhP 120",
      "parkingRate": " PhP 3,500",
      "lastUpdated": "Fully Leased\r\nUpdated May 11, 2023",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined",
      "floorPlate": "To be determined floor plate",
      "netUseableArea": "To be determined",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined finished ceiling height",
      "parkingAllocation": "Parking Allocation of To be determined",
      "twentyFourSevenOperations": "24/7 Operations: ",
      "remarks": "GF – no heavy cooking allowed; light cooking subject for approval"
    },
    {
      "buildingName": "Corinthian Plaza",
      "businessDistrict": "Makati",
      "address": "Paseo de Roxas Street, Legaspi Village, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 7,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Semi-Fitted",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "Metered",
      "quotedRent": "\r\n3F – PhP 850\r\n7F – PhP 1,300",
      "cusa": "PhP 85",
      "parkingRate": " PhP 8,000",
      "lastUpdated": "Fully Leased ",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined",
      "floorPlate": "To be determined floor plate",
      "netUseableArea": "To be determined",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: Eastern Telecom, Globe, PLDT",
      "ceilingHeight": "To be determined finished ceiling height",
      "parkingAllocation": "Parking Allocation \r\nGarden level – subject to availability\r\n7F – 2 slots",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "CYA Land & Properties Inc.",
      "businessDistrict": "Makati",
      "address": "Rada Street, Legaspi Village,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Does not allow brokers",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "To be determined AC",
      "acCharges": "To be determined",
      "quotedRent": "To be determined",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "lastUpdated": "X",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Exchange Corner",
      "businessDistrict": "Makati",
      "address": "V.A. Rufino Street, Legaspi Village,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "Tenant to Provide AC",
      "acCharges": "To be determined",
      "quotedRent": "To be determined",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "lastUpdated": "Fully Leased ",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Dolmar Gold Tower",
      "businessDistrict": "Makati",
      "address": "C. Palanca Street, Legazpi Village, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 6,
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Tenant to Provide Split Type AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 495",
      "cusa": "To be determined",
      "parkingRate": " PhP 2,000",
      "lastUpdated": "Fully Leased ",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Don Jacinto Building",
      "businessDistrict": "Makati",
      "address": "141 Salcedo Street, Legaspi Village,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion 1970",
      "certifications": "Non-PEZA",
      "totalFloors": 9,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Fitted",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "PhP 150 PSM",
      "quotedRent": "PhP 600",
      "cusa": "N/A",
      "parkingRate": " PhP 4,950",
      "lastUpdated": "Fully Leased ",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 6,300 sqm",
      "floorPlate": "700 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "2 Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "2.5 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Euro-ville",
      "businessDistrict": "Makati",
      "address": "V.A. Rufino Street, Legaspi Village,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 6,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "To be determined AC",
      "acCharges": "To be determined",
      "quotedRent": "To be determined",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "lastUpdated": "Fully Leased ",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Feliza Building",
      "businessDistrict": "Makati",
      "address": "V.A. Rufino Street, Legazpi Village,\r\nMakati City",
      "developer": "AC Enterpise",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 11,
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Semi-Fitted",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "Metered",
      "quotedRent": "\r\nGF – PhP 1,000",
      "cusa": "N/A",
      "parkingRate": " PhP 5,000",
      "lastUpdated": "Fully Leased ",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "3 Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation \r\nGF – 401.43 sqm (1 parking slot)\r\nGF – 478 sqm (2 parking slots)",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "Min. lease term – 1 year, renewable"
    },
    {
      "buildingName": "Filipino Building",
      "businessDistrict": "Makati",
      "address": "135 Dela Rosa Street, Legaspi Village, Makati City",
      "developer": "A. Aguirre Inc ",
      "yearConstructed": "Completion 1990",
      "certifications": "Non-PEZA",
      "totalFloors": 7,
      "availableSpace": "6F – 270 sqm\r\n7F – 220 sqm",
      "totalAvailableSpace": 490,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Warm Shell",
      "acSystem": "Tenant to provide Split Type AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 800",
      "cusa": "\r\nTraditional – PhP 85\r\n24/7 – Subject to additional charge if elevators would run",
      "parkingRate": " PhP 8,000",
      "lastUpdated": "July 27, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1 slot",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "First Capital Condominium",
      "businessDistrict": "Makati",
      "address": "Rada Street, Legazpi Village,\r\nMakati City ",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 3,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "To be determined AC",
      "acCharges": "To be determined",
      "quotedRent": "PhP 850",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "FM Lopez Building",
      "businessDistrict": "Makati",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "To be determined AC",
      "acCharges": "To be determined",
      "quotedRent": "To be determined",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Herco Center",
      "businessDistrict": "Makati",
      "address": "114 Benavidez Street, Legazpi Village,\r\nMakati City",
      "developer": "Upturn Corporation",
      "yearConstructed": 1994,
      "certifications": "Non-PEZA",
      "totalFloors": 9,
      "availableSpace": "\r\nUnder Nego:\r\n3F – 450 sqm \r\n4F – 450 sqm\r\n7F – 450 sqm\r\n\r\n",
      "totalAvailableSpace": 1350,
      "density": "1:10",
      "floorEfficiency": 0.8,
      "handoverCondition": "Handover Condition \r\nSemi-fitted\r\n\r\n3F 4F 5F – Fitted (Previous tenant was lalamove)",
      "acSystem": "Spli type",
      "acCharges": "Metered",
      "quotedRent": "\r\nGF – PhP 1,500\r\nOther floors – PhP 800",
      "cusa": "PhP 130",
      "parkingRate": " PhP 4,500",
      "lastUpdated": "December 12, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of 3,600 sqm",
      "floorPlate": "450 sqm floor plate",
      "netUseableArea": "400 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "2 Elevators",
      "telcoProviders": "PLDT, Globe, Sky Cable, Converge =",
      "ceilingHeight": "2.2 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation Depends on availability",
      "twentyFourSevenOperations": "24/7 Operations: Subject for approval",
      "remarks": "Heavy cooking not allowed in GF space\r\nDetails from JLL Deck"
    },
    {
      "buildingName": "Glass Tower",
      "businessDistrict": "Makati",
      "address": "115 Don Carlos Palanca Jr. St., Legaspi Village",
      "developer": "LHI Real Estate Corporation",
      "yearConstructed": "1972\r\n(Renovated 2000)",
      "certifications": "Non-PEZA",
      "totalFloors": 8,
      "availableSpace": "3F Unit C – 120 sqm\r\n4F Unit CD – 253 sqm\r\n5F Units A&B – 255 sqm\r\n6F Unit B – 125 sqm\r\n6F Unit C – 136 sqm\r\n6F Unit D – 125 sqm\r\n8F Units A to D – 547 sqm",
      "totalAvailableSpace": 1691,
      "density": "1:6 to 1:7",
      "floorEfficiency": "To be determined",
      "handoverCondition": "Semi-Fitted",
      "acSystem": "Split Type",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,170",
      "cusa": " PhP 100",
      "parkingRate": "PhP 3,500",
      "lastUpdated": "October 23, 2023\r\n8F - Nov. 29, 2023 ",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 6,261.39 sqm",
      "floorPlate": "547 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "2 Passenger Elevators",
      "telcoProviders": "Telco Providers: Converge, PLDT, Eastern Telecom, Bayantel, Globe, Smart, Rise, Infinivan",
      "ceilingHeight": "2.4 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation: 4 Slots/floor",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "Greenbelt Mansion",
      "businessDistrict": "Makati",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "To be determined AC",
      "acCharges": "To be determined",
      "quotedRent": "To be determined",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "HRC Center",
      "businessDistrict": "Makati",
      "address": "104 Rada Street, Legazpi Village,\r\nMakati City ",
      "developer": "Brandywine Corporation",
      "yearConstructed": 1994,
      "certifications": "Non-PEZA",
      "totalFloors": 7,
      "availableSpace": "6F – 540 sqm\r\n7F Unit C – 220.5 sqm",
      "totalAvailableSpace": 760.5,
      "density": "1:8",
      "floorEfficiency": 0.85,
      "handoverCondition": "Semi-Fitted",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Metered ",
      "quotedRent": " GF – PhP 1,500 \r\n6F, 7F – PhP 800",
      "cusa": "PhP 135",
      "parkingRate": "PhP 4,500",
      "lastUpdated": "July 28, 2023, 6F - November 23, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of Approx. 3,500 sqm",
      "floorPlate": "540 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% back-up power",
      "elevators": "2 Passenger Elevators",
      "telcoProviders": "PLDT, Sky Cable, Converge",
      "ceilingHeight": "2.40 meters",
      "parkingAllocation": "4 slots per floor",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "4 Slots alloted \r\nandlord prefers to lease it short term they plan to renovate the buidling"
    },
    {
      "buildingName": "Insular Healthcare Building",
      "businessDistrict": "Makati",
      "address": "Legazpi Street corner Dela Rosa Street, Legazpi Village",
      "developer": "Insular Life",
      "yearConstructed": 1986,
      "certifications": "Non-PEZA",
      "totalFloors": 7,
      "availableSpace": "Retail:\r\nGF – 115.80 sqm\r\n\r\n2F – 307.48 sqm\r\n6F – 368 sqm\r\n6F – 59.04 sqm\r\n7F – 169.65 sqm",
      "totalAvailableSpace": 904.17,
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "Semi-Fitted",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Metered",
      "quotedRent": "GF – PhP 1,400\r\nOthers -PhP 800",
      "cusa": "PhP 104 ",
      "parkingRate": " \r\nOpen parking – PhP 4,500\r\nBasement – PhP 5,000",
      "lastUpdated": "December 12, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of 6,020 sqm",
      "floorPlate": "850 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "2 Passenger",
      "telcoProviders": "Telco Providers: Globe, PLDT, Smart ",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation Subject to Availability",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "GF space allows light cooking"
    },
    {
      "buildingName": "Jose Cojuanco & Sons",
      "businessDistrict": "Makati",
      "address": "Palanca Street corner Dela Rosa Street, Legazpi Village,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "To be determined AC",
      "acCharges": "To be determined",
      "quotedRent": "PhP 650",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Kalayaan Building",
      "businessDistrict": "Makati",
      "address": "Dela Rosa Street, Legazpi Village, \r\nMakati City",
      "developer": "LFM Properties Corporation",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 8,
      "availableSpace": "4F – 621 sqm\r\nCuts:\r\nUnit 1 – 302 sqm\r\nUnit 2 -319 sqm\r\n7F – 447.12 sqm\r\n8F – 320 sqm\r\n8F - 290 sqm ",
      "totalAvailableSpace": 1689.12,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "4F and 7F – Bare Shell\r\n8F – As is where is",
      "acSystem": "Tenant to provide AC",
      "acCharges": "Regular hours (7:00AM to 5:00PM) – Metered",
      "quotedRent": "Rent (PSM):\r\n4F & 7F – PhP 550\r\n8F – PhP 650",
      "cusa": "Included in Rent",
      "parkingRate": " PhP 4,000",
      "lastUpdated": "November 29, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation \r\nGF – Subject to Availability \r\n4F (302 sqm) – 2 slots  \r\n4F (319 sqm) –  3 slots\r\n7F – 4 slots\r\n8F – 3 slots",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "No recruitment offices"
    },
    {
      "buildingName": "Legaspi Tower 200",
      "businessDistrict": "Makati",
      "address": "Paseo de Roxas Street, Legazpi Village, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion 1970s",
      "certifications": "Non-PEZA",
      "totalFloors": 8,
      "availableSpace": "Fully Leased\r\n",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Tenant to Provide AC",
      "acCharges": "Metered",
      "quotedRent": "Lower GF – PhP 800\r\n1F – PhP 800",
      "cusa": "\r\nLower GF – Included in rent\r\n1F – PhP 80",
      "parkingRate": " \r\nLower GF – PhP 3,500\r\n1F – PhP 3,360 (VAT-inclusive) – but no more slot available as of 3/17",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of Approx. 5,600 sqm",
      "floorPlate": "Approx. 700 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: ",
      "remarks": "Lower GF – 1 slot parking allocation"
    },
    {
      "buildingName": "OPL Building",
      "businessDistrict": "Makati",
      "address": "C. Palanca corner Dela Rosa",
      "developer": "To be determined",
      "yearConstructed": 1979,
      "certifications": "Non-PEZA",
      "totalFloors": 9,
      "availableSpace": "4F Unit C2 - 94.52 sqm\r\n5F Unit G – 83.95 sqm\r\n8F Unit A to E – 629 sqm\r\n8F Unit H to L – 518.01 sqm\r\n\r\n8F Units:\r\n8F Unit 8-A - 21.58\r\n8F Unit 8-B - 127.12\r\n8F Unit 8-C - 138.61\r\n8F Unit 8-D - 146.29\r\n8F Unit 8-E - 195.40\r\n8F Unit 8-H - 120.46\r\n8F Unit 8-I - 132.65\r\n8F Unit 8-J - 125.03\r\n8F Unit 8-K - 117.74\r\n8F Unit 8-L - 22.13",
      "totalAvailableSpace": 1325.4800000000002,
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "Bare Shell - Kindly Confirm",
      "acSystem": "Tenant to Provide AC",
      "acCharges": "Regular hours (8:00AM to 5:00PM) – Metered",
      "quotedRent": " PhP 750",
      "cusa": "Regular Hours: PhP 100\r\n24/7: PhP 200",
      "parkingRate": "PhP 4,000",
      "lastUpdated": "December 7, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 10,206.84 sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "50% Backup Power",
      "elevators": "3 Elevators",
      "telcoProviders": "Telco Providers: Eastern Telecom, Converge, Globe, PLDT, PT&T, Rise",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation Subject to Availability",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "Allows 24/7 operations\r\nMinimum 1 – 3 yrs, renewable"
    },
    {
      "buildingName": "Philcox Building",
      "businessDistrict": "Makati",
      "address": "Salcedo Street, Legazpi Village,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 8,
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "Approx 85% Efficiency",
      "handoverCondition": "Handover Condition Semi-Fitted",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 700",
      "cusa": "N/A",
      "parkingRate": " PhP 5,000",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of 4,000 sqm",
      "floorPlate": "Approx 500 sqm floor plate",
      "netUseableArea": "425 sqm net usable",
      "backupPower": "Partial Backup Power",
      "elevators": "2 Elevators",
      "telcoProviders": "Telco Providers: PLDT, Globe",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "Kentek Building",
      "businessDistrict": "Makati",
      "address": "828 A. Arnaiz Avenue,\r\nMakati City",
      "developer": "E.V. Edgemond",
      "yearConstructed": "Completion 2Q 2016",
      "certifications": "Non-PEZA",
      "totalFloors": 5,
      "availableSpace": "GF – 531.71 sqm\r\n5F – 505.52 sqm\r\n\r\nWhole floor lease only",
      "totalAvailableSpace": 1037.23,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Variable Refrigerant Flow System",
      "acCharges": " Regular hours (6:00 AM to 11:00 PM) – Metered",
      "quotedRent": "GF – PhP 2,000\r\n5F – PhP 1,000",
      "cusa": "PhP 150 ",
      "parkingRate": " PhP 6,000",
      "lastUpdated": "November 9, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "Approx. GLA of 2,900 sqm",
      "floorPlate": "Approx. 500 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "2 Elevators",
      "telcoProviders": "Telco Providers: Globe, provision for other providers",
      "ceilingHeight": "2.6 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation \r\nGF – 2 slots\r\n5F – 2 slots",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "No BPOs allowed\r\n\r\nFor AC Units: Mitsubishi VRF Aircons are installed for direct cost reimbursement of 1.8M upon contract signing."
    },
    {
      "buildingName": "King's Court",
      "businessDistrict": "Makati",
      "address": "Chino Roces Avenue corner Dela Rosa Street, Legaspi Village",
      "developer": "King's Development Incorporated",
      "yearConstructed": 1981,
      "certifications": "PEZA Certified",
      "totalFloors": 11,
      "availableSpace": "Building 1\r\nGF - 117.29 sqm\r\n4F - 187 sqm\r\n4F - 306 sqm\r\n7F - 265 sqm\r\n10F - 97 sqm\r\n10F - 285 sqm\r\n\r\nBuilding 2\r\n4F – 310.58 sqm\r\n5F - 123 sqm\r\n5F - 110 sqm\r\n6F – 220.67 sqm \r\n7F – 880 sqm \r\n\r\n\r\n7F can be subdivided – 150 sqm minimum",
      "totalAvailableSpace": 2111.25,
      "density": "To be determined",
      "floorEfficiency": 0.88,
      "handoverCondition": "Semi-Fitted",
      "acSystem": "GF – Tenant to provide\r\nOffice – Centralized Chilled Water",
      "acCharges": "Regular hours (6:00 AM to 6:00 PM) – Metered",
      "quotedRent": "PhP 850",
      "cusa": "PhP 150 + PhP 10 Pest Control",
      "parkingRate": "PhP 5,000",
      "lastUpdated": "November 6, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of 28,104 sqm",
      "floorPlate": "1,514 sqm floor plate",
      "netUseableArea": "1,332.32 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "4 Passenger Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT, Eastern Telecom",
      "ceilingHeight": "2.8 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "May operate 24/7\r\nNo online gaming tenants allowed\r\nGF – No heavy cooking allowed"
    },
    {
      "buildingName": "Leelin II Building",
      "businessDistrict": "Makati",
      "address": "820 Arnaiz Avenue, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 3,
      "availableSpace": "3F – 430 sqm\r\n\r\nCan't be subdivided",
      "totalAvailableSpace": 430,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition \r\n3F (260 sqm) – Bare Shell\r\n3F (154.5 sqm) – Warm Shell",
      "acSystem": "Tenant to Provide AC System",
      "acCharges": "Metered ",
      "quotedRent": "PhP 600",
      "cusa": "PhP 55",
      "parkingRate": " Included in rent (2 free slots)",
      "lastUpdated": "October 3, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "N/A",
      "telcoProviders": "Telco Providers: PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation \r\n3F – 3 slots ",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "812 Arnaiz Building",
      "businessDistrict": "Makati",
      "address": "812 Arnaiz Avenue, Makati City",
      "developer": "A. Aguirre, Inc. ",
      "yearConstructed": 1990,
      "certifications": "Non-PEZA",
      "totalFloors": 4,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "Semi-Fitted",
      "acSystem": "Split Type AC",
      "acCharges": "Metered ",
      "quotedRent": "GF & 2F – PhP 1,300",
      "cusa": "PhP 90",
      "parkingRate": "PhP 7,000",
      "lastUpdated": "May 22, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "N/A",
      "telcoProviders": "Telco Providers: PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "AO1 United Life Building",
      "businessDistrict": "Makati",
      "address": "837 Arnaiz Avenue",
      "developer": "ATLAS Office",
      "yearConstructed": "4Q 2015",
      "certifications": "Non-PEZA",
      "totalFloors": 8,
      "availableSpace": "2F – 189.79 sqm",
      "totalAvailableSpace": 1002.87,
      "density": "1:6",
      "floorEfficiency": 0.8533,
      "handoverCondition": "2F – Warm Shell\r\n7F & 8F – Semi-Fitted",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": "Metered ",
      "quotedRent": "2F – PhP 880\r\n7F – PhP 980\r\n8F –  PhP 1,180",
      "cusa": "PhP 265",
      "parkingRate": "PhP 5,500",
      "lastUpdated": "October 23, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 8,061.61 sqm",
      "floorPlate": "1,062.50 sqm floor plate",
      "netUseableArea": "903.63 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "4 Passenger Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "3.6 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation\r\n2F – 4 slots\r\n3F, 5F, 6F – 7 slots/floor\r\n8F – 3 slots",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "ATLAS Offices\r\nTere Falcunaya\r\n<mfalcunaya@atlasoffice.com>, \r\nMariecar Umipig <mumipig@atlasoffice.com>\r\nRey Cruz\r\n<rcruz@atlasoffice.com>\r\n\r\nRey Cruz \r\n0917-855-5964"
    },
    {
      "buildingName": "Loyola Plans Building",
      "businessDistrict": "Makati",
      "address": "Arnaiz Avenue, Legaspi Village\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 8,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Tenant to provide AC",
      "acCharges": "Metered",
      "quotedRent": "GF – PhP 1,200\r\n2F – PhP 700\r\n3F – PhP 500",
      "cusa": "PhP 70",
      "parkingRate": " PhP 4,000",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "2 Elevators",
      "telcoProviders": "Telco Providers: PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "Allocation of 2 parking slots per tenant in 2F and 3F\r\n\r\nDoes not allow 24/7 operations"
    },
    {
      "buildingName": "LPL Tower",
      "businessDistrict": "Makati",
      "address": "112 Legazpi Street, Legazpi Village,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion 1994",
      "certifications": "Non-PEZA",
      "totalFloors": 11,
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Tenant to Provide Split Type AC",
      "acCharges": "Metered",
      "quotedRent": "Rent (PSM):\r\nGF – PhP 25,000\r\n5F – PhP 13,000\r\n6F – PhP 55,000 ",
      "cusa": "PhP 75",
      "parkingRate": " PhP 3,500",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "50% Backup Power",
      "elevators": "1 Passenger Elevator\r\n1 Service Elevator",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "LTA Building",
      "businessDistrict": "Makati",
      "address": "118 Perea Street, Legaspi Village,\r\nMakati City",
      "developer": "LTA Condominium Corp.",
      "yearConstructed": "Completion 1980",
      "certifications": "Non-PEZA",
      "totalFloors": 9,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Warm Shell",
      "acSystem": "Tenant to provide  AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 800",
      "cusa": "PhP 120 to 125",
      "parkingRate": " PhP 3,500",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of 5,480 sqm",
      "floorPlate": "548 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "Partial Backup Power",
      "elevators": "9 Elevators",
      "telcoProviders": "Telco Providers: PLDT, Globe, Eastern Telecom, PTNT",
      "ceilingHeight": "2.5 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: ",
      "remarks": "Elevator only reaches the 8F, you have to use the stairway to get to the 9F or PH\r\n\r\nParking in the basement can only accommodate small vehicles "
    },
    {
      "buildingName": "Frabelle Business Center",
      "businessDistrict": "Makati",
      "address": "111 Rada Street, Legazpi Street",
      "developer": "Frabelle Fishing Corporation",
      "yearConstructed": 2014,
      "certifications": "Non-PEZA",
      "totalFloors": 20,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "1:6",
      "floorEfficiency": 0.81,
      "handoverCondition": "Semi-Fitted",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,100",
      "cusa": "PhP 190",
      "parkingRate": " PhP 6,000",
      "lastUpdated": 45138,
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 6,969.78 sqm",
      "floorPlate": "862.59 sqm floor plate",
      "netUseableArea": "699.93 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "3 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Converge ICT, PLDT",
      "ceilingHeight": "4.00 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation – 1:70",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Luz Building",
      "businessDistrict": "Makati",
      "address": "Gamboa Street, Legazpi Village, \r\nMakati City",
      "developer": "AC Enterprises",
      "yearConstructed": "Completion 4Q 2018",
      "certifications": "Non-PEZA",
      "totalFloors": 16,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of 1:8",
      "floorEfficiency": "87% Efficiency",
      "handoverCondition": "Handover Condition Warm Shell",
      "acSystem": "Variable Refrigerant Flow System",
      "acCharges": " Regular Hours (8:00 AM to 6:00 PM) – Metered",
      "quotedRent": "PhP 950",
      "cusa": "Pro-rated",
      "parkingRate": " PhP 5,000",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 5,699.52 sqm ",
      "floorPlate": "686.46 sqm floor plate",
      "netUseableArea": "587.36 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "3 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: PLDT",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:100",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "Allows BPO tenants (but building is non-PEZA)\r\n\r\nAC Extension Charge – Metered\r\n"
    },
    {
      "buildingName": "Maripola Building",
      "businessDistrict": "Makati",
      "address": "109 Perea Street, Legazpi Village,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion 1978",
      "certifications": "Non-PEZA",
      "totalFloors": 8,
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Tenant to provide AC",
      "acCharges": "Metered",
      "quotedRent": "\r\nGF – PhP 1,200\r\n8F – PhP 600\r\n",
      "cusa": "N/A",
      "parkingRate": " \r\nOpen Parking – PhP 2,500\r\nBasement Parking – PhP 3,500 ",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "Partial Backup Power",
      "elevators": "2 Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT, Eastern Telecom",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:100",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Pioneer House",
      "businessDistrict": "Makati",
      "address": "Paseo de Roxas corner Legaspi Street,\r\nMakati City",
      "developer": "Pioneer Insurance",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 7,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Variable Refrigerant Flow System",
      "acCharges": "Metered",
      "quotedRent": "PhP 650",
      "cusa": "PhP 80",
      "parkingRate": " PhP 5,000",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "3 Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT, Eastern Telecom",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: ",
      "remarks": "Tenants are the owner of the building"
    },
    {
      "buildingName": "Planters Products Building",
      "businessDistrict": "Makati",
      "address": "Esteban Street, Legazpi Village,\r\nMakati City",
      "developer": "LW Construction",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 5,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Centralized Chilled Water AC System",
      "acCharges": "Metered",
      "quotedRent": "PhP 700 (VAT inclusive)",
      "cusa": "To be finalized",
      "parkingRate": " PhP 3,000",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "50% Backup Power",
      "elevators": "1 Passenger Elevator",
      "telcoProviders": "Telco Providers: Bayantel, Globe, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 8 slots",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Plaza One Hundred",
      "businessDistrict": "Makati",
      "address": "V.A. Rufino Street, Legazpi Village,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "GF Unit 106 – 90 sqm",
      "totalAvailableSpace": 90,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "To be determined",
      "acCharges": "To be determined",
      "quotedRent": "To be determined",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Port Royal Place",
      "businessDistrict": "Makati",
      "address": "118 Rada Street, Legazpi Village,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Semi-Fitted",
      "acSystem": "Centralized Chilled Water AC System",
      "acCharges": "Metered",
      "quotedRent": "\r\nGF – PhP 1,000\r\n4F, 5F – PhP 700",
      "cusa": "Pro-rated",
      "parkingRate": " PhP 5,000",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 2 slots",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "Prince Building",
      "businessDistrict": "Makati",
      "address": "117 Rada Street, Legazpi Village",
      "developer": "To be determined",
      "yearConstructed": 1977,
      "certifications": "Non-PEZA",
      "totalFloors": 6,
      "availableSpace": "2F – 520.76 sqm\r\n3F - 249.32 sqm\r\n4F – 520.76 sqm (Prev occupied by Ex-VP)\r\nPH Unit A – 193.51 sqm\r\nPH Unit B – 250.40 sqm\r\n\r\nCan only do half floor subdivision",
      "totalAvailableSpace": 1734.75,
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "Semi-Fitted",
      "acSystem": "2F, 3F – Centralized Chilled Water AC\r\nPH – Tenant to provide",
      "acCharges": "Metered",
      "quotedRent": "2F - PhP 800\r\n3F – PhP 900\r\n4F – PhP 800\r\nPH Unit A - PhP 800\r\nPH Unit B – PhP 900 ",
      "cusa": "Pro-rated",
      "parkingRate": "PhP 6,000",
      "lastUpdated": "July 25, 2023, PH Unit B - October 10, 2023\r\n2F, 4F - Nov. 29, 2023 ",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of 3,124.56 sqm",
      "floorPlate": "520.76 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "1 Elevator",
      "telcoProviders": "Telco Providers: PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation\r\n2F, 3F – Subject to availability\r\nPH – 2 slots",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "2 parking slots allocation\r\nDoes not allow 24/7 operations"
    },
    {
      "buildingName": "RCI Building",
      "businessDistrict": "Makati",
      "address": "Rada Street, Legazpi Village,\r\nMakati City",
      "developer": "To be determined+",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "4F - 88 sqm\r\n6F - 110 sqm\r\n7F - approx. 535 sqm, can be subdivided into 418 sqm and 117 sqm\r\n8F - 232 sqm",
      "totalAvailableSpace": 965,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "As is where is",
      "acSystem": "To be determined AC System",
      "acCharges": "Metered",
      "quotedRent": "PhP 750",
      "cusa": "PhP 50",
      "parkingRate": "PhP 4000 PSM",
      "lastUpdated": "August 16, 2023",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Sage House",
      "businessDistrict": "Makati",
      "address": "V.A. Rufino Street, Legazpi Village,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "\r\nFully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "To be determined AC System",
      "acCharges": "To be determined",
      "quotedRent": "PhP 800",
      "cusa": "PhP 100",
      "parkingRate": " To be determined",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "24 hour operations not allowed\r\nOnline gaming tenants not allowed"
    },
    {
      "buildingName": "Salamin Building",
      "businessDistrict": "Makati",
      "address": "197 Salcedo Street, Legaspi Village",
      "developer": "Gervel Incorporated",
      "yearConstructed": "1980's",
      "certifications": "Non-PEZA",
      "totalFloors": 8,
      "availableSpace": " 4F, 5F – 580 sqm/floor\r\n8F – 550 sqm (under negotiation)",
      "totalAvailableSpace": 1710,
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "Semi-Fitted",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "Metered",
      "quotedRent": "4F - PhP 650\r\n5F - PhP 600 ",
      "cusa": "PhP 75",
      "parkingRate": "PhP 4,500",
      "lastUpdated": "4F , 5F - November 29, 2023\r\nOthers - September",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of 5,800 sqm",
      "floorPlate": "580 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "2 Passenger Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "2.4 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 5F – 2 slots",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Salustiana D. Ty Tower",
      "businessDistrict": "Makati",
      "address": "Paseo de Roxas, Legazpi Village",
      "developer": "Wellington Investments Corporation",
      "yearConstructed": 1990,
      "certifications": "Non-PEZA",
      "totalFloors": 10,
      "availableSpace": "Basement – 316.15 sqm\r\n4F – 345.86 sqm",
      "totalAvailableSpace": 661.71,
      "density": "1:5",
      "floorEfficiency": 0.85,
      "handoverCondition": "Semi-Fitted",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "PhP 125 PSM\r\nAC Extension – PhP 1,650/ hour",
      "quotedRent": "Basement – PhP 668\r\n4F – PhP 700",
      "cusa": "PhP 70",
      "parkingRate": "PhP 3,500 to PhP 4,500",
      "lastUpdated": "July 31, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 14,798 sqm",
      "floorPlate": "1,956 sqm floor plate",
      "netUseableArea": "1,662.60 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "4 Passenger Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT, Eastern Telecom",
      "ceilingHeight": "2.43 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of Subject to Availability",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "SEDCCO",
      "businessDistrict": "Makati",
      "address": "Rada Street, Legazpi Village,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 7,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "Centralized AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 600",
      "cusa": "N/A",
      "parkingRate": " PhP 3,200",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "Partial Backup Power",
      "elevators": "2 Passenger Elevators\r\n1 Service Elevator",
      "telcoProviders": "Telco Providers: PLDT, Globe",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Spirit of Communications Center",
      "businessDistrict": "Makati",
      "address": "C. Palanca Street, Legazpi Village,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion 1970",
      "certifications": "Non-PEZA",
      "totalFloors": 6,
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "AC System See Remarks",
      "acCharges": "Metered",
      "quotedRent": "PhP 500",
      "cusa": "N/A",
      "parkingRate": " N/A",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of 3,598 sqm",
      "floorPlate": "514 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation N/A",
      "twentyFourSevenOperations": "24/7 Operations: ",
      "remarks": "Building does not allow serviced office\r\n\r\nAC System: \r\n5F – Centralized Chilled Water AC\r\n\r\nParking Allocation : First Come, First Serve (no charges)"
    },
    {
      "buildingName": "Sterling Centre",
      "businessDistrict": "Makati",
      "address": "Dela Rosa Street, Legaspi Village\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion 1978",
      "certifications": "Non-PEZA",
      "totalFloors": 11,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "Tenant to Provide AC",
      "acCharges": "To be determined",
      "quotedRent": "To be determined",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "lastUpdated": "November 9, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 6,000 sqm ",
      "floorPlate": "546.26 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Solaris One",
      "businessDistrict": "Makati",
      "address": "130 Dela Rosa Street, Legaspi Village\r\nMakati City",
      "developer": "Ayala Land, Inc.",
      "yearConstructed": 2009,
      "certifications": "PEZA Certified",
      "totalFloors": 25,
      "availableSpace": "10F – 1,027.09 sqm\r\n10F - 1,700 sqm",
      "totalAvailableSpace": "2,727.09 sqm",
      "density": "1:6.5",
      "floorEfficiency": 0.8696,
      "handoverCondition": "10F - (1,027.09 sqm) - Warm Shell\r\n10F (1,700 sqm) - Semi-Fitted ",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "PhP 180.06 PSM",
      "quotedRent": "PhP 1,200 ",
      "cusa": "PhP 393.88",
      "parkingRate": "PhP 8,826.84",
      "lastUpdated": "August 18, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 46,868 sqm",
      "floorPlate": "3,300 sqm floor plate",
      "netUseableArea": "2,869.68 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "16 Passenger Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "2.6 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "Lease term is only until April 2020"
    },
    {
      "buildingName": "SSHG Law Center",
      "businessDistrict": "Makati",
      "address": "Paseo de Roxas, Legaspi Village\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion 1970's",
      "certifications": "Non-PEZA",
      "totalFloors": 7,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Tenant to Provide AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 845.52",
      "cusa": "Building Dues: PhP 38,340.60 per month",
      "parkingRate": " N/A",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: ",
      "remarks": "No parking slots available."
    },
    {
      "buildingName": "Tuscan Building",
      "businessDistrict": "Makati",
      "address": "V.A. Rufino Street, Legazpi Village,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion 1975",
      "certifications": "Non-PEZA",
      "totalFloors": 7,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Semi-Fitted",
      "acSystem": "To be determined AC System",
      "acCharges": "To be determined",
      "quotedRent": "PhP 900",
      "cusa": "PhP 116.73",
      "parkingRate": " To be determined",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Universal LMS",
      "businessDistrict": "Makati",
      "address": "Esteban Street, Legaspi Village\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion 1974",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Semi-Fitted",
      "acSystem": "Tenant to Provide AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 600",
      "cusa": "\r\nRegular hours – PhP 85\r\n24/7 – PhP 100",
      "parkingRate": " PhP 3,000",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "A&V Crystal Tower",
      "businessDistrict": "Makati",
      "address": "Esteban Street, Legaspi Village\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion 1982",
      "certifications": "Non-PEZA",
      "totalFloors": 6,
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Split Type AC System",
      "acCharges": "Metered",
      "quotedRent": "PhP 650",
      "cusa": "PhP 50",
      "parkingRate": " PhP 5,000",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "1 Passenger Elevators\r\n",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 2 free slots",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "24 hour operations not allowed"
    },
    {
      "buildingName": "Universal RE Building",
      "businessDistrict": "Makati",
      "address": "Paseo de Roxas, Legazpi Village,\r\nMakati City",
      "developer": "Ayala Realty Development Corporation",
      "yearConstructed": "Completion 1970s",
      "certifications": "Non-PEZA",
      "totalFloors": 7,
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "89.3% Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Variable Refrigerant Flow AC System",
      "acCharges": "Metered",
      "quotedRent": "PhP 750",
      "cusa": "PhP 99",
      "parkingRate": " \r\nGround Level – PhP 4,000\r\nBasement – PhP 5,000",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of Approx. 7,000 sqm",
      "floorPlate": "Approx. 1,000 sqm floor plate",
      "netUseableArea": "893 sqm net usable",
      "backupPower": "Only for common areas – Backup Power",
      "elevators": "2 Passenger Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "2.8 meters (slab to slab) finished ceiling height",
      "parkingAllocation": "Parking Allocation 2 slots/unit",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "3F – 2 parking slots"
    },
    {
      "buildingName": "Philsteel Tower",
      "businessDistrict": "Makati",
      "address": "140 Amorsolo Street, Legazpi Village, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 8,
      "availableSpace": "GF – 175 sqm\r\n5F – 197 sqm\r\n(Does not work with brokers anymore)",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Tenant to Provide AC",
      "acCharges": "Metered",
      "quotedRent": "\r\nGF – 1,300 \r\n5F – PhP 700 ",
      "cusa": "Included in rent",
      "parkingRate": " PhP 4,000",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "2 Passenger Elevator",
      "telcoProviders": "Telco Providers: Eastern Telecom, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation Subject to Availability",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "Erlag Building",
      "businessDistrict": "Makati",
      "address": "102 Esteban, Legazpi Village",
      "developer": "To be determined",
      "yearConstructed": "To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 5,
      "availableSpace": "2F Unit 200 – 23.08 sqm\r\n2F Unit 201 – 9.16 sqmq\r\n2F Unit 200-A – 24.06 sqm\r\n2F Unit 202 – 16.41 sqm\r\n2F Unit 204 – 120.99 sqm\r\n2F Unit 205 – 18 sqm\r\n4F Unit 402-B – 136.85 sqm\r\n\r\n5F – 444.05 sqm",
      "totalAvailableSpace": 432.44999999999993,
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "Semi-Fitted",
      "acSystem": "Tenant to Provide AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 700",
      "cusa": "PhP 90",
      "parkingRate": "PhP 4,500",
      "lastUpdated": "July 31, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of 2,220 sqm",
      "floorPlate": "444 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "Backup Power To be determined",
      "elevators": "Elevators To be determined",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: For approval"
    },
    {
      "buildingName": "Altaire",
      "businessDistrict": "Makati",
      "address": "Malugay Street",
      "developer": "Innoland Development Corporation",
      "yearConstructed": "1Q 2024",
      "certifications": "Under Application for PEZA",
      "totalFloors": 45,
      "availableSpace": "10F to 44F – Approx. 1,500 sqm to 2,000 sqm/floor\r\n\r\n\r\nOnly the 27F, 28F, 34F, 35F can be subdivided into quadrants",
      "totalAvailableSpace": 62503.96,
      "density": "1:5",
      "floorEfficiency": "To be finalized",
      "handoverCondition": "Bare Shell",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,300",
      "cusa": "PhP 210",
      "parkingRate": "PhP 6,500",
      "lastUpdated": "November 15, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 65,983 sqm",
      "floorPlate": "Approx. 1,800 to 2,200 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "223 Salcedo",
      "businessDistrict": "Makati",
      "address": "Salcedo corner Gamboa Street, Legaspi Village, Makati City",
      "developer": "Urban Environment Reality, Inc. ",
      "yearConstructed": "Completion 2Q 2019",
      "certifications": "Non-PEZA",
      "totalFloors": 5,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of 1:10",
      "floorEfficiency": "92% Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Tenant to Provide AC",
      "acCharges": " Regular Hours (8:00 AM to 6:00 PM) – Metered",
      "quotedRent": "3F, 4F – PhP 925\r\n5F – PhP 900,000 /month",
      "cusa": "PhP 50",
      "parkingRate": "PhP 5,000",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 2,923 sqm",
      "floorPlate": "609 sqm floor plate",
      "netUseableArea": "569 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "2 Passenger Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "5.0 meters (slab to slab) ceiling height",
      "parkingAllocation": "Parking Allocation 6 slots/floor",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "6 Parking slots per floor"
    },
    {
      "buildingName": "Calistoga Office Building",
      "businessDistrict": "Makati",
      "address": "855 A. Arnaiz Ave. Legazpi Village, Makati City",
      "developer": "Paralorenzo Holdings, Inc.",
      "yearConstructed": "Completion 1Q 2024",
      "certifications": "Non-PEZA",
      "totalFloors": 10,
      "availableSpace": "GF – 352.02 sqm\r\nCuts:\r\nGF Unit 1 – 87.56 sqm\r\nGF Unit 2 – 88.45 sqm\r\nGF Unit 3 – 87.56 sqm\r\nGF Unit 4 – 88.45 sqm\r\n\r\n2F – 376.9 sqm\r\n3F – 422.75 sqm\r\n5F – 555.65 sqm\r\n6F – 571.54 sqm\r\n7F – 572.62 sqm\r\n8F – 567.52 sqm",
      "totalAvailableSpace": 3419,
      "density": "Density of To be determined",
      "floorEfficiency": "83% Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Variable Refrigerant Flow AC System",
      "acCharges": "Metered",
      "quotedRent": "To be determined",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "buildingGrade": "Grade B-",
      "grossLeasableArea": "GLA of 4,839.24 sqm",
      "floorPlate": "Approx. 500 sqm floot plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "2 Passenger Elevators\r\n1 Service Elevator",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "107 Aguirre",
      "businessDistrict": "Makati",
      "address": "107 Aguirre, Legazpi Village",
      "developer": "ATLAS Land",
      "yearConstructed": "1Q 2021",
      "certifications": "Non-PEZA; Pre-Certified LEED Silver",
      "totalFloors": 14,
      "availableSpace": "FULLY LEASED",
      "totalAvailableSpace": 0,
      "density": "1:6",
      "floorEfficiency": 0.85,
      "handoverCondition": "Bare Shell with rough flooring",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": "Metered+DR17",
      "quotedRent": "PhP 980",
      "cusa": "PhP 265",
      "parkingRate": "PhP 5,500",
      "lastUpdated": "Jul 27, 2023",
      "buildingGrade": "Grade A",
      "grossLeasableArea": "GLA of 6,965.45 sqm",
      "floorPlate": "Approx. 900 sqm floor plate",
      "netUseableArea": "Approx. 765 sqm net usable",
      "backupPower": "N+1 Backup Power",
      "elevators": "4 Passenger Elevators\r\n1 Service Elevator",
      "telcoProviders": "Telco Providers: Provision for 3 telco providers",
      "ceilingHeight": "7F to 12F – 4.2 meters (floor to ceiling)\r\nPH – 8 meters (floor to ceiling)",
      "parkingAllocation": "Parking Allocation 60 slots\r\n- Allocation per floor will be for discussion",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "GF units cannot be combined since both are located at opposite sides of the building"
    },
    {
      "buildingName": "First Midland Building",
      "businessDistrict": "Makati",
      "address": "109 Gamboa Street, Legazpi Village",
      "developer": "To be determined",
      "yearConstructed": 2009,
      "certifications": "Non-PEZA",
      "totalFloors": 2,
      "availableSpace": "2F Unit 6 – 133 sqm",
      "totalAvailableSpace": 133,
      "density": "1:5",
      "floorEfficiency": "To be determined",
      "handoverCondition": "2F – As is where is",
      "acSystem": "Tenant to Provide AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 450",
      "cusa": " Included in rent",
      "parkingRate": "1 Slot – Included in rent",
      "lastUpdated": "October 19, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "GF – 3.08 meters\r\nMezzanine – 3.05 meters\r\n2F Unit 5 – 2.43 meters\r\n2F Unit 6 -2.6 meters",
      "parkingAllocation": "Parking Allocation 1 slot per floor",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "MJL Building",
      "businessDistrict": "Makati",
      "address": "1175 Don Chino Roces Avenue, Makati",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "\r\nGF Retail Unit  – 135.86 sqm",
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "Bare Shell",
      "acSystem": "Tenant to Provide AC",
      "acCharges": "Metered",
      "quotedRent": "2F – PhP 500\r\n4F – PhP 450\r\nGF - PhP 900",
      "cusa": "PhP 50",
      "parkingRate": " PhP 5,000",
      "lastUpdated": "November 20, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Dela Rosa Square",
      "businessDistrict": "Makati",
      "address": "Chino Roces Avenue corner Dela Rosa Street, Makati City",
      "developer": "To be determined",
      "yearConstructed": 2004,
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "2F -99 sqm\r\n2F - 143 sqm\r\n2F – 63 sqm\r\n2F – 57 sqm\r\n\r\n\r\n\r\n143 sqm & 63 sqm can be combined",
      "totalAvailableSpace": 362,
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Tenant to Provide AC",
      "acCharges": "Metered",
      "quotedRent": " PhP 700",
      "cusa": "PhP 99",
      "parkingRate": "1 slot per unit",
      "lastUpdated": "October 25, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: Subject for approval",
      "remarks": "no provision for water"
    },
    {
      "buildingName": "Allegro Center",
      "businessDistrict": "Makati",
      "address": "Chino Roces Avenue (Extension),\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion 2006",
      "certifications": "PEZA Certified",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Tenant to Provide AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 15,000",
      "cusa": "Inclusive in Rent ",
      "parkingRate": " PhP 2,500",
      "lastUpdated": "X",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "1,591 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "2 Elevators",
      "telcoProviders": "Telco Providers: PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: ",
      "remarks": "Back Unit – GF 23 sqm \r\n1 Slot "
    },
    {
      "buildingName": "Wilcon IT Hub",
      "businessDistrict": "Makati",
      "address": "2251 Chino Roces Ave, Cor Epifanio de los Santos Ave",
      "developer": "Anstay Realty and Development Corporation",
      "yearConstructed": 2015,
      "certifications": "PEZA",
      "totalFloors": 15,
      "availableSpace": "2F Unit 1 – 90.74 sqm\r\n2F Unit 2 – 89.71 sqm\r\n2F Unit 3 – 85.34 sqm\r\n2F Unit 5 – 110.08 sqm\r\n2F Unit 6 – 52.49 sqm\r\n\r\n4F Units 1 to 8 – 3,791.97 sqm - being used by Wilcon branch, NOT LEASED\r\n\r\n14F Unit 1 – 381.91 sqm \r\n14F Unit 4 – 381.91  sqm\r\n15F Unit 2 – 362.90 sqm\r\n16F Unit 5 - 500.38 sqm - not yet reported in dream team\r\n16F Unit 6  – 396.14 sqm\r\n16F Unit 7 – 396.29 sqm\r\n16F Unit 8 – 499.26 sqm\r\n(Adjacent units can be combined)\r\n\r\nAvailable soon:\r\n14F Unit 2 – 362.90 sqm\r\n14F Unit 3 – 362.90 sqm",
      "totalAvailableSpace": 4162.13,
      "density": "1:6",
      "floorEfficiency": 0.86,
      "handoverCondition": "Warm Shell\r\n15 Unit 2 – Semi-Fitted",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": "Metered",
      "quotedRent": "GF - PhP 2,000\r\n2F - PhP 1,500\r\n4F, 5F Unit 2  - PhP 1,200\r\nOffice Floors - PhP 1,000",
      "cusa": "PhP 150",
      "parkingRate": "PhP 4,700",
      "lastUpdated": "October 27, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 26,242.25",
      "floorPlate": "3,372.44 sqm floor plate",
      "netUseableArea": "2,911.09 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "8 Passenger Elevators",
      "telcoProviders": "Telco Providers: Eastern Telecom, Globe, ICT Converge, PLDT",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation\r\n15F – 5 slots\r\n15F Unit 1502 & Unit 1508 – Subject to Availability",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "16F and PH floors were previously occupied by Van Gogh BPO"
    },
    {
      "buildingName": "2266 Chino Roces Avenue",
      "businessDistrict": "Makati",
      "address": "2266 Chino Roces Avenue (Extension), Makati City ",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA Certified",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Variable Refrigerant Flow AC System",
      "acCharges": "Metered",
      "quotedRent": "Warehouse – PhP 500\r\nOffice buildings – PhP 900\r\nOpen Space – PhP 300",
      "cusa": "Included in rent",
      "parkingRate": " PhP 4,000",
      "lastUpdated": "Fully Leased",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 11,979.49 sqm",
      "floorPlate": "Approx. 2,000 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "To be determined Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation Approx. 120 slots",
      "twentyFourSevenOperations": "24/7 Operations: ",
      "remarks": "Available for warehouse"
    },
    {
      "buildingName": "Southgate Tower",
      "businessDistrict": "Makati",
      "address": "EDSA corner Chino Roces Avenue (Extension)",
      "developer": "Alphaland Corporation",
      "yearConstructed": 2011,
      "certifications": "PEZA Certified",
      "totalFloors": 20,
      "availableSpace": "4F Units A & E – 1,047.70 sqm\r\n4F Unit F – 1,052 sqm\r\n6F (Podium) – 861.50 sqm\r\n7F Unit D – 428.29 sqm\r\n8F – 1,798.89 sqm",
      "totalAvailableSpace": 5188.38,
      "density": "1:6",
      "floorEfficiency": 0.76,
      "handoverCondition": "Warm Shell",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,000",
      "cusa": "Warehouse – PhP 175",
      "parkingRate": "PhP 5,000",
      "lastUpdated": "November 14, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 46,200 sqm ",
      "floorPlate": "1,912 sqm floor plate",
      "netUseableArea": "1,453.12 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "6 Passenger Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "3.5 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:200 ",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "DPC Place Building",
      "businessDistrict": "Makati",
      "address": "Chino Roces Avenue (Extension)",
      "developer": "Cyan Management Corp.",
      "yearConstructed": 1999,
      "certifications": "PEZA Certified",
      "totalFloors": 18,
      "availableSpace": "2F Unit 2 – 295.56 sqm\r\n6F – 380.20 sqm\r\n9F Unit 3 –  273.75 sqm\r\n10F Unit 3 –  273.80 sqm\r\n11F Unit 1 –  279.60 sqm\r\n12F Unit 2 – 258 sqm",
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "As is where is",
      "acSystem": "\r\nSemi-Centralized (Each unit has its own AHU)\r\nTenant to provide split type AC for 24/7 Operations",
      "acCharges": "Metered",
      "quotedRent": "PhP 850",
      "cusa": "None",
      "parkingRate": " PhP 4,000",
      "lastUpdated": "December 20, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 18,000 sqm",
      "floorPlate": "1,000 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "50% Backup Power",
      "elevators": "4 Passenger Elevators",
      "telcoProviders": "Telco Providers: PLDT, Globe",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Ecoplaza",
      "businessDistrict": "Makati",
      "address": "Chino Roces Avenue (Extension)",
      "developer": "SuperPrime Holdings",
      "yearConstructed": 2009,
      "certifications": "PEZA Certified",
      "totalFloors": 12,
      "availableSpace": "Mezzanine Unit 1 - 1,606.27 sqm\r\nMezzanine Unit 2 - 204 sqm\r\nMezzanine Unit 3 - 87.90 sqm\r\n3F - 812.32 sqm\r\n6F Unit 4 – 272.35 sqm\r\n6F Unit 6 – 276.12 sqm\r\n6F Unit 7 – 408 sqm\r\n8F Unit 1 – 681.53 sqm\r\n10F – 2,579.29 sqm\r\nPH - 2,611.58 sqm\r\n\r\nCannot do 100-120 sqm cuts",
      "totalAvailableSpace": 12583.11,
      "density": "1:6",
      "floorEfficiency": 0.9,
      "handoverCondition": "GF, PH – Bare Shell\r\nOthers – Warm Shell",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": "Metered",
      "quotedRent": "GF – PhP 2,500\r\nOthers – PhP 950",
      "cusa": "PhP 276.84",
      "parkingRate": "Non-Reserved – PhP 8,206.10 Reserved – PhP 8,848.35",
      "lastUpdated": "October 18, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 24,782.44 sqm",
      "floorPlate": "2,604.71 sqm floor plate",
      "netUseableArea": "2,344.24 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "7 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT, Eastern Telecom",
      "ceilingHeight": "2.75 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "G&A Building",
      "businessDistrict": "Makati",
      "address": "Chino Roces Avenue (Extension)",
      "developer": "G&A Realty Corporation",
      "yearConstructed": 1970,
      "certifications": "Non-PEZA",
      "totalFloors": 4,
      "availableSpace": "\r\nGF/Warehouse – 358 sqm\r\n3F – 260 sqm\r\n3F – 78 sqm\r\n\r\nAvaialble Next year:\r\n2F - 89 sqm",
      "totalAvailableSpace": 696,
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "Semi-fitted\r\n3F (78 sqm) –+ Bare Shell",
      "acSystem": "Tenant to provide",
      "acCharges": "Metered",
      "quotedRent": "GF – PhP 800\r\n3F – PhP 610",
      "cusa": "PhP 60",
      "parkingRate": "PhP 6,000",
      "lastUpdated": "December 5, 2023",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "Tenant to provide Backup Power",
      "elevators": "1 Passenger Elevator",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 2 slots",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "Commercial terms to be provided upon viewing"
    },
    {
      "buildingName": "Green Sun",
      "businessDistrict": "Makati",
      "address": "Chino Roces Avenue (Extension),\r\nMakati City",
      "developer": "Green Sun ",
      "yearConstructed": "Completion 1Q 2017",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "ALL UNITS ARE ON HOLD. BUILDING IS UNDER REPAIR. CONSTRUCTION TO BE FINISHED BY JUNE 2023\r\n\r\nGF – 97 sqm\r\nGF – 193.07 sqm\r\n5F Penthouse U2 – 244 sqm\r\n5F Atrium – 317.47 sqm\r\n5F – 82 sqm",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Semi-Fitted",
      "acSystem": "Split Type AC System",
      "acCharges": " PhP 100 ",
      "quotedRent": "Rent (PSM):\r\nGF – PhP 1,500\r\n5F – PhP 500",
      "cusa": "PhP 100",
      "parkingRate": " PhP 6,000",
      "lastUpdated": "Jan 16, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of To be determined",
      "floorPlate": "1,044 sqm  floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "2 Passenger Elevators \r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Converge ",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 2 slots/unit",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "4F units may be combined\r\n\r\nRental for AC units – PhP 15,500/unit/month"
    },
    {
      "buildingName": "Karrivin Plaza",
      "businessDistrict": "Makati",
      "address": "Chino Roces Avenue (Extension),\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": 1980,
      "certifications": "Non-PEZA",
      "totalFloors": 5,
      "availableSpace": "2F – 376.05 sqm",
      "totalAvailableSpace": 376.05,
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "As is where is",
      "acSystem": "Tenant to provide",
      "acCharges": "Metered",
      "quotedRent": "PhP 600",
      "cusa": "PhP 90",
      "parkingRate": "PhP 4,000",
      "lastUpdated": "November 10, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations:No"
    },
    {
      "buildingName": "La Fuerza Plaza",
      "businessDistrict": "Makati",
      "address": "2100 Chino Roces Avenue,\r\nMakati City",
      "developer": "Megaworld Corporation",
      "yearConstructed": "Completion 2000",
      "certifications": "Non-PEZA",
      "totalFloors": 4,
      "availableSpace": "Plaza 1 \r\n3F Unit 3 to 4 - 96 sqm\r\n3F Unit 6 - 53 sqm\r\n4F Unit 15 - 64 sqm\r\n\r\nPlaza 2\r\n2F Unit 1 & 2 - 67 sqm\r\n2F Unit 10 - 27 sqm\r\n2F Unit 220 – 42 sqm\r\n\r\nOther Building\r\nNew Building 3F - 92 sqm\r\nNew Building 4F - 98 sqm\r\nBLDG 11-2F – 468 sqm\r\nBLDG 11-3F – 468 sqm",
      "totalAvailableSpace": 1475,
      "density": "Density of 1:5.6",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Semi-Fitted\r\n4F Unit 15, 2F Unit 1 & 2, 2F Unit 10, 2F Unit 20 - TBD",
      "acSystem": "Tenant to provide AC System",
      "acCharges": "Metered",
      "quotedRent": "PhP 600",
      "cusa": "PhP 75",
      "parkingRate": " \r\n PhP 5,000",
      "lastUpdated": "Decembeer 4, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "Tenant to provide Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation Subject to Availability",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "No heavy cooking allowed"
    },
    {
      "buildingName": "UPRC III",
      "businessDistrict": "Makati",
      "address": "2289 Chino Roces Avenue,\r\nMakati City",
      "developer": "United Philippines Realty Corporation",
      "yearConstructed": "To be determined ",
      "certifications": "Non-PEZA",
      "totalFloors": 2,
      "availableSpace": "\r\n\r\n\r\n\r\nGF & 2F –  643 sqm \r\nWarehouse – \r\n\r\nCannot be subdivided\r\n\r\n",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition \r\nGF – Bare Shell\r\n2F – Semi-Fitted",
      "acSystem": "Tenant to provide AC System",
      "acCharges": "Regular Hours (8:00 AM to 6:00 PM) – Metered",
      "quotedRent": " PhP 700",
      "cusa": "PhP 100",
      "parkingRate": " PhP 3,500//5000, 4 slots",
      "lastUpdated": "November 28, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "No Backup Power",
      "elevators": "No Elevator",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "2.5 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation: See Remarks",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "Parking Allocation: 6 slots\r\n\r\nGF & 2F Previously tenanted by Studio 58 Printing"
    },
    {
      "buildingName": "Mazda Makati",
      "businessDistrict": "Makati",
      "address": "2301 Don Chino Roces Extension, Makati City",
      "developer": "Auto ZoomZoom Inc.",
      "yearConstructed": "Completion 1Q 2015",
      "certifications": "Non-PEZA",
      "totalFloors": 3,
      "availableSpace": "3F – Approx. 700 sqm\r\n\r\nOn Hold",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "As is where is",
      "acSystem": "Tenant to provide AC System",
      "acCharges": "Regular Hours (8:00 AM to 6:00 PM) – Metered",
      "quotedRent": "PhP 600",
      "cusa": "PhP 25",
      "parkingRate": " Free parking",
      "lastUpdated": "May 2, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "Tenant to provide Backup Power",
      "elevators": "No Elevator",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "2.8 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 3 cars and 5 motorbikes",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Mirax Building",
      "businessDistrict": "Makati",
      "address": "Chino Roces Avenue,\r\nMakati City",
      "developer": "Mirax Corporation",
      "yearConstructed": "Completion 2013",
      "certifications": "Non-PEZA",
      "totalFloors": 3,
      "availableSpace": "\r\n\r\n\r\n\r\n\r\nFully Leased\r\n\r\n\r\n\r\n\r\n",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Semi-Fitted",
      "acSystem": "Tenant to provide AC System",
      "acCharges": "Metered",
      "quotedRent": "\r\nGF (600 sqm) – PhP 600\r\nGF (200 sqm) – PhP 700",
      "cusa": "PhP 88",
      "parkingRate": " PhP 5,000",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of 2,250 sqm",
      "floorPlate": "750 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1 slot/unit",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "MJ Corporate Plaza",
      "businessDistrict": "Makati",
      "address": "Chino Roces Avenue (Extension),\r\nMakati City",
      "developer": "Majalco, Inc.",
      "yearConstructed": "Completion 2Q 2016",
      "certifications": "PEZA Certified",
      "totalFloors": 11,
      "availableSpace": "\r\n\r\n\r\n\r\n\r\nFully Leased\r\n\r\n\r\n\r\n\r\n",
      "totalAvailableSpace": 0,
      "density": "Density of 1:6",
      "floorEfficiency": "85% Efficiency",
      "handoverCondition": "Handover Condition Warm Shell",
      "acSystem": "Variable Refrigerant Flow System",
      "acCharges": "Metered",
      "quotedRent": "\r\nIndividual Mezzanine Units 1 to 4 – PhP 1,600\r\nCombined Mezzanine Units 1 to 4 – PhP 1,000\r\n",
      "cusa": "Building Dues: Php 150 PSM",
      "parkingRate": " Php 5,000 ",
      "lastUpdated": "X",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 21,295.38 sqm",
      "floorPlate": "2,549.46 sqm floor plate",
      "netUseableArea": "2,167.04 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "6 Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT, Eastern Telecom",
      "ceilingHeight": "3 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Natividad Building",
      "businessDistrict": "Makati",
      "address": "Chino Roces Avenue (Extension),\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion Approx. 1970",
      "certifications": "Non-PEZA",
      "totalFloors": 2,
      "availableSpace": "Warehouse - 638.11 sqm",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Split Type AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 750",
      "cusa": "N/A",
      "parkingRate": " See Remarks",
      "lastUpdated": 44986,
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: ",
      "remarks": "Inclusive of 1 free parking slot"
    },
    {
      "buildingName": "Pacifica One Center",
      "businessDistrict": "Makati",
      "address": "2178 Chino Roces Avenue",
      "developer": "Orinda Land Corporation",
      "yearConstructed": 2016,
      "certifications": "Non-PEZA",
      "totalFloors": 12,
      "availableSpace": "5F – 443.74 sqm\r\n6F – 443.74 sqm\r\n11F Units 1-3 – 293.74 sqm \r\n11F Units 4-5 - 149.89 sqm\r\n12F – 356.16 sqm ",
      "totalAvailableSpace": 1687.2700000000002,
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "Others – Semi-Fitted\r\n5F, 6F – Warm Shell",
      "acSystem": "Tenant to Provide",
      "acCharges": "Metered",
      "quotedRent": "PhP 850",
      "cusa": "Traditional (8 Hours) – PhP 100\r\n24/7 – PhP 200",
      "parkingRate": "Regular Hours (8 hours) – Php 3,920\r\n24/7 – PhP 5,600",
      "lastUpdated": "September 22, 2023 ",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 3,760 sqm ",
      "floorPlate": "470 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation Subject to Availability",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "2287 Chino Roces",
      "businessDistrict": "Makati",
      "address": "2287 Chino Roces Avenue, Makati City",
      "developer": "United Philippine Realty Corporation",
      "yearConstructed": "To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 2,
      "availableSpace": "Retail \r\nShowroom – 525 sqm\r\n\r\nOffice\r\nOffice – 450 sqm\r\nWarehouse – 425 sqm",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "As is where is",
      "acSystem": "Tenant to Provide AC",
      "acCharges": "Metered",
      "quotedRent": "\r\n\r\nPhP 1.75 Million for the whole property\r\n",
      "cusa": "None",
      "parkingRate": "None",
      "lastUpdated": "May 2, 2023",
      "buildingGrade": "Grade: To be determined",
      "grossLeasableArea": "GLA To be determined",
      "floorPlate": "Floor Plate To be determined",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "None",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation : 10 slots at the front",
      "twentyFourSevenOperations": "24/7 Operations: ",
      "remarks": "Available by June 15,2017"
    },
    {
      "buildingName": "7522 Santillan",
      "businessDistrict": "Makati",
      "address": "7522 Santillan Street, Makati City",
      "developer": "Luxliving Resources, Inc.",
      "yearConstructed": "Completion: 2Q 2018",
      "certifications": "Non-PEZA",
      "totalFloors": 4,
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Fitted",
      "acSystem": "Package AC",
      "acCharges": "To be determined",
      "quotedRent": "PhP 1,000\r\nWhole Building: PhP 600,000",
      "cusa": "Building Dues (PSM) : PhP 100\r\nWhole building taker: N/A",
      "parkingRate": " PhP 4,500",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 800 sqm",
      "floorPlate": "200 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "No Backup Power",
      "elevators": "None",
      "telcoProviders": "Telco Providers: PLDT",
      "ceilingHeight": "3 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation: See Remarks",
      "twentyFourSevenOperations": "24/7 Operations: ",
      "remarks": "1 parking slot per floor"
    },
    {
      "buildingName": "KDC Plaza",
      "businessDistrict": "Makati",
      "address": "2212 Chino Roces Avenue, Barangay San Lorenzo, Makati City",
      "developer": "Kanlaon Development Corporation",
      "yearConstructed": "Completion: 1Q 2020",
      "certifications": "Non-PEZA",
      "totalFloors": 5,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of 1:5",
      "floorEfficiency": "88% Efficiency",
      "handoverCondition": "Handover Condition \r\n2F – As is where is (with partitions, lighting, partial flooring & some furniture)\r\n4F, 4F Annex – Bare Shell",
      "acSystem": "Variable Refrigerant Flow AC System",
      "acCharges": "Metered",
      "quotedRent": "PhP 800",
      "cusa": "Building Due (PSM): PhP 75",
      "parkingRate": " PhP 4,000",
      "buildingGrade": "Grade: B",
      "grossLeasableArea": "GLA of 4,308.98 sqm",
      "floorPlate": "Floor Plate Approx. 1,200 sqm",
      "netUseableArea": "Approx. 1,060 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "2 Passenger Elevators",
      "telcoProviders": "Telco Providers: Lessee to apply directly to their preferred provider",
      "ceilingHeight": "Other floors – 4.0 (slab to slab) meters finished ceiling height\r\n4F Extension floor – To be determined",
      "parkingAllocation": "Parking Allocation \r\n2F – 5 slots\r\n4F Units 405 to 408 – 7 slots or more\r\n4F Annex – 3 slots or more",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "One Oculus Center",
      "businessDistrict": "Makati",
      "address": "2120 Chino Roces Avenue, Barangay San Lorenzo, Makati City",
      "developer": "Developer:Logan Lane Holding Inc. ",
      "yearConstructed": "4Q 2020 (December)",
      "certifications": "Non-PEZA",
      "totalFloors": "7F –  592.8 sqm\r\n9F – 361.80 sqm\r\n\r\nCUTS:\r\n7F Unit 1 – 111.95 sqm\r\n7F Units 2 to 3 – 197.5 sqm\r\n7F Units 4 to 5 – 196.7 sqm\r\n7F Unit 6 – 86.65 sqm\r\n9F Unit 1 – 89.85 sqm\r\n9F Units 2 to 3 – 195.55 sqm - Available, while the rest are under advanced nego\r\n9F Unit 6 – 76.4 sqm\r\n\r\n\r\nAll units can be combined\r\n\r\nAll units are under advance negotiation (7.26.2023)",
      "availableSpace": "7F –  592.8 sqm\r\n9F – 361.80 sqm\r\n\r\nCUTS:\r\n7F Unit 1 – 111.95 sqm\r\n7F Units 2 to 3 – 197.5 sqm\r\n7F Units 4 to 5 – 196.7 sqm\r\n7F Unit 6 – 86.65 sqm\r\n9F Unit 1 – 89.85 sqm\r\n9F Units 2 to 3 – 195.55 sqm - Available, while the rest are under advanced nego\r\n9F Unit 6 – 76.4 sqm\r\n\r\n\r\nAll units can be combined\r\n\r\nAll units are under advance negotiation (7.26.2023)",
      "totalAvailableSpace": 954.6,
      "density": "To be determined",
      "floorEfficiency": "Approx. 80%",
      "handoverCondition": "Warm Shell",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,000",
      "cusa": "PhP 150",
      "parkingRate": "PhP 3,500",
      "lastUpdated": "Aug 16 2023",
      "buildingGrade": "Grade: To be determined",
      "grossLeasableArea": "GLA of 6,944 sqm",
      "floorPlate": "Floor Plate Approx. 700 sqm",
      "netUseableArea": "Approx. 500 sqm net usable",
      "backupPower": "100%  Backup Power",
      "elevators": "2 Passenger Elevators\r\n1 Executive Elevator\r\n1 Service Elevator",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "2723 Sabio Street",
      "businessDistrict": "Makati",
      "address": "2723 Sabio Street, Chino Roces, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion: To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 1,
      "availableSpace": "GF – 176 sqm",
      "totalAvailableSpace": "GF – 176 sqm",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Semi-Fitted",
      "acSystem": "Tenant to provide AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 700",
      "cusa": "Building Due (PSM):PhP 80",
      "parkingRate": " PhP 3,500",
      "buildingGrade": "Grade: B",
      "grossLeasableArea": "GLA of Approx. 2,684 sqm",
      "floorPlate": "Floor Plate To be determined",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "None Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 10 slots",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "Available for warehouse"
    },
    {
      "buildingName": "Alsons Building",
      "businessDistrict": "Makati",
      "address": "2286 Chino Roces Avenue, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion: To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 3,
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Semi-Fitted ",
      "acSystem": "Centralized Chilled Water",
      "acCharges": " PhP 120",
      "quotedRent": "PhP 1,000",
      "cusa": "Building Due (PSM): 120",
      "parkingRate": "Parking Rate (/slot/mo.):To be determined",
      "buildingGrade": "Grade: To be determined",
      "grossLeasableArea": "GLA of To be determined",
      "floorPlate": "Floor Plate To be determined",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "None Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 6 slots",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "ID Building",
      "businessDistrict": "Makati",
      "availableSpace": "3F – 611 sqm\r\n\r\n3F can be subdivided into smaller units:\r\nArea A: 210.60 sqm\r\nArea B: 307.80 sqm",
      "lastUpdated": "September 12, 2023",
      "buildingGrade": "Grade: To be determined"
    },
    {
      "buildingName": "PRCI Building",
      "businessDistrict": "Makati",
      "address": "Chino Roces Avenue cor. Barasoain and Novaliches Sts., Brgy. Olympia",
      "developer": "To be determined",
      "yearConstructed": "To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 3,
      "availableSpace": "GF to 3F with Mezzanine and Roofdeck – Approx. 829 sqm",
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "As is where is",
      "acSystem": "To be determined",
      "acCharges": "Metered",
      "quotedRent": "PhP 700",
      "cusa": "To be determined",
      "parkingRate": "To be determined",
      "lastUpdated": "September 22, 2023",
      "buildingGrade": "Grade: To be determined"
    },
    {
      "buildingName": "Sterling Place",
      "businessDistrict": "Makati",
      "address": "2302 Chino Roces Avenue, Makati City",
      "developer": "To be determined",
      "yearConstructed": "To be determined",
      "certifications": "Non-PEZA",
      "availableSpace": "3F - 1,202.03 sqm",
      "quotedRent": "PhP 900",
      "cusa": "PhP 150",
      "lastUpdated": "September 12, 2023",
      "buildingGrade": "Grade: To be determined"
    },
    {
      "buildingName": "Classica Tower 1",
      "businessDistrict": "Makati",
      "address": "114 H.V. Dela Costa, Makati City",
      "developer": "Pragmatic Development and Construction Corp.",
      "yearConstructed": "Completion: To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Tenant to provide AC",
      "acCharges": " Metered",
      "quotedRent": "PhP 750",
      "cusa": "Building Due (PSM): PhP 100",
      "parkingRate": "Parking Rate (/slot/mo.):PhP 5,000",
      "buildingGrade": "Grade: To be determined",
      "grossLeasableArea": "GLA of To be determined",
      "floorPlate": "Floor Plate To be determined",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "The Atrium of Makati",
      "businessDistrict": "Makati",
      "address": "Makati Avenue, Makati City",
      "developer": "Strata-titled ",
      "yearConstructed": "Completion 1970s",
      "certifications": "Non-PEZA",
      "totalFloors": 9,
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Tenant to provide AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 500",
      "cusa": "Building Due (PSM): PhP 78.40",
      "parkingRate": " PhP 3,000",
      "lastUpdated": "Fully Leased Jun 1, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA To be determined",
      "floorPlate": "Approx. 1,250 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "4 Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT, Provision for other telcos",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation Subject to availability",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "139 Corporate Center",
      "businessDistrict": "Makati",
      "address": "139 Valero St., Salcedo Village,\r\nMakati City",
      "developer": "Century Properties",
      "yearConstructed": "Completion 1993",
      "certifications": "Non-PEZA",
      "totalFloors": 30,
      "availableSpace": "3F - 220 sqm ",
      "totalAvailableSpace": 220,
      "density": "Density of To be determined",
      "floorEfficiency": "85% Efficiency",
      "handoverCondition": "Handover Condition Semi-Fitted",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "Metered",
      "quotedRent": "\r\nGF – PhP 1,750\r\n15F – PhP 700\r\n21F – PhP 750\r\n23F – PhP 750",
      "cusa": "PhP 79",
      "parkingRate": " PhP 5,000  (subject to change)",
      "lastUpdated": "August 31, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 22,440 sqm",
      "floorPlate": "748 sqm floor plate",
      "netUseableArea": "635.80 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "4 Passenger Elevators\r\n2 Service Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "3.2 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "88 Corporate Center",
      "businessDistrict": "Makati",
      "address": "Sedeno corner Valero Street,\r\nMakati City",
      "developer": "Belgen Realty Development, Inc.",
      "yearConstructed": 1999,
      "certifications": "Non-PEZA",
      "totalFloors": 30,
      "availableSpace": "9F Unit 4 - 135.49 sqm\r\n11F Unit 2 - 111.24 sqm\r\n11F Unit 3 - 135.49 sqm\r\n11F Unit 4 - 135.49 sqm\r\n11F Unit 5 - 111.24 sqm\r\n11F Unit 7 - 74.35 sqm",
      "totalAvailableSpace": 925.7800000000001,
      "density": "To be determined",
      "floorEfficiency": 0.89,
      "handoverCondition": "9F Unit 4 & 5 - Warm Shell\r\n10F Unit 5, 11F Unit 2 to 5, & 7 - Fitted / As is where is",
      "acSystem": "Tenant to provide",
      "acCharges": "Metered",
      "quotedRent": "PhP 900",
      "cusa": "PhP 45\r\nAssoc. Dues: PhP 65",
      "parkingRate": "To be finalized",
      "lastUpdated": "December 4, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 32,912 sqm ",
      "floorPlate": "1,000 sqm floor plate",
      "netUseableArea": "787 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "7 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT, Eastern Telecom, Converge, Infinivan, Source Telco",
      "ceilingHeight": "2.65 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "AC Charge\r\nPhP 1,227 / hour – Saturday\r\nPhP 1,336 / hour – Sunday/holiday"
    },
    {
      "buildingName": "Valero One Center",
      "businessDistrict": "Makati",
      "address": "102 Valero, Salcedo Village",
      "developer": "To be determined",
      "yearConstructed": 1980,
      "certifications": "Non-PEZA",
      "totalFloors": 7,
      "availableSpace": "2F Unit D - 199.78 sqm\r\n4F Unit 7 – 342.62 sqm\r\n6F Unit A* – 273.46 sqm\r\n6F Unit C – 408.82 sqm\r\nPH – 246.43 sqm\r\n\r\n*6F Unit A Cuts:\r\nUnit 6A1 – 72.02 sqm\r\nUnit 6A2 – 73.33 sqm\r\nUnit 6A3 – 93.91 sqm\r\nUnit 6A4 – 34.20 sqm",
      "totalAvailableSpace": 1471.1100000000001,
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "Semi-Fitted",
      "acSystem": "Tenant to Provide",
      "acCharges": "Metered",
      "quotedRent": "PhP 750",
      "cusa": "Regular hours – PhP 100\r\n24/7 – PhP 200",
      "parkingRate": "PhP 4,480",
      "lastUpdated": "October 25,2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 5,241.88 sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "50% Backup Power",
      "elevators": "2 Passenger Elevators",
      "telcoProviders": "Telco Providers: Eastern Telecom, Globe, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:100",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "MJ Plaza",
      "businessDistrict": "Makati",
      "address": "106 Valero, Salcedo Village",
      "developer": "Majalco Inc.",
      "yearConstructed": 2007,
      "certifications": "PEZA Certified",
      "totalFloors": 7,
      "availableSpace": "GF to 7F – 755 sqm/floor\r\n\r\nNo. of workstations:\r\n3F – 172 seats\r\n4F – 173 seats\r\n5F – 180 seats\r\n6F – 180 seats\r\n7F – 176 seats\r\n\r\nCannot be subdivided, whole floor takers only",
      "totalAvailableSpace": 5285,
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "Fully Fitted",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,000",
      "cusa": "To be finalized with the lessor\r\nNo CUSA for whole building takers",
      "parkingRate": "Subject to negotiation",
      "lastUpdated": "October 6, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 5,286 sqm",
      "floorPlate": "755 sqm floor plate",
      "netUseableArea": "755 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "2 Passenger Elevators",
      "telcoProviders": "Telco Providers: Multiple Providers",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 32 total slots",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "iAcademy Plaza",
      "businessDistrict": "Makati",
      "address": "324 Sen Gil. Puyat Avenue, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion 1980",
      "certifications": "Non-PEZA",
      "totalFloors": 11,
      "availableSpace": "FULLY LEASED",
      "density": "Density of To be determined",
      "floorEfficiency": "74% Efficiency",
      "handoverCondition": "Handover Condition Semi-Fitted",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 658.56",
      "cusa": "PhP 144.06",
      "parkingRate": " PhP 5,250",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 6,700 sqm",
      "floorPlate": "660 sqm floor plate",
      "netUseableArea": "488 sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "3 Passenger Elevators\r\n1 Service Elevator",
      "telcoProviders": "Telco Providers: Converge, Eastern Telecom, Globe, PLDT",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:100",
      "twentyFourSevenOperations": "24/7 Operations:"
    },
    {
      "buildingName": "Trident Tower",
      "businessDistrict": "Makati",
      "address": "Sen. Gil J. Puyat Ave, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0
    },
    {
      "buildingName": "ACI Group Building",
      "businessDistrict": "Makati",
      "address": "147 H.V. Dela Costa, Salcedo Village\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion 2009",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Semi-Warm Shell",
      "acSystem": "Tenant to Provide AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 900",
      "cusa": "PhP 85",
      "parkingRate": " Php 4,000",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 5,241.88 sqm ",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Aguirre Building ",
      "businessDistrict": "Makati",
      "address": "108 H.V. Dela Costa Street, Salcedo Village",
      "developer": "Mabatang Corporation",
      "yearConstructed": "To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 2,
      "availableSpace": "2F – 114 sqm\r\n2F - 76 sqm",
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "Semi-Fitted",
      "acSystem": "Tenant to Provide",
      "acCharges": "Regular Hours (8:00AM to 7:00PM) Metered",
      "quotedRent": "PhP 1,100",
      "cusa": "PhP 60",
      "parkingRate": " PhP 7,000",
      "lastUpdated": "November 23, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined sqm ",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "No Elevators",
      "telcoProviders": "Telco Providers: PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:100",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "Parking Allocation: 2 slots"
    },
    {
      "buildingName": "ACT Tower",
      "businessDistrict": "Makati",
      "address": "124 H.V. Dela Costa, Salcedo Village\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": " ",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Fully Fitted",
      "acSystem": "Split Type AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 700",
      "cusa": "PhP 72",
      "parkingRate": " To be determined",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of To be determined sqm ",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: ",
      "remarks": "Parking Allocation: 2 slots for 8F"
    },
    {
      "buildingName": "Adamson Center",
      "businessDistrict": "Makati",
      "address": "L.P. Leviste Street, Salcedo Village,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 8,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 730 ",
      "cusa": "Included in Rent",
      "parkingRate": " PhP 4,000",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined sqm ",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "2 Elevators",
      "telcoProviders": "Telco Providers: Converge, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "AFC Building",
      "businessDistrict": "Makati",
      "address": "L.P. Leviste Street, Salcedo Village,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased\r\n\r\nNO CONTACT",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "To be determined AC",
      "acCharges": "To be determined",
      "quotedRent": "To be determined",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm ",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "ALPAP I Building",
      "businessDistrict": "Makati",
      "address": "L.P. Leviste Street, Salcedo Village,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased\r\n\r\nNO CONTACT",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "To be determined AC",
      "acCharges": "To be determined",
      "quotedRent": "To be determined",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm ",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Cambridge Centre Building",
      "businessDistrict": "Makati",
      "address": "108 L.P. Leviste Street, Salcedo Village, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 7,
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Split Type AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 700",
      "cusa": "PhP 75",
      "parkingRate": " PhP 4,500",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined sqm ",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation\r\n1 to 3 slots",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Antel Corporate Center 2000",
      "businessDistrict": "Makati",
      "address": "121 Valero Street, Salcedo Village, Makati City",
      "developer": "Antel Group of Companies",
      "yearConstructed": "Completion 1997",
      "certifications": "Non-PEZA",
      "totalFloors": 30,
      "availableSpace": "Fully Leased",
      "density": "Density of 1:9",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Semi-Fitted",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "Regular Hours (8:00 AM to 6:00 PM ) – Metered",
      "quotedRent": "PhP 900",
      "cusa": "PhP 85",
      "parkingRate": " PhP 5,000",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 15,600.00 sqm ",
      "floorPlate": "709 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "6 Elevators",
      "telcoProviders": "Telco Providers: Bayantel, Converge, Eastern Telecom, Globe, PLDT",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 2 slots",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "does not allow 24 hour operations"
    },
    {
      "buildingName": "Asian Bank Center",
      "businessDistrict": "Makati",
      "address": "H.V. Dela Costa Street, \r\nSalcedo Village, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": 2,
      "totalFloors": "To be determined ",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "To be determined AC",
      "acCharges": "To be determined",
      "quotedRent": "To be determined",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm ",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Athenaeum Condominium",
      "businessDistrict": "Makati",
      "address": "L.P. Leviste Street, Salcedo Village\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "To be determined AC",
      "acCharges": "To be determined",
      "quotedRent": "PhP 650",
      "cusa": "PhP 68.50",
      "parkingRate": " PhP 4,000",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm ",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "BDO Equitable Tower",
      "businessDistrict": "Makati",
      "address": "Paseo de Roxas, Salcedo Village",
      "developer": "Equitable Banking Corporation",
      "yearConstructed": 1998,
      "certifications": "Non-PEZA",
      "totalFloors": 30,
      "availableSpace": "8F - 612.17 sqm\r\n9F Unit 3 – 226.66 sqm\r\n11F Units 3 & 4 – 645.11 sqm\r\n20F Unit 1 – 327.82 sqm\r\n25F Units 1 & 2 –1,323.98 sqm\r\n",
      "totalAvailableSpace": 3135.74,
      "density": "To be determined",
      "floorEfficiency": 0.84,
      "handoverCondition": "Semi-Fitted",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Metered\r\nAC Extension Charge – PhP 1500/hour",
      "quotedRent": "PhP 1,200",
      "cusa": "PhP 105",
      "parkingRate": "PhP 5,000",
      "lastUpdated": "October 5, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 37,596 sqm",
      "floorPlate": "1,177 sqm floor plate",
      "netUseableArea": "988.68 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "10 Passenger Elevators\r\n2 Service Elevators",
      "telcoProviders": "Telco Providers: PLDT",
      "ceilingHeight": "2.8 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 \r\n22F -5 slots",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "23F – Kooky/Yeli"
    },
    {
      "buildingName": "Centrum II Building",
      "businessDistrict": "Makati",
      "address": "Valero Street, Salcedo Village,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased\r\n\r\nNO CONTACT",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "To be determined",
      "quotedRent": "To be determined",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm ",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Chatham House",
      "businessDistrict": "Makati",
      "address": "V.A. Rufino corner Valero and San Agustin Streets, Salcedo Village\r\nMakati City",
      "developer": "Strata Title",
      "yearConstructed": "Completion 1996",
      "certifications": "Non-PEZA",
      "totalFloors": 30,
      "availableSpace": "Keyland:\r\n30F Unit 30-C - 211.94 sqm\r\n31F Unit 31-C – 211.94 sqm\r\n31F Unit 31-D – 211.94 sqm\r\n\r\nSKF Units: \r\n18F – 235 sqm\r\n\r\nPrime Properties:\r\n12F Unit C & D - 465.10 sqm\r\n14F Unit D – 235.90 sqm\r\n22F Unit D – 223.91 sqm\r\nRoofdeck – 216 sqm\r\n\r\nAvailable by January 2024:\r\n14F Unit C - 253.89 sqm\r\n14F Unit B – 162.85 sqm ",
      "totalAvailableSpace": 2011.7300000000002,
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "As is where is\r\n18F Unit C – Fitted (SKF)\r\n\r\nKeyland Units - Semi-Fitted",
      "acSystem": "Split Type AC",
      "acCharges": "Metered",
      "quotedRent": "12F – PhP 550\r\n14F, 18F – PhP 600\r\n22F – PhP 600\r\n31F, Roofdeck – PhP 600\r\n\r\nPrime Properties - PhP 600",
      "cusa": "PhP 75",
      "parkingRate": " PhP 5,000",
      "lastUpdated": "SKF – July 28, 2023\r\nKeyland – September 5, 2023\r\nPrime Properties - October 24, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 20,000 sqm",
      "floorPlate": "800 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "3 Elevators",
      "telcoProviders": "Telco Providers: PLDT, Globe",
      "ceilingHeight": "2.6 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 4 slots",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "24 hour operations not allowed"
    },
    {
      "buildingName": "Citibank Center",
      "businessDistrict": "Makati",
      "address": "8741 Paseo de Roxas corner Villar Street, Salcedo Village, Makati City ",
      "developer": "Citibank",
      "yearConstructed": "Completion 1975",
      "certifications": "Non-PEZA",
      "totalFloors": 20,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of 1:5",
      "floorEfficiency": "81% Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": " Regular Hours (7:00 AM to 7:00 PM) Metered",
      "quotedRent": "Rent (PSM):\r\n8F Unit F – PhP 800\r\n18F, 19F – PhP 1,300\r\nOther floors – PhP 1,000",
      "cusa": "\r\nWhole floor tenant – PhP 200\r\nMulti-tenanted – PhP 210\r\n18F – PhP 200\r\n8F, 19F – Direct to building admin",
      "parkingRate": " \r\nOther floors – PhP 6,000\r\n18F – PhP 8,913.72 (2 slots)\r\n19F – PhP 16,081.15 (4 slots)",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 27,082 sqm",
      "floorPlate": "1,191.77 sqm floor plate",
      "netUseableArea": "965.33 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "6 Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "2.6 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 \r\n8F – 2 slots\r\n18F – 2 slots\r\n19F – 4 slots",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "10F space cost of furniture – PhP 16.267 Million \r\n\r\n8F Unit F –  Tenant may install back-up AC; split type or ceiling mounted"
    },
    {
      "buildingName": "Citibank Tower",
      "businessDistrict": "Makati",
      "address": "Villar Street corner Valero Street,\r\nMakati City ",
      "developer": "Citibank",
      "yearConstructed": "Completion 1995",
      "certifications": "Non-PEZA",
      "totalFloors": 34,
      "availableSpace": "25F - 180.73 sqm ",
      "totalAvailableSpace": 180.73,
      "density": "Density of 1:9.3",
      "floorEfficiency": "89% Efficiency",
      "handoverCondition": "Handover Condition \r\n8F – As is where is\r\n24F – Fully Fitted\r\n23F, 25F – Semi-Fitted",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": " Regular Hours (8:00 AM to 5:00 PM) – Metered\r\nExtension Charge (5:00 PM to 9:00 PM) – PhP 2,000/hour",
      "quotedRent": "PhP 1,300",
      "cusa": " PhP 220",
      "parkingRate": " PhP 5,000",
      "buildingGrade": "Grade A",
      "grossLeasableArea": "GLA of 48,376 sqm",
      "floorPlate": "2,049 sqm floor plate",
      "netUseableArea": "1,823.61 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "11 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Eastern Telecom, Globe, PLDT, Skycable ",
      "ceilingHeight": "2.5 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation \r\n24F – 4 slots\r\n23F, 25F – 2 slots",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "Parking – can request for more parking slots\r\n"
    },
    {
      "buildingName": "Country Space I Building",
      "businessDistrict": "Makati",
      "address": "Sen. Gil Puyat Avenue,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion 1983",
      "certifications": "Non-PEZA",
      "totalFloors": 12,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Semi-Fitted\r\n(with partitions/washroom/pantry) ",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "Metered ",
      "quotedRent": "\r\nOffice Hours – PhP 550\r\n24/7 – 600",
      "cusa": "PhP 85 (inclusive of VAT)",
      "parkingRate": " N/A",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of To be determined sqm ",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "Backup Power See Remarks",
      "elevators": "2 Elevators ",
      "telcoProviders": "Telco Providers: Bayantel, Eastern Telecom, Globe, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: ",
      "remarks": "unit owner : 631-9109 – mr francisco lejano"
    },
    {
      "buildingName": "Don Chua Lamko Building",
      "businessDistrict": "Makati",
      "address": "H.V. Dela Costa, Salcedo Village,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion 1987",
      "certifications": "Non-PEZA",
      "totalFloors": 9,
      "availableSpace": "4F – 660 sqm \r\n6F – 660 sqm\r\n8F – 330 sqm",
      "totalAvailableSpace": 1650,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Single Package Unit AC",
      "acCharges": " Metered",
      "quotedRent": "Tenant to Offer",
      "cusa": "PhP 150",
      "parkingRate": " PhP 3,500",
      "lastUpdated": "November 23, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 5,940 sqm ",
      "floorPlate": "660 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "Lights and Electricty (except AC)   Backup Power",
      "elevators": "2 Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:200",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "Allows 24 hour operations\r\nAC Extension Charge (after 6pm) : PhP 200/hour"
    },
    {
      "buildingName": "Finman Centre",
      "businessDistrict": "Makati",
      "address": "131 Tordesillas Street, Salcedo Village, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion 1986",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition \r\nBasement – Semi-Fitted\r\n2F – Fully Fitted",
      "acSystem": "Basement – Variable Refrigerant Flow AC\r\n2F – Split Type AC",
      "acCharges": "Metered",
      "quotedRent": "\r\nBasement – PhP 600\r\n2F – PhP 1,600",
      "cusa": "PhP 100",
      "parkingRate": " To be determined",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of To be determined",
      "floorPlate": "To be determined floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: Converge ICT, Eastern Telecom",
      "ceilingHeight": "2.6 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Three Salcedo Place",
      "businessDistrict": "Makati",
      "address": "Tordesillas Street, Salcedo Village, Makati City",
      "developer": "To be determined",
      "yearConstructed": "To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased",
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "Warm Shell",
      "acSystem": "To be determined",
      "acCharges": "To be determined",
      "quotedRent": "PhP 3,500",
      "cusa": "To be determined",
      "parkingRate": "To be determined",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined",
      "floorPlate": "To be determined floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "Herrera Tower",
      "businessDistrict": "Makati",
      "address": "Valero corner Rufino Streets, Salcedo Village, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "To be determined AC",
      "acCharges": "To be determined",
      "quotedRent": "To be determined",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm ",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: ",
      "remarks": "Does not entertain brokers"
    },
    {
      "buildingName": "Lepanto Building",
      "businessDistrict": "Makati",
      "address": "Paseo de Roxas, Salcedo Village",
      "developer": "Zamcore Realty",
      "yearConstructed": 1978,
      "certifications": "PEZA Certified",
      "totalFloors": 23,
      "availableSpace": "6F Unit B – 518.00 sqm (75 Seats)\r\n7F – 1,025.00 sqm\r\n14F Unit A – 346 sqm\r\n14F Unit B – 227 sqm\r\n14F Unit C – 462.35 sqm\r\n15F – 1,025.00 sqm\r\n",
      "totalAvailableSpace": 3603.35,
      "density": "1:5",
      "floorEfficiency": 0.85,
      "handoverCondition": "Semi-Fiitted\r\n5F Unit B, 6F Unit B – Fully Fitted",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Metered\r\nExtension Charge – PhP 2,500 /hour",
      "quotedRent": "GF – PhP 1,500\r\nSemi-fitted – PhP 800\r\nFully Fitted – PhP 1,000",
      "cusa": "PhP 210",
      "parkingRate": "PhP 7,000",
      "lastUpdated": "November 28, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 23,575 sqm",
      "floorPlate": "1,200 sqm floor plate",
      "netUseableArea": "1,025 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "8 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Converge, Eastern Telecom, Globe, PLDT",
      "ceilingHeight": "2.4 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation\r\n16F- 2 slots",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Liberty Center",
      "businessDistrict": "Makati",
      "address": "104 H.V. Dela Costa Street, Salcedo Village",
      "developer": "LFM Properties Corporation",
      "yearConstructed": 2000,
      "certifications": "Non-PEZA",
      "totalFloors": "19 \r\n(12 office floors)",
      "availableSpace": "9F Unit 1 – 135 sqm\r\n15F Unit 4 – 124 sqm",
      "totalAvailableSpace": 259,
      "density": "1:5",
      "floorEfficiency": "To be determined",
      "handoverCondition": "As is where is",
      "acSystem": "Split Type AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 850",
      "cusa": "Included in rent",
      "parkingRate": " PhP 5,000",
      "lastUpdated": "October 19, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 8,103 sqm",
      "floorPlate": "583 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: Any telco provider",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1 slot/floor",
      "twentyFourSevenOperations": "24/7 Operations: ",
      "remarks": "No signage"
    },
    {
      "buildingName": "SM Cyber Makati One",
      "businessDistrict": "Makati",
      "address": "373 Sen. Gil J. Puyat Ave., Makati City",
      "developer": "SM Prime Holdings, Inc. ",
      "yearConstructed": "Completion 2008",
      "certifications": "PEZA Certified",
      "totalFloors": 4,
      "availableSpace": "Approx. 200 sqm",
      "totalAvailableSpace": 0,
      "density": "Density of 1:5",
      "floorEfficiency": "93% Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Variable Refrigerant Flow System",
      "acCharges": " Metered + PhP 100",
      "quotedRent": "PhP 1,000",
      "cusa": "PhP 188",
      "parkingRate": " PhP 5,000",
      "lastUpdated": "November 8, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 14,032.65 sqm",
      "floorPlate": "3,508.16 floor plate",
      "netUseableArea": "3,262.59 sqm net usable",
      "backupPower": "100%  Backup Power",
      "elevators": "3 Passenger Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "3.0 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:100",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "SM Cyber Makati Two",
      "businessDistrict": "Makati",
      "address": "Jupiter and Zodiac St. corner Sen. Gil J. Puyat Ave., Makati City",
      "developer": "SM Prime Holdings, Inc. ",
      "yearConstructed": "Completion 2008",
      "certifications": "PEZA Certified",
      "totalFloors": "To be determined ",
      "availableSpace": "GF – 317 sqm",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": " Metered\r\n24/7 – PhP 138.60 PSM",
      "quotedRent": "PhP 1,000",
      "cusa": "PhP 170",
      "parkingRate": " PhP 5,000",
      "lastUpdated": "November 8, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 11, 185.60 sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Liberty Plaza",
      "businessDistrict": "Makati",
      "address": "Dela Costa Street, Salcedo Village,\r\nMakati City",
      "developer": "LFM Properties Corporation",
      "yearConstructed": "Completion 4Q 2018",
      "certifications": "Non-PEZA",
      "totalFloors": 19,
      "availableSpace": "16F – 660 sqm",
      "totalAvailableSpace": 660,
      "density": "1:5",
      "floorEfficiency": 1,
      "handoverCondition": "Semi-Fitted (Semi-furnished inclusive of partitions, existing A/C, light fixtures, common comfort rooms) ",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": " Regular Hours (7:00 AM to 7:00 PM) – Metered",
      "quotedRent": "PhP 850",
      "cusa": "\r\nWhole-floor tenants – PhP 100 /sqm\r\nMulti-tenanted – Included in rent",
      "parkingRate": " PhP 5,000",
      "lastUpdated": "August 18. 2023",
      "buildingGrade": "Grade B+",
      "grossLeasableArea": "GLA of Approx. 8,148.50 sqm",
      "floorPlate": "Approx. 657.73 sqm floor plate",
      "netUseableArea": "Approx. 579.15 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "3 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "4m floor to floor height",
      "parkingAllocation": "Parking Allocation 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: No, but management may reconsider if it is a reputable company"
    },
    {
      "buildingName": "Makati Executive Center",
      "businessDistrict": "Makati",
      "address": "114 Valero Street, Salcedo Village,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully leased\r\n\r\nNO CONTACT",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "Split Type AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 15,000 per month",
      "cusa": "PhP 35",
      "parkingRate": " No parking slots",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined",
      "floorPlate": "To be determined floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "The Paseo Center IT Center ",
      "businessDistrict": "Makati",
      "address": "8757 Paseo de Roxas, Salcedo Village, Makati City",
      "developer": "Megaworld Corp.",
      "yearConstructed": "Completion 2010",
      "certifications": "PEZA Certified",
      "totalFloors": 6,
      "availableSpace": "Fully leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Semi-Fitted",
      "acSystem": "Comobination of Wall Mounted & Ceiling Type AC",
      "acCharges": " Metered",
      "quotedRent": "PhP 1,031.40 sqm\r\n*Subject to 10% rent escalation by Nov 26, 2020",
      "cusa": "PhP 185",
      "parkingRate": " PhP 5,000",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of Approx. 2,700",
      "floorPlate": "1,361.70 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "3 Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation Subject to availability",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "PCCI Condominium",
      "businessDistrict": "Makati",
      "address": "118 L.P. Leviste Street, Salcedo Village, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased ",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "To be determined AC",
      "acCharges": "To be determined",
      "quotedRent": "To be determined",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined",
      "floorPlate": "To be determined floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: ",
      "remarks": "Undergoing new Manangement "
    },
    {
      "buildingName": "PDCP Bank Centre",
      "businessDistrict": "Makati",
      "address": "V.A. Rufino corner L.P. Leviste Street, Salcedo Village, Makati",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 12,
      "availableSpace": "Fully Leased\r\n",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Split-type AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 500",
      "cusa": "PhP 85",
      "parkingRate": " PhP 780 (dues only)",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined",
      "floorPlate": "To be determined floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "2 Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT, Eastern Telecom, Converge",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "1 parking allocation\r\nDoes not allow 24/7 operations"
    },
    {
      "buildingName": "Peak Tower",
      "businessDistrict": "Makati",
      "address": "107 L.P. Leviste Street, Salcedo Village, Makati City",
      "developer": "Century Properties",
      "yearConstructed": 1995,
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "Fully Fitted (60 Seats)",
      "acSystem": "To be determined",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,200",
      "cusa": "PhP 110",
      "parkingRate": "To be determined",
      "lastUpdated": "December 19, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of To be determined",
      "floorPlate": "To be determined floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: ",
      "remarks": "Free Parking \r\nFor more info : call PNB tower "
    },
    {
      "buildingName": "Pearl Bank Center",
      "businessDistrict": "Makati",
      "address": "Valero Street, Salcedo Village",
      "developer": "Aquarich, Inc.",
      "yearConstructed": 2000,
      "certifications": "Non-PEZA",
      "totalFloors": 18,
      "availableSpace": "5F – 207  sqm\r\n8F Unit 3 to 4 – 171 sqm\r\n9F – 207 sqm\r\n16F Units 1 & 6 – 426 sqm\r\n16F Units 4 & 5 – 189 sqm\r\n16F Unit 3 – 93 sqm\r\n20F Unit 1 – Approx. 207 sqm\r\n20F Unit 2 – Approx. 225 sqm\r\n20F Unit 3 – Approx. 221 sqm\r\n20F Unit 4 – Approx. 172 sqm\r\n(Units can be combined)\r\n",
      "totalAvailableSpace": 2118,
      "density": "1:6",
      "floorEfficiency": 0.8,
      "handoverCondition": "Semi-Fitted",
      "acSystem": "Split Type AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 600",
      "cusa": "PhP 150",
      "parkingRate": "PhP 5,000",
      "lastUpdated": "December 4, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of 14,400 sqm",
      "floorPlate": "800 sqm floor plate",
      "netUseableArea": "640 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "4 Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:100",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "Building does not allow serviced office"
    },
    {
      "buildingName": "The Peninsula Court",
      "businessDistrict": "Makati",
      "address": "Paseo De Roxas, Salcedo Village",
      "developer": "Peninsula Enterprises & Realty Development Corp.",
      "yearConstructed": 1988,
      "certifications": "Non-PEZA",
      "totalFloors": 8,
      "availableSpace": "2F Unit 6 – 25 sqm\r\n4F Unit 10 – 50.00 sqm\r\n5F Unit 2 – 15.00 sqm\r\n5F Unit 4 – 7.00 sqm\r\n5F Unit 5 – 75.00 sqm\r\n5F Unit 7 – 65.00 sqm\r\n5F Unit 13 – 65.00 sqm\r\n5F Unit 17 – 75.00 sqm\r\n5F Unit 19 – 198.00 sqm\r\n6F Unit A – 1,000.00 sqm\r\n7F Unit A – 500.00 sqm\r\n7F Unit B – 500.00 sqm\r\n8F Units 1 to 8 – 130.00 sqm\r\n8F Unit 9 – 75.00 sqm\r\n8F Unit 13 – 75.00  sqm",
      "totalAvailableSpace": 2855,
      "density": "1:7",
      "floorEfficiency": "To be determined",
      "handoverCondition": "Semi-Fitted",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Pro-rated",
      "quotedRent": "PhP 900",
      "cusa": "PhP 220",
      "parkingRate": "PhP 5,000",
      "lastUpdated": "Jan 23, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of 7,685 sqm",
      "floorPlate": "1,000 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "2 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Converge, Eastern Telecom, Globe, PLDT",
      "ceilingHeight": "2.5 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation: 1 slot",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "No – Manning Agency"
    },
    {
      "buildingName": "Philam Life Salcedo",
      "businessDistrict": "Makati",
      "address": "Leviste Street, Salcedo Village",
      "developer": "Philam Properties",
      "yearConstructed": "Completion 1983",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Warm Shell",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 535.50",
      "cusa": "PhP 170",
      "parkingRate": " PhP 3,600",
      "lastUpdated": "November 10, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of To be determined",
      "floorPlate": "Approx. 590 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: PLDT, Globe, Eastern Telecom, Philcom",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "Allows 24/7 operations"
    },
    {
      "buildingName": "Philamlife Tower",
      "businessDistrict": "Makati",
      "address": "Paseo de Roxas",
      "developer": "Philam Properties",
      "yearConstructed": 1999,
      "certifications": "PEZA",
      "totalFloors": 46,
      "availableSpace": "Philamlife Floors:\r\nGF – 99.64 sqm\r\nGF – 525 sqm\r\n\r\n10F- 186 sqm\r\n15F – 714.92 sqm\r\n25F – 183.66 sqm\r\n25F – 274 sqm\r\n27F – 881 sqm\r\n30F – 727.18 sqm\r\n30F – 604 sqm\r\n32F - 867 sqm\r\n39F – 810 sqm\r\n41F – 459.69 sqm\r\n43F – 355.05 sqm\r\n\r\nMr. Soong's Unit:\r\n45F – 444 sqm (Approx.35 Seats)\r\n\r\n38F Unit B – 405 sqm (Frabelle floor)",
      "totalAvailableSpace": 6911.45,
      "density": "1:9",
      "floorEfficiency": 0.8,
      "handoverCondition": "As-is Where-is\r\n10F (186sqm), 25F (274 sqm),38F Unit B, 30F – 727.18 sqm – Semi-Fitted\r\n45F – Fully Fitted\r\n25F (183.66sqm) - Bare Shell",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Regular Hours (7:00 AM to 6:00 PM) – Included in cusa\r\nExtension Charge (6:00 PM to 7:00 AM) – PhP 1,500/hour",
      "quotedRent": "GF – PhP 2,000\r\n9F to 15F – PhP 1,120\r\n25F to 30F – PhP 1,200\r\n39F to 45F  PhP 1,250\r\n38F Unit B – PhP 1,000\r\n45F – PhP 2,200",
      "cusa": "PhP 230",
      "parkingRate": "Tandem – PhP 4,500\r\nSingle – PhP 5,000\r\n38F Unit B – PhP 6,000 ",
      "lastUpdated": "Philamlife Floors - December 12, 2023",
      "buildingGrade": "Prime",
      "grossLeasableArea": "GLA of 61,476 sqm ",
      "floorPlate": "2,025 sqm floor plate",
      "netUseableArea": "1,620 sqm net usable",
      "backupPower": "N+1 Backup Power",
      "elevators": "16 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Eastern Telecom, Globe, PLDT, Smart",
      "ceilingHeight": "Other floors – 2.75 meters\r\n38F – 4.00 finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: Subject for approval",
      "remarks": "No – bitcoin tenants\r\nSubject for approval – serviced office\r\n"
    },
    {
      "buildingName": "Sagittarius Building",
      "businessDistrict": "Makati",
      "address": "H.V. Dela Costa, Salcedo Village,\r\nMakati City",
      "developer": "Justicia Realty",
      "yearConstructed": "Completion 1982",
      "certifications": "Non-PEZA",
      "totalFloors": 16,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Warm Shell",
      "acSystem": "Split Type AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 600",
      "cusa": "PhP 80",
      "parkingRate": " PhP 4,500",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined",
      "floorPlate": "To be determined floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "Building doesn't allow serviced office"
    },
    {
      "buildingName": "Solar Century Tower",
      "businessDistrict": "Makati",
      "address": "Tordesillas corner H.V. Dela Costa,\r\nMakati City",
      "developer": "Sa",
      "yearConstructed": "Completion 1980s",
      "certifications": "Non-PEZA",
      "totalFloors": 12,
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Split Type AC",
      "acCharges": "Metered ",
      "quotedRent": "PhP 500",
      "cusa": "PhP 200",
      "parkingRate": " PhP 2,500",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of To be determined",
      "floorPlate": "To be determined floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "2 Passenger Elevators",
      "telcoProviders": "Telco Providers: Globe, InfinivanPLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation  2 to 3 slots/unit",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Trafalgar Plaza",
      "businessDistrict": "Makati",
      "address": "H.V. Dela Costa, Salcedo Village",
      "developer": "Ray Burton",
      "yearConstructed": 1993,
      "certifications": "PEZA Certified",
      "totalFloors": 27,
      "availableSpace": "GF – 539.68 sqm (Office only)\r\n14F Unit A – 272 sqm\r\n17F Unit C – 228.09 sqm\r\n18F Unit B – 227.76 sqm\r\n22F Units C & D – 494 sqm (40 Seats)\r\n26F Unit B – 226.96 sqm\r\n27F – 994.91 sqm (178 Seats)",
      "totalAvailableSpace": 2983.4,
      "density": "1:5",
      "floorEfficiency": 0.85,
      "handoverCondition": "Semi-Fitted\r\n22F Unit C & D, 27F Unit A to D - Fully Fitted\r\n26F- Warm Shell",
      "acSystem": "Centralized Chilled Water\r\nGF, 27F - Tenant to Provide",
      "acCharges": "Metered\r\nExtension Charge – PhP 750 PSM",
      "quotedRent": "PhP 800\r\nGF – PhP 1,500",
      "cusa": "PhP 112.57",
      "parkingRate": "PhP 4,500",
      "lastUpdated": "September 19, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 27,540 sqm ",
      "floorPlate": "1,020 sqm floor plate",
      "netUseableArea": "867 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "6 Passenger Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT, Smart, Eastern Telecom",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation \r\n1 slot per quadrant",
      "twentyFourSevenOperations": "24/7 Operations: Subject for approval\r\n(Most likely no)",
      "remarks": "Does not want to entertain BPO's anymore but might consider\r\n\r\nGF does not have a frontage"
    },
    {
      "buildingName": "V Corporate Centre",
      "businessDistrict": "Makati",
      "address": "L.P. Leviste Street, Salcedo Village",
      "developer": "Vita Realty Corporation",
      "yearConstructed": 2014,
      "certifications": "PEZA Certified",
      "totalFloors": 12,
      "availableSpace": "6F Unit 2 – 686 sqm\r\n6F Unit 3 – 718 sqm\r\n6F Unit 4 – 529 sqm\r\n\r\nUnits can be combined",
      "totalAvailableSpace": 1943,
      "density": "To be determined",
      "floorEfficiency": 0.86,
      "handoverCondition": "Warm Shell",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,300",
      "cusa": "PhP 215",
      "parkingRate": "PhP 6,500",
      "lastUpdated": "August 29, 2023",
      "buildingGrade": "Grade A",
      "grossLeasableArea": "GLA of 21,656.55 sqm",
      "floorPlate": "2,543.30 sqm floor plate",
      "netUseableArea": "2,184.14 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "7 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Valero Tower",
      "businessDistrict": "Makati",
      "address": "122 Valero Street, Salcedo Village,\r\nMakati",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "To be determined AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 600",
      "cusa": "PhP 68.32",
      "parkingRate": " PhP 3500",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm ",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: Bayantel, Eastern Telecom, Globe, PLDT, Smart ",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "BPI Card Center",
      "businessDistrict": "Makati",
      "address": "8753 Paseo de Roxas, Makati City",
      "developer": "Filinvest Land",
      "yearConstructed": "Completion 1984",
      "certifications": "Non-PEZA",
      "totalFloors": 20,
      "availableSpace": "SCHEDULED FOR REDEVELOPMENT",
      "totalAvailableSpace": 0,
      "density": "Density of 1:5",
      "floorEfficiency": "85% to 87% Efficiency",
      "handoverCondition": "Handover Condition\r\nOther floors – Semi-Fitted\r\nPH – Bare Shell",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": " Metered",
      "quotedRent": "PhP 850 to PhP 1,200",
      "cusa": "PhP 150",
      "parkingRate": " PhP 5,000",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 10,128 sqm ",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "3 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:250",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Algo Center",
      "businessDistrict": "Makati",
      "address": "162 L.P. Leviste Street, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Fully Fitted",
      "acSystem": "Variable Refrigerant Flow AC",
      "acCharges": "Metered",
      "quotedRent": "To be determined",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm ",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Salcedo Towers",
      "businessDistrict": "Makati",
      "address": "169 H.V. Dela Costa Street, Salcedo Village, Makati City",
      "developer": "Salcedo Tower Condominium Corporation",
      "yearConstructed": 1998,
      "certifications": "Non-PEZA",
      "totalFloors": 28,
      "availableSpace": "Fully Leased",
      "density": "1:9",
      "floorEfficiency": 0.85,
      "handoverCondition": "Semi-Fitted",
      "acSystem": " Centralized Chilled Water",
      "acCharges": " Regular Hours (8:00 AM to 6PM) – Metered\r\nExtension Charge – PhP 500 /hour (weekdays); PhP 2,000 /hour (weekends)",
      "quotedRent": "PhP 900",
      "cusa": "PhP 150",
      "parkingRate": "PhP 5,000",
      "lastUpdated": 45184,
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 14,504 sqm",
      "floorPlate": "518 sqm floor plate",
      "netUseableArea": "440.30 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "8 Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT, Smart, Sun Cellular, Digitel",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "Zuellig Building",
      "businessDistrict": "Makati",
      "address": "Makati Avenue corner Paseo De Roxas",
      "developer": "Bridgebury Realty Corporation",
      "yearConstructed": 2012,
      "certifications": "PEZA Certified",
      "totalFloors": 34,
      "availableSpace": "14F Unit 3 – 185.79 sqm\r\n24F Units 2 to 3 – 372.78 sqm\r\n28F Unit 6 - 186.39 sqm\r\n30F Units 1 & 6 to 8 – 1,075.06 sqm\r\n\r\nAVAILABLE BY 1Q 2024:\r\n18F Unit 6 - 328.15 sqm\r\n26F Units 2 to 3 - 372.78 sqm\r\n34F unit 1 - 351.87 sqm",
      "totalAvailableSpace": 1820.02,
      "density": "1:8.5",
      "floorEfficiency": 0.91,
      "handoverCondition": "Warm Shell",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,700",
      "cusa": "PhP 290",
      "parkingRate": "PhP 9,000",
      "lastUpdated": "November 9, 2023",
      "buildingGrade": "Prime",
      "grossLeasableArea": "GLA of 54,444 sqm ",
      "floorPlate": "1,900 sqm floor plate",
      "netUseableArea": "1,729 sqm net usable",
      "backupPower": "100% (N+1) Backup Power",
      "elevators": "Low-Zone   8 Elevators\r\nHigh–Zone    8 Elevators\r\nParking    2 Elevators\r\nService    2 Elevators",
      "telcoProviders": "Telco Providers: Converge ICT, Eastern Telecom, Globe, PLDT, Radius",
      "ceilingHeight": "2.8 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: For approval",
      "remarks": "No AC extension charge"
    },
    {
      "buildingName": "Frabelle Corporate Plaza",
      "businessDistrict": "Makati",
      "address": "129 Tordesillas Street corner Bautista Street, Salcedo Village, Makati City",
      "developer": "Frabelle Fishing Corporation",
      "yearConstructed": "Completion 4Q 2018",
      "certifications": "Non-PEZA, For LEED Certification",
      "totalFloors": 16,
      "availableSpace": "PH - 800 sqm",
      "density": "Density of 1:6.5 (based on GLA)",
      "floorEfficiency": "79% Efficiency",
      "handoverCondition": "PH - Semi-fitted",
      "acSystem": "Variable Refrigerant Flow System",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,300",
      "cusa": "PhP 190",
      "parkingRate": " PhP 6,000",
      "lastUpdated": "October 18, 2023",
      "buildingGrade": "Grade A",
      "grossLeasableArea": "GLA of 6,731.29 sqm",
      "floorPlate": "828.23 sqm floor plate",
      "netUseableArea": "655.67 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "3 Passenger Elevators\r\n1 Service Elevator",
      "telcoProviders": "Telco Providers: PLDT, Globe",
      "ceilingHeight": "3.0 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:57",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "Earlist Fitout date : June 2018"
    },
    {
      "buildingName": "Armstrong Corporate Center",
      "businessDistrict": "Makati",
      "address": "134 H.V. Dela Costa St. Salcedo Village, Makati City",
      "developer": "Gel Realty Development Corporation",
      "yearConstructed": "4Q 2020",
      "certifications": "Non-PEZA",
      "totalFloors": 14,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "1:6",
      "floorEfficiency": 0.82,
      "handoverCondition": "Warm Shell",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "To be determined",
      "quotedRent": "Other floors – PhP 1,200\r\nPH – PhP 1,500 to PhP 1,600",
      "cusa": "To be determined",
      "parkingRate": "PhP 5,500",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 6,957.79 sqm",
      "floorPlate": "993.97 sqm floor plate",
      "netUseableArea": "Approx. 882.85 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "4 Passenger Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "4.0 meters ceiling height ",
      "parkingAllocation": "Parking Allocation 1:100",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "2F to  6F: Five (5) parking levels\r\n7F to 13F: Seven  (7) office floors"
    },
    {
      "buildingName": "Executive Building",
      "businessDistrict": "Makati",
      "address": "Sen. Gil Puyat corner Makati Avenue,",
      "developer": "Peninsula Enterprise & Realty Development Corp.",
      "yearConstructed": 1988,
      "certifications": "Non-PEZA",
      "totalFloors": 6,
      "availableSpace": "2F Unit 1 – 265.00 sqm\r\n3F Unit 0 – 50.00 sqm\r\n3F Unit 1 – 200.00 sqm\r\n3F Unit 3 – 65 sqm\r\n4F Unit 1 – 68 sqm\r\n4F Unit 2 – 110.00 sqm\r\n4F Unit 9A2 – 60.00 sqm\r\n4F Unit 9B – 90.00 sqm",
      "totalAvailableSpace": 908,
      "density": "1:7",
      "floorEfficiency": "Approx. 75%",
      "handoverCondition": "Semi-Fitted",
      "acSystem": "GF – Tenant to provide\r\nOffice Floors – Centralized Chilled Water AC",
      "acCharges": "Regular Hours (8:00 AM to 5:00 PM) – Pro-rated\r\nExtension Charge: Install own AC unit",
      "quotedRent": "PhP 800",
      "cusa": "PhP 220",
      "parkingRate": "PhP 5,900",
      "lastUpdated": "May 30, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of 8,766 sqm ",
      "floorPlate": "1,600 sqm floor plate",
      "netUseableArea": "1,200 sqm net usable",
      "backupPower": "75% Backup Power",
      "elevators": "2 Elevators",
      "telcoProviders": "Telco Providers: PLDT",
      "ceilingHeight": "3.5 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "Allows 24 hour operations (except for BPOs) -- for IT firms only\r\nBuilding does NOT allow serviced office\r\nHas provision for back up AC\r\nParking Allocation of 1 slot per unit"
    },
    {
      "buildingName": "Tech Zone Building",
      "businessDistrict": "Makati",
      "address": "7071 Malugay Street, Makati City",
      "developer": "SM Prime Holdings Inc.",
      "yearConstructed": "Completion 2015",
      "certifications": "PEZA Certified",
      "totalFloors": 28,
      "availableSpace": "22F Units 3 & 4 – 1,241.04 sqm",
      "totalAvailableSpace": 1241.04,
      "density": "Density of 1:6",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition \r\n10F, 11F – As is where is\r\n19F – Bare Shell\r\n22F, 27F – Semi-Fitted\r\n23F – Semi-Warm Shell\r\nPH – Fully Fitted (chairs & tables)",
      "acSystem": "Variable Refrigerant Flow System",
      "acCharges": " Metered",
      "quotedRent": "PhP 1,000",
      "cusa": "PhP 180",
      "parkingRate": " PhP 5,105.13",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 46,484.85 sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "16 Passenger Elevators",
      "telcoProviders": "Telco Providers: Converge, ETPI, Globe, Philcom, PLDT, Radius, Sky Cable, Source",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 8 slots per floor",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "Strata building"
    },
    {
      "buildingName": "Enzo Building",
      "businessDistrict": "Makati",
      "address": "399 Sen. Gil Puyat Ave., Brgy. Bel-Air, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion 2000's",
      "certifications": "Non-PEZA",
      "totalFloors": 5,
      "availableSpace": "GF  – 464 sqm (Sublease for min. 1 year\r\nSublessor: SOFA)\r\nCuts:\r\n267 sqm\r\n197 sqm\r\n\r\nEASY OFFICE (Serviced Office)\r\n3F – Approx. 220 sqm (Seat Count to follow)",
      "totalAvailableSpace": 464,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Fully Fitted (chairs & tables)",
      "acSystem": "To be determined AC System",
      "acCharges": "\r\nGF – To be determined\r\n3F – Included in rent",
      "quotedRent": "GF – PhP 672\r\n\r\n3F Serviced office – Php 310,000/month",
      "cusa": "\r\nGF – PhP 168\r\n3F – Included in rent",
      "parkingRate": " \r\n3F – Included in rent (1 slot)",
      "lastUpdated": "Enzo Building- December 20, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation \r\nGF – To be determined \r\n3F – 1 slot (additional slots is subject for availability)",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Accelerando Building",
      "businessDistrict": "Makati",
      "address": "Sen. Gil Puyat Avenue,\r\nMakati City",
      "developer": "Hiipin Group Ventures",
      "yearConstructed": "Completion 1980",
      "certifications": "Non-PEZA",
      "totalFloors": 6,
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "Pro-rated",
      "quotedRent": "\r\nWhole floor taker – PhP 680\r\nHalf floor taker – PhP 750",
      "cusa": "N/A",
      "parkingRate": " PhP 4,000",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of 12,000 sqm",
      "floorPlate": "1,200 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "2 Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: ",
      "remarks": "Building does now allow serviced office"
    },
    {
      "buildingName": "Justine Building",
      "businessDistrict": "Makati",
      "address": "317 Sen. Gil Puyat Avenue, Makati City",
      "developer": "United Philippines Realty Corporation",
      "yearConstructed": "Completion 1990's",
      "certifications": "Non-PEZA",
      "totalFloors": 9,
      "availableSpace": "GF – 630.90 sqm\r\n2F to 8F – Approx. 676.5 sqm/floor\r\nPH – 247.20 sqm\r\nOpen space – 154.20 sqm \r\n(on the rooftop)\r\n\r\n(Can entertain multi-tenants but prefers whole floor takers) \r\n\r\n(PREFERS WHOLE BLDG TAKERS)\r\n",
      "totalAvailableSpace": 5613.599999999999,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition \r\nOther floors – As is where is\r\n6F, 7F, PH – Semi-Fitted",
      "acSystem": "Centralized Chilled Water AC System",
      "acCharges": " Metered ",
      "quotedRent": "Rent (PSM):\r\n7M",
      "cusa": "To be determined",
      "parkingRate": " PhP 5,500",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of Approx. 5,767.8 sqm",
      "floorPlate": "Approx. 676.5 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "80% (not including the AC)  Backup Power",
      "elevators": "3 Passenger Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:120",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "50 parking slots"
    },
    {
      "buildingName": "ALCO Building",
      "businessDistrict": "Makati",
      "address": "Sen. Gil Puyat Avenue,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "To be determined AC System",
      "acCharges": "To be determined",
      "quotedRent": "PhP 700",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "PSI Building",
      "businessDistrict": "Makati",
      "address": "252 Urban Avenue, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 5,
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "As is where is",
      "acSystem": "Tenant to Provide AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 550",
      "cusa": "To be determined",
      "parkingRate": " Not Applicable",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "Approx. 550 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "1 Passenger Elevator",
      "telcoProviders": "Telco Providers: Provision for other telco providers",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation First come, first serve",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Burgundy Corporate Tower",
      "businessDistrict": "Makati",
      "address": "Sen. Gil Puyat Avenue,\r\nMakati City",
      "developer": "SM Prime Holdings",
      "yearConstructed": "Completion 2002",
      "certifications": "PEZA Certified",
      "totalFloors": 38,
      "availableSpace": "2F Unit A – 47.04 sqm\r\n11F Unit G – 126.54 sqm\r\n12F Unit E – 64.80 sqm\r\n17F Unit F – 60.48 sqm\r\n19F Unit G – 126.54 sqm\r\n20F Unit E – 64.80 sqm\r\n20F Unit P – 126.61 sqm\r\n21F Unit B – 65.39 sqm\r\n21F Unit AR02 – 46.87 sqm\r\n21F Unit AR03 – 45.25 sqm\r\n21F Unit IJ02 – 51.17 sqm\r\n38F Unit E – 125.50 sqm",
      "totalAvailableSpace": 950.99,
      "density": "Density of To be determined",
      "floorEfficiency": "87% Efficiency",
      "handoverCondition": "Handover Condition\r\n2F, 21F – Warm Shell\r\nOther floors – Semi-Fitted",
      "acSystem": "Tenant to Provide AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 500",
      "cusa": "\r\n8 hours – PhP 80\r\n24/7 – PhP 125 ",
      "parkingRate": " PhP 5,000",
      "lastUpdated": "December 6, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of 60,838 sqm",
      "floorPlate": "1,601 sqm floor plate",
      "netUseableArea": "1,392.87 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "3 Passenger Elevators (Low Rise)\r\n3 Passenger Elevators (High Rise)\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "2.6 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of Subject to Availability",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "\r\nBDO properties – no online gaming tenants allowed\r\n24/7 operations allowed"
    },
    {
      "buildingName": "Export Bank Plaza",
      "businessDistrict": "Makati",
      "address": "Sen. Gil Puyat Avenue",
      "developer": "Geo Estate Development Corp.",
      "yearConstructed": 1998,
      "certifications": "PEZA Certified",
      "totalFloors": 37,
      "availableSpace": "Basement Units 2 to 5 – 998.19 sqm\r\n9F to 11F, 14F to 21F – 1,274.87 sqm\r\n12F – 1,121 sqm\r\n24F – 1,277.02 sqm\r\n25F – 1,277.89 sqm\r\n32F Unit 3 – 252.27 sqm\r\n32F Unit 5 – 256.45 sqm\r\n37F – 1,244.60 sqm\r\nPH – 381.66 sqm",
      "totalAvailableSpace": 20833.209999999992,
      "density": "To be determined",
      "floorEfficiency": 0.85,
      "handoverCondition": "Warm Shell\r\n25F – Semi-Fitted",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Metered (8:00 AM to 7:00 PM)\r\nExtension Charge to be finalized",
      "quotedRent": "PhP 1,102.50",
      "cusa": "PH – 381.66 sqm\r\nPhP 85",
      "parkingRate": "PhP 3,500",
      "lastUpdated": "Nov 14, 2023 (Availability of 9F - 11F, 14F - 21F, 12F, 24F, 25F)",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 40,610.98 sqm ",
      "floorPlate": "1,102.21 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "12 Elevators",
      "telcoProviders": "Telco Providers: PLDT, Smart, Globe, Sun",
      "ceilingHeight": "2.6 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:70",
      "twentyFourSevenOperations": "24/7 Operations: ",
      "remarks": "18F and 21F are owned by EIB."
    },
    {
      "buildingName": "M1 Tower",
      "businessDistrict": "Makati",
      "address": "141 H.V. Dela Costa Street, Salcedo Village",
      "developer": "Brighthall Realty & Development Corporation",
      "yearConstructed": "1Q 2018",
      "certifications": "Non-PEZA",
      "totalFloors": 16,
      "availableSpace": "16F LPH – 1,069.75 sqm\r\nRoofdeck – 832.46 sqm\r\n\r\n16F LPH cannot be subdivided",
      "totalAvailableSpace": 1902.21,
      "density": "1:6",
      "floorEfficiency": 0.83,
      "handoverCondition": "16F – Fully Fitted\r\nRoofdeck – As is where is",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": "Metered",
      "quotedRent": "16F – PhP 1,400\r\nRoofdeck – PhP 700",
      "cusa": "PhP 185",
      "parkingRate": "PhP 6,000",
      "lastUpdated": "December 14, 2023",
      "buildingGrade": "Grade B+",
      "grossLeasableArea": "GLA of 8,920.69 sqm",
      "floorPlate": "1,069.75 sqm floor plate",
      "netUseableArea": "888.17 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "4 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Multiple Providers",
      "ceilingHeight": "3 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "GF and Partners",
      "businessDistrict": "Makati",
      "address": "139 H.V. Dela Costa Street, Salcedo Village, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion Renovated in 2018",
      "certifications": "Non-PEZA",
      "totalFloors": 6,
      "availableSpace": "6F – 533.19 sqm",
      "density": "Density To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Semi-Fitted",
      "acSystem": "Variable Refrigerant Flow System",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,000",
      "cusa": "PhP 150",
      "parkingRate": " To be determined",
      "buildingGrade": "To be determined",
      "grossLeasableArea": "GLA To be determined",
      "floorPlate": "To be determined floor plate",
      "netUseableArea": "To be determined net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined ceiling height",
      "parkingAllocation": "Parking Allocation To be determined",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Maria Cornelia Building",
      "businessDistrict": "Makati",
      "address": "Sen. Gil Puyat Avenue\r\nMakati City",
      "developer": "Go Soc & Sons and Sy Gui Huat,  Inc.",
      "yearConstructed": 2003,
      "certifications": "Non-PEZA",
      "totalFloors": 6,
      "availableSpace": "ON HOLD, SPACES FOR RETROFITTING\r\n2F – 1,277 sqm\r\n5F – 688.50 sqm\r\n6F – 1,377 sqm\r\n\r\nON HOLD\r\nGF – 328 sqm (under renovation)\r\n\r\nFloors cannot be subdivided",
      "totalAvailableSpace": 2492.5,
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "Bare Shell",
      "acSystem": "Tenant to Provide",
      "acCharges": "Regular Hours (8:00 AM to 5:00 PM) – Metered",
      "quotedRent": "GF – PhP 1,500\r\n2F – PhP 800\r\nOther floors – PhP 750",
      "cusa": "PhP 150",
      "parkingRate": "PhP 4,000",
      "lastUpdated": "May 4, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of 7,400 sqm ",
      "floorPlate": "1,329 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "2 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: PLDT",
      "ceilingHeight": "3.1 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:400",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "New Solid Building",
      "businessDistrict": "Makati",
      "address": "Sen. Gil Puyat Avenue,\r\nMakati City",
      "developer": "New Solid Realty Corporation",
      "yearConstructed": "Completion 1972",
      "certifications": "Non-PEZA",
      "totalFloors": 6,
      "availableSpace": "4F -1,158 sqm\r\nc/o Mikko & Jo",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition \r\n4F – Semi-Fitted",
      "acSystem": "Split Type AC",
      "acCharges": " Regular Hours (7:00 AM to 5:00 PM) – Metered",
      "quotedRent": "\r\nGF – PhP 1,250\r\n2F, 3F – PhP 550\r\n4F – PhP 640,000 (monthly)",
      "cusa": "PhP 50\r\n4F – PhP 65,000 (monthly)",
      "parkingRate": " \r\nOther floors – PhP 4,000\r\n4F – PhP 4,500 ",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of 7,200 sqm ",
      "floorPlate": "1,200 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "2 Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT, Eastern Telecom",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of \r\nOther floors – 1:100\r\n4F – 10 slots ",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "No frontage\r\nParking subject to availability "
    },
    {
      "buildingName": "Pacific Star",
      "businessDistrict": "Makati",
      "address": "Makati Avenue corner Sen. Gil Puyat Avenue",
      "developer": "Century Properties",
      "yearConstructed": 1989,
      "certifications": "PEZA",
      "totalFloors": 28,
      "availableSpace": "BDO FLOORS: \r\n4F Unit 1 – 263.80 sqm\r\n4F Unit  2 – 262.49 sqm\r\n4F Unit 3 – 177 sqm\r\n4F Unit 4 - 120.34\r\n17F – 370.91 sqm\r\n17F – 679.63 sqm\r\n19F - 1,184.47\r\n29F – 363.19 sqm\r\n\r\nKEYLAND FLOORS: \r\n24F – 1,884.47 sqm\r\n\r\nTiptip\r\n2F -545 SQM: ",
      "totalAvailableSpace": 6006.3,
      "density": "To be determined",
      "floorEfficiency": 0.87,
      "handoverCondition": "Semi-Fitted",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Regular Hours (8:00 AM to 6:00 PM) – PhP 78\r\nExtension Charge – PhP 1,900 /hour",
      "quotedRent": "2F – PhP 700\r\nOthers – PhP 850",
      "cusa": "2F – PhP 175.50\r\nOthers – PhP 86 + 11.50 (Water)",
      "parkingRate": "2F – PhP 3,500\r\nOthers – PhP 6,000",
      "lastUpdated": "BDO – December 7, 2023\r\nKeyland – November 14, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 28,000 sqm",
      "floorPlate": "1,800 sqm floor plate",
      "netUseableArea": "1,566 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "15 Passenger Elevators\r\n2 Service Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "2.45 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:130",
      "twentyFourSevenOperations": "24/7 Operations: no"
    },
    {
      "buildingName": "ITC Building",
      "businessDistrict": "Makati",
      "address": "Sen. Gil Puyat Avenue,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion 1975",
      "certifications": "Non-PEZA",
      "totalFloors": 6,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "Included in electricity charge",
      "quotedRent": "PhP 500\r\n",
      "cusa": "See remarks",
      "parkingRate": " \r\nOpen Parking – Php 3,500\r\nBasement Parking – Php 4,000",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined sqm ",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "2 Passenger Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "Water charge – PhP 37\r\nElectricity charge – Php 130 to 160\r\nAC hours: 8 AM to 5 PM\r\nAC Charge for after 5 PM: PhP 1,450 per hour\r\n\r\nDoes not allow 24 hour operations\r\nOffice tenants only"
    },
    {
      "buildingName": "Oppen Building",
      "businessDistrict": "Makati",
      "address": "Sen. Gil Puyat Avenue,\r\nMakati City",
      "developer": "Welding Industries of the Philippines",
      "yearConstructed": "Completion 1980",
      "certifications": "Non-PEZA",
      "totalFloors": 6,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Tenant to Provide AC",
      "acCharges": "Metered",
      "quotedRent": "\r\n2F Unit 203 – PhP 600\r\n2F Unit 204 – PhP 650",
      "cusa": "PhP 65",
      "parkingRate": " PhP 1,800",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of 12,000 sqm ",
      "floorPlate": "2,000 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "2 Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "Does not allow 24 hour operations\r\nOnline gaming tenants not allowed"
    },
    {
      "buildingName": "Goodland IT Center",
      "businessDistrict": "Makati",
      "address": "Sen. Gil Puyat Avenue,\r\nMakati City",
      "developer": "Goodland Corporation",
      "yearConstructed": "Completion To be determined",
      "certifications": "PEZA Certified",
      "totalFloors": 6,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "85% Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Split Type AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 600",
      "cusa": "\r\nRegular – PhP 45\r\n24/7 Operation – PhP 70 ",
      "parkingRate": " PhP 3,500",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 7,200 sqm ",
      "floorPlate": "1,020 sqm floor plate",
      "netUseableArea": "867 sqm net usable",
      "backupPower": "50% Backup Power",
      "elevators": "2 Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: ",
      "remarks": "2 slots available\r\n\r\nBasement unit allows retail except food"
    },
    {
      "buildingName": "BPI Buendia Center",
      "businessDistrict": "Makati",
      "address": "Sen. Gil Puyat Avenue,\r\nMakati City",
      "developer": "BPI Family Bank",
      "yearConstructed": "Completion 1995",
      "certifications": "PEZA Certified",
      "totalFloors": 27,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "88% Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": "To be determined",
      "quotedRent": "To be determined",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 41,942 sqm ",
      "floorPlate": "1,200 sqm floor plate",
      "netUseableArea": "1,056 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "9 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "2.65 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Mondragon House",
      "businessDistrict": "Makati",
      "address": "Sen. Gil Puyat Avenue,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "To be determined AC",
      "acCharges": "To be determined",
      "quotedRent": "To be determined",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined",
      "floorPlate": "To be determined floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Petron Megaplaza",
      "businessDistrict": "Makati",
      "address": "Sen. Gil Puyat Avenue,\r\nMakati City",
      "developer": "Megaworld Corp.",
      "yearConstructed": "Completion 1998",
      "certifications": "Non-PEZA",
      "totalFloors": 45,
      "availableSpace": "BDO Floors:\r\n9F – 494.92 sqm\r\n14F Unit E & F – 264.64 sqm\r\n",
      "totalAvailableSpace": 759.56,
      "density": "Density of 1:9 (based on net)",
      "floorEfficiency": "77% Efficiency",
      "handoverCondition": "Handover Condition  Semi-Fitted",
      "acSystem": "Centralized Chilled Water AC",
      "acCharges": " Regular Hours (7:00 AM to 6:00 PM) –  Metered",
      "quotedRent": "\r\nBDO Floors – PhP 1,000\r\nOthers – PhP 950",
      "cusa": 210,
      "parkingRate": "  PhP 5,500",
      "lastUpdated": "December 12, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 52,044.82 sqm ",
      "floorPlate": "1,530 sqm floor plate",
      "netUseableArea": "1,178.10 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "16 Passenger Elevators\r\n2 Service Elevators",
      "telcoProviders": "Telco Providers: Eastern Telecom, Globe, PLDT",
      "ceilingHeight": "2.6 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation\r\n15F – 3 slots",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "AC Extension: \r\nWeekdays – P1,000/sqm/hr\r\nWeekends and holidays – P1,300 / hr\r\n\r\nBuilding allows serviced office but NOT 24/7 operations\r\n\r\nParking Allocation: \r\n12F – to be determined\r\n11F, 22F to 23F – 23 slots, with 2 slots free"
    },
    {
      "buildingName": "Phil Axa Life Center",
      "businessDistrict": "Makati",
      "address": "Sen. Gil Puyat Avenue,\r\nMakati City",
      "developer": "Federal Land Properties",
      "yearConstructed": 1996,
      "certifications": "Non-PEZA",
      "totalFloors": 25,
      "availableSpace": "Federal Land:\r\n\r\n23F – 993 sqm\r\n25F – 250.56 sqm\r\n26F – 437.82 sqm\r\n26F – 555.31 sqm\r\n27F – 993.13 sqm",
      "totalAvailableSpace": 3229.8199999999997,
      "density": "1:6",
      "floorEfficiency": 0.85,
      "handoverCondition": "Others – Warm Shell\r\n10F – Semi-Fitted",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Regular Hours (8:00 AM to 6:00 PM) Metered\r\nExtension Charge – PhP 675/ hour",
      "quotedRent": "PhP 750",
      "cusa": "PhP 137",
      "parkingRate": "PhP 3500",
      "lastUpdated": 45133,
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 24,875 sqm ",
      "floorPlate": "993 sqm floor plate",
      "netUseableArea": "844.05 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "6 Elevators",
      "telcoProviders": "Telco Providers: PLDT, Globe, Philcom, Eastern",
      "ceilingHeight": "2.3 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation \r\n10F – 1 slot\r\n26F – Subject to availability",
      "twentyFourSevenOperations": "24/7 Operations: Yes "
    },
    {
      "buildingName": "The World Centre",
      "businessDistrict": "Makati",
      "address": "330 Sen. Gil Puyat",
      "developer": "Megaworld Corp.",
      "yearConstructed": 1997,
      "certifications": "Non-PEZA",
      "totalFloors": 30,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of 1:5.6",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition \r\nGF Annex – Warm Shell\r\n3F – As is where is (with ceiling works and smooth cement ready for carpet tiles)",
      "acSystem": "(Tenant to provide) Centralized Chilled Water AC ",
      "acCharges": " Regular Hours (8:00 AM to 5:00 PM) – Metered\r\nExtension Charge – PhP 700 /hour",
      "quotedRent": "PhP 900",
      "cusa": "PhP 164 (double check)",
      "parkingRate": " PhP 5,000",
      "lastUpdated": "November 16, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of approx. 21,735 sqm ",
      "floorPlate": "Approx. 724.50 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "6 Passenger Elevators\r\n2 Service Elevators\r\n1 Parking Elevators",
      "telcoProviders": "Telco Providers: Eastern Telecom, Globe, PLDT",
      "ceilingHeight": "3F Annex – 2.5\r\nOther floors – 2.6 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:100",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "AC 8am to 6pm (AC Extension Charge is at PhP 700 per hour)\r\n\r\nServiced Office tenants subject for approval"
    },
    {
      "buildingName": "331 Building",
      "businessDistrict": "Makati",
      "address": "Sen. Gil Puyat Avenue",
      "developer": "Megaworld Corp.",
      "yearConstructed": 2015,
      "certifications": "Non-PEZA",
      "totalFloors": 5,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "1:5.6",
      "floorEfficiency": 0.9,
      "handoverCondition": "Semi – Fitted",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": "Metered",
      "quotedRent": "PhP 900",
      "cusa": "PhP 170",
      "parkingRate": " PhP 5,000",
      "lastUpdated": "November 16, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of Approx. 8,000 sqm",
      "floorPlate": "1,406.94 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "2 Passenger Elevators",
      "telcoProviders": "Telco Providers: Cconverge, Eastern, Infinivan, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:227",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "Online gaming tenants allowed\r\n24 hr operations allowed"
    },
    {
      "buildingName": "Makati Commerce Tower",
      "businessDistrict": "Makati",
      "address": "Sen Gil Puyat corner Chino Roces Avenue",
      "developer": "Empresas Diesel Development Inc",
      "yearConstructed": "3Q 2022",
      "certifications": "Under Application for PEZA, LEED Certified",
      "totalFloors": "37 \r\n(25 office floors)",
      "availableSpace": "12F – 2,178.20 sqm\r\n15F to 24F – 2,178.20 sqm/floor\r\n25F – 2,120.29 sqm\r\n26F to 36F – 2,251.57 sqm/floor\r\n\r\n\r\n12F may be subdivided into multi-cuts\r\nOther floors – Quadrant cuts",
      "totalAvailableSpace": 50847.76,
      "density": "1:6",
      "floorEfficiency": 0.89,
      "handoverCondition": "Bare Shell",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Regular Office Hours (8:00 AM – 5:00 PM): Inclusive in CUSA\r\nAfter Office Hours Charges: P32.00 x FCU Tonnage x Hour\r\n24/7 Operation - PhP 105",
      "quotedRent": " PhP 1,300",
      "cusa": "PhP 180",
      "parkingRate": "PhP 7,000",
      "lastUpdated": "November 8, 2023",
      "buildingGrade": "Grade B+",
      "grossLeasableArea": "GLA of 58,395.95 sqm",
      "floorPlate": "Mid Zone – 2,157.72 sqm floor plate\r\nHigh Zone – 2,099.84 sqm to 2,227.88 sqm floor plate",
      "netUseableArea": "Approx. 1,900 to 1,970 sqm net usable",
      "backupPower": "N+1  Backup Power",
      "elevators": "16  Passenger Elevators\r\n2 Service Elevators\r\n2 Parking Elevators\r\n1 Retail Elevators",
      "telcoProviders": "Telco Providers: Provision for 4 providers",
      "ceilingHeight": "2.8 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation: 1:100",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Filinvest Buendia",
      "businessDistrict": "Makati",
      "address": "387 Sen. Gil Puyat Avenue",
      "developer": "Filinvest Land",
      "yearConstructed": "April 2024",
      "certifications": "Non-PEZA",
      "totalFloors": 14,
      "availableSpace": "6F to 15F – 1,112.24 sqm/floor\r\n\r\n\r\nFloors can be subdivided into half only",
      "totalAvailableSpace": 10010.16,
      "density": "1:5",
      "floorEfficiency": "To be determined",
      "handoverCondition": "Bare Shell with VRF AC",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": "PhP 80 PSM",
      "quotedRent": "PhP 1,100\r\n(might need to increase the rent for POGO clients))",
      "cusa": "PhP 185",
      "parkingRate": "PhP 5,500",
      "lastUpdated": "December 4, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 11,264.61 sqm",
      "floorPlate": "1,145.78 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "N+1 Backup Power",
      "elevators": "5 Passenger Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 141 slots",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "14F & 15F are pre-committed"
    },
    {
      "buildingName": "The Yuchengco Centre",
      "businessDistrict": "Makati",
      "address": "333 Sen. Gil Puyat Avenue, Bel-Air",
      "developer": "San Lorenzo Ruiz Investment Holdings and Services, Inc.",
      "yearConstructed": "3Q 2025",
      "certifications": "LEED & WELL Certified",
      "totalFloors": 27,
      "availableSpace": "9F to 16F - 4,070.19 sqm\r\n17F to 21F - 4,119.16 sqm\r\n22F - 4,079.16 sqm\r\n23F to 24F - 4,183.41 sqm",
      "totalAvailableSpace": 61533.91,
      "floorEfficiency": 0.85,
      "handoverCondition": "Warm Shell",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": "To be finalized",
      "quotedRent": "To be finalized",
      "cusa": "To be finalized",
      "parkingRate": "To be finalized",
      "lastUpdated": "December 14, 2023",
      "buildingGrade": "To be determined",
      "grossLeasableArea": "GLA of 83,048",
      "floorPlate": "Approx. 4,100 sqm floor plate",
      "netUseableArea": "Approx. 3,500 sqm net usable ",
      "backupPower": "100% Backup Power",
      "elevators": "To be determined",
      "telcoProviders": "Telco Providers: Eastern Telcom, Globe, PLDT",
      "ceilingHeight": "To be determined",
      "parkingAllocation": "Parking Allocation 1:100",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Tara Building",
      "businessDistrict": "Makati",
      "address": "389 Sen. Gil Puyat Avenue",
      "developer": "To be determined",
      "yearConstructed": 1980,
      "certifications": "Non-PEZA",
      "totalFloors": 4,
      "availableSpace": "GF – 850 sqm\r\n2F - 1,071 sqm\r\n4F - 990 sqm\r\n\r\nGF can be subdivided into half",
      "totalAvailableSpace": 2911,
      "handoverCondition": "To be determined",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Metered",
      "quotedRent": "GF - PhP 1,800\r\nPhP 1,300",
      "cusa": "PhP 150",
      "parkingRate": "PhP 3,000",
      "lastUpdated": "September 18, 2023",
      "buildingGrade": "To be determined",
      "floorPlate": 1109.25,
      "parkingAllocation": "Parking Allocation 1:150"
    },
    {
      "buildingName": "134 Jupiter",
      "businessDistrict": "Makati",
      "address": "134 Jupiter St., Bel-Air, Makati City",
      "developer": "To be determined",
      "yearConstructed": "To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 5,
      "availableSpace": "3F – 398.37 sqm\r\n4F – 398.37 sqm",
      "totalAvailableSpace": 796.74,
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "Warm Shell",
      "acSystem": "Split Type",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,200",
      "cusa": "PhP 160",
      "parkingRate": "PhP 10,000",
      "lastUpdated": "November 29, 2023",
      "buildingGrade": "To be determined",
      "backupPower": "100% Backup Power",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "EHC Building",
      "businessDistrict": "Makati",
      "address": "100 Jupiter Street, Corner Makati Avenue, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 4,
      "availableSpace": "3F – 150 sqm",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Tenant to Provide AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 650",
      "cusa": "Included in rent",
      "parkingRate": "PhP 6,000",
      "lastUpdated": "July 28, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined sqm ",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation \r\n3F – 1 slot",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Jupiter Street cor. Antares",
      "businessDistrict": "Makati",
      "address": "Jupiter Street corner Antares Street, \r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Tenant to Provide AC",
      "acCharges": "Metered",
      "quotedRent": "Php 650",
      "cusa": "N/A",
      "parkingRate": " Inclusive in rent",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm ",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: ",
      "remarks": "Inclusive of 1 parking slot"
    },
    {
      "buildingName": "50 Jupiter Street",
      "businessDistrict": "Makati",
      "address": "Jupiter Street,\r\nMakati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": " ",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Tenant to Provide AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 580",
      "cusa": "PhP 100",
      "parkingRate": " PhP 3,000",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm ",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "1 Elevator",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "Serviced Office and 24/7 operations allowed"
    },
    {
      "buildingName": "58 Jupiter",
      "businessDistrict": "Makati",
      "address": "58 Jupiter Street, Makati City",
      "developer": "Aseana Holdings, Inc.",
      "yearConstructed": "3Q 2021",
      "certifications": "Non-PEZA",
      "totalFloors": 5,
      "availableSpace": "4F – 425.09 sqm\r\nCuts:\r\nUnit 1 – 109.13 sqm\r\nUnit 2 – 106.96 sqm\r\nUnit 3 – 94.13 sqm\r\nUnit 4 – 114.87 sqm\r\n",
      "totalAvailableSpace": 425.09,
      "density": "1:6",
      "floorEfficiency": 0.66,
      "handoverCondition": "Bare Shell",
      "acSystem": "Split Type",
      "acCharges": "Metered",
      "quotedRent": "GF – PhP 2,200\r\nOffice Floors – PhP 1,300",
      "cusa": "PhP 170",
      "parkingRate": "PhP 5,000",
      "lastUpdated": "July 28, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of 2,433.47 sqm ",
      "floorPlate": "486.69 sqm floor plate",
      "netUseableArea": "320.76 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "1 Passenger Elevator",
      "telcoProviders": "Telco Providers: Provision for one telco provider",
      "ceilingHeight": "3.3 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:100",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "Jupiter Place",
      "businessDistrict": "Makati",
      "address": "136 – 138 Jupiter Street, Bel-Air",
      "developer": "Felton Properties Inc",
      "yearConstructed": "4Q 2023",
      "certifications": "Non-PEZA",
      "totalFloors": 5,
      "availableSpace": "2F – 827.43 sqm\r\n3F & 4F – 915.44 sqm/floor\r\n5F – 887.17 sqm\r\n\r\n2F Cuts\r\nUnit 1 – 241.10 sqm\r\nUnit 2 – 220.72 sqm\r\nUnit 3 – 229.01 sqm\r\nUnit 4 – 248.95 sqm\r\n3F & 4F Cuts\r\nUnit 1 – 292.12 sqm\r\nUnit 2 – 245.75 sqm\r\nUnit 3 – 215.25 sqm\r\nUnit 4 – 234.19 sqm\r\n5F – 887.17 sqm\r\n\r\nAdjacent units can be combined",
      "totalAvailableSpace": 2914.4,
      "floorEfficiency": 0.9,
      "handoverCondition": "Bare Shell",
      "acSystem": "Single Split Concealed Unit",
      "acCharges": "Inclusive in CUSA",
      "quotedRent": "PhP 1,300",
      "cusa": "PhP 180",
      "parkingRate": "PhP 5,000",
      "lastUpdated": "December 14, 2023",
      "buildingGrade": "To be determined",
      "grossLeasableArea": "4,727.58 sqm",
      "floorPlate": "Approx 945 sqm",
      "netUseableArea": "Approx 848",
      "backupPower": "100% Backup Power",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "JP Rizal",
      "businessDistrict": "Makati",
      "address": "705 JP Rizal Ave., Barangay Valenzuela, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Tenant to Provide AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 500",
      "cusa": "PhP 80",
      "parkingRate": " PhP 3,500",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm ",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "YL Holdings",
      "businessDistrict": "Makati",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "To be determined AC",
      "acCharges": "To be determined",
      "quotedRent": "PhP 500",
      "cusa": "PhP 100",
      "parkingRate": " To be determined",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of To be determined sqm ",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Riverbend Building",
      "businessDistrict": "Makati",
      "address": "1869 P. Domingo, Makati",
      "developer": "BJS Development Corporation",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "2F Unit 2B -120 sqm\r\n2F Unit 202B – 120 sqm\r\n2F Unit 204 -114 sqm\r\n4F Units 1 to 3 – 411.15 sqm",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "As-is, Where-is",
      "acSystem": "To be determined AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 439.89",
      "cusa": "PhP 85",
      "parkingRate": " To be determined",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of To be determined sqm ",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Gomez Building",
      "businessDistrict": "Makati",
      "address": "954 JP Rizal Street corner Gomez Street, Poblacion, Makati City",
      "developer": "Marnol Realty",
      "yearConstructed": "Completion 4Q 2015",
      "certifications": "Non-PEZA",
      "totalFloors": 17,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "88% Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Variable Refrigerant Flow System",
      "acCharges": "Metered",
      "quotedRent": "PhP 700",
      "cusa": "PhP 160",
      "parkingRate": " PhP 3,500",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 12,987.53 sqm",
      "floorPlate": "Approx. 710 sqm floor plate",
      "netUseableArea": "Approx. 624.8 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "5 Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 97 slots in total",
      "twentyFourSevenOperations": "24/7 Operations: ",
      "remarks": "Option for warm shell handover condition available\r\nOpen for online gaming tenants\r\nDoes not allow 24/7 operations"
    },
    {
      "buildingName": "Circuit Corporate Center 1",
      "businessDistrict": "Makati",
      "address": "J.P. Rizal Avenue, Circuit City",
      "developer": "Ayala Land",
      "yearConstructed": "4Q 2017",
      "certifications": "PEZA Certified",
      "totalFloors": 25,
      "availableSpace": "\r\n\t5F – 1,458.34 sqm\r\n\t6F – 1,452.22 sqm/floor\r\n\t16F – 2,398.59 sqm\r\n\t17F – 2,438.19 sqm\r\n\t18F to 20F – 2,476.95 sqm/floor\r\n24F & 25F – 2,326.74 sqm/floor\r\n(May accommodate cuts)",
      "totalAvailableSpace": 35904.31,
      "density": "1:5 ",
      "floorEfficiency": 0.97,
      "handoverCondition": "\r\n5F, 6F, 8F, 16F, 19F - Semi-Fitted",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Metered",
      "quotedRent": "PhP 850\r\n",
      "cusa": "PhP 200",
      "parkingRate": "PhP 5,500",
      "lastUpdated": "November 8, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 47,143 sqm ",
      "floorPlate": "Low Zone : 2,426.53  sqm floor plate\r\nHigh Zone : 2,344.71 sqm floor plate",
      "netUseableArea": "2,353.73 to 2,274.37 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "14 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of \r\nUGF to L5 – Subject to availability\r\nOffice floors – 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "Mall Area:\r\nUGF – 2,192.96 sqm\r\nUGF – 1,538.74 sqm\r\nL2 – 1,492.93 sqm\r\nL3 – 1,433.10 sqm\r\nL4 – 491 sqm\r\nL5 – 794 sqm\r\n(Need confirmation for availabilities)"
    },
    {
      "buildingName": "Circuit Corporate Center 2",
      "businessDistrict": "Makati",
      "address": "J.P. Rizal Avenue, Circuit City",
      "developer": "Ayala Land",
      "yearConstructed": "4Q 2017",
      "certifications": "PEZA Certified",
      "totalFloors": 19,
      "availableSpace": "7F, 8F – 2,156.58 sqm/floor\r\n9F to 12F, 14F to 16F – 2,173.42 sqm/floor\r\n17F – 2,101.43 sqm\r\n18F – 1,857.35 sqm\r\n\r\n(May accommodate cuts)",
      "totalAvailableSpace": 16999.3,
      "density": "1:5 ",
      "floorEfficiency": 0.97,
      "handoverCondition": "6F - 8F, 11F, 18F - Semi-Fitted \r\nOthers - To be determined",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": "Metered",
      "quotedRent": "PhP 850",
      "cusa": "PhP 200",
      "parkingRate": "PhP 5,500",
      "lastUpdated": "November 8, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 26,644.71 sqm ",
      "floorPlate": "2,142.75 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "7 Passenger Elevators\r\n1 Service Elevators\r\n1 Parking Elevator",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1:100 ",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "24 hour operations allowed"
    },
    {
      "buildingName": "HMA 773",
      "businessDistrict": "Makati",
      "address": "773 JP Rizal Street, Makati City",
      "developer": "HMA Concepts",
      "yearConstructed": "Completion 3Q 2020",
      "certifications": "Non-PEZA",
      "totalFloors": 3,
      "availableSpace": "\r\n\r\nFully Leased\r\n",
      "totalAvailableSpace": 0,
      "density": "Density To be determined",
      "floorEfficiency": "99% Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Tenant to provide AC",
      "acCharges": "Metered",
      "quotedRent": "\r\nGF – PhP 1,500\r\n2F – PhP 700\r\n3F – PhP 750",
      "cusa": "PhP 150 to PhP 200",
      "parkingRate": " PhP 6,000",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of 531 sqm",
      "floorPlate": "250 sqm floor plate",
      "netUseableArea": "Approx. 248 sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: Eastern Telecom, Globe, PLDT",
      "ceilingHeight": "2F – 2.8\r\n3F – 3.0  meters finished ceiling height",
      "parkingAllocation": "Parking Allocation of 1 slot/floor",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "MRJ Corporate Center",
      "businessDistrict": "Makati",
      "address": "954 J.P. Rizal Street corner Gomez Street, Poblacion",
      "developer": "To be determined",
      "yearConstructed": 2017,
      "certifications": "Non-PEZA",
      "totalFloors": "17 stories",
      "availableSpace": "6F – 313.4 sqm\r\n6F – 366.85 sqm\r\n7F to 17F – 700 sqm/floor",
      "totalAvailableSpace": 7680.25,
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "6F – Warm Shell\r\n7F to 17F – Bare Shell",
      "acSystem": "Split Type",
      "acCharges": "Metered",
      "quotedRent": "PhP 800\r\n\r\nAdditional rent for 24/7 Operations to be finalized",
      "cusa": "PhP 160\r\n\r\nAdditional CUSA for 24/7 Operations to be finalized",
      "parkingRate": "PhP 3,500",
      "lastUpdated": "November 13, 2023",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA Approx. 10,500 sqm",
      "floorPlate": "Approx. 700 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "To be determined",
      "twentyFourSevenOperations": "24/7 Operations: yes",
      "remarks": "Total vacant space is around 7,900 sqm, but Juliet did not give the breakdown"
    },
    {
      "buildingName": "Servier Building",
      "businessDistrict": "Makati",
      "address": "Orion St. corner Kalayaan Avenue, Makati City",
      "developer": "Ionian Property",
      "yearConstructed": "Completion 1995",
      "certifications": "Non-PEZA",
      "totalFloors": 3,
      "availableSpace": "\r\n\r\nFully Leased\r\n",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Tenant to provide AC",
      "acCharges": "To be determined",
      "quotedRent": "PhP 650",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of 1,931.42 sqm ",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Century Spire Tower",
      "businessDistrict": "Makati",
      "address": "Century City, Kalayaan Avenue,\r\nMakati City",
      "developer": "Century Properties",
      "yearConstructed": "Completion 4Q 2021",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "All office floors available\r\n(with 50 sqm as smallest cut)\r\n\r\nbreakthrouh properties ",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "To be determined AC",
      "acCharges": "To be determined",
      "quotedRent": "To be determined",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of 23,000 sqm ",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Century Diamond Tower",
      "businessDistrict": "Makati",
      "address": "Lot 3 Kalayaan Ave., cor. Salamanca St., Brgy. Poblacion",
      "developer": "Century City Development II Corporation",
      "yearConstructed": "4Q 2019",
      "certifications": "PEZA Certified; Pre-Certified LEED",
      "totalFloors": 34,
      "availableSpace": "Mid Zone\r\n20F – 916.19 sqm\r\n21F Unit 4 – 216.94 sqm\r\n21F Unit 8 – 320.43 sqm\r\nHigh Zone\r\n30F – 2,150.66 sqm\r\n31F – 2,151.46 sqm\r\n32F -2,193.46 sqm\r\n\r\n20F may be subdivided into 2 units\r\n32F may be subdivided into 3 units\r\n\r\n28F – 2,115.75 sqm FOR SALE & FOR LEASE\r\n30F – 2,150.66 sqm FOR SALE & FOR LEASE\r\n31F – 2,151.46 sqm FOR SALE & FOR LEASE",
      "totalAvailableSpace": 7949.14,
      "density": "1:5.5",
      "floorEfficiency": 0.85,
      "handoverCondition": "Warm Shell\r\n21F,  28F, 29F – Semi-Fitted / Fully Fitted*\r\n\r\n*With an option to lease the space in a fully fitted condition. They have approx. 300 workstations available",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": "Metered",
      "quotedRent": "PhP 900",
      "cusa": "PhP 190",
      "parkingRate": "PhP 6,000",
      "lastUpdated": "December 14, 2023",
      "buildingGrade": "Grade A",
      "grossLeasableArea": "GLA of 57,121.95 sqm",
      "floorPlate": "2,237.52 sqm sqm floor plate",
      "netUseableArea": "1,901.89 sqm net usable",
      "backupPower": "100% (N+1) Backup Power",
      "elevators": "Low Zone – 6 Passenger \r\nMid Zone – 6 Passenger \r\nHigh Zone – 4 Passenger, \r\n1 Executive\r\n1 Service \r\n2 Parking",
      "telcoProviders": "Telco Providers: Globe, PLDT & provision for third telco provider",
      "ceilingHeight": "3.0 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:100",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "ARGO 11",
      "businessDistrict": "Makati",
      "address": "4740 Salamanca Street, Brgy. Poblacion, Makati City",
      "developer": "Bluemont Prime Resources Inc.",
      "yearConstructed": "Completion 2Q 2016",
      "certifications": "Non-PEZA",
      "totalFloors": 4,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density of To be determined",
      "floorEfficiency": "80% Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Tenant to provide AC",
      "acCharges": "Metered",
      "quotedRent": "To be determined",
      "cusa": "PhP 125",
      "parkingRate": " To be determined",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of 2,000 sqm",
      "floorPlate": "500 sqm floor plate",
      "netUseableArea": "400 sqm net usable",
      "backupPower": "Provision for Backup Power",
      "elevators": "1 Elevator",
      "telcoProviders": "Telco Providers: PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1 slot is to 1 tenant",
      "twentyFourSevenOperations": "24/7 Operations: ",
      "remarks": "Tenant has to put up the back up power in their office. Building's back up power only covers common areas.\r\n\r\nWith provision for signage"
    },
    {
      "buildingName": "7844 Makati Ave",
      "businessDistrict": "Makati",
      "address": "7844 Makati Avenue, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 6,
      "availableSpace": "2F – Approx. 225 sqm/floor\r\n5F – 80 sqm\r\n6F – 225 sqm\r\n\r\nLessor prefers whole building takers",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition\r\n2F –Warm Shell\r\n5F – As is where is\r\n6F – Bare Shell",
      "acSystem": "2F – Tenant to provide\r\n5F – Split Type\r\n6F – Window Type",
      "acCharges": "Metered",
      "quotedRent": "Rent: PhP 150,000 /floor",
      "cusa": "Building Dues: PhP 30,000 /month",
      "parkingRate": " To be determined",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of To be determined",
      "floorPlate": "Approx. 200 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "1 Passenger Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation Not Applicable",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "Name:Sam Cuison \r\nOrganization:7373 Bakawan Makati \r\n‎‏‎‎‎‎‎‏‎‏‏‏‎‎‎‎‎‎‏‎‎‏‎‎‎‎‏‏‏‏‏‏‏‏‎‏‏‎‏‎‎‎‏‏‏‎‎‏‏‏‎‏‎‏‏‎‏‎‏‎‏‎‎‎‏‏‎‏‏‎‎‎‎‎‎‏‏‏‏‎‏‎‎‎‏‎‎‎‎‎‏‏‏‏‎‏‎Mobile‎‏‎‎‏‎:+63 915 452 4295\r\nEmail: samanthacuison@gmail.com"
    },
    {
      "buildingName": "7840 Makati Avenue",
      "businessDistrict": "Makati",
      "address": "7840 Makati Avenue, Makati City",
      "developer": "Time, Inc.",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 2,
      "availableSpace": "GF, 2F, Roofdeck – Approx. 400 sqm/floor\r\n\r\nLessor prefers whole building takers",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Tenant to provide AC",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,750 ",
      "cusa": "To be determined",
      "parkingRate": " To be determined",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA of Approx. 1,200 sqm ",
      "floorPlate": "Approx. 400 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "None Passenger Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "BCC House",
      "businessDistrict": "Makati",
      "address": "5046 P. Burgos, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Lower GF – 167 sqm\r\nGF – 165 sqm\r\n2F – 238 sqm",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Tenant to provide AC",
      "acCharges": "Metered",
      "quotedRent": "Rent (PSM):\r\nLower GF – PhP 700\r\nGF – PhP 1,200\r\n2F to 5F – PhP 850\r\nPH - PhP 1,000",
      "cusa": "PhP 75",
      "parkingRate": "PhP 3,500",
      "lastUpdated": "August 23, 2023",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of 1,000 sqm ",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 2 slots",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "1 parking slot for 3rd floor \r\n"
    },
    {
      "buildingName": "100 West",
      "businessDistrict": "Makati",
      "address": "Sen. Gil Puyat Avenue corner Washington",
      "developer": "Filinvest Land",
      "yearConstructed": "4Q 2019",
      "certifications": "Non-PEZA",
      "totalFloors": "4 office floors",
      "availableSpace": "8F – 3,454.15 sqm\r\n10F – 3,633.48 sqm\r\n\r\nFloors can be subdivided iinto 3 units",
      "totalAvailableSpace": 7087.63,
      "density": "1:5",
      "floorEfficiency": 0.89,
      "handoverCondition": "Semi-Fitted/Fully Fitted",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": "Metered + PhP 50",
      "quotedRent": "Non-POGO – PhP 1,100\r\nPOGO  – PhP 1,500",
      "cusa": "PhP 200",
      "parkingRate": "PhP 5,000",
      "lastUpdated": "December 12, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 12,792.22 sqm ",
      "floorPlate": "Approx. 3,600 sqm floor plate",
      "netUseableArea": "Approx. 3,096 sqm net usable",
      "backupPower": "N+1 Backup Power",
      "elevators": "4 Elevators",
      "telcoProviders": "Telco Providers: Multiple Providers",
      "ceilingHeight": "3.6 meters finished ceiling height\r\n(floor to ceiling)",
      "parkingAllocation": "Parking Allocation 1:70",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "Rates are subject to change"
    },
    {
      "buildingName": "eWestPod",
      "businessDistrict": "Makati",
      "address": "Corner Chino Roces Avenue, Yakal and Malugay Streets, Brgy. San Antonio, Makati City ",
      "developer": "Eton Properties",
      "yearConstructed": "1Q 2019",
      "certifications": "Non-PEZA",
      "totalFloors": 9,
      "availableSpace": "7F – 3,161.52 sqm\r\n8F – 3,283.80 sqm",
      "totalAvailableSpace": 6445.32,
      "density": "To be determined",
      "floorEfficiency": "Approx. 80% to 90%",
      "handoverCondition": "Semi-Fitted",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": "PhP 120",
      "quotedRent": "PhP 850",
      "cusa": "PhP 120",
      "parkingRate": "PhP 5,000",
      "lastUpdated": "January 3, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 13,116.13 sqm",
      "floorPlate": "Approx. 3,600 sqm floor plate",
      "netUseableArea": "Approx. 3,300 to 3,500 sqm net usable",
      "backupPower": "100%  Backup Power",
      "elevators": "5 Passenger Elevators\r\n1 Service Elevator",
      "telcoProviders": "Telco Providers: Multiple Providers",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:100",
      "twentyFourSevenOperations": "24/7 Operations: ",
      "remarks": "Fitout: 4Q 2018"
    },
    {
      "buildingName": "Project Zeus",
      "businessDistrict": "Makati",
      "address": "Lot 26 Jupiter corner Canopus Street,\r\nVillage 4 Bel-Air, Makati City, Philippines",
      "developer": "One Lavender Resources Corp.",
      "yearConstructed": "1Q 2019 (January)",
      "certifications": "Non-PEZA",
      "totalFloors": 5,
      "availableSpace": "4F, 5F – 538 sqm/floor\r\n\r\n\r\nSubdivision of floors ia subject for approval",
      "totalAvailableSpace": 1076,
      "density": "1:7",
      "floorEfficiency": 0.86,
      "handoverCondition": "Bare Shell",
      "acSystem": "3F, 4F – Tenant to provide AC\r\n5F – Variable Refrigerant Flow",
      "acCharges": "Metered",
      "quotedRent": "3F, 4F – PhP 1,000\r\n5F- PhP 1,200",
      "cusa": "PhP 150",
      "parkingRate": "Subject to negotiation",
      "lastUpdated": "May 22, 2023",
      "buildingGrade": "Grade To be determined",
      "grossLeasableArea": "GLA of 2,490",
      "floorPlate": "538 sqm floor plate",
      "netUseableArea": "465 sqm net usable",
      "backupPower": "25%  Backup Power",
      "elevators": "2 Passenger Elevators",
      "telcoProviders": "Telco Providers: PLDT",
      "ceilingHeight": "4F – 3.15 (slab to slab)\r\n5F – 3.00 (slab to slab) meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: NO",
      "remarks": "Total number of parking slots: 35 slots\r\nPrefers whole floor takers"
    },
    {
      "buildingName": "Blakes Tower",
      "businessDistrict": "Makati",
      "address": "Corner Chino Roces Avenue, Yakal and Malugay Streets, Brgy. San Antonio",
      "developer": "Eton Properties",
      "yearConstructed": "4Q 2020 (December)",
      "certifications": "Non-PEZA",
      "totalFloors": 36,
      "availableSpace": "11F to 18F – 1,055.09 sqm/floor",
      "totalAvailableSpace": 7385.63,
      "density": "1:5",
      "floorEfficiency": "80% to 90%",
      "handoverCondition": "Bare Shell",
      "acSystem": "Split Type",
      "acCharges": "PhP 120",
      "quotedRent": "PhP 850 (PhP 900 for small cuts)",
      "cusa": "PhP 120",
      "parkingRate": "PhP 5,000",
      "lastUpdated": "January 3, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 11,431.86 sqm",
      "floorPlate": "1,039.26 sqm floor plate",
      "netUseableArea": "Approx. 831.41 sqm to 935.33 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "3 Passenger Elevators\r\n1 Service Elevator",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:100",
      "twentyFourSevenOperations": "24/7 Operations: Yes "
    },
    {
      "buildingName": "Phinma Plaza",
      "businessDistrict": "Makati",
      "address": "39 Plaza Drive, Rockwell Center, Makati",
      "developer": "Phinma Property Holdings",
      "yearConstructed": "Completion 2000",
      "certifications": "Non-PEZA",
      "totalFloors": 13,
      "availableSpace": "Mezzanine – 240 sqm",
      "totalAvailableSpace": 240,
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition \r\nMezzanine – As is where is\r\n11F – Fully Fitted",
      "acSystem": "Variable Refrigerant Flow System",
      "acCharges": "Metered",
      "quotedRent": " PhP 1,050",
      "cusa": "PhP 156",
      "parkingRate": " PhP 4,000",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of approx. 12,350 sqm ",
      "floorPlate": "Approx. 950 sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "4 Passenger Elevators\r\n1 Service Elevator",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:100",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "38 Rockwell",
      "businessDistrict": "Makati",
      "address": "Rockwell Drive,\r\nMakati City ",
      "developer": "Rockwell Land Corporation",
      "yearConstructed": "Completion 2003",
      "certifications": "Non-PEZA",
      "totalFloors": 3,
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition 2F – Bare Shell\r\n3F – As is where is",
      "acSystem": "Tenant to provide AC",
      "acCharges": "Metered",
      "quotedRent": "3F – PhP 750\r\n",
      "cusa": "PhP 125",
      "parkingRate": " PhP 4,000",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of To be determined sqm ",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: Bayantel, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation To be determined ",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "8 Rockwell",
      "businessDistrict": "Makati",
      "address": "Rockwell Drive,\r\nMakati City ",
      "developer": "Rockwell Land Corporation",
      "yearConstructed": "Completion 4Q 2015",
      "certifications": "PEZA Certified",
      "totalFloors": 19,
      "availableSpace": "Roof deck - 832.46 sqm",
      "totalAvailableSpace": 832.46,
      "density": "Density of 1:10",
      "floorEfficiency": "90% Efficiency",
      "handoverCondition": "Handover Condition Semi-Fitted",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": "PhP 75 psm",
      "quotedRent": "PhP 1,300",
      "cusa": "PhP 125",
      "parkingRate": " PhP 6,000",
      "buildingGrade": "Grade A",
      "grossLeasableArea": "GLA of 34,511 sqm ",
      "floorPlate": "1,791.59 sqm floor plate",
      "netUseableArea": "1,619.91 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "6 Passenger Elevators\r\n1 Service Elevators",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:70 ",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "9F – Strata title, commission is 1 month regardless of lease term"
    },
    {
      "buildingName": "1 Proscenium",
      "businessDistrict": "Makati",
      "address": "Rockwell Drive",
      "developer": "Rockwell Land Corporation",
      "yearConstructed": "2Q 2022",
      "certifications": "Non-PEZA; For LEED Certification ",
      "totalFloors": 24,
      "availableSpace": "10F, 23F – Approx. 620 sqm, ",
      "totalAvailableSpace": 1280,
      "density": "1:8",
      "floorEfficiency": 0.9,
      "handoverCondition": "Bare Shell",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": "PhP 75",
      "quotedRent": "PhP 1,300",
      "cusa": "PhP 125",
      "parkingRate": " PhP 5,000",
      "lastUpdated": "November 15, 2023",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 12,200 qm",
      "floorPlate": "Approx.640 sqm floor plate",
      "netUseableArea": "Approx. 640 sqm net usable ",
      "backupPower": "100% Backup Power ",
      "elevators": "3 Passenger Elevators\r\n1 Service Elevator",
      "telcoProviders": "Telco Providers: PLDT",
      "ceilingHeight": "Slab to slab – 3.6\r\n2.6 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation \r\nOthers- 1:70\r\nUnit 204 – 1 slot",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "Kirov Tower",
      "businessDistrict": "Makati",
      "address": "Estrella Drive, Rockwell Center, Makati City",
      "developer": "Rockwell Land Corporation",
      "yearConstructed": "Completion 2019",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "FOR SALE:\r\n2F Unit 03 – 188 sqm\r\n2F Unit 04 – 162 sqm\r\n3F Unit 03 – 193 sqm\r\n3F Unit 04 – 86 sqm",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Tenant to provide AC System",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,300",
      "cusa": "To be finalized",
      "parkingRate": " PhP 5,000",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA To be determined",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: Bayantel, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:75",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "Sakura Tower",
      "businessDistrict": "Makati",
      "address": "Estrella Drive, Rockwell Center, Makati City",
      "developer": "Rockwell Land Corporation",
      "yearConstructed": "Completion 2019",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "FOR SALE:\r\n2F Unit 03 –  127 sqm\r\n3F Unit 02 – 117 sqm",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Tenant to provide AC System",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,300",
      "cusa": "To be finalized",
      "parkingRate": " PhP 5,000",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA To be determined",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: Bayantel, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:75",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "Lincoln Tower",
      "businessDistrict": "Makati",
      "address": "Estrella Drive, Rockwell Center, Makati City",
      "developer": "Rockwell Land Corporation",
      "yearConstructed": "Completion 2019",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "FOR SALE:\r\n3F Unit 02 – 147 sqm",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Tenant to provide AC System",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,300",
      "cusa": "To be finalized",
      "parkingRate": " PhP 5,000",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA To be determined",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: Bayantel, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:75",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "Lorraine Tower",
      "businessDistrict": "Makati",
      "address": "Estrella Drive, Rockwell Center, Makati City",
      "developer": "Rockwell Land Corporation",
      "yearConstructed": "Completion 2019",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "FOR SALE:\r\n2F Unit 02 – 235 sqm\r\n2F Unit 02-B – 175 sqm\r\n3F Unit 01 – 272 sqm",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Tenant to provide AC System",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,300",
      "cusa": "To be finalized",
      "parkingRate": " PhP 5,000",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA To be determined",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: Bayantel, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:75",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "North Garden Villa",
      "businessDistrict": "Makati",
      "address": "Estrella Drive, Rockwell Center, Makati City",
      "developer": "Rockwell Land Corporation",
      "yearConstructed": "Completion 2019",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "FOR SALE:\r\n3F Unit 02 – 236 sqm",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Tenant to provide AC System",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,300",
      "cusa": "To be finalized",
      "parkingRate": " PhP 5,000",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA To be determined",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: Bayantel, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:75",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "West Garden Villa",
      "businessDistrict": "Makati",
      "address": "Estrella Drive, Rockwell Center, Makati City",
      "developer": "Rockwell Land Corporation",
      "yearConstructed": "Completion 2019",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Tenant to provide AC System",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,300",
      "cusa": "To be finalized",
      "parkingRate": " PhP 5,000",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA To be determined",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: Bayantel, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:75",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "East Garden Villa",
      "businessDistrict": "Makati",
      "address": "Estrella Drive, Rockwell Center, Makati City",
      "developer": "Rockwell Land Corporation",
      "yearConstructed": "Completion 2019",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "Fully Leased",
      "density": "Density of To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Tenant to provide AC System",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,300",
      "cusa": "To be finalized",
      "parkingRate": " PhP 5,000",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA To be determined",
      "floorPlate": "To be determined sqm floor plate",
      "netUseableArea": "To be determined sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger Elevators\r\nTo be determined Service Elevators",
      "telcoProviders": "Telco Providers: Bayantel, PLDT",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation 1:75",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "B and P Building",
      "businessDistrict": "Makati",
      "address": "843 Arnaiz Avenue, Makati City",
      "developer": "Developer:To be determined",
      "yearConstructed": "Completion: To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 7,
      "availableSpace": "Fully Leased",
      "density": "Density To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition\r\n5F – Semi-Fitted\r\n6F – As is where is",
      "acSystem": "Split Type AC System",
      "acCharges": "Included in building dues",
      "quotedRent": "PhP 800",
      "cusa": "Building Dues (PSM):Pro-rated",
      "parkingRate": " Included in rent",
      "buildingGrade": "Grade: To be determined",
      "grossLeasableArea": "GLA: To be determined",
      "floorPlate": "To be determined Floor Plate",
      "netUseableArea": "To be determined net usable",
      "backupPower": "To be determined Backup Power ",
      "elevators": "1 Passenger",
      "telcoProviders": "Telco Providers: PLDT",
      "ceilingHeight": "To be determined",
      "parkingAllocation": "Parking Allocation\r\n5F – 1 slot\r\n6F – 3 slots",
      "twentyFourSevenOperations": "24/7 Operations: For Approval"
    },
    {
      "buildingName": "Aguirre Building",
      "businessDistrict": "Makati",
      "address": "A Arnaiz Avenue, Makati",
      "developer": "Developer:To be determined",
      "yearConstructed": "Completion: To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 4,
      "availableSpace": "\r\n2F​ – 347​ sqm\r\n",
      "totalAvailableSpace": 347,
      "density": "Density To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Split Type AC System",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,300",
      "cusa": "None",
      "parkingRate": "Parking Rate (/slot/mo.):\r\n1  Slot Free",
      "buildingGrade": "Grade: To be determined",
      "grossLeasableArea": "GLA: To be determined",
      "floorPlate": "To be determined Floor Plate",
      "netUseableArea": "To be determined net usable",
      "backupPower": "To be determined Backup Power ",
      "elevators": "To be determined",
      "telcoProviders": "Telco Providers: PLDT",
      "ceilingHeight": "To be determined",
      "parkingAllocation": "Parking Allocation: 1  Slot",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Paseo Tesoro Building",
      "businessDistrict": "Makati",
      "address": "A Arnaiz Avenue, Makati",
      "developer": "Developer:To be determined",
      "yearConstructed": "Completion: 2009",
      "certifications": "Non-PEZA",
      "totalFloors": 3,
      "density": "Density To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Tenant to provide AC System",
      "acCharges": "Metered",
      "quotedRent": "PhP 750",
      "cusa": "Building Dues (PSM):PhP 100",
      "parkingRate": "Parking Rate: PhP 8,000",
      "buildingGrade": "Grade: To be determined",
      "grossLeasableArea": "GLA: To be determined",
      "floorPlate": "To be determined Floor Plate",
      "netUseableArea": "To be determined net usable",
      "backupPower": "To be determined Backup Power ",
      "elevators": "To be determined",
      "telcoProviders": "Telco Providers: PLDT",
      "ceilingHeight": "To be determined",
      "parkingAllocation": "Parking Allocation: To be determined",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "SAN LORENZO VILLAGE ",
      "businessDistrict": "Makati",
      "address": "814 Arnaiz Avenue, San Lorenzo Village, Makati City",
      "developer": "ISOC Holdings, Inc. ",
      "yearConstructed": "Completion: 4Q 2022",
      "certifications": "Non-PEZA",
      "totalFloors": 4,
      "availableSpace": "Basement – 299.35 sqm\r\nGF – 223.47 sqm\r\nMezzanine – 223.47 sqm\r\n2F – 312.59 sqm\r\n3F – 317.10 sqm\r\n4F – 307.42 sqm",
      "density": "Density To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition To be determined",
      "acSystem": "To be determined AC Sytem",
      "acCharges": "To be determined",
      "quotedRent": "Rent (PSM):\r\nBasement – PhP 900\r\nGF – PhP 2,500\r\nMezzanine – PhP 2,000\r\n2F to 4F – PhP 1,200",
      "cusa": "Building Dues(PSM): PhP 100",
      "parkingRate": "Parking Rate: To be determined",
      "buildingGrade": "Grade: To be determined",
      "grossLeasableArea": "GLA: 1,683 sqm",
      "floorPlate": "To be determined Floor Plate",
      "netUseableArea": "To be determined net usable",
      "backupPower": "To be determined Backup Power ",
      "elevators": "To be determined",
      "telcoProviders": "To be determined",
      "ceilingHeight": "To be determined",
      "parkingAllocation": "Parking Allocation To be determined",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "SMDC Air",
      "businessDistrict": "Makati",
      "address": "7257  Yakal Street",
      "developer": "SM Prime Holdings, Inc.",
      "yearConstructed": "3Q 2021 (September)",
      "certifications": "Under Application for PEZA",
      "totalFloors": "59 (2 Office Floors)",
      "availableSpace": "6F – 4,886.99 sqm\r\n7F – 2,619.82 sqm",
      "totalAvailableSpace": 7506.8099999999995,
      "density": "1:5 (based on net)",
      "floorEfficiency": "To be determined",
      "handoverCondition": "Bare Shell",
      "acSystem": "Variable Refrigerant Flow",
      "acCharges": "Metered + PhP 90",
      "quotedRent": "PhP 800",
      "cusa": "PhP 140",
      "parkingRate": "PhP 5,000",
      "lastUpdated": "November 6, 2023",
      "buildingGrade": "Grade: B",
      "grossLeasableArea": "GLA: 7,506.81 sqm",
      "floorPlate": "Approx. 4,000 sqm Floor Plate",
      "netUseableArea": "To be determined net usable",
      "backupPower": "100% Backup Power ",
      "elevators": "3 Passenger Elevators",
      "telcoProviders": "Telco Providers: PLDT",
      "ceilingHeight": "To be determined",
      "parkingAllocation": "Parking Allocation Subject to availability",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Metropolitan Commercial Center",
      "businessDistrict": "Makati",
      "address": "1045 Metropolitan Avenue, San Antonio, Makati City",
      "developer": "Developer:To be determined",
      "yearConstructed": "Completion 3Q 2021",
      "certifications": "Non-PEZA",
      "totalFloors": 11,
      "availableSpace": "6F to 11F – 197 to 233 sqm/floor",
      "density": "Density To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "To be determined AC Sytem",
      "acCharges": "To be finalized",
      "quotedRent": "To be finalized",
      "cusa": "Building Dues (PSM) : To be finalized",
      "parkingRate": "Parking Rate: To be finalized",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA: 1,390 sqm",
      "floorPlate": "To be determined Floor Plate",
      "netUseableArea": "To be determined net usable",
      "backupPower": "To be determined Backup Power ",
      "elevators": "Passenger Elevator To be determined",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation: To be determined",
      "twentyFourSevenOperations": "24/7 Operations:"
    },
    {
      "buildingName": "Anubing Building",
      "businessDistrict": "Makati",
      "address": "Anubing Street corner Kamagong, San Antonio Village, Makati City",
      "developer": "Developer:To be determined",
      "yearConstructed": "Completion 4Q 2021",
      "certifications": "Non-PEZA",
      "totalFloors": 7,
      "availableSpace": "GF to 7F, Roofdeck – 270 sqm/floor\r\n2F to 5F – 270 sqm/floor",
      "density": "Density To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Warm Shell",
      "acSystem": "To be determined AC Sytem",
      "acCharges": "To be finalized",
      "quotedRent": "To be finalized",
      "cusa": "Building Dues (PSM) : To be finalized",
      "parkingRate": "Parking Rate: To be finalized",
      "buildingGrade": "Grade C",
      "grossLeasableArea": "GLA: 2,160 sqm",
      "floorPlate": "270 Floor Plate",
      "netUseableArea": "To be determined net usable",
      "backupPower": "To be determined Backup Power ",
      "elevators": "Passenger Elevator To be determined",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation: To be determined",
      "twentyFourSevenOperations": "24/7 Operations:"
    },
    {
      "buildingName": "Expocraft International Building",
      "businessDistrict": "Makati",
      "address": "108 Metropolitan Avenue, Makati City",
      "developer": "Developer:To be determined",
      "yearConstructed": "Completion: 1978",
      "certifications": "Non-PEZA",
      "totalFloors": 3,
      "availableSpace": "3F – Approx. 320 sqm",
      "density": "Density To be determined",
      "floorEfficiency": "100% Efficiency",
      "handoverCondition": "Handover Condition Fully Fitted ",
      "acSystem": "Split Type AC Sytem",
      "acCharges": "Metered",
      "quotedRent": "PhP 400",
      "cusa": "Building Dues (PSM) : To be determined",
      "parkingRate": "Parking Rate: PhP 4,000",
      "buildingGrade": "Grade: To be determined",
      "grossLeasableArea": "GLA: 901 sqm",
      "floorPlate": "320 sqm Floor Plate",
      "netUseableArea": "320 sqm net usable",
      "backupPower": "Tenant to provide Backup Power ",
      "elevators": "N/A Passenger Elevator",
      "telcoProviders": "Telco Providers: PLDT and others",
      "ceilingHeight": "To be determined meters finished ceiling height",
      "parkingAllocation": "Parking Allocation: 2 slots",
      "twentyFourSevenOperations": "24/7 Operations:"
    },
    {
      "buildingName": "Alrose Building",
      "businessDistrict": "Makati",
      "address": "1009 Metropolitan Avenue, Makati City",
      "developer": "Developer:To be determined",
      "yearConstructed": "Completion: To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 4,
      "availableSpace": "Fully Leased",
      "density": "Density To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Tenant to provide AC Sytem",
      "acCharges": "Metered",
      "quotedRent": "PhP 550",
      "cusa": "Building Dues (PSM) : PhP 50",
      "parkingRate": "Parking Rate: First come, first served",
      "buildingGrade": "Grade: To be determined",
      "grossLeasableArea": "GLA: To be determined",
      "floorPlate": "To be determined Floor Plate",
      "netUseableArea": "To be determined net usable",
      "backupPower": "To be determined Backup Power ",
      "elevators": "To be determined",
      "telcoProviders": "Telco Providers: PLDT",
      "ceilingHeight": "To be determined",
      "parkingAllocation": "Parking Allocation: 4 parking slots \r\n(first come, first serve)",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "4 slots"
    },
    {
      "buildingName": "7373 Bakawan St.",
      "businessDistrict": "Makati",
      "address": "7373 Bakawan St.San Antonio Village, Makati City",
      "developer": "Developer:To be determined",
      "yearConstructed": "Completion: 2000's (renovated in 2018)",
      "certifications": "Non-PEZA",
      "totalFloors": 6,
      "availableSpace": "Fully Leased",
      "density": "Density To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition \r\nGF – Warm Shell\r\n2F to 4F – As is where is\r\n6F – Bare Shell\r\n",
      "acSystem": "Tenant to provide AC Sytem",
      "acCharges": "Metered",
      "quotedRent": "\r\nGF – PhP 1,200\r\n2F, 3F, 4F – PhP 900\r\n6F – PhP 700",
      "cusa": "Building Dues (PSM) : \r\nRegular hours – PhP 100\r\n24/7 – PhP 200",
      "parkingRate": "Parking Rate: PhP 5,000",
      "buildingGrade": "Grade: C",
      "grossLeasableArea": "GLA: Approx. 2,800 sqm",
      "floorPlate": "Approx. 400 Floor Plate",
      "netUseableArea": "To be determined net usable",
      "backupPower": "100% Backup Power ",
      "elevators": "1 Passenger Elevator",
      "telcoProviders": "Telco Providers: PLDT, Globe, Smart",
      "ceilingHeight": "To be determined",
      "parkingAllocation": "Parking Allocation: 6 parking slots",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Nexus Center",
      "businessDistrict": "Makati",
      "address": "1010 Metropolitan Avenue, Makati City",
      "developer": "Wordtext Systems, Inc.",
      "yearConstructed": "Completion: 1Q 2019",
      "certifications": "Non-PEZA",
      "totalFloors": 11,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density 1:8 to 1:10",
      "floorEfficiency": "Approx. 87% to 89% Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Variable Refrigerant Flow AC System",
      "acCharges": "Metered",
      "quotedRent": "PhP 870",
      "cusa": "PhP 170",
      "parkingRate": "Parking Rate: PhP 4,000",
      "buildingGrade": "Grade: B",
      "grossLeasableArea": "GLA: Approx. 5,800 sqm",
      "floorPlate": "968 sqm Floor Plate",
      "netUseableArea": "Approx. 800 sqm net usable",
      "backupPower": "100% Backup Power",
      "elevators": "3 Passenger Elevators\r\n1 Service Elevator",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation: 1:100",
      "twentyFourSevenOperations": "24/7 Operations: No",
      "remarks": "5 Podium Parking; 1 Basement Parking"
    },
    {
      "buildingName": "Lumiere Building",
      "businessDistrict": "Makati",
      "address": "8860 Sampaloc St. Makati City",
      "developer": "GSI Gruppo Sachin, Inc.",
      "yearConstructed": "Completion: 4Q 2019 (December)",
      "certifications": "Non-PEZA",
      "totalFloors": 6,
      "availableSpace": "3F to 6F – 440.59 sqm/floor\r\n\r\nDont offer: Building on HOLD",
      "totalAvailableSpace": 0,
      "density": "Density 1:6 to 1:7",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Warm Shell",
      "acSystem": "Tenant to provide AC System",
      "acCharges": "To be determined",
      "quotedRent": "\r\nBare Shell – PhP 800\r\nWarm Shell – PhP 900",
      "cusa": "PhP 90",
      "parkingRate": "Parking Rate: PhP 5,000",
      "buildingGrade": "Grade B",
      "grossLeasableArea": "GLA of 2,964.44 sqm",
      "floorPlate": "440.59 sqm floor plate",
      "netUseableArea": "Approx. 300 sqm net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "1 Passenger Elevator",
      "telcoProviders": "Teclo Providers: Converge, Globe, PLDT",
      "ceilingHeight": "2.8 meters finished ceiling height\r\n3.40 meters (slab to slab)",
      "parkingAllocation": "Parking Allocation: To be determined",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Mapua Makati",
      "businessDistrict": "Makati",
      "address": "1191 Pablo Ocampo Sr. Extension, Barangay Sta. Cruz, Makati City",
      "developer": "RCBC Realty Corporation",
      "yearConstructed": "4Q 2020",
      "certifications": "Non-PEZA",
      "totalFloors": 8,
      "availableSpace": "7F – 1,454.24 sqm\r\nCuts:\r\nUnit 701 – 231.63 sqm\r\nUnit 702 – 200.05 sqm\r\nUnit 703 – 163.54 sqm\r\nUnit 704 – 266.75 sqm\r\nUnit 705 – 286.59 sqm\r\nUnit 706 – 305.67 sqm",
      "totalAvailableSpace": 1454.14,
      "density": "Density 1:8",
      "floorEfficiency": "80% Efficiency",
      "handoverCondition": "Handover Condition Warm Shell",
      "acSystem": "Variable Refrigerant Flow AC System",
      "acCharges": "Metered",
      "quotedRent": "PhP 800",
      "cusa": "PhP 175",
      "parkingRate": "Parking Rate: PhP 4,000",
      "buildingGrade": "Grade: B",
      "grossLeasableArea": "GLA of 1,454.24 sqm",
      "floorPlate": "1,454.24 sqm floor plate",
      "netUseableArea": "1,167.08 net usable",
      "backupPower": "100% Backup Power",
      "elevators": "6 Passenger Elevators",
      "telcoProviders": "Telco Providers: Eastern Telecom, PLDT",
      "ceilingHeight": "2.8 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation: 1:100",
      "twentyFourSevenOperations": "24/7 Operations: Subject for approval"
    },
    {
      "buildingName": "PMI Tower",
      "businessDistrict": "Makati",
      "address": "273 Pablo Ocampo Sr. Extension, Brgy. Lapaz, Makati City",
      "developer": "Developer:To be determined",
      "yearConstructed": "Completion: 4Q 2021",
      "certifications": "Under Application for PEZA",
      "totalFloors": 21,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density 1:5",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Split Type AC Sytem",
      "acCharges": "Metered",
      "quotedRent": "\r\nNon-POGO – PhP 1,200\r\nPOGO – PhP 1,500",
      "cusa": "Building Dues(PSM): PhP 200",
      "parkingRate": "Parking Rate: To be determined",
      "buildingGrade": "Grade: B",
      "grossLeasableArea": "GLA: 11,849.4 sqm",
      "floorPlate": "Approx. 1,600 sqm Floor Plate",
      "netUseableArea": "To be determined net usable",
      "backupPower": "100% Backup Power ",
      "elevators": "1 Passenger\r\n1 Service",
      "telcoProviders": "Telco Providers: PLDT",
      "ceilingHeight": "5.50 meters (slab to slab) finished ceiling height",
      "parkingAllocation": "Parking Allocation: To be determined",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Villa Building",
      "businessDistrict": "Makati",
      "address": "Makati Avenue crner Jupiter St., Makati City",
      "developer": "Developer:Villa Development Corporation",
      "yearConstructed": "Completion: 1998",
      "certifications": "Non-PEZA",
      "totalFloors": 3,
      "availableSpace": "Fully Leased",
      "density": "Density To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Split Type AC System",
      "acCharges": "Metered",
      "quotedRent": "Rent (PSM):\r\nOffice – PhP650\r\nRetail – PhP775 ",
      "cusa": "PhP 50",
      "parkingRate": "Parking Rate: PhP5,000",
      "buildingGrade": "Grade: To be determined",
      "grossLeasableArea": "GLA of 1,734 sqm",
      "floorPlate": "930 sqm floor plate",
      "netUseableArea": "To be determined net usable",
      "backupPower": "Backup Power: To be installed Q2 2017",
      "elevators": "N/A",
      "telcoProviders": "Telco Providers: Globe, Smart,PLDT",
      "ceilingHeight": "2.7 meters finished ceiling height",
      "parkingAllocation": "Parking Allocation: To be determined",
      "twentyFourSevenOperations": "24/7 Operations: ",
      "remarks": "2nd Floor can be leased out for retail \r\nPossible branding opportunity on pylon signage along Makati Avenue\r\n\r\nContact Details:\r\nKMC "
    },
    {
      "buildingName": "Euro Crest Building",
      "businessDistrict": "Makati",
      "address": "124 Jupiter Street, Makati, 1209 Metro Manila",
      "developer": "Developer:To be determined",
      "yearConstructed": "Completion: To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 4,
      "availableSpace": "Fully Leased",
      "density": "Density To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Tenant to provide AC Sytem",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,650 + 12% VAT",
      "cusa": "Building Dues(PSM): Included in Rent",
      "parkingRate": "Parking Rate: N/A",
      "buildingGrade": "Grade: To be determined",
      "grossLeasableArea": "GLA: To be determined",
      "floorPlate": "To be determined Floor Plate",
      "netUseableArea": "To be determined net usable",
      "backupPower": "To be determined Backup Power ",
      "elevators": "1 Passenger",
      "telcoProviders": "To be determined",
      "ceilingHeight": "To be determined",
      "parkingAllocation": "Parking Allocation:First come first serve",
      "twentyFourSevenOperations": "24/7 Operations: ",
      "remarks": "Contact Details:\r\n8962074 Clarence or Josephine \r\n\r\nPays 1 month's commission only"
    },
    {
      "buildingName": "Muebles Italiano Building",
      "businessDistrict": "Makati",
      "address": "Paseo De Magallanes, Makati City",
      "developer": "St. Joseph the Worker Builders and Realty, Inc. ",
      "yearConstructed": "Completion: 2007",
      "certifications": "Non-PEZA",
      "totalFloors": 6,
      "availableSpace": "GF – 899.10 sqm\r\n2F – 504.31 sqm\r\n3F to 6F – 648.83 sqm/floor\r\nRoofdeck – 291.74 sqm\r\n(Prefers whole building takers)",
      "totalAvailableSpace": 4290.47,
      "density": "Density To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Semi-Fitted",
      "acSystem": "Split Type",
      "acCharges": "Metered",
      "quotedRent": "PhP 8M /month (for entire building)",
      "cusa": "Building Dues(PSM): Not Applicable",
      "parkingRate": "Parking Rate: N/A",
      "lastUpdated": "Oct 18, 2023",
      "buildingGrade": "Grade: C",
      "grossLeasableArea": "4,290.74 sqm",
      "floorPlate": "Approx. 650 sqm Floor Plate",
      "netUseableArea": "To be determined net usable",
      "backupPower": "To be determined Backup Power ",
      "elevators": "1 Passenger\r\n1 Service",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "GF – 3.0 meters\r\n2F – 2.98 meters\r\n3F to 5F – 2.54 meters\r\n6F – 2.64 meters",
      "parkingAllocation": "Parking Allocation To be determined",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "NCM Building",
      "businessDistrict": "Makati",
      "address": "Paseo De Magallanes, Makati City",
      "developer": "St. Joseph the Worker Builders and Realty, Inc. ",
      "yearConstructed": "Completion: 2011",
      "certifications": "Non-PEZA",
      "totalFloors": 4,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Semi-Fitted",
      "acSystem": "Split Type AC Sytem",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,200",
      "cusa": "Building Dues(PSM): Not Applicable ",
      "parkingRate": "Parking Rate: To be determined",
      "buildingGrade": "Grade: C",
      "grossLeasableArea": "GLA: 1,042 sqm",
      "floorPlate": "To be determined Floor Plate",
      "netUseableArea": "To be determined net usable",
      "backupPower": "To be determined Backup Power ",
      "elevators": "N/A Passenger",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "To be determined",
      "parkingAllocation": "Parking Allocation To be determined",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "SJW Building",
      "businessDistrict": "Makati",
      "address": "Paseo De Magallanes",
      "developer": "St. Joseph the Worker Builders and Realty, Inc. ",
      "yearConstructed": "1Q 2021",
      "certifications": "Non-PEZA",
      "totalFloors": 7,
      "availableSpace": "3F to 4F – 712.70 sqm/floor\r\n7f Mezzanine – 228 sqm \r\n\r\nFloors may be subdivided into half",
      "totalAvailableSpace": 1653.4,
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "Bare Shell",
      "acSystem": "Tenant to provide",
      "acCharges": "Metered + PhP 150",
      "quotedRent": "PhP 1,000 – Whole Floor\r\nPhP 1,250 – Half Floor",
      "cusa": "PhP 150",
      "parkingRate": "To be discussed",
      "lastUpdated": "Oct 18, 2023",
      "buildingGrade": "Grade: C",
      "grossLeasableArea": "GLA 3,868.7 sqm",
      "floorPlate": "712.70 sqm Floor Plate",
      "netUseableArea": "To be determined net usable",
      "backupPower": "100% Backup Power +JO34",
      "elevators": "1 Passenger\r\n1 Service",
      "telcoProviders": "Telco Providers: Globe, PLDT",
      "ceilingHeight": "GF to 2F – 7.2 meters\r\n3F to 6F – 4.5 meters ",
      "parkingAllocation": "Parking Allocation To be determined",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "METS Place",
      "businessDistrict": "Makati",
      "address": "Phase 1 Lot 3, Block 4, Santiago Street, Paseo de Magallenes, Makati City",
      "developer": "Ironcon Builders Development Corporation",
      "yearConstructed": "Completion: 4Q 2018",
      "certifications": "Non-PEZA",
      "totalFloors": 10,
      "availableSpace": "2F - 25sqm",
      "totalAvailableSpace": 25,
      "density": "Density To be determined",
      "floorEfficiency": "53% Efficiency",
      "handoverCondition": "Semi-Fitted",
      "acSystem": "Centralized Chilled Water",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,300",
      "cusa": "Included in rent",
      "parkingRate": "PhP 5,000",
      "lastUpdated": "Oct 18, 2023",
      "buildingGrade": "Grade: B",
      "grossLeasableArea": "GLA of 5,589.79 sqm",
      "floorPlate": "To be determined Floor Plate",
      "netUseableArea": "2,980.32 sqm net usable",
      "backupPower": "100% Backup Power ",
      "elevators": "2 Passenger\r\n2 Parking",
      "telcoProviders": "Telco Providers: Globe, PLDT, Radius",
      "ceilingHeight": "2.6 meters ceiling height",
      "parkingAllocation": "To be finalized",
      "twentyFourSevenOperations": "24/7 Operations: No"
    },
    {
      "buildingName": "JM Building",
      "businessDistrict": "Makati",
      "address": "6305 SLEX, Osmena Highway",
      "developer": "JM Compound, Inc. ",
      "yearConstructed": "Completion: 4Q 2018",
      "certifications": "Non-PEZA",
      "totalFloors": 5,
      "availableSpace": "2F - 604 sqm",
      "totalAvailableSpace": 604,
      "density": "Density To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition\r\nSemi-Warm Shell (Painted Walls & Smooth Cement Flooring, No AC)",
      "acSystem": "Tenant to provide AC Sytem",
      "acCharges": "Metered",
      "quotedRent": "PhP 650",
      "cusa": "None",
      "parkingRate": "Parking Rate: N/A",
      "lastUpdated": "October 23, 2023",
      "buildingGrade": "Grade: C",
      "grossLeasableArea": "GLA Approx 5,000 sqm",
      "floorPlate": "To be determined Floor Plate",
      "netUseableArea": "To be determined net usable",
      "backupPower": "100%  Backup Power",
      "elevators": "2 Passenger",
      "telcoProviders": "Telco Providers: Multiple Providers",
      "ceilingHeight": "4F, 5F – Approx. 3.0 meters ceiling height\r\n5F (1,052 sqm) – 6 meters ceiling height",
      "parkingAllocation": "Parking Allocation 1:200",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Picar Building",
      "businessDistrict": "Makati",
      "address": "5470 SLEX, Brgy. Bangkal, Makati City",
      "developer": "Picar Development, Inc.",
      "yearConstructed": "Completion: To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "7F – Approx. 300 sqm \r\n8F – 238.48 sqm\r\n\r\n(does not offer broker's fee)",
      "density": "Density To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition As is where is",
      "acSystem": "Tenant to provide AC Sytem",
      "acCharges": "Metered",
      "quotedRent": "PhP 600",
      "cusa": "Building Dues(PSM): PhP 90",
      "parkingRate": "Parking Rate: N/A",
      "lastUpdated": "X",
      "buildingGrade": "Grade: C",
      "grossLeasableArea": "GLA To be determined",
      "floorPlate": "To be determined Floor Plate",
      "netUseableArea": "To be determined net usable",
      "backupPower": "To be determined Backup Power",
      "elevators": "To be determined Passenger",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined ",
      "parkingAllocation": "Parking Allocation 1 slot",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Timstate Building",
      "businessDistrict": "Makati",
      "address": " 5438 SLEX, Brgy. Bangkal",
      "developer": "To be determined",
      "yearConstructed": "To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 7,
      "availableSpace": "Mezzanine – 145 sqm\r\n4F – Approx. 300 sqm ",
      "density": "To be determined",
      "floorEfficiency": "To be determined",
      "handoverCondition": "GF, Mezzanine, PH (120 sqm) – Semi-Fitted\r\n4F, 5F, PH (240 sqm) – Bare Shell",
      "acSystem": "Tenant to provide",
      "acCharges": "Metered",
      "quotedRent": "Regular hours – PhP 700\r\n24/7 – PhP 800",
      "cusa": "Regular hours – PhP 100\r\n24/7 – PhP 200",
      "parkingRate": "PhP 3,000 / 3,500 ",
      "lastUpdated": "Mar 29, 2023",
      "buildingGrade": "Grade: C",
      "grossLeasableArea": "GLA To be determined",
      "floorPlate": "To be determined Floor Plate",
      "netUseableArea": "To be determined net usable",
      "backupPower": "100% Backup Power",
      "elevators": "To be determined Passenger",
      "telcoProviders": "Telco Providers: Eastern Telecom, Globe, PLDT, PT&T",
      "ceilingHeight": "To be determined ",
      "parkingAllocation": "Parking Allocation \r\nSubject to availability",
      "twentyFourSevenOperations": "24/7 Operations: Yes",
      "remarks": "Rent is PhP 800 for GF space"
    },
    {
      "buildingName": "Baja Building",
      "businessDistrict": "Makati",
      "address": "Osmeña Highway corner General Malvar Street, Makati City",
      "developer": "Baja Holdings, Inc.",
      "yearConstructed": "Completion: 1Q 2020 (January)",
      "certifications": "Non-PEZA",
      "totalFloors": 4,
      "availableSpace": "\r\n2F to 4F -Approx. 300 sqm/floor\r\nRoofdeck – Approx. 300 sqm",
      "totalAvailableSpace": 1200,
      "density": "Density To be determined",
      "floorEfficiency": "86% Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Tenant to provide  AC Sytem",
      "acCharges": "Metered",
      "quotedRent": "PhP 800",
      "cusa": "Included in rent",
      "parkingRate": "Included in rent",
      "buildingGrade": "Grade: C",
      "grossLeasableArea": "GLA of Approx. 1,480 sqm",
      "floorPlate": "Approx. 300 sqm Floor Plate",
      "netUseableArea": "Approx. 250 net usable",
      "backupPower": "100% Backup Power",
      "elevators": "1  Passenger\r\n1 Service",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "GF – 6 meters (slab to slab)\r\nOther floors – 4 meters (slab to slab)\r\n\r\n2.75  finished ceiling height",
      "parkingAllocation": "Parking Allocation \r\nSubject to availability",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Kristeph",
      "businessDistrict": "Makati",
      "address": "4335 Dayap St. corner Filmore, Palanan, Makati City",
      "developer": "Makati Co. Inc",
      "yearConstructed": "Completion: 3Q 2020 (Sept)",
      "certifications": "Non-PEZA",
      "totalFloors": 4,
      "availableSpace": "DOES NOT GIVE BROKER FEES\r\n\r\nGF – 38 sqm (2 parking slots included in the rent)\r\n4F – 74 sqm\r\n4F – 13.6 sqm ",
      "totalAvailableSpace": 126,
      "density": "Density To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Warm Shell",
      "acSystem": "Tenant to provide  AC Sytem",
      "acCharges": "Metered",
      "quotedRent": "To be determined",
      "cusa": "Building Dues(PSM): To be determined",
      "parkingRate": "Parking Rate: To be determined",
      "lastUpdated": "May 15, 2023",
      "buildingGrade": "Grade: C",
      "grossLeasableArea": "GLA of Approx. 1,000 sqm",
      "floorPlate": "Approx. 250 sqm Floor Plate",
      "netUseableArea": "To be determined net usable",
      "backupPower": "100% Backup Power",
      "elevators": "1  Passenger Elevator",
      "telcoProviders": "Telco Providers: PLDT",
      "ceilingHeight": "To be determined ",
      "parkingAllocation": "Parking Allocation To be determined",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Poweraire Building",
      "businessDistrict": "Makati",
      "address": "1320 Filmore St. Brgy. Palanan, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion: To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 3,
      "availableSpace": "3F - 90 sqm",
      "density": "Density To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Semi-Fitted ",
      "acSystem": "Tenant to provide  AC Sytem",
      "acCharges": "Metered",
      "quotedRent": "\r\n2F – PhP 600\r\n3F – PhP 550",
      "cusa": "Building Dues(PSM): PhP 60",
      "parkingRate": "Parking Rate: Included in rent",
      "buildingGrade": "Grade: C",
      "grossLeasableArea": "GLA of To be determined sqm",
      "floorPlate": "Approx. 100 sqm Floor Plate",
      "netUseableArea": "To be determined net usable",
      "backupPower": "100% Backup Power",
      "elevators": "To be determined Passenger",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined ",
      "parkingAllocation": "Parking Allocation Not Available",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "RNC Plaza",
      "businessDistrict": "Makati",
      "address": "2671 Honduras St. Barangay San Isidro, Makati City",
      "developer": "To be determined",
      "yearConstructed": "Completion: To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 5,
      "availableSpace": "Fully Leased",
      "totalAvailableSpace": 0,
      "density": "Density To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Tenant to provide  AC Sytem",
      "acCharges": "Metered",
      "quotedRent": "PhP 550",
      "cusa": " To be determined",
      "parkingRate": "Parking Rate: Included in rent",
      "buildingGrade": "Grade: C",
      "grossLeasableArea": "GLA of 1,954 sqm",
      "floorPlate": "398 sqm Floor Plate",
      "netUseableArea": "To be determined net usable",
      "backupPower": "100% Backup Power",
      "elevators": "1 Passenger",
      "telcoProviders": "Telco Providers: To be determined",
      "ceilingHeight": "To be determined ",
      "parkingAllocation": "Parking Allocation 5 slots/floor",
      "twentyFourSevenOperations": "24/7 Operations: "
    },
    {
      "buildingName": "Tristar Building",
      "businessDistrict": "Makati",
      "address": "Iran St. corner EDSA Brgy, Pinagkaisahan, Makati City",
      "developer": "SM Prime Holdings, Inc.",
      "yearConstructed": "Completion: To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 5,
      "availableSpace": "GF  – 353.22 sqm\r\n2F – 387.74 sqm\r\n4F – 268.59 sqm",
      "density": "Density To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Tenant to provide  AC Sytem",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,000",
      "cusa": "PhP 170",
      "parkingRate": "PhP 5,000",
      "lastUpdated": "November 8, 2023",
      "buildingGrade": "Grade: C",
      "grossLeasableArea": "GLA of 1,859.70 sqm",
      "floorPlate": "Approx. 300 sqm Floor Plate",
      "netUseableArea": "To be determined net usable",
      "backupPower": "100% Backup Power",
      "elevators": "To be determined Passenger",
      "telcoProviders": "Telco Providers: Philcom, PLDT",
      "ceilingHeight": "To be determined ",
      "parkingAllocation": "Parking Allocation To be determined",
      "twentyFourSevenOperations": "24/7 Operations: Subject for approval"
    },
    {
      "buildingName": "Pet Plans Tower",
      "businessDistrict": "Makati",
      "address": "EDSA, Makati City",
      "developer": "DEI Properties",
      "yearConstructed": "To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": "To be determined ",
      "availableSpace": "2F - 115 sqm",
      "density": "Density To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition \r\nBare Shell (Optional to reinstate to Warm Shell)",
      "acSystem": "Variable Refrigerant Flow  AC Sytem",
      "acCharges": "Metered",
      "quotedRent": "Bare Shell – Upon negotiation with the Lessor\r\nWarm Shell – PhP 650",
      "cusa": "Building Dues(PSM): PhP 28",
      "parkingRate": "Parking Rate:\r\n1 Tandem Parking (2 slots) – PhP 7,000 /month\r\n2 Tandem Parking (4 slots) – PhP 14,000 /month",
      "buildingGrade": "Grade: C",
      "grossLeasableArea": "GLA of To be determined",
      "floorPlate": "Approx. 530 sqm Floor Plate",
      "netUseableArea": "To be determined net usable",
      "backupPower": "50% Backup Power",
      "elevators": "4 Passenger",
      "telcoProviders": "Telco Providers: Philcom, PLDT",
      "ceilingHeight": "To be determined",
      "parkingAllocation": "Parking Allocation \r\nMulti-tenants – 1 tandem parking (2 slots)\r\nWhole floor takers – 2 tandem parking (4 slots)",
      "twentyFourSevenOperations": "24/7 Operations: Yes"
    },
    {
      "buildingName": "Palmyra Building",
      "businessDistrict": "Makati",
      "address": "Kalayaan Avenue corner EDSA, Makati City",
      "developer": "SM Prime Holdings, Inc.",
      "yearConstructed": "Completion: To be determined",
      "certifications": "Non-PEZA",
      "totalFloors": 5,
      "availableSpace": "GF – 200.17 sqm\r\n2F – 700 sqm\r\n5F – 400 sqm",
      "density": "Density To be determined",
      "floorEfficiency": "To be determined Efficiency",
      "handoverCondition": "Handover Condition Bare Shell",
      "acSystem": "Tenant to provide  AC Sytem",
      "acCharges": "Metered",
      "quotedRent": "PhP 1,000",
      "cusa": "Building Dues(PSM): PhP 135",
      "parkingRate": "Parking Rate: PhP 5,000",
      "buildingGrade": "Grade: C",
      "grossLeasableArea": "GLA of 3,607.14 sqm",
      "floorPlate": "To be determined sqm Floor Plate",
      "netUseableArea": "To be determined net usable",
      "backupPower": "100% Backup Power",
      "elevators": "To be determined Passenger",
      "telcoProviders": "Telco Providers: Philcom, PLDT",
      "ceilingHeight": "To be determined ",
      "parkingAllocation": "Parking Allocation To be determined",
      "twentyFourSevenOperations": "24/7 Operations: Subject for approval"
    }
  ]