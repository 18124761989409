import React, { useEffect } from "react";
import useStorage from "../Hooks/UseStorage";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import usePhotoUpload from "../Hooks/UsePhotoUpload";
import useProfilePhotoUpload from "../Hooks/UseProfilePhotoUpload";

function ProfilePhotoProgress({ file, id,  setInProcess }) {
  const { url, progress } = useProfilePhotoUpload(file, id);

    useEffect(() => {
        if (url) {
            setInProcess(false);
        }
    }, [progress, url]);

  return (
    <div>
      <>
        <div className="bg-gray-200 rounded-full mt-4 flex items-center">
          <div
            className="bg-green-500 rounded-full h-2"
            style={{ width: progress + "%" }}
          ></div>
          {url && <CheckCircleIcon className="text-green-500" />}
        </div>
      </>
    </div>
  );
}


export default ProfilePhotoProgress;
