import React from "react";
import { useRef, useState, useEffect } from "react";
import {
  setDocData,
  addNewDoc,
  storage,
  getAllBuildingPhotosSnap,
  deleteDocById,
  deleteObjectByUrl,
  getDocDataSnap,
} from "../Firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { current } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { v4 } from "uuid";
import PhotoViewModal from "../components/PhotoViewModal";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Button from "../components/Button";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";

function BuildingPhotos({ buildingId, buildingDoc }) {
  const inputref = useRef();
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [selectedImg, setSelectedImg] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [primaryPhoto, setPrimaryPhoto] = useState(null);

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const getPhotos = async () => {
      const res = getAllBuildingPhotosSnap(
        "buildingPhotos",
        buildingId,
        (data) => {
          // arrange photos by date
          data.sort((a, b) => b.createdAt - a.createdAt);
          setPhotos(data);
        }
      );
    };
    getPhotos();
  }, [file]);

  useEffect(() => {
    const primary = buildingDoc.primaryPhoto;
    setPrimaryPhoto(primary);
  }, [buildingDoc]);

  const handleClick = (e) => {
    inputref.current.click();
  };

  const handleSelect = (e) => {
    const selected = e.target.files[0];
    setFile(selected);

    if (selected) {
      const handleUpload = async () => {
        const fileName = `buildings/${buildingId}/photos/${
          selected.name
        }+${v4()}`;
        const storageRef = ref(storage, fileName);
        const uploadTask = uploadBytesResumable(storageRef, selected);

        const res = uploadTask.on(
          "state_changed",
          (snap) => {
            let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
            setProgress(percentage);
          },
          (err) => {
            setError(err);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            const url = downloadURL;
            const createdAt = new Date();
            const res = await addNewDoc("buildingPhotos", {
              buildingId,
              url,
              createdAt,
              createdBy: currentUser.email,
            });

            setProgress(0);
          }
        );
        setProgress(0);
      };
      handleUpload();
    } else {
      setFile(null);
      setProgress(0);
    }
  };

  const handleDelelete = (item) => {
    if (window.confirm("Are you sure you want to delete this photo?")) {
      deleteDocById("buildingPhotos", item.id);
      deleteObjectByUrl(item.url);
      toast.success("Photo deleted");
    } else {
      return;
    }
  };

  const handlePrimarySet = (photo) => {
    const res = setDocData("masterlist", buildingId, {
      primaryPhoto: photo.id,
      primaryPhotoUrl: photo.url,
    });
    toast.success("Primary photo set");
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="uppercase text-sm font-bold text-leechiuBlue">
        Building Photos
      </div>
      {photos.length === 0 && (
        <div className="font-bold text-gray-600 text-sm">
          No Photos Uploaded
        </div>
      )}
      <div className="flex items-center gap-2">
        <button
          className="blue-button"
          onClick={() => {
            setEditMode(!editMode);
          }}
        >
          {editMode ? "Save" : "Edit"}
        </button>
        <button className="blue-button" onClick={handleClick}>
          Upload Photo
        </button>
        <input
          ref={inputref}
          className="hidden"
          type="file"
          accept="image/png, image/jpeg"
          onChange={handleSelect}
        />
        <div>
          {progress > 0 && (
            <div className="bg-gray-200 rounded-full h-2 w-40">
              <div
                className="bg-blue-800 rounded-full h-2"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          )}
        </div>
      </div>

      <div>
        <div className="flex items-center gap-4 flex-wrap">
          {photos.map((photo, index) => (
            <div key={index} className="relative flex items-center">
              <img
                src={photo.url}
                className="h-48"
                alt="building photo"
                onClick={() => setSelectedImg(photo.url)}
              />
              {editMode && (
                <button
                  className="absolute -top-6 -right-2 text-red-500"
                  onClick={() => {
                    handleDelelete(photo);
                  }}
                >
                  <RemoveCircleIcon />
                </button>
              )}
              {editMode && (
                <button
                  className="absolute bottom-0 text-xs text-white text-center w-full hover:bg-gray-700 bg-gray-600"
                  onClick={() => {
                    handlePrimarySet(photo);
                  }}
                >
                  {photo.id === primaryPhoto ? "Primary" : "Set as primary"}
                </button>
              )}
            </div>
          ))}
        </div>
      </div>

      <div>
        {selectedImg && (
          <PhotoViewModal
            selectedImg={selectedImg}
            setSelectedImg={setSelectedImg}
          />
        )}
      </div>
    </div>
  );
}

export default BuildingPhotos;
