import * as React from "react";
import { IoAppsSharp } from "react-icons/io5";
import { IoList } from "react-icons/io5";
import { BsBuildingCheck } from "react-icons/bs";
import { HiMiniBuildingOffice2 } from "react-icons/hi2";
import { MdForum } from "react-icons/md";
import { RiBillLine } from "react-icons/ri";
import { FaPeopleArrows } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa";
import { HiDocumentReport } from "react-icons/hi";
import { NavLink } from "react-router-dom";
import ProfilePicture from "../../components/ProfilePicture";
import { useSelector } from "react-redux";

export default function Sidebar() {
  const currentUser = useSelector((state) => state.userObject);

  const sideBarLinks = [
    {
      link: "dashboard",
      label: "Dashboard",
      icon: <IoAppsSharp />,
    },

    {
      link: "tasks",
      label: "All Tasks",
      icon: <IoList />,
    },
    {
      link: "legal-templates",
      label: "Legal Templates",
      icon: <IoList />,
    },

    {
      link: "all-listings",
      label: "All Listings",
      icon: <BsBuildingCheck />,
    },
    // {
    //   link: "hospitality",
    //   label: "Hospitality",
    //   icon: <HomeWorkIcon className="mr-4" />,
    // },
    // {
    //   link: "residential",
    //   label: "Residential",
    //   icon: <PiHouseLineLight />,
    // },
    {
      link: "leasing?link=Masterlist",
      label: "Office Leasing",
      icon: <HiMiniBuildingOffice2 />,
    },
    {
      link: "research",
      label: "Research and Valuation",
      icon: <HiDocumentReport />,
    },
    {
      link: "discussion?link=requirements",
      label: "Discussion Board",
      icon: <MdForum />,
    },

    {
      link: "billing",
      label: "Request to Bill",
      icon: <RiBillLine />,
    },
    {
      link: "billinglist/mybillings",
      label: "View All Billings",
      icon: <RiBillLine />,
    },
    {
      link: "myclients",
      label: "Clients Database",
      icon: <FaPeopleArrows />,
    },
    {
      link: "clients_db",
      label: "Log Client Interaction",
      icon: <FaPeopleArrows />,
    },
    {
      link: "myprofile",
      label: "My Profile",
      icon: <FaRegUser />,
    },
  ];

  const approverSidebarLinks = [
    {
      link: "billinglist/billingsPending",
      label: "Billings for Approval",
      icon: <RiBillLine />,
    },
  ];

  const adminSidebarLinks = [
    {
      link: "userAccess",
      label: "User Access ",
      icon: <FaRegUser />,
    },
  ];

  const financeSidebarLinks = [
    {
      link: "billinglist/financePending",
      label: "Finance for Approval",
      icon: <RiBillLine />,
    },
  ];

  return (
    <div className="text-sm text-gray-600 bg-gray-100 w-64 p-2 min-h-screen sidebar">
      <div className="flex items-center justify-center mb-4">
        <img
          src="https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721711625/leechiuweb3/newlogo_leechiu_sm3mgv_1_mop8e5.png"
          className="h-12 "
          alt=""
        />
      </div>

      <div className="bg-white p-2 rounded-xl">
        <ProfilePicture />
      </div>

      <div className="flex flex-col items-start gap-2 mt-4 bg-white p-2 rounded-xl">
        {sideBarLinks.map((item, index) => (
          <div key={index}>
            <NavLink to={item.link}>
              <div className="flex items-center gap-2">
                <div>{item.icon}</div>
                <div>{item.label}</div>
              </div>
            </NavLink>
          </div>
        ))}
      </div>

      <div className="mt-2 rounded-xl bg-white p-2">
        {currentUser.roles && currentUser.roles.includes("approver") && (
          <h4 className=" p-2 uppercase text-sm text-leechiuBlue">
            Approver Functions
          </h4>
        )}

        {currentUser.roles &&
          currentUser.roles.includes("finance") &&
          financeSidebarLinks.map((item, index) => (
            <div key={index}>
              <NavLink to={item.link}>
                <div className="flex items-center gap-2">
                  <div>{item.icon}</div>
                  <div>{item.label}</div>
                </div>
              </NavLink>
            </div>
          ))}

        {currentUser.roles &&
          currentUser.roles.includes("approver") &&
          approverSidebarLinks.map((item, index) => (
            <div key={index}>
              <NavLink to={item.link}>
                <div className="flex items-center gap-2">
                  <div>{item.icon}</div>
                  <div>{item.label}</div>
                </div>
              </NavLink>
            </div>
          ))}

        {currentUser.roles && currentUser.roles.includes("admin") && (
          <h4 className="p-2 uppercase text-leechiuBlue font-bold text-sm">
            Admin Functions
          </h4>
        )}

        {currentUser.roles &&
          currentUser.roles.includes("admin") &&
          adminSidebarLinks.map((item, index) => (
            <div key={index}>
              <NavLink to={item.link}>
                <div className="flex items-center gap-2">
                  <div>{item.icon}</div>
                  <div>{item.label}</div>
                </div>
              </NavLink>
            </div>
          ))}
      </div>
    </div>
  );
}
