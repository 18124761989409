import React from "react";

function Button({ label, ...otherprops }) {
  return (
    <>
      <button
        className="bg-leechiuBlue hover:bg-blue-700 text-white py-2 px-4 rounded w-fit disabled:opacity-50 disabled:bg-slate-500 text-sm blue-button"
        {...otherprops}
      >
        {label}
      </button>
    </>
  );
}

export default Button;
