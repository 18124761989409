import {
  getDocDataSnap,
  addNewDoc,
  setDocData,
  queryAllDocsByField,
  deleteDocById,
} from "../../Firebase/Firebase.utils";
import { useState, useEffect } from "react";
import Modal from "../Top1k/Modal";
import AddInteractionForm from "./AddInteractionForm";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";

function InteractionData({ setActiveContactMode, contact }) {
  const [addMode, setAddMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [interactionDataToEdit, setInteractionDataToEdit] = useState({});

  const [interactions, setInteractions] = useState([]);

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const fetchInteractions = async () => {
      const interactions = await queryAllDocsByField(
        "interactions",
        "touch_point_ref",
        "==",
        contact.id,
        (results) => {
          setInteractions(results);
          setIsLoading(false);
        }
      );
    };
    fetchInteractions();
  }, [contact]);

  //arrange interactions by date
  useEffect(() => {
    const sorted = interactions.sort((a, b) => {
      if (a.date < b.date) {
        return -1;
      }
      if (a.date > b.date) {
        return 1;
      }
      return 0;
    });
    setInteractions(sorted);
  }, [interactions]);

  const tableHeaders = ["Type", "Date", "Notes", "By", "Actions"];

  const handleAdd = () => {
    setAddMode(true);
  };

  if (isLoading) return <div>Loading...</div>;

  const handleEdit = (interaction) => {
    setEditMode(true);
    setAddMode(true);
    setInteractionDataToEdit(interaction);
  };

  const handleDelete = async (interaction) => {
    if (window.confirm("Are you sure you want to delete this interaction?")) {
      await deleteDocById("interactions", interaction.id);
      const updatedInteractions = interactions.filter(
        (item) => item.id !== interaction.id
      );
      setInteractions(updatedInteractions);
      toast.success("Interaction deleted successfully");
    }
  };

  return (
    <div className="relative p-2 rounded-xl bg-white text-zinc-800">
      <button
        className="absolute top-2 right-2 underline text-leechiuBlue text-xs"
        onClick={() => setActiveContactMode(false)}
      >
        Close
      </button>

      <div className="uppercase font-bold text-leechiuBlue mb-4">
        {contact.name_of_person} - {contact.designation}
      </div>
      <div>
        <button
          onClick={handleAdd}
          className="bg-leechiuOrange text-white p-2 shadow-sm text-xs"
        >
          Add an interaction
        </button>
      </div>

      <table className="w-full mt-8">
        <thead>
          <tr>
            {tableHeaders.map((header) => (
              <th
                key={header}
                className="text-left w-[600px]
                border-b border-gray-200 p-2 bg-zinc-200 text-zinc-800
              "
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {interactions.map((interaction) => (
            <tr key={interaction.type} className="border-b border-gray-200">
              <td className="w-36 p-2 capitalize">{interaction.type}</td>
              <td className="w-36 p-2">{interaction.date}</td>
              <td className="p-2 max-w-[400px] w-fit">{interaction.notes}</td>
              <td className="w-36 p-2 ">{interaction.created_by}</td>
              <td className="space-x-4 w-36 p-2">
                {interaction.created_by === currentUser.email && (
                  <button onClick={() => handleEdit(interaction)}>Edit</button>
                )}

                {interaction.created_by === currentUser.email && (
                  <button onClick={() => handleDelete(interaction)}>
                    Delete
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {addMode && (
        <Modal>
          <AddInteractionForm
            setAddMode={setAddMode}
            contact={contact}
            editMode={editMode}
            setEditMode={setEditMode}
            interactionDataToEdit={interactionDataToEdit}
          />
        </Modal>
      )}
    </div>
  );
}

export default InteractionData;
