import React from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getDocData, setDocData } from "../../Firebase/Firebase.utils";
import InputBox from "../../components/InputBox";
import SpinnerPage from "../../components/SpinnerPage";
import { useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";

function DetailedInfo({ listing }) {
  const { id } = useParams();
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState(listing);
  const [processing, setProcessing] = useState(false);

  const {
    property_name,
    listing_type,
    address,
    google_maps_pin,
    owner,
    lot_area,
    zoning,
    zonal,
    commission_rate,
    selling_price,
    leaseRate, // for leasing
    feeMonths,
    potential_fee,
    leasingPotential_fee,
    frontage,
    far,
    height_limit,
    exclusive,
    agency_start,
    agency_end,
    current_use,
    tenanted,
    monthly_rental,
    handover_condition,
    appraisal,
    total_price,
    listing_description,
    vatable,
    website_title,
    upload_website,
  } = formData;

  const handleEditMode = (e) => {
    e.preventDefault();
    setEditMode(!editMode);
    if (editMode) {
      setProcessing(true);
      const processData = async () => {
        const res = setDocData("listings", id, formData);
        if (res) {
          setProcessing(false);
        }
      };
      processData();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const { selling_price, commission_rate, lot_area } = formData;
    const potential_fee = (selling_price * commission_rate * lot_area) / 100;
    setFormData({ ...formData, potential_fee });
  }, [formData.selling_price, formData.commission_rate, formData.lot_area]);

  return (
    <motion.div
      className="mt-4 bg-white p-2 rounded-xl text-sm"
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
    >
      {processing && <SpinnerPage />}

      <button
        className="bg-leechiuBlue hover:bg-blue-700 text-white py-2 px-4 rounded mb-4"
        onClick={handleEditMode}
      >
        {editMode ? "Save" : "Edit"}
      </button>

      {!editMode ? (
        <div>
          <h4>Listing Detailed Information</h4>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <tbody>
              {Object.entries({
                "Property Name": property_name,
                "Website Title": website_title,
                "Upload to Website": upload_website || "Yes",
                Description: listing_description,
                "Listing Type": listing_type,
                Address: address,
                "Google Maps Pin": (
                  <a
                    href={google_maps_pin}
                    target="_blank"
                    className="underline"
                  >
                    Click Here for Google Maps Pin
                  </a>
                ),
                "Owner on Title": owner,
                "Lot Area in sqm": parseFloat(lot_area).toLocaleString(),
                Zoning: zoning,
                "Zonal Value per sqm": parseFloat(zonal).toLocaleString(),
                "Commission Rate": `${commission_rate}%`,
                "Selling Price per sqm":
                  parseFloat(selling_price).toLocaleString(),
                "Total Price": parseFloat(total_price).toLocaleString(),
                "Lease rate if leasing": leaseRate,
                Vatable: vatable,
                "Fee in Months if leasing": feeMonths,
                "Potential Fee": parseFloat(potential_fee).toLocaleString(),
                "Potential Fee if Leasing":
                  parseFloat(leasingPotential_fee).toLocaleString(),
                "Appraised Value per sqm":
                  parseFloat(appraisal).toLocaleString(),
                Frontage: frontage,
                "Floor Area Ratio": far,
                "Height Limit": height_limit,
                Exclusive: exclusive,
                "Agency Start": agency_start,
                "Agency End": agency_end,
                "Current Use": current_use,
                Tenanted: tenanted,
                "Monthly Rental": monthly_rental,
                "Handover Condition": handover_condition,
              }).map(([label, value], index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
                    padding: "2px",
                  }}
                >
                  <td
                    className="w-48"
                    style={{ padding: "8px", border: "1px solid #ddd" }}
                  >
                    {label}
                  </td>
                  <td
                    className="w-96"
                    style={{ padding: "8px", border: "1px solid #ddd" }}
                  >
                    {value}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}

      {editMode ? (
        <form>
          <div className="flex flex-wrap gap-2 ">
            <InputBox
              label={"Property Name"}
              value={formData.property_name || ""}
              name="property_name"
              onChange={handleInputChange}
            />

            <InputBox
              label={"Website Title"}
              value={formData.website_title || ""}
              name="website_title"
              onChange={handleInputChange}
            />

            <InputBox
              label={"Upload to Website?"}
              value={formData.upload_website}
              placeholder={"Yes or No"}
              name="upload_website"
              onChange={handleInputChange}
            />

            <InputBox
              label={"Description"}
              value={formData.listing_description || ""}
              name="listing_description"
              onChange={handleInputChange}
            />

            <InputBox
              label={"Owner on Title"}
              value={formData.owner || ""}
              name="owner"
              onChange={handleInputChange}
            />

            <InputBox
              label={"Area in sqms"}
              value={formData.lot_area || ""}
              name="lot_area"
              onChange={handleInputChange}
              type={"number"}
            />

            <InputBox
              label={"Property Address"}
              value={formData.address || ""}
              name="address"
              onChange={handleInputChange}
            />

            <InputBox
              label={"Google Maps Pin"}
              value={formData.google_maps_pin || ""}
              name="google_maps_pin"
              onChange={handleInputChange}
            />

            <InputBox
              label={"Zoning"}
              value={formData.zoning || ""}
              name="zoning"
              onChange={handleInputChange}
            />

            <InputBox
              label={"Zonal Value"}
              value={formData.zonal || ""}
              name="zonal"
              onChange={handleInputChange}
              type={"number"}
            />

            <InputBox
              label={"Appraised Value per sqm"}
              value={formData.appraisal || ""}
              name="appraisal"
              onChange={handleInputChange}
              type={"number"}
            />

            <InputBox
              label={"Commission Rate in %"}
              value={formData.commission_rate || ""}
              placeholder={"e.g. 3"}
              type={"number"}
              name="commission_rate"
              onChange={handleInputChange}
            />

            <InputBox
              label={"Selling Price per sqm"}
              value={formData.selling_price || ""}
              name="selling_price"
              onChange={handleInputChange}
              type={"number"}
            />

            <InputBox
              label={"Lease rate per sqm if leasing"}
              value={formData.leaseRate || ""}
              name="leaseRate"
              onChange={handleInputChange}
              type={"number"}
            />
            <InputBox
              label={"Number of months fee if leasing"}
              value={formData.leaseMonths || ""}
              name="leaseMonths"
              onChange={handleInputChange}
              type={"number"}
            />

            <InputBox
              label={"Total Price"}
              value={formData.total_price || ""}
              name="total_price"
              onChange={handleInputChange}
              type={"number"}
            />

            <InputBox
              label={"Vatable"}
              value={formData.vatable || ""}
              name="vatable"
              onChange={handleInputChange}
              type={"text"}
              placeholder={"Yes or No"}
            />

            <InputBox
              label={"Potential Fee if Sale"}
              value={parseFloat(formData.potential_fee).toLocaleString() || 0}
              disabled={true}
              name="potential_fee"
            />

            <InputBox
              label={"Potential Fee if Leasing"}
              value={formData.leasingPotential_fee || ""}
              name="leasingPotential_fee"
              onChange={handleInputChange}
              type={"number"}
            />

            <InputBox
              label={"Frontage"}
              value={formData.frontage || ""}
              name="frontage"
              onChange={handleInputChange}
            />

            <InputBox
              label={"Floor Area Ratio"}
              value={formData.far || ""}
              name="far"
              onChange={handleInputChange}
            />

            <InputBox
              label={"Height Limit"}
              value={formData.height_limit || ""}
              name="height_limit"
              onChange={handleInputChange}
            />

            <InputBox
              label={"Agency Exclusive?"}
              value={formData.exclusive || ""}
              name="exclusive"
              onChange={handleInputChange}
              placeholder={"Yes or No"}
            />

            <InputBox
              label={"Agency Agreement Start Date"}
              type="date"
              value={formData.agency_start || ""}
              name="agency_start"
              onChange={handleInputChange}
            />

            <InputBox
              label={"Agency Agreement Expiry Date"}
              type="date"
              value={formData.agency_end || ""}
              name="agency_end"
              onChange={handleInputChange}
            />

            <InputBox
              label={"Current Use of Property"}
              type="text"
              value={formData.current_use || ""}
              name="current_use"
              onChange={handleInputChange}
            />

            <InputBox
              label={"Tenanted"}
              type="text"
              placeholder={"Yes or No"}
              value={formData.tenanted || ""}
              name="tenanted"
              onChange={handleInputChange}
            />

            <InputBox
              label={"Monthly Rental Income"}
              type="text"
              value={formData.monthly_rental || ""}
              name="monthly_rental"
              onChange={handleInputChange}
            />

            <InputBox
              label={"Handover Condition"}
              type="text"
              value={formData.handover_condition || ""}
              name="handover_condition"
              onChange={handleInputChange}
            />
          </div>
        </form>
      ) : null}

      <div className="bubble">
        <div>Other Options</div>
        <div>
          <InputBox
            name="websitePrice"
            value={formData.websitePrice}
            onChange={handleInputChange}
            label="Website Price per Sqm"
            disabled={!editMode}
            type="text"
            placeholder={"Enter the price for the website"}
          />

          <InputBox
            name="totalWebsitePrice"
            value={formData.totalWebsitePrice}
            onChange={handleInputChange}
            label="Total Advertised Price"
            disabled={!editMode}
            type="text"
            placeholder={"Enter the price for the website"}
          />
        </div>
      </div>
    </motion.div>
  );
}

export default DetailedInfo;
