import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getDocDataSnap,
  setDocData,
  deleteDocById,
  storage,
} from "../Firebase/Firebase.utils";
import Tiptap from "./tiptap/Tiptap";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

function ArticleDetails() {
  const { id } = useParams();
  const [article, setArticle] = React.useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [content, setContent] = useState({});
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const fetchArticle = async () => {
      await getDocDataSnap("news", id, (data) => {
        setArticle(data);
        setContent(data.content);
        setIsLoading(false);
      });
    };

    fetchArticle();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleEdit = async (e) => {
    setEditMode(!editMode);

    if (editMode) {
      await setDocData("news", id, {
        ...article,
        content,
      });
      toast.success("Article successfully saved");
    }
  };

  const handleDelete = async (e) => {
    if (window.confirm("Are you sure you want to delete this article?")) {
      await deleteDocById("news", id);
      toast.success("Article successfully deleted");
      navigate("/news");
    }
  };

  const togglePublish = async (e) => {
    await setDocData("news", id, {
      status: article.status === "published" ? "unpublished" : "published",
    });

    toast.success("Article successfully updated");
  };

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `news/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
        setProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        console.error(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setDocData("news", id, {
            ...article,
            coverPhoto: downloadURL,
          });
        });
      }
    );
  };

  return (
    <div className="bubble text-gray-600 relative">
      <div className="absolute top-1 right-1">
        <div
          onClick={togglePublish}
          className={` p-1 rounded text-xs cursor-pointer ${
            article.status === "published"
              ? "bg-green-600 text-white "
              : "bg-gray-100"
          }`}
        >
          {article.status}
        </div>
      </div>
      <div className="mb-8 flex items-center gap-2">
        <button className="blue-button" onClick={handleEdit}>
          {editMode ? "Save" : "Edit"}
        </button>
        {editMode && (
          <button className="underline text-xs" onClick={handleDelete}>
            Delete
          </button>
        )}
      </div>
      <div className="flex flex-col items-start gap-2">
        <textarea
          className="w-full header"
          type="text"
          value={article.title}
          disabled={!editMode}
        />
        <input value={article.author} disabled={!editMode} />
        <input value={article.date} disabled={!editMode} />
      </div>

      <div className=" text-xs mt-8 ">
        <div>Cover Photo</div>
        <div className="flex flex-col gap-2">
          <input type="file" onChange={handleUpload} />
          {progress > 0 && <progress value={progress} max="100" />}
        </div>

        {article.coverPhoto && (
          <img
            src={article.coverPhoto}
            className="w-full h-40 object-cover"
            alt=""
          />
        )}
      </div>

      <div className="w-full min-h-[500px] border-2 mt-8 p-2">
        <div className="border">
          <Tiptap
            content={content}
            setContent={setContent}
            editable={editMode}
            placeholder="edit here"
          />
        </div>

        <div></div>
      </div>
    </div>
  );
}

export default ArticleDetails;
