import React, { useEffect } from "react";
import { setDocData } from "../Firebase/Firebase.utils";
import toast from "react-hot-toast";
import Modal from "../components/Modal";
import NewContactForm from "./NewContactForm";
import { queryAllDocsByField } from "../Firebase/Firebase.utils";
import ContactCard from "./ContactCard";

export default function CompanyProfile({ requirementData }) {
  const [editMode, setEditMode] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState(requirementData);
  const [addMode, setAddMode] = React.useState(false);
  const [contacts, setContacts] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const fetchContacts = async () => {
      await queryAllDocsByField(
        "contacts",
        "accountId",
        "==",
        requirementData.id,
        (data) => {
          data.sort((a, b) => {
            return a.contactName.localeCompare(b.contactName);
          });
          setContacts(data);
          setIsLoading(false);
        }
      );
    };
    fetchContacts();
  }, []);

  if (isLoading) {
    return <div>Loading</div>;
  }

  const tableFields = [
    { label: " Name", value: "tenantName" },
    {
      label: " Industry",
      value: "tenantIndustry",
    },
    {
      label: " Contact Person",
      value: "tenantContactPerson",
    },
    {
      label: " Contact Number",
      value: "tenantContactNumber",
    },
    {
      label: " Email Address",
      value: "tenantEmail",
    },
  ];

  const handleEditMode = async () => {
    setEditMode(!editMode);

    if (editMode) {
      setIsProcessing(true);
      await setDocData("landlordRepRequirements", requirementData.id, formData);
      setIsProcessing(false);
      toast.success("Changes saved");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  return (
    <div>
      <div className="bubble">
        <div>
          <button className="blue-button mb-4" onClick={handleEditMode}>
            {editMode ? "Save" : "Edit"}
          </button>
        </div>
        <div className="header mb-4">Company Profile</div>
        {!editMode && (
          <div className="flex items-center flex-wrap max-w-5xl text-sm">
            <table className="w-full bg-white border border-gray-300">
              <tbody>
                {tableFields.map((field, index) => (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
                  >
                    <td className="border border-gray-300 px-2 py-1">
                      {field.label}
                    </td>
                    <td className="border border-gray-300 px-2 py-1">
                      {requirementData[field.value]}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {editMode && (
          <div className="flex items-center flex-wrap max-w-5xl text-sm">
            <table className="w-full bg-white border border-gray-300">
              <tbody>
                {tableFields.map((field, index) => (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
                  >
                    <td className="border border-gray-300 px-2 py-1">
                      {field.label}
                    </td>
                    <td className="border border-gray-300 px-2 py-1">
                      <input
                        type="text"
                        name={field.value}
                        placeholder="Enter value"
                        value={requirementData[field.value]}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <div>
        <div className="header mt-4">contact persons</div>
        <div>
          <button className="blue-button" onClick={handleAddMode}>
            Add additional contact person
          </button>
        </div>
      </div>

      <div className="mt-4 space-y-4">
        {contacts.map((contact, index) => (
          <ContactCard key={index} contact={contact} />
        ))}
      </div>

      {addMode && (
        <Modal>
          <NewContactForm
            requirementData={requirementData}
            handleAddMode={handleAddMode}
          />
        </Modal>
      )}
    </div>
  );
}
