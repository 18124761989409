import React from "react";
import { getDocDataSnap, setDocData } from "../Firebase/Firebase.utils";
import Modal from "./Modal";
import AddCardForm from "./AddCardForm";
import toast from "react-hot-toast";

function Datapage({ pageName, pageId }) {
  const [formData, setFormData] = React.useState({});
  const [pageData, setPageData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [editMode, setEditMode] = React.useState(false);
  const [addMode, setAddMode] = React.useState(false);
  const [editCard, setEditCard] = React.useState(false);
  const [editIndex, setEditIndex] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  React.useEffect(() => {
    getDocDataSnap("website", pageId, (data) => {
      setPageData(data);
      setFormData(data);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const createDataObject = (data) => {
    return {
      ...data,
    };
  };

  const handleSave = async (e) => {
    e.preventDefault();

    setEditMode(!editMode);
    if (editMode) {
      if (window.confirm("Are you sure you want to save changes?")) {
        setIsSubmitting(true);
        const data = createDataObject(formData);
        setDocData("website", pageId, data).then(() => {
          setPageData(formData);
          setIsSubmitting(false);
          toast.success("Data Saved Successfully");
        });
      } else {
        setFormData(pageData);
      }
    }
  };

  const tableHeaders = ["Title", "Description", "Action"];

  const handleDeleteCard = (index) => {
    if (window.confirm("Are you sure you want to delete this card?")) {
      const newCards = pageData.cards.filter((card, i) => i !== index);
      const data = {
        cards: newCards,
      };
      setDocData("website", pageId, data);
      setPageData({ ...pageData, cards: newCards });
      toast.success("Card Deleted Successfully");
    }
  };

  return (
    <div>
      <div className="bg-white rounded-xl p-2 mt-4 text-sm">
        <form onSubmit={handleSave} className="space-y-4">
          <div className="flex items-center gap-2 ">
            <div className="w-32 md:w-80">Tag Line</div>
            <input
              type="text"
              placeholder="input tag line"
              className="px-4 py-2 rounded border w-full text-sm"
              name="tagline"
              onChange={handleChange}
              disabled={!editMode}
              value={formData.tagline}
            />
          </div>

          <div className="flex items-center gap-2 ">
            <div className="w-32 md:w-80">Page Description</div>
            <textarea
              type="text"
              rows={8}
              placeholder="input page description"
              className="px-4 py-2 rounded border w-full text-sm"
              name="pageDescription"
              onChange={handleChange}
              disabled={!editMode}
              value={formData.pageDescription}
            />
          </div>

          <div className="flex items-center gap-2 ">
            <div className="w-32 md:w-80">Solutions Description</div>
            <textarea
              type="text"
              rows={8}
              placeholder="input solutions description"
              className="px-4 py-2 rounded border w-full text-sm"
              name="solutionsDescription"
              onChange={handleChange}
              disabled={!editMode}
              value={formData.solutionsDescription}
            />
          </div>

          <div className="flex items-center gap-2 ">
            <div className="w-32 md:w-80">Photo Card Description</div>
            <textarea
              type="text"
              rows={8}
              placeholder="input photo card description"
              className="px-4 py-2 rounded border w-full text-sm"
              name="photoCardDescription"
              onChange={handleChange}
              disabled={!editMode}
              value={formData.photoCardDescription}
            />
          </div>

          <div className="flex items-center gap-2">
            <button className="bg-leechiuBlue border border-leechiuBlue text-white px-4 py-2 rounded mt-2">
              {editMode ? (isSubmitting ? "Saving" : "Save") : "Edit"}
            </button>
            {editMode && (
              <button
                className="border border-black text-zinc-700 px-4 py-2 rounded mt-2"
                onClick={(e) => {
                  e.preventDefault();
                  setFormData(pageData);
                  setEditMode(false);
                }}
              >
                Cancel
              </button>
            )}
          </div>
        </form>
      </div>

      <div className="bg-white p-2 rounded-xl mt-4 mb-4">
        <div className="text-leechiuBlue text-sm font-bold">
          <button
            className="bg-leechiuBlue px-4 py-2 border border-leechiuBlue text-white rounded text-sm"
            onClick={() => setAddMode(!addMode)}
          >
            Add a Solution
          </button>
        </div>

        <div>
          <table className="w-full mt-4 text-xs">
            <thead>
              <tr>
                {tableHeaders.map((header, index) => (
                  <th key={index} className="border border-gray-300 p-2">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {pageData.cards?.map((card, index) => (
                <tr key={index}>
                  <td className="border border-gray-300 p-2">{card.title}</td>
                  <td className="border border-gray-300 p-2">
                    {card.description}
                  </td>
                  <td className="border border-gray-300 p-2 space-x-4 space-y-4 text-right w-20 md:w-32">
                    <button
                      onClick={() => {
                        setEditCard(true);
                        setAddMode(true);
                        setEditIndex(index);
                      }}
                      className="text-sm text-leechiuBlue"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDeleteCard(index)}
                      className="text-sm text-red-500"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {addMode && (
        <Modal>
          <AddCardForm
            page={pageId}
            setAddMode={setAddMode}
            cards={pageData.cards}
            setEditCard={setEditCard}
            editIndex={editIndex}
            editCard={editCard}
          />
        </Modal>
      )}
    </div>
  );
}

export default Datapage;
