import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import InputBox from "../../components/InputBox";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import { getDocDataSnap, setDocData } from "../../Firebase/Firebase.utils";
import { useSelector } from "react-redux";

function Marketing() {
  const { id } = useParams();
  const [showEditor, setShowEditor] = useState(false);
  const [formData, setFormData] = useState({});
  const [marketingTracker, setMarketingTracker] = useState([]);
  const currentUser = useSelector((state) => state.userObject);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const getSnap = async () => {
      try {
        const res = await getDocDataSnap("listings", id, (data) => {
          if (data.marketing_tracker === undefined) {
            setMarketingTracker([]);
          } else {
            setMarketingTracker(data.marketing_tracker);
          }
        });
      } catch (error) {}
    };
    getSnap();
  }, []);

  const handleShowEditor = () => {
    setShowEditor(!showEditor);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let docObject = {};

    if (
      formData.id === undefined ||
      formData.id === null ||
      formData.id === ""
    ) {
      docObject = {
        marketing_tracker: [
          ...marketingTracker,
          {
            name: formData.name,
            company_name: formData.company_name,
            date_of_inquiry: formData.date_of_inquiry,
            description: formData.description,
            created_by: currentUser.email,
            created_at: new Date(),
            id: Math.floor(Math.random() * 100000000),
          },
        ],
      };
    } else {
      docObject = {
        marketing_tracker: [
          ...marketingTracker,
          {
            name: formData.name,
            company_name: formData.company_name,
            date_of_inquiry: formData.date_of_inquiry,
            description: formData.description,
            created_by: currentUser.email,
            created_at: new Date(),
            id: formData.id,
          },
        ],
      };
    }

    const sendMarketingTracker = async () => {
      try {
        const res = setDocData("listings", id, docObject);
      } catch (error) {}
    };
    sendMarketingTracker();
    setFormData("");
    setShowEditor(false);
    setEditMode(false);
  };

  const handleDelete = (e, itemId) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete this item?")) {
      const newMarketingTracker = marketingTracker.filter(
        (item) => item.id !== itemId
      );
      setMarketingTracker(newMarketingTracker);
      let docObject = {};
      docObject = {
        marketing_tracker: [...newMarketingTracker],
      };
      const sendMarketingTracker = async () => {
        try {
          const res = setDocData("listings", id, docObject);
        } catch (error) {}
      };
      sendMarketingTracker();
    } else {
      return;
    }
  };

  const handleEdit = (e, item) => {
    setEditMode(true);
    e.preventDefault();
    const newMarketingTracker = marketingTracker.filter(
      (n) => n.id !== item.id
    );
    setMarketingTracker(newMarketingTracker);
    setShowEditor(true);
    setFormData(item);
  };

  return (
    <motion.div initial={{ opacity: 0, x: 100 }} animate={{ opacity: 1, x: 0 }}>
      <h4 className="mb-4">Marketing Tracker</h4>
      <button
        onClick={handleShowEditor}
        className="bg-leechiuBlue hover:bg-blue-700 text-white text-xs py-2 px-4 rounded mb-4"
      >
        {showEditor ? "Close" : "Add New"}
      </button>

      {showEditor && (
        <motion.div
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-8  bg-gray-100 p-2 border pb-16"
        >
          <form onSubmit={handleSubmit}>
            <div
              className="
            flex flex-wrap
            lg:grid lg:grid-cols-2"
            >
              <div>
                <InputBox
                  label={"Name of Prospect"}
                  placeholder={"Enter name of prospect"}
                  onChange={handleChange}
                  name="name"
                  required
                  value={formData.name}
                />
                <InputBox
                  label={"Company Name"}
                  placeholder={"Company Name"}
                  onChange={handleChange}
                  name="company_name"
                  required
                  value={formData.company_name}
                />
                <InputBox
                  label={"Date of Inquiry"}
                  type="date"
                  onChange={handleChange}
                  name="date_of_inquiry"
                  required
                  value={formData.date_of_inquiry}
                />
              </div>
              <textarea
                className=" w-full border md:h-36 sm:h-36 lg:h-60 lg:col-span-1 p-4"
                placeholder="Enter short description or feedback from prospect"
                onChange={handleChange}
                name="description"
                required
                value={formData.description}
              ></textarea>
            </div>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white text-xs py-2 px-4 rounded mb-4 mt-4 float-right"
            >
              Add
            </button>
          </form>
        </motion.div>
      )}

      <div className="trackerlist">
        {marketingTracker.map((item) => (
          <div
            className="bg-white p-2 border rounded mb-4 relative bubble text-gray-600"
            key={item.id}
          >
            <div className="flex justify-between">
              <div className="text-xs text-gray-500">
                {item.date_of_inquiry}
              </div>
              <div className="text-xs text-gray-500">{item.created_by}</div>
            </div>
            <div className="text-md font-bold uppercase">
              {item.company_name}
            </div>
            <div className="text-xs text-gray-800 mb-2 ">{item.name}</div>
            <div className="text-xs text-gray-800 mb-4">{item.description}</div>
            <div className="absolute bottom-0 right-2">
              <button
                className="text-xs py-2 px-4 rounded mt-4 disabled:text-gray-300 disabled:cursor-not-allowed underline"
                onClick={(e) => handleDelete(e, item.id)}
                disabled={editMode}
              >
                Delete
              </button>
              <button
                disabled={editMode}
                onClick={(e) => handleEdit(e, item)}
                className="text-xs py-2 px-4 rounded mt-4 disabled:text-gray-300 disabled:cursor-not-allowed underline"
              >
                Edit
              </button>
            </div>
          </div>
        ))}
      </div>
    </motion.div>
  );
}

export default Marketing;
