import React from "react";
import { addNewDoc } from "../../Firebase/Firebase.utils";
import toast from "react-hot-toast";
import InputBox from "../../components/InputBox";
import TextArea from "../../components/TextArea";
import Select from "react-select";
import ProfilePhotoOnly from "../../components/ProfilePhotoOnly";
import { useSelector } from "react-redux";

function AddTaskForm({ listing, handleAddMode }) {
  const [formData, setFormData] = React.useState({});
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const currentUser = useSelector((state) => state.userObject);

  const options = listing.team_members.map((member) => ({
    value: member,
    label: <ProfilePhotoOnly email={member} />,
  }));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const docObject = {
      ...formData,
      listing_id: listing.id,
      created_at: new Date().toISOString(),
      status: "Open",
      created_by: currentUser.email,
    };
    await addNewDoc("tasks", docObject);
    setIsSubmitting(false);
    handleAddMode();
    toast.success("Task added successfully");
    // Add task to firebase
  };

  const handleSelect = (e) => {
    let selected = e.map((item) => item.value);
    setFormData({ ...formData, assignee_emails: selected });
  };
  return (
    <div className="relative max-w-[350px] min-h-[500px]">
      <div
        className="underline top-0 right-0 absolute cursor-pointer text-xs"
        onClick={handleAddMode}
      >
        Close
      </div>
      <div className="header pt-8">Add Task</div>
      <form className="flex flex-col gap-2 mt-4" onSubmit={handleSubmit}>
        <InputBox
          name="task_name"
          label="Task Name"
          type="text"
          value={formData.task_name}
          placeholder="Enter task name"
          onChange={handleChange}
          required={true}
        />

        <InputBox
          name="due_date"
          label="Due Date"
          type="date"
          value={formData.due_date}
          onChange={handleChange}
          required={true}
        />

        <TextArea
          name="description"
          label="Task Description"
          type="text"
          value={formData.description}
          placeholder="Enter additional details"
          onChange={handleChange}
        />

        <div>
          <label className="text-xs capitalize text-gray-600 font-bold">
            Assigned to
          </label>
          <Select
            options={options}
            isMulti={true}
            onChange={handleSelect}
            required={true}
          />
        </div>

        <div className="mt-4">
          <button
            className="bg-leechiuBlue text-white px-2 py-1 disabled:opacity-50"
            disabled={isSubmitting}
            type="submit"
          >
            {isSubmitting ? "Adding Task..." : "Add Task"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddTaskForm;
