import React from "react";
import { Link } from "react-router-dom";
import OptionBox from "../../components/OptionBox";

function Billing() {
  return (
    <div>
      <div
        className=" header
        text-lg text-gray-700 font-bold text-center mb-8
        "
      >
        Select from the following Options:
      </div>
      <div className="flex gap-4 w-full items-center justify-center flex-wrap ">
        <OptionBox link="/ISbillingSale" label="Sale Transaction" />
        <OptionBox link="/leaseBilling" label="Commercial Leasing Billing" />
        <OptionBox link="/ISbillingSale" label="Residential Sales Billing" />
        <OptionBox
          link="/residentialLeaseBilling"
          label="Residential Lease Billing"
        />
        <OptionBox
          link="/researchValuationBilling"
          label="Research and Studies"
        />
        <OptionBox
          link="/billinglist/mybillings"
          label="View All My Billings"
        />
      </div>
    </div>
  );
}

export default Billing;
