import React from "react";
import Button from "./Button";
import ReactDOM from "react-dom";

function Modal({ header, text, children }) {
  return ReactDOM.createPortal((
    <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-white rounded-lg w-1/2 p-6 flex flex-col gap-4 items-center justify-center ">
        <h4>{header}</h4>
        <p>{text}</p>
        <div className="flex gap-2 flex-wrap items-center justify-center">{children}</div>
       
      </div>
    </div>
  ), document.body);
}

export default Modal;
