import React from "react";
import { useSelector } from "react-redux";
import { deleteDocById } from "../Firebase/Firebase.utils";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function LandlordRepREqCard({ requirement }) {
  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this requirement?")) {
      try {
        await deleteDocById("landlordRepRequirements", requirement.id);
        toast.success("Requirement deleted successfully");
      } catch (error) {
        toast.error("Error deleting requirement");
      }
    }
  };

  const handleNavigate = () => {
    navigate(
      `/leasing/landlordrepresentation/${requirement.id}?link=Requirement+Details`
    );
  };

  return (
    <div
      className="bubble relative cursor-pointer hover:shadow-md"
      onClick={handleNavigate}
    >
      <div className="close-button">
        {/* {requirement.createdBy === currentUser.email && (
          <button className="underline" onClick={handleDelete}>
            Delete
          </button>
        )} */}
      </div>
      <div className="font-bold text-sm text-gray-600">
        {requirement.tenantName}
      </div>
      <div className="text-xs ">
        <div>{requirement.requirementSize}</div>
        <div>{requirement.buildingName}</div>
      </div>
    </div>
  );
}
