import React from "react";
import Button from "./Button";
import { Link } from "react-router-dom";

function BillingCard({ billing }) {
  if (!billing) {
    return null;
  }

  return (
    <Link to={`/billingview/${billing?.id}`}>
      <div
        className="mt-4 text-gray-600 relative hover:border transform hover:border-leechiuOrange
        transition duration-800 ease-in-out bubble
      "
      >
        <div className="flex flex-col">
          <div className="text-sm capitalize font-bold">
            {billing?.client_name?.toLowerCase()}
          </div>
          <p className="text-xs uppercase font-bold text-leechiuOrange">
            Status: {billing.status} {billing.approver}
            <div className="rounded-full py-1 px-4 bg-leechiuOrange text-white absolute top-1 right-2">
              {billing.finance_status || "finance checking"}
            </div>
          </p>
        </div>
        <div className=" flex flex-col  mt-2 text-xs flex-wrap ml-8 lg:ml-0">
          <span className="text-gray-600 mr-4 uppercase">
            Seller: {billing.seller}
          </span>
          <span className="text-gray-600  mr-4 capitalize">
            Buyer: {billing.buyer}
          </span>
          <span className="text-gray-600 mr-4 capitalize">
            Description: {billing.description}
          </span>
          <span className="text-small  text-orange-600 font-bold">
            Fee: Php {parseFloat(billing.amount).toLocaleString()}
          </span>
        </div>
        <div className="flex flex-row flex-wrap lg:gap-4 text-gray-400 my-2">
          <span className="text-xs">Requested by: {billing.created_by}</span>
          <span className="text-xs ">Billing ID: {billing.id}</span>
          <span className="text-xs">
            Date Created: {billing.created_at?.toDate().toString()}
          </span>
          {/*   {
            approved_on && <span className="text-xs">
            Date Approved: {approved_on?.toDate().toString()}
          </span>
         } */}
        </div>
      </div>
    </Link>
  );
}

export default BillingCard;
