import React from "react";
import { useParams } from "react-router-dom";
import { getDocDataSnap } from "../../Firebase/Firebase.utils";
import { useState, useEffect } from "react";
import LeaseBillingFormComponent from "./LeaseBillingFormComponent";
import BillSupport from "./BillSupport";
import ISBillingFormComponent from "./ISBillingFormComponent";
import { Update } from "@mui/icons-material";
import FinanceStatusUpdateComponent from "./FinanceStatusUpdateComponent";
import { useSelector } from "react-redux";
import ResiLeaseBillingFormComponent from "../Residential/ResiLeaseBillingFormComponent";
import ResearchBilling from "../../Research/ResearchBilling";

function FinanceView() {
  const { id } = useParams();
  const [loading, isLoading] = useState(true);
  const [billingData, setBillingData] = useState({});
  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const fetchBillingData = async () => {
      const data = await getDocDataSnap("billing", id, (res) => {
        setBillingData(res);
        isLoading(false);
      });
    };
    fetchBillingData();
  }, []);

  const handleChange = (e) => {};

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!billingData) {
    return <div>No data found</div>;
  }

  if (billingData.billing_type === "lease") {
    return (
      <div>
        <div>
          <LeaseBillingFormComponent formData={billingData} disabled={true} />
        </div>
        <div className="mt-4">
          <h4 className="text-orange-600">Step 2: Supporting Documents</h4>
          <BillSupport id={id} />
        </div>
        <FinanceStatusUpdateComponent
          id={id}
          billingData={billingData}
          currentUser={currentUser}
        />
      </div>
    );
  }

  if (billingData.billing_type === "sale") {
    return (
      <div>
        <div>
          <ISBillingFormComponent formData={billingData} disabled={true} />
          <div className="mt-4">
            <h4 className="text-orange-600 font-bold uppercase">
              Step 2: Upload supporting documents
            </h4>
            <BillSupport id={id} />
          </div>
        </div>
        <FinanceStatusUpdateComponent
          id={id}
          billingData={billingData}
          currentUser={currentUser}
        />
      </div>
    );
  }

  if (billingData.billing_type === "residentialLease") {
    return (
      <div>
        <div>
          <h4>Residential Lease Billing</h4>
          <div className="mt-4">
            <ResiLeaseBillingFormComponent
              formData={billingData}
              disabled={true}
            />
          </div>
          <div className="mt-4">
            <h4 className="text-orange-600 font-bold uppercase">
              Step 2: Upload supporting documents
            </h4>
            <BillSupport id={id} />
          </div>
        </div>
        <FinanceStatusUpdateComponent
          id={id}
          billingData={billingData}
          currentUser={currentUser}
        />
      </div>
    );
  }

  if (billingData.billing_type === "research") {
    return (
      <div>
        <div>
          <h4>Research Billing</h4>
          <div className="mt-4">
            <ResearchBilling
              data={billingData}
              disabled={true}
            />
          </div>
          <div className="mt-4">
            <h4 className="text-orange-600 font-bold uppercase">
              Step 2: Upload supporting documents
            </h4>
            <BillSupport id={id} />
          </div>
        </div>
        <FinanceStatusUpdateComponent
          id={id}
          billingData={billingData}
          currentUser={currentUser}
        />
      </div>
    );
  }

  return (
    <div>
      <div>Update Status Here</div>
    </div>
  );
}

export default FinanceView;
