import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { queryAllDocsByField } from "../Firebase/Firebase.utils";
import { useSelector } from "react-redux";
import StudyCard from "./StudyCard";

export default function ResearchStudy() {
  const [studies, setStudies] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const fetchStudies = async () => {
      await queryAllDocsByField(
        "researchStudies",
        "teamMembers",
        "array-contains",
        currentUser.email,
        (data) => {
          setStudies(data);
          setIsLoading(false);
        }
      );
    };
    fetchStudies();
  }, []);

  if (isLoading) {
    return (
      <div>
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div>
        <div className="blue-button w-fit">
          <Link to="/research/new-research-study">Create a new study</Link>
        </div>
      </div>

      <div>
        <div className="header">Your Accounts</div>
        <div className="mt-4 space-y-4">
          {studies.map((study) => (
            <div key={study.id}>
              <Link to={`/research/research-study/${study.id}`}>
                <StudyCard study={study} />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
