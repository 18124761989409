import React from "react";
import UseGetAllDoc from "../Hooks/UseGetAllDoc";
import ProfilePhotoOnly from "../components/ProfilePhotoOnly";
import { setDocData } from "../Firebase/Firebase.utils";
import toast from "react-hot-toast";

export default function TeamMembers({ requirementData }) {
  const { data: users, isLoading } = UseGetAllDoc({ collection: "users" });
  const [searchTerm, setSearchTerm] = React.useState("");
  const [editMode, setEditMode] = React.useState(false);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelect = async (user) => {
    const newTeamMembers = [...requirementData.team_members, user.email];
    await setDocData("landlordRepRequirements", requirementData.id, {
      team_members: newTeamMembers,
    });
    toast.success("Team members updated successfully");
    setSearchTerm("");
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleRemove = async (email) => {
    const newTeamMembers = requirementData.team_members.filter(
      (member) => member !== email
    );
    await setDocData("landlordRepRequirements", requirementData.id, {
      team_members: newTeamMembers,
    });
    toast.success("Team members updated successfully");
  };

  const handleTeamLead = async (email) => {
    await setDocData("landlordRepRequirements", requirementData.id, {
      team_lead: email,
    });
    toast.success("Team lead updated successfully");
  };
  return (
    <div className="bubble">
      <div className="header">Team members</div>

      <div className="mb-8">
        <div className="text-sm font-semibold text-gray-600">
          Select Team Members
        </div>
        <input
          className="px-2 py-1 border border-gray-300 rounded-md"
          type="search"
          placeholder="Search and select an agent"
          onChange={handleSearch}
          value={searchTerm}
        />
        <div className="max-h-36 absolute overflow-hidden w-60 bg-gray-100 shadow-md b">
          {searchTerm.length > 0 &&
            users
              .filter((user) => {
                return user.displayName.toLowerCase().includes(searchTerm);
              })
              .map((user) => {
                return (
                  <div
                    key={user.id}
                    className="flex items-center justify-between "
                  >
                    <div className="flex items-center hover:bg-gray-200 border-b p-2 border-gray-200 w-full cursor-pointer">
                      <div className="ml-2" onClick={() => handleSelect(user)}>
                        {user.displayName}
                      </div>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>

      <div>
        <div className="mb-4 text-xs font-bold text-gray-600">Team members</div>
        <div className="flex items-center gap-4">
          <button className="blue-button" onClick={handleEditMode}>
            {editMode ? "Done" : "Edit"}
          </button>
          {requirementData?.team_members.map((member, index) => {
            return (
              <div key={index}>
                <ProfilePhotoOnly email={member} />
                <div className="flex items-center justify-normal gap-2">
                  {editMode && (
                    <button
                      onClick={() => handleRemove(member)}
                      className="text-red-500 text-xs underline"
                    >
                      Remove
                    </button>
                  )}
                  {editMode && requirementData.team_lead !== member && (
                    <button
                      className="text-xs underline text-blue-500"
                      onClick={() => handleTeamLead(member)}
                    >
                      Set team lead
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
