import React, { useEffect, useState } from "react";
import { getAllDocsSnap } from "../../Firebase/Firebase.utils";
import ListingCard from "./ListingCard";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

function AllListings() {
  const [listings, setListings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const listingsPerPage = 10; // Number of listings to display per page
  const [activePill, setActivePill] = useState("My Listings");

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const fetchListings = async () => {
      const data = await getAllDocsSnap("listings", (data) => {
        //arrange listing by property_name in ascending order
        data.sort((a, b) => {
          return a.property_name?.localeCompare(b.property_name);
        });

        setListings(data);
        setIsLoading(false);
      });
    };
    fetchListings();
  }, []);

  //filter listings based on the active pill
  let filteredListings =
    activePill === "My Listings"
      ? listings.filter((listing) =>
          listing.team_members?.includes(currentUser.email)
        )
      : listings;

  // Filter listings based on the search term
  filteredListings = filteredListings.filter(
    (listing) =>
      listing.property_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      listing.address?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate the number of listings to display for the current page
  const indexOfLastListing = currentPage * listingsPerPage;
  const indexOfFirstListing = indexOfLastListing - listingsPerPage;
  const currentListings = filteredListings.slice(
    indexOfFirstListing,
    indexOfLastListing
  );

  // Calculate total pages
  const totalPages = Math.ceil(filteredListings.length / listingsPerPage);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const inactivePillStyle =
    "rounded-full w-fit text-center text-xs px-2 py-1 bg-gray-300 border border-leechiuOrange text-leechiuOrange cursor-pointer";

  const activePillStyle =
    "rounded-full w-fit text-center text-xs px-2 py-1 bg-leechiuOrange text-white cursor-pointer";

  const navigate = useNavigate();

  return (
    <div className="text-left p-2 w-full">
      <div className="uppercase font-bold text-leechiuBlue">
        Listings Database
      </div>

      <div className="mt-4">
        <button className="blue-button" onClick={() => navigate("/newlisting")}>
          Add a new listing
        </button>
      </div>

      <div className="flex items-center justify-normal gap-4 mt-4">
        <div
          className={
            activePill === "All Listings" ? activePillStyle : inactivePillStyle
          }
          onClick={() => setActivePill("All Listings")}
        >
          All Listings
        </div>
        <div
          className={
            activePill === "My Listings" ? activePillStyle : inactivePillStyle
          }
          onClick={() => setActivePill("My Listings")}
        >
          My Listings
        </div>
      </div>

      <div>
        <input
          className="p-2 rounded-lg border border-gray-300 w-full mt-4"
          type="search"
          placeholder="Search for listings here"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>

      {/* Page Number Indicator */}
      <div className="text-left my-4 uppercase font-semibold text-xs text-gray-600">
        <span>
          Page {currentPage} of {totalPages}
        </span>
      </div>

      <div className="flex items-start justify-center sm:justify-normal mx-auto w-full gap-4 flex-wrap">
        {isLoading ? (
          <div className="w-full">Loading...</div>
        ) : (
          currentListings.map((listing, index) => (
            <div
              className="transform hover:border-leechiuOrange border border-gray-300 rounded-xl
            transition duration-300 ease-in-out cursor-pointer
            "
              key={index}
            >
              <Link
                to={
                  listing.team_members?.includes(currentUser.email)
                    ? `/listing/${listing.id}/?link=Summary+Info`
                    : `/flyer/${listing.id}`
                }
              >
                <ListingCard listing={listing} />
              </Link>
            </div>
          ))
        )}
      </div>
      {/* Pagination Controls */}
      <div className="flex justify-center mt-4">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className={`mx-1 rounded px-3 py-1 ${
            currentPage === 1
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-blue-500 text-white"
          }`}
        >
          Previous
        </button>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={`mx-1 rounded px-3 py-1 ${
            currentPage === totalPages
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-blue-500 text-white"
          }`}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default AllListings;
