import React from "react";
import AddIcon from "@mui/icons-material/Add";
import UploadFileInput from "../../components/UploadFileInput";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { getDocDataSnap, setDocData } from "../../Firebase/Firebase.utils";
import PhotoProgressBar from "../../components/PhotoProgressBar";
import PhotoGrid from "./PhotoGrid";
import PhotoViewModal from "../../components/PhotoViewModal";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

function Photos() {
  const { id } = useParams();
  const inputref = useRef();
  const [listing, setListing] = useState({});
  const [file, setFile] = useState(null);
  const [inProcess, setInProcess] = useState(false);
  const [error, setError] = useState(null);
  const [selectedImg, setSelectedImg] = useState(null);

  useEffect(() => {
    const collectionName = "listings";
    const docId = id;

    const unsubscribe = getDocDataSnap(collectionName, docId, (data) => {
      // Handle the real-time updates or absence of the document data
      if (data !== false) {
        setListing(data);
      } else {
      }
    });
  }, []);

  const handleUpload = (e) => {
    inputref.current.click();
  };

  const types = ["image/png", "image/jpeg"];

  const handleChange = (e) => {
    let selected = e.target.files[0];

    if (selected && types.includes(selected.type)) {
      setFile(selected);
      setInProcess(true);
      setError("");
    } else {
      setFile(null);
      setInProcess(false);
      setError("Please select an image file (png or jpg)");
    }
  };

  return (
    <motion.div initial={{ opacity: 0, x: 100 }} animate={{ opacity: 1, x: 0 }}>
      <button
        onClick={() => inputref.current.click()}
        className="rounded-full bg-blue-500 text-white w-10 h-10 flex items-center justify-center cursor-pointer"
      >
        <AddIcon />
      </button>
      {error && <div className="text-red-500">{error}</div>}

      {/* //hidden input field */}
      <div style={{ display: "none" }}>
        <input
          type="file"
          ref={inputref}
          onChange={handleChange}
          accept="image/png, image/jpeg"
        />
      </div>

      {/* progress bar only shows if in process is set to true */}
      {inProcess && (
        <PhotoProgressBar file={file} setInProcess={setInProcess} id={id} />
      )}

      {/* photo grid component */}
      <div className="mt-8">
        <PhotoGrid id={id} setSelectedImg={setSelectedImg} />
      </div>

      <AnimatePresence mode="modal">
        {selectedImg && (
          <PhotoViewModal
            selectedImg={selectedImg}
            setSelectedImg={setSelectedImg}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default Photos;
