import React, { useState } from "react";
import { logOutUser } from "../../Firebase/Firebase.utils";
import { useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import Sidebar, { SideDrawer } from "./Sidebar";
import { FaWindowClose } from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";

export default function Header() {
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState(false);

  const handleLogout = () => {
    logOutUser();
    navigate("/login");
  };

  const handleDrawer = () => {
    setViewMode(!viewMode);
  };

  return (
    <div className="bg-gray-200 h-12 text-leechiuBlue flex items-center lg:justify-end justify-between gap-4 px-8 py-2 text-xs w-full">
      <button className="md:hidden" onClick={handleDrawer}>
        <GiHamburgerMenu />
      </button>
      <div className="flex items-center gap-4">
        <div>Notifications</div>
        <div>Profile</div>
        <button
          onClick={handleLogout}
          className="hover:bg-leechiuOrange p-2 rounded-lg hover:text-white"
        >
          Log Out
        </button>
      </div>

      <AnimatePresence>
        {viewMode && (
          <div
            className="bg-black bg-opacity-50 w-screen z-20 absolute top-0 left-0"
            onClick={() => setViewMode(false)}
          >
            <motion.div
              className="relative w-64"
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.3 }}
            >
              <Sidebar />
              <div className="absolute top-2 right-2 text-leechiuOrange">
                <button onClick={() => setViewMode(false)}>
                  <FaWindowClose />
                </button>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
}
