import React from "react";
import toast from "react-hot-toast";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

function LeaseListingSummary({ listing }) {
  const formData = listing;
  const mainDivRef = React.useRef(null);

  const handleCopy = () => {
    // Get the text content of the main div and its children
    const textToCopy = mainDivRef.current.innerText;

    // Create a temporary textarea element to copy the text
    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;

    // Append the textarea to the document
    document.body.appendChild(textarea);

    // Select the text in the textarea
    textarea.select();

    // Execute the copy command
    document.execCommand("copy");

    // Remove the temporary textarea
    document.body.removeChild(textarea);

    // Alert the copied text
    toast.success("Copied to clipboard");
  };

  return (
    <div className="">
      <h4 className=" text-gray-600 text-sm">Summary Information</h4>
      <div className="text-sm font-bold text-gray-600 mb-4">
        Pipeline Status: {listing.pipeline_status}
      </div>
      <div
        className="relative text-xs border rounded border-gray-300 shadow-md p-2 "
        ref={mainDivRef}
      >
        <div className="font-bold text-gray-500">Property for Lease</div>
        <div>Address: {formData.address}</div>
        <div>
          Lot Area: {parseFloat(formData.lot_area).toLocaleString()} square
          meters
        </div>
        <div>
          Asking Price: Php{" "}
          {parseFloat(formData.selling_price).toLocaleString()} per sqm
        </div>
        <div>
          Total Asking Price: Php{" "}
          {parseFloat(formData.total_price).toLocaleString()}{" "}
          {formData.vatable === "Yes" ? " + VAT" : ""}
        </div>
        <div>Zoning: {formData.zoning}</div>
        <div>Frontage: {formData.frontage}</div>
        <div>Current Use: {formData?.current_use}</div>
        <button className="absolute top-2 right-2" onClick={handleCopy}>
          <ContentCopyIcon />
        </button>
      </div>
    </div>
  );
}

export default LeaseListingSummary;
