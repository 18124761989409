import React from "react";
import InputBox from "../../components/InputBox";
import Button from "../../components/Button";
import { useState } from "react";
import { signupWithEmail, checkUser } from "../../Firebase/Firebase.utils";
import { updateUserDisplayName } from "../../Firebase/Firebase.utils";
import { createUserDoc } from "../../Firebase/Firebase.utils";

import { useDispatch } from "react-redux";
import { loadUser } from "../../store/userSlice";
import { Form, useNavigate } from "react-router-dom";

function SignupForm() {
  const [formData, setFormData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const checkDomainEmail = (email) => {
    const domain = email.split("@")[1];
    if (domain === "leechiu.com") {
      return true;
    } else {
      return false;
    }
  };

  const checkPassword = (password, confirmPassword) => {
    if (password === confirmPassword) {
      return true;
    } else {
      return false;
    }
  };

  const checkPasswordFormat = (password) => {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
    if (regex.test(password)) {
      return true;
    } else {
      return false;
    }
  };

  const createUserDocument = async (userAuth, additionalData) => {
    if (!userAuth) return;
    const userRef = await createUserDoc(userAuth, additionalData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const { displayName, email, password, confirm_password } = formData;
    const roles = [];

    if (!email.endsWith("@leechiu.com")) {
      alert("Please use a Leechiu email address (@leechiu.com)");
      setIsProcessing(false);
      return;
    }

    if (password !== confirm_password) {
      alert("Passwords do not match");
      setIsProcessing(false);
      return;
    }

    const passwordCheck = checkPasswordFormat(password);
    if (!passwordCheck) {
      alert(
        "Please choose a password of at least 8characters long which is a combination of upppercase, lowercase, numbers"
      );
      setIsProcessing(false);
      return;
    }

    // signUpUsingEmail(email, password);
    const signUp = async () => {
      try {
        const res = await signupWithEmail(email, password);
        if (res) {
          const userCheck = await checkUser(res.user);

          if (!userCheck) {
            await createUserDoc(res.user, { displayName });
            dispatch(
              loadUser({ email, displayName, uid: res.user.uid }, roles)
            );
            navigate("/dashboard");
          } else {
            alert("User already exists, sign in instead");
          }
        }
      } catch (error) {}
    };
    signUp();
  };

  return (
    <form
      className="mx-4 mt-2 flex items-center justify-around flex-col space-y-2"
      onSubmit={handleSubmit}
    >
      <InputBox
        type="text"
        placeholder="Full Name"
        label="Full Name"
        name="displayName"
        required
        onChange={handleChange}
        value={formData.displayName}
      />
      <InputBox
        type="email"
        placeholder="Enter Leechiu Email Address"
        label="Email Address"
        name="email"
        required
        onChange={handleChange}
        value={formData.email}
      />
      <InputBox
        type="password"
        placeholder="Enter Password"
        label="Password"
        name="password"
        required
        onChange={handleChange}
        value={formData.password}
      />
      <InputBox
        type="password"
        placeholder="Confirm Password"
        label="Confirm Password"
        name="confirm_password"
        required
        onChange={handleChange}
        value={formData.confirm_password}
      />
      <Button
        text="Sign Up"
        label={isProcessing ? "Processing..." : "Sign Up"}
        type="submit"
        disabled={isProcessing}
      />
    </form>
  );
}

export default SignupForm;
