import React from "react";
import InputBox from "../../components/InputBox";
import Button from "../../components/Button";
import { useSelector } from "react-redux";

function ResiLeaseBillingFormComponent({
  handleChange,
  handleSubmit,
  handleSaveDraft,
  formData,
  disabled,
}) {
  const currentUser = useSelector((state) => state.userObject);

  const approvers = [
    {
      name: "Select Approver",
      email: "",
    },
    {
      name: "Henry Cabrera",
      email: "henry.cabrera@leechiu.com",
    },
    {
      name: "Lylah Ledonio",
      email: "lylah.ledonio@leechiu.com",
    },
    {
      name: "Phillip Anonuevo",
      email: "phillip.anonuevo@leechiu.com",
    },
    {
      name: "Roy Golez",
      email: "roy.golez@leechiu.com",
    },
  ];

  return (
    <div>
      <h1>Residential Lease Billing</h1>
      <div className="mt-4">
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <select
            className="disabled:text-gray-800 disabled:bg-gray-200 w-80 px-4 py-2 mt-2 border rounded-md border-gray-400 focus:outline-none focus:border-blue-500"
            onChange={handleChange}
            name="approver"
            value={formData.approver || ""}
            required
            disabled={disabled}
          >
            {approvers.map((approver, index) => (
              <option key={index} value={approver.email}>
                {approver.name}
              </option>
            ))}
          </select>

          <h4>Tenant Information</h4>

          <div className="flex items-center gap-2 flex-wrap">
            <InputBox
              label="Tenant Name"
              type="text"
              placeholder="Tenant Name"
              name="tenantName"
              onChange={handleChange}
              value={formData.tenantName}
              disabled={disabled}
            />
            <InputBox
              label="Address"
              type="text"
              placeholder="Address"
              name="tenantAddress"
              onChange={handleChange}
              value={formData.tenantAddress}
              disabled={disabled}
            />
            <InputBox
              label="Email"
              type="text"
              placeholder="Email Address"
              name="tenantEmail"
              onChange={handleChange}
              value={formData.tenantEmail}
              disabled={disabled}
            />
            <InputBox
              label="Contact Number"
              type="text"
              placeholder="Contat Number"
              name="tenantContact"
              onChange={handleChange}
              value={formData.tenantContact}
              disabled={disabled}
            />
          </div>

          <h4>Landlord Information</h4>
          <div className="flex items-center gap-2 flex-wrap">
            <InputBox
              label="Landlord Name"
              type="text"
              placeholder="Landlord Name"
              name="landlordName"
              onChange={handleChange}
              value={formData.landlordName}
              disabled={disabled}
            />
            <InputBox
              label="Address"
              type="text"
              placeholder="Address"
              name="landlordAddress"
              onChange={handleChange}
              value={formData.landlordAddress}
              disabled={disabled}
            />
            <InputBox
              label="Email"
              type="text"
              placeholder="Email Address"
              name="landlordEmail"
              onChange={handleChange}
              valeu={formData.landlordEmail}
              disabled={disabled}
            />
            <InputBox
              label="Contact Number"
              type="text"
              placeholder="Contat Number"
              name="landlordContact"
              onChange={handleChange}
              value={formData.landlordContact}
              disabled={disabled}
            />
          </div>

          <h4>Unit Information</h4>
          <div className="flex items-center gap-2 flex-wrap">
            <InputBox
              label="Unit Number"
              type="text"
              placeholder="Unit Number"
              name="unitNumber"
              onChange={handleChange}
              value={formData.unitNumber}
              disabled={disabled}
            />
            <InputBox
              label="Building or Subdivision"
              type="text"
              placeholder="Building Name / Subdivision Name"
              name="buildingName"
              onChange={handleChange}
              value={formData.buildingName}
              disabled={disabled}
            />
            <InputBox
              label="Floor Area"
              type="text"
              placeholder="Floor Area"
              name="floorArea"
              onChange={handleChange}
              value={formData.floorArea}
              disabled={disabled}
            />
            <InputBox
              label={`Unit Type`}
              type="text"
              placeholder="Unit Type"
              name="unitType"
              onChange={handleChange}
              value={formData.unitType}
              disabled={disabled}
            />
            <InputBox
              label="Parking Slot"
              type="text"
              placeholder="No. of Parking Slot"
              name="parkingSlot"
              onChange={handleChange}
              value={formData.parkingSlot}
              disabled={disabled}
            />
            <InputBox
              label="Monthly Rent"
              type="text"
              placeholder="Monthly Rent"
              name="monthlyRent"
              onChange={handleChange}
              value={formData.monthlyRent}
              disabled={disabled}
            />
            <InputBox
              label="Start Date"
              type="date"
              placeholder="Start Date"
              name="startDate"
              onChange={handleChange}
              value={formData.startDate}
              disabled={disabled}
            />
            <InputBox
              label="End Date"
              type="date"
              placeholder="End Date"
              name="endDate"
              onChange={handleChange}
              value={formData.endDate}
              disabled={disabled}
            />
            <InputBox
              label="Lease Term"
              type="text"
              placeholder="Lease Term"
              name="leaseTerm"
              onChange={handleChange}
              value={formData.leaseTerm}
              disabled={disabled}
            />
            <InputBox
              label="Advance Payment"
              type="text"
              placeholder="No. of months advance payment"
              name="advancePayment"
              onChange={handleChange}
              value={formData.advancePayment}
              disabled={disabled}
            />
            <InputBox
              label="Security Deposit"
              type="text"
              placeholder="no. of months security deposit"
              name="securityDeposit"
              onChange={handleChange}
              value={formData.securityDeposit}
              disabled={disabled}
            />
          </div>

          <h4>Billing Information</h4>
          <div className="flex items-center gap-2 flex-wrap">
            <InputBox
              label="Client Name"
              type="text"
              placeholder="Client Name"
              name="client_name"
              onChange={handleChange}
              value={formData.client_name}
              disabled={disabled}
            />
            <InputBox
              label="Address"
              type="text"
              placeholder="Address"
              name="clientAddress"
              onChange={handleChange}
              value={formData.clientAddress}
              disabled={disabled}
            />
            <InputBox
              label="Number of months commission"
              type="text"
              placeholder="no. of months commission"
              name="months_commission"
              onChange={handleChange}
              value={formData.months_commission}
              disabled={disabled}
            />
            <InputBox
              label="Amount"
              type="number"
              placeholder="Amount"
              name="amount"
              onChange={handleChange}
              value={formData.amount}
              disabled={disabled}
            />
            <InputBox
              label="VAT"
              type="number"
              placeholder="VAT"
              name="vat"
              onChange={handleChange}
              value={formData.vat}
              disabled={disabled}
            />
            <InputBox
              label="Description"
              type="text"
              placeholder="Description"
              name="description"
              onChange={handleChange}
              value={formData.description}
              disabled={disabled}
            />
          </div>
          {currentUser.roles.includes("finance") ? null : (
            <div className="flex items-center gap-2">
              <Button
                label="Proceed to Step 2"
                type="submit"
                disabled={disabled}
              />
              <button
                onClick={handleSaveDraft}
                className="bg-white px-4 py-2 border border-solid border-gray-800 rounded-lg"
                disabled={disabled}
                type="button"
              >
                Save as Draft
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default ResiLeaseBillingFormComponent;
