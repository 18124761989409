import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";

export default function NewMain() {
  return (
    <div className="flex items-start w-screen bg-gray-100 text-gray-600 text-xs">
      <div className="hidden md:block top-0 left-0 w-64">
        <Sidebar />
      </div>
      <div className="w-full min-h-screen">
        <div>
          <Header />
        </div>
        <div className="p-8 bg-gray-100">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
