import React from "react";
import { useEffect, useState } from "react";
import { getDocData, setDocData } from "../../Firebase/Firebase.utils";
import InputBox from "../../components/InputBox";
import Button from "../../components/Button";
import Select from "react-select";
import SelectBox from "../../components/SelectBox";
import ReactSelectBox from "../../components/ReactSelectBox";
import { useSelector } from "react-redux";

function ReqDetailedInfoForm({ requirementData, id, setEditMode }) {
  const [formData, setFormData] = useState({ ...requirementData });
  const [isProcessing, setIsProcessing] = useState(false);
  const currentUser = useSelector((state) => state.userObject);

  const tableFields = [
    { label: "Tenant Name", value: "tenantName" },
    { label: "Type", value: "requirementType" },
    { label: "Size", value: "requirementSize" },
    { label: "Location", value: "requirementLocation" },
    { label: "Budget", value: "requirementBudget" },
    { label: "Move-In Date", value: "requirementMoveInDate" },
    { label: "Lease Term", value: "requirementTerm" },
    { label: "Potential Fee", value: "potentialFee" },

    { label: "Notes", value: "requirementNotes" },
  ];

  const leadSource = [
    {
      label: "Referral",
      value: "Referral",
    },
    {
      label: "Website",
      value: "Website",
    },
    {
      label: "Social Media",
      value: "Social Media",
    },
    {
      label: "Email",
      value: "Email",
    },
    {
      label: "Call",
      value: "Call",
    },
    {
      label: "External Brokers/Agents",
      value: "External Brokers/Agents",
    },
    {
      label: "Networking",
      value: "Networking",
    },
    {
      label: "Others",
      value: "Others",
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const docObject = {
      ...formData,
      updatedAt: new Date().toLocaleString(),
      lastUpdatedBy: currentUser.email,
    };
    await setDocData("tenantRep", id, docObject);
    setIsProcessing(false);
    setEditMode(false);
  };

  return (
    <div>
      <div>Edit Details of Requirement</div>

      <div>
        <form>
          <div className="flex items-center gap-4 flex-wrap">
            {tableFields.map((field, index) => (
              <InputBox
                key={index}
                label={field.label}
                placeholder={field.label}
                name={field.value}
                required={true}
                value={formData[field.value]}
                onChange={handleChange}
              />
            ))}
            <div className="flex flex-col items-start w-80">
              <label className="text-gray-600 uppercase text-xs font-semibold">
                Pipeline Percentage Status
              </label>
              <Select
                className="w-full"
                name="pipeline_status"
                value={
                  formData.pipeline_status
                    ? {
                        label: `${formData.pipeline_status}`,
                        value: formData.pipeline_status,
                      }
                    : null
                }
                onChange={(e) =>
                  setFormData({ ...formData, pipeline_status: e.value })
                }
                options={[
                  { label: "0%", value: 0 },
                  { label: "Requirement - 10%", value: "10%" },
                  { label: "Signed Appointment - 30%", value: "30%" },
                  { label: "Signed LOI - 50%", value: "50%" },
                  { label: "Closed/For Billing - 90%", value: "90%" },
                  { label: "Billed and Collection - 100%", value: "100%" },
                ]}
              />
            </div>

            <div>
              <ReactSelectBox
                label={"Lead Source"}
                name={"leadSource"}
                value={leadSource.find(
                  (item) => item.value === formData.leadSource
                )}
                options={leadSource}
                placeholder={"Select Lead Source"}
                onChange={(e) =>
                  setFormData({ ...formData, leadSource: e.value })
                }
              />
            </div>
          </div>
          <div className="mt-4">
            <Button
              label={isProcessing ? "Processing" : "Save Changes"}
              onClick={handleSubmit}
              disabled={isProcessing}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default ReqDetailedInfoForm;
