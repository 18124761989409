import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { queryAllDocsByField } from "../../Firebase/Firebase.utils";
import { useNavigate } from "react-router-dom";

export default function LeasingAccountCountCard() {
  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();
  const [leasingAccounts, setLeasingAccounts] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const getLeasingAccounts = async () => {
      const data = await queryAllDocsByField(
        "tenantRep",
        "team_members",
        "array-contains",
        currentUser.email,
        (leasingAccounts) => {
          setLeasingAccounts(leasingAccounts);
          setIsLoading(false);
        }
      );
    };
    getLeasingAccounts();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const potentialFee = leasingAccounts.reduce((acc, curr) => {
    return acc + curr.potentialFee;
  }, 0);

  const handleNavigate = () => {
    navigate("/leasing");
  };

  return (
    <div className="bg-white" onClick={handleNavigate}>
      <div className="font-bold text-leechiuBlue">
        You have {leasingAccounts.length} Accounts
      </div>
      <div className="text-gray-600 text-sm">
        Potential Fee: Php {potentialFee}
      </div>
    </div>
  );
}
