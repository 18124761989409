import React, { useState } from "react";
import InputBox from "../../components/InputBox";
import { addNewDoc, storage } from "../../Firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

export default function AddDocForm({ setAddMode }) {
  const currentUser = useSelector((state) => state.userObject);
  const [formData, setFormData] = useState({});
  const [progress, setProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `legalTemplates/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
        setProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        console.error(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFormData({ ...formData, url: downloadURL });
        });
      }
    );
  };

  const handleSave = async (e) => {
    e.preventDefault();

    if (progress !== 100) {
      toast.error("Wait for the file to finish uploading, then try again");
      return;
    }
    setIsProcessing(true);
    const docObject = {
      ...formData,
      createdBy: currentUser.email,
      createdAt: new Date().toLocaleString(),
      updateType: "new legal template created",
    };
    await addNewDoc("legalTemplates", docObject);
    toast.success("Document uploaded successfully");
    setIsProcessing(false);
    setAddMode(false);
  };

  return (
    <div className="relative">
      <button className="close-button" onClick={() => setAddMode(false)}>
        Close
      </button>
      <form action="" className="space-y-4" onSubmit={handleSave}>
        <div className="header">Add a new document</div>
        <InputBox
          name="fileName"
          placeholder="File name"
          label="File Name"
          required
          onChange={handleChange}
        />

        <InputBox
          name="version"
          placeholder="Version Number"
          label="Version Number"
          required
          onChange={handleChange}
        />

        <InputBox
          name="fileDesc"
          placeholder="File description"
          label="File Description"
          required
          onChange={handleChange}
        />
        <input className="mt-4" type="file" onChange={handleUpload} required />

        {isProcessing && <div className="mt-4">Uploading... ${progress} %</div>}

        <div>
          <button className="blue-button" type="submit">
            Save
          </button>
        </div>
      </form>
    </div>
  );
}
