import React from "react";
import { useState, useEffect } from "react";
import { getAllDocs } from "../../Firebase/Firebase.utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function IsPipelineListComponent({ selectedAgent }) {
  const [listings, setListings] = useState([]); // [{}, {}, {}
  const [pipeline, setPipeline] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewType, setViewType] = useState("card");
  const [agentPipeline, setAgentPipeline] = useState([]);
  const [statusFilter, setStatusFilter] = useState("all");
  const [filteredPipeline, setFilteredPipeline] = useState([]);

  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAgentListing = async () => {
      const listings = await getAllDocs("listings");
      setListings(listings);
    };
    fetchAgentListing();
  }, []);

  useEffect(() => {
    const fetchAgentListing = async () => {
      const agentListings = listings.filter(
        (listing) => listing.team_lead === selectedAgent.value
      );

      //arrange by property_name in ascending order
      agentListings.sort((a, b) => {
        return a.property_name?.localeCompare(b.property_name);
      });

      setPipeline(agentListings);
      setLoading(false);
      setAgentPipeline(agentListings);
      setFilteredPipeline(agentListings);
    };
    fetchAgentListing();
  }, [selectedAgent, listings]);

  //filter pipeline by status
  useEffect(() => {
    if (statusFilter === "all") {
      setFilteredPipeline(agentPipeline);
    } else {
      const filteredPipeline = agentPipeline.filter(
        (listing) => listing.pipeline_status === statusFilter
      );
      setFilteredPipeline(filteredPipeline);
    }
  }, [agentPipeline, statusFilter]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleViewType = (type) => {
    setViewType(type);
  };

  const handleNavigate = (itemId) => {
    navigate(`/listing/${itemId}/summary`);
  };

  const pillStyle =
    "bg-orange-600 text-white rounded-full px-4 py-1 text-xs border";
  const activePillStyle =
    "bg-white text-orange-600 rounded-full px-4 py-1 text-xs border";

  return (
    <div>
      <div>
        <div className="flex items-center gap-2 p-2 rounded-xl bg-white">
          <div className="text-xs text-leechiuBlue uppercase font-bold">
            Filter View Type
          </div>
          <button
            className={viewType !== "list" ? activePillStyle : pillStyle}
            onClick={() => handleViewType("list")}
          >
            list{" "}
          </button>
          <button
            className={viewType !== "card" ? activePillStyle : pillStyle}
            onClick={() => handleViewType("card")}
          >
            card
          </button>
        </div>
        <div className="flex items-center justify-start gap-4 mt-4 flex-wrap bg-white rounded-xl p-2 ">
          <div className="text-xs text-leechiuBlue uppercase font-bold">
            Filter By Status %
          </div>
          <div>
            <button
              className={`
            ${statusFilter === "all" ? pillStyle : activePillStyle} `}
              onClick={() => setStatusFilter("all")}
            >
              All
            </button>
            <button
              className={`
            ${statusFilter === "10%" ? pillStyle : activePillStyle} `}
              onClick={() => setStatusFilter("10%")}
            >
              10%
            </button>
            <button
              className={`
            ${statusFilter === "30%" ? pillStyle : activePillStyle} `}
              onClick={() => setStatusFilter("30%")}
            >
              30%
            </button>
            <button
              className={`
            ${statusFilter === "50%" ? pillStyle : activePillStyle} `}
              onClick={() => setStatusFilter("50%")}
            >
              50%
            </button>
            <button
              className={`
            ${statusFilter === "90%" ? pillStyle : activePillStyle} `}
              onClick={() => setStatusFilter("90%")}
            >
              90%
            </button>
            <button
              className={`
            ${statusFilter === "100%" ? pillStyle : activePillStyle} `}
              onClick={() => setStatusFilter("100%")}
            >
              100%
            </button>
          </div>
        </div>
        <div
          className={
            viewType === "list"
              ? ""
              : "flex items-center gap-2 flex-wrap rounded-xl justify-center sm:justify-normal mt-4 p-2 "
          }
        >
          {filteredPipeline.map((listing) => (
            <div
              key={listing.id}
              className={
                viewType === "list"
                  ? "relative flex items-center gap-2 border bg-white rounded-xl shadow-lg p-2 mt-2 text-sm"
                  : "relative w-80 h-96 flex flex-col items-start gap-2 border bg-white rounded-xl shadow-lg p-2 mt-2 text-sm"
              }
            >
              <div className="absolute top-0 left-4 px-2 py-1 rounded-xl bg-leechiuBlue text-xs text-white">
                {listing.listing_type}
              </div>
              <img
                className={
                  viewType === "list"
                    ? "h-36 w-36 rounded-xl object-cover"
                    : "h-48 w-full rounded-xl object-cover"
                }
                src={
                  listing?.primaryPhotoUrl ||
                  "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1722782583/leechiuweb3/No_Image_Available_dp22ro.jpg"
                }
                alt=""
              />
              <div className="">
                <div className="font-semibold">{listing.property_name}</div>
                <a
                  className="text-xs text-blue-600"
                  href={listing.google_maps_pin}
                  target="_blank"
                >
                  Google Maps Pin
                </a>
                <div className="text-xs">{listing.address}</div>
                <div className="text-xs">
                  {parseFloat(listing.lot_area).toLocaleString()} square meters
                </div>
                <div className="text-xs">
                  Php {parseFloat(listing.selling_price).toLocaleString()} per
                  sqm
                </div>
                <div className="text-xs">
                  Php {parseFloat(listing.total_price).toLocaleString()}
                </div>
                <div className="text-xs">
                  Fee: Php {parseFloat(listing.potential_fee).toLocaleString()}
                </div>

                <div className="text-xs">
                  Pipeline Status: {listing.pipeline_status}
                </div>
              </div>
              {currentUser.roles.includes("admin") && (
                <button
                  className="absolute bottom-0 right-2"
                  onClick={() => handleNavigate(listing.id)}
                >
                  See Details
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default IsPipelineListComponent;
