import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getDocDataSnap,
  setDocData,
  deleteDocById,
} from "../Firebase/Firebase.utils";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

export default function ResearchBillingView() {
  const currentUser = useSelector((state) => state.userObject);
  const { billingid: id } = useParams();
  const [billing, setBilling] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const navigate = useNavigate();
  const [newComment, setNewComment] = React.useState("");
  const [comments, setComments] = React.useState([]);

  useEffect(() => {
    const fetchBilling = async () => {
      await getDocDataSnap("billing", id, (data) => {
        setBilling(data);
        setComments(data.comments || []);
        setIsLoading(false);
      });
    };
    fetchBilling();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleContinue = () => {
    navigate(`/researchValuationBilling?id=${id}`);
  };

  const handleApprove = async () => {
    const docObject = {
      ...billing,
      status: "approved",
      finance_status: "finance checking",
      approved_on: new Date(),
    };
    await setDocData("billing", id, docObject);
    toast.success("Billing approved");
    navigate("/billinglist/billingsPending");
  };

  const handleReject = async () => {
    const docObject = {
      ...billing,
      status: "rejected",
      finance_status: "finance checking",
      return_date: new Date(),
    };
    await setDocData("billing", id, docObject);
    toast.success("Billing rejected");
    navigate("/billinglist/billingsPending");
  };

  const handleChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleAddComment = async () => {
    const docObject = {
      ...billing,
      comments: [
        ...comments,
        {
          comment: newComment,
          created_at: new Date(),
          commenter: currentUser.email,
        },
      ],
    };

    await setDocData("billing", id, docObject);
    toast.success("Comment added");
    setNewComment("");
  };

  const handleDelete = async () => {
    await deleteDocById("billing", id);
    toast.success("Billing deleted");
    navigate("/billinglist/mybillings");
  };

  return (
    <div className="relative">
      {(billing.status === "draft" || billing.status === "rejected") && (
        <button className="close-button" onClick={handleDelete}>
          Delete
        </button>
      )}
      <div className="header">Billing for a Research Study</div>

      <div className="mt-4">
        <div className="w-96 flex items-center justify-between">
          <div className="font-bold">Client Name</div>
          <div>{billing.client_name}</div>
        </div>

        <div className="w-96 flex items-center justify-between">
          <div className="font-bold">Billing Type</div>
          <div>{billing.milestone}</div>
        </div>

        <div className="w-96 flex items-center justify-between">
          <div className="font-bold">Percentage</div>
          <div>{billing.billing_percentage}</div>
        </div>

        <div className="w-96 flex items-center justify-between">
          <div className="font-bold">Billing Amount</div>
          <div>
            {parseFloat(billing.amount).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        </div>

        <div className="w-96 flex items-center justify-between">
          <div className="font-bold">Total Contract Amount</div>
          <div>
            {parseFloat(billing.total_contract_fee).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        </div>

        <div className="w-96 flex items-center justify-between">
          <div className="font-bold">Description</div>
          <div>{billing.description}</div>
        </div>

        <div className="mt-4 ">
          <div className="flex items-center gap-2">
            {(billing.status === "draft" ||
              billing.status === "rejected" ||
              billing.finance_status === "for fixing") && (
              <button className="blue-button" onClick={handleContinue}>
                Continue this draft
              </button>
            )}

            {billing.status === "pending approval" && (
              <button className="blue-button" onClick={handleApprove}>
                Approve
              </button>
            )}

            {billing.status === "pending approval" && (
              <button className="blue-button" onClick={handleReject}>
                Return
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="mt-4">
        <div>
          <textarea
            name="comment"
            rows={"4"}
            placeholder="Insert comment here"
            className="border rounded-lg border-gray-600 p-2 w-full"
            id=""
            onChange={handleChange}
            value={newComment}
          ></textarea>
          <button className="blue-button" onClick={handleAddComment}>
            Add Comment
          </button>
        </div>
      </div>

      <div className="mt-4">
        <div className="header">Comments</div>
        <div className="space-y-2">
          {comments.map((comment) => (
            <div key={comment.created_at} className="comment">
              <div>{comment.comment}</div>
              <div>{comment.commenter}</div>
              <div>{comment.created_at.toDate().toString()}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
