import React from "react";

export default function StudyCard({ study }) {
  return (
    <div className="bubble text-xs text-gray-600 hover:border hover:border-leechiuBlue cursor-pointer">
      <div className="font-bold uppercase">{study.studyName}</div>
      <div>{study.clientName}</div>
      <div>{study.pipeline_status}</div>
    </div>
  );
}
