import React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function AccordionComponent({ label, children }) {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="text-orange-600 text-sm font-bold"> {label}</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="">{children}</div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default AccordionComponent;
