import { storage, db } from "../Firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useState, useEffect } from "react";
import { doc, setDoc, collection } from "firebase/firestore";
import { useSelector } from "react-redux";

export default function usePhotoUpload(file, id) {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [url, setUrl] = useState(null);

  const fileName = `Photos/Listings/${id}/${file.name}`;
  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const storageRef = ref(storage, fileName);
    const uploadTask = uploadBytesResumable(storageRef, file);

    const unsubscribe = uploadTask.on(
      "state_changed",
      (snap) => {
        let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
        setProgress(percentage);
      },
      (err) => {
        setError(err);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        const url = downloadURL;
        const createdAt = new Date();
        const colRef = collection(db, "photos");
        const docRef = doc(colRef);
        const docObject = {
          url,
          createdAt,
          uploadedBy: currentUser,
          listingId: id,
          primary: false,
          comments: [],
        };
        await setDoc(docRef, docObject, { merge: true });
        setUrl(url);
      }
    );

    // Cleanup function
    return () => {
      // Cancel the ongoing upload task
      uploadTask.cancel();
      // Unsubscribe from the state_changed event
      unsubscribe();
    };
  }, [file]);
  return { progress, url, error };
}
