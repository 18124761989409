import React from "react";

function TextArea({ label, placeholder, type, rows, ...otherprops }) {
  return (
    <div className="flex flex-col w-80">
      <label className="text-sm font-semibold text-gray-600 uppercase" {...otherprops}>
        {label}
      </label>
      <textarea
        className="disabled:text-gray-800 disabled:bg-gray-200 w-80 px-4 py-2 mt-2 border rounded-md border-gray-400 focus:outline-none focus:border-blue-500"
        type={type}
        placeholder={placeholder}
        {...otherprops}
        rows={rows}
      />
    </div>
  );
}

export default TextArea;
