import React from "react";
import { motion } from "framer-motion";

function Modal({ children }) {
  return (
    <div className="z-20">
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <motion.div
          initial={{opacity: 0,  y: "100" }}
          animate={{opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: "100" }}
          transition={({ duration: 0.2 }, { type: "tween", stiffness: 100 })}
          className="bg-white p-4 rounded-xl"
        >
          {children}
        </motion.div>
      </div>
    </div>
  );
}

export default Modal;
