import React, { useState, useEffect } from "react";
import { queryAllListings } from "../../Firebase/Firebase.utils";
import { Link } from "react-router-dom";
import InputBox from "../../components/InputBox";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import ListingCardComponent from "../Listing/ListingCardComponent";


function AllHospitalityListings() {
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredList, setFilteredList] = useState([]);
  const currentUser = useSelector((state) => state.userObject);
  const listingObjects = useSelector((state) => state.listingObject);
  const [activeSearchPill, setActiveSearchPill] = useState("fees");

  useEffect(() => {
    setListings(listingObjects.slice()); // Create a shallow copy
    setFilteredList(listingObjects.slice()); // Create a shallow copy
    setLoading(false);
  }, [listingObjects]);

  const handleSearch = (e) => {
    const search = e.target.value;
    const filteredList = listings.filter((listing) => {
      return (
        listing.property_name.toLowerCase().includes(search.toLowerCase()) ||
        listing.address.toLowerCase().includes(search.toLowerCase())
      );
    });
    setFilteredList(filteredList);
  };

  const navigate = useNavigate();
  const handleAddListing = () => {
    navigate("/newlisting");
  };

  const sortListingsByFees = () => {
    const sortedList = [...listingObjects].sort((a, b) => {
      return b.potential_fee - a.potential_fee;
    });
    setFilteredList(sortedList);
    setActiveSearchPill("fees");
  };

  const sortListingsByProperty = () => {
    const sortedList = [...listingObjects].sort((a, b) => {
      return a.property_name.localeCompare(b.property_name);
    });
    setFilteredList(sortedList);
    setActiveSearchPill("property");
  };

  const pillstyle =
    "bg-gray-200 text-gray-700 px-2 py-1 rounded-full cursor-pointer hover:bg-gray-300 text-xs";
  const activePill =
    "bg-orange-500 text-white px-2 py-1 rounded-full cursor-pointer text-xs";

  const authorizedList = ["admin"];

  if (
    !currentUser.roles.some((role) => authorizedList.includes(role)) &&
    currentUser.department !== "Hospitality"
  ) {
    return (
      <div>
        <h1>Unauthorized</h1>
        <p>Sorry, you are not authorized to view this page.</p>
        <p>Only HTL Department or Approvers can view</p>
      </div>
    );
  }

  const handleNavigate = (id) => {
    navigate(`/listing/${id}/summary`);
  };

  return (
    <motion.div initial={{ opacity: 0, x: 100 }} animate={{ opacity: 1, x: 0 }}>
      <div className="flex flex-wrap lg:gap-4 items-center justify-between max-w-5xl">
        <InputBox
          type="text"
          label="Search for Listing"
          placeholder="search"
          onChange={handleSearch}
        />
        <Button label="Add a listing" onClick={handleAddListing} />
      </div>
      <div className="mt-4 mb-4">Arrange by</div>
      <div className="flex items center gap-4">
        <button
          onClick={sortListingsByFees}
          className={activeSearchPill === "fees" ? activePill : pillstyle}
        >
          Fees
        </button>
        <button
          onClick={sortListingsByProperty}
          className={activeSearchPill === "property" ? activePill : pillstyle}
        >
          Name
        </button>
      </div>

      {filteredList.length > 0 ? (
        <div>
          {filteredList.map((listing) => (
            <ListingCardComponent
              key={listing.id}
              listing={listing}
              handleNavigate={handleNavigate}
            />
          ))}
        </div>
      ) : (
        <div>No listings found</div>
      )}
      {loading && <div>Loading...</div>}
    </motion.div>
  );
}

export default AllHospitalityListings;
