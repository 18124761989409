import React from "react";
import ProfilePhotoOnly from "../../components/ProfilePhotoOnly";
import TeamMembersForm from "./TeamMembersForm";
import Modal from "../../components/Modal";

function TeamMembers({ listing }) {
  const [editMode, setEditMode] = React.useState(false);
  const [editItem, setEditItem] = React.useState("");

  return (
    <div>
      <div className="text-left bg-white p-2 rounded-xl mt-4 text-sm text-gray-600">
        <div className="font-bold ">
          Team Members ({listing.team_members.length})
        </div>
        <div className="max-md:grid max-md:grid-cols-2 flex items-center gap-2 mt-2 ml-4 flex-wrap">
          {listing.team_members.map((member, index) => (
            <div key={index} className="relative">
              <div>
                <ProfilePhotoOnly email={member} />
              </div>
              {member === listing.team_lead && (
                <div className="absolute -bottom-3  bg-leechiuOrange px-1 text-white rounded-full w-fit text-[8px]">
                  Team Leader
                </div>
              )}
            </div>
          ))}
        </div>
        <button
          className=" mt-4 px-4 py-2 bg-leechiuBlue rounded-lg text-white"
          onClick={() => {
            setEditMode(true);
            setEditItem("team");
          }}
        >
          Edit
        </button>
      </div>

      {editMode && editItem === "team" && (
        <Modal>
          <div>
            <TeamMembersForm listing={listing} setEditMode={setEditMode} />
          </div>
        </Modal>
      )}
    </div>
  );
}

export default TeamMembers;
