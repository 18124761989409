import React from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { getUserByEmail } from "../Firebase/Firebase.utils";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import NoProfilePhoto from "../assets/blankprofile.webp";

function UserAvatar({ email }) {
  const [profilePhoto, setProfilePhoto] = useState("");
  const [user, setUser] = useState({});
  const [displayName, setDisplayName] = useState("");

  useEffect(() => {
    const getUserPhoto = async () => {
      try {
        const res = await getUserByEmail("users", email);
        setProfilePhoto(res.profilePhoto);
        setDisplayName(res.displayName);
        setUser(res);
      } catch (error) {}
    };

    getUserPhoto();
  }, []);

  return (
    <div>
      <Stack direction="row" spacing={2}>
        <Link to={`/profile/${user.id}`}>
          <Avatar
            sx={{ bgcolor: deepOrange[500], width: 30, height: 30 }}
            alt={displayName}
            src={profilePhoto || NoProfilePhoto}
          />
        </Link>
      </Stack>

      <div className="text-gray-600 font-bold text-xs capitalize">
        {displayName.toLowerCase()}
      </div>
    </div>
  );
}

export default UserAvatar;
