import React, { useEffect, useRef, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { motion, useMotionValue } from "framer-motion";

import { SlArrowLeftCircle } from "react-icons/sl";
import { SlArrowRightCircle } from "react-icons/sl";

function PhotoCarousel({ photos }) {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const dragX = useMotionValue(0);
  const [dragging, setDragging] = React.useState(false);
  const [mobileWidth, setMobileWidth] = useState(0);

  const containerRef = useRef(null);

  useEffect(() => {
    // get width of the container
    if (containerRef.current) {
      setMobileWidth(containerRef.current.offsetWidth);
    }

    const handleResize = () => {
      if (containerRef.current) {
        setMobileWidth(containerRef.current.offsetWidth);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [containerRef.current]);

  const onDragStart = () => {
    setDragging(true);
  };

  const onDragEnd = () => {
    setDragging(false);

    if (dragX.get() < -10) {
      if (currentIndex === photos.length - 1) {
        return;
      } else {
        setCurrentIndex((prev) => prev + 1);
      }
    } else if (dragX.get() > 10) {
      if (currentIndex === 0) {
        return;
      } else {
        setCurrentIndex((prev) => prev - 1);
      }
    }
  };

  const handlenext = () => {
    if (currentIndex === photos.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex((prev) => prev + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex === 0) {
      setCurrentIndex(photos.length - 1);
    } else {
      setCurrentIndex((prev) => prev - 1);
    }
  };

  return (
    <div className="h-96 aspect-square lg:aspect-video ">
      {/* create a carousel of photos */}
      <motion.div
        className="h-96 aspect-square lg:aspect-video object-cover rounded-xl flex items-center overflow-hidden relative"
        ref={containerRef}
      >
        <motion.div
          className="flex items-center cursor-grab active:cursor-grabbing"
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          style={{
            x: dragX,
          }}
        >
          {photos.map((photo, index) => (
            <div
              key={index}
              className={`h-96 rounded-xl shrink-0 bg-cover bg-center bg-no-repeat `}
              style={{
                transform: `translateX(-${currentIndex * 100}%)`,
                transition: "transform 0.5s ease",
                width: `${mobileWidth}px`,
              }}
            >
              <img
                src={photo}
                alt="property"
                className="h-96 w-full object-cover rounded-xl"
                // stop the drag event from bubbling up to the parent
                onDragStart={(e) => e.preventDefault()}
                onTouchStart={(e) => e.preventDefault()}
              />
            </div>
          ))}
        </motion.div>

        {/* create dot indicators */}
        {photos.length > 1 && (
          <div className="flex items-center justify-center gap-2 absolute bottom-1 p-2 rounded-xl bg-opacity-60 left-1/2 -translate-x-1/2 bg-black w-fit">
            {photos.map((photo, index) => (
              <div
                key={index}
                className={`h-2 w-2 rounded-full ${
                  index === currentIndex ? "bg-leechiuOrange" : "bg-white"
                }`}
              ></div>
            ))}
          </div>
        )}

        {/* create buttons for navigation */}
        {photos.length > 1 && (
          <>
            <div className="absolute top-1/2 left-0 transform -translate-y-1/2 max-sm:hidden ">
              <button
                className="rounded-full bg-opacity-50"
                onClick={handlePrev}
              >
                <SlArrowLeftCircle className="text-white h-8 w-8 opacity-50 hover:opacity-100 ml-2" />
              </button>
            </div>
            <div className="absolute top-1/2 right-0 transform -translate-y-1/2 max-sm:hidden">
              <button
                className="rounded-full bg-opacity-50 "
                onClick={handlenext}
              >
                <SlArrowRightCircle className="text-white h-8 w-8 opacity-50 hover:opacity-100 mr-2" />
              </button>
            </div>
          </>
        )}
      </motion.div>
    </div>
  );
}

export default PhotoCarousel;
