import React from "react";
import { useState, useEffect } from "react";
import { getDocData, setDocData } from "../../Firebase/Firebase.utils";
import InputBox from "../../components/InputBox";
import Button from "../../components/Button";
import toast from "react-hot-toast";

function CompanyProfileForm({ requirementData, id, setEditMode }) {
  const [formData, setFormData] = useState({ ...requirementData });
  const [isProcessing, setIsProcessing] = useState(false);

  const tableFields = [
    { label: " Name", value: "tenantName" },
    {
      label: " Industry",
      value: "tenantIndustry",
    },
    {
      label: " Contact Person",
      value: "tenantContactPerson",
    },
    {
      label: " Contact Number",
      value: "tenantContactNumber",
    },
    {
      label: " Email Address",
      value: "tenantEmail",
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    await setDocData("tenantRep", id, formData);
    setIsProcessing(false);
    setEditMode(false);
    toast.success("Changes saved");
  };

  return (
    <div className="bubble mt-4">
      <div className="mb-4 header ">Company Profile Form</div>
      <form>
        <div>
          {tableFields.map((field, index) => (
            <InputBox
              key={index}
              label={field.label}
              placeholder={field.label}
              name={field.value}
              required={true}
              value={formData[field.value]}
              onChange={handleChange}
            />
          ))}
        </div>
        <div className="mt-4">
          <Button
            label={isProcessing ? "Processing" : "Save Changes"}
            onClick={handleSubmit}
            disabled={isProcessing}
          />
        </div>
      </form>
    </div>
  );
}

export default CompanyProfileForm;
