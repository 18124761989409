import React from "react";
import { useParams } from "react-router-dom";
import {
  getDocData,
  deleteDocById,
  updateDocById,
} from "../../Firebase/Firebase.utils";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import ResiLeaseBillingFormComponent from "./ResiLeaseBillingFormComponent";

function ContinueDraftResiLeaseBill() {
  const [isLoading, setIsLoading] = useState(true);
  const [billingDoc, setBillingDoc] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBillingDoc = async () => {
      const doc = await getDocData("billing", id);
      setBillingDoc(doc);
      setFormData(doc);
      setIsLoading(false);
    };
    fetchBillingDoc();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDraft = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const docObject = {
      ...formData,
    };
    const updateDocData = async () => {
      try {
        const res = await updateDocById("billing", id, docObject);
        setIsProcessing(false);
        navigate(`/billinglist/myBillings`);
      } catch (error) {
        setIsProcessing(false);
      }
    };
    updateDocData();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const docObject = {
      ...formData,
    };
    const updateDocData = async () => {
      try {
        const res = await updateDocById("billing", id, docObject);
        navigate(`/bill-support/${id}`);
      } catch (error) {}
    };
    updateDocData();
  };

  return (
    <div>
      <div>
        <ResiLeaseBillingFormComponent
          formData={formData}
          handleChange={handleChange}
          handleSaveDraft={handleDraft}
          handleSubmit={handleSubmit}
          setisProcessing={setIsProcessing}
          isProcessing={isProcessing}
          disabled={false}
        />
      </div>
    </div>
  );
}

export default ContinueDraftResiLeaseBill;
