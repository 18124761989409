import React, { useEffect } from "react";
import { getDocById } from "../../Firebase/Firebase.utils";
import { Link } from "react-router-dom";
import LandlordRep from "../Leasing/LandlordRep";

function TaskCard({ task }) {
  const [listing, setListing] = React.useState({});
  const [tenantReps, setTenantReps] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [landlordReps, setLandlordReps] = React.useState({});

  useEffect(() => {
    const fetchTenantReps = async () => {
      const tenantReps = await getDocById("tenantRep", task.listing_id);
      console.log(tenantReps);
      setTenantReps(tenantReps);
      setIsLoading(false);
    };
    fetchTenantReps();
  }, []);

  useEffect(() => {
    const fetchLandlordReps = async () => {
      const landlordReps = await getDocById(
        "landlordRepRequirements",
        task.listing_id
      );
      setLandlordReps(landlordReps);
      setIsLoading(false);
    };
    fetchLandlordReps();
  }, []);

  useEffect(() => {
    const fetchListing = async () => {
      const listing = await getDocById("listings", task.listing_id);
      setListing(listing);
      setIsLoading(false);
    };
    fetchListing();
  }, []);

  if (isLoading) {
    return (
      <div className="h-10 rounded-xl w-full bg-white animate-pulse"></div>
    );
  }

  return (
    <Link
      to={
        task.type === "Leasing"
          ? `/leasing/tenantRep/req/${task.listing_id}?link=Tasks`
          : task.type === "landlordRep"
          ? `/leasing/landlordrepresentation/${task.listing_id}?link=Tasks`
          : `/listing/${task.listing_id}?link=Task+Management`
      }
    >
      <div className="bubble text-gray-600 text-xs hover:shadow-lg cursor-pointer">
        <div className="capitalize-first font-bold">{task.task_name}</div>
        <div>
          {task.type === "Leasing"
            ? tenantReps?.tenantName
            : task.type === "landlordRep"
            ? landlordReps.tenantName
            : listing.property_name}
        </div>
        <div>
          {"Due on: "}
          {task.due_date}
        </div>
      </div>
    </Link>
  );
}

export default TaskCard;
