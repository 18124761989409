import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { loadUser } from "../store/userSlice";
import { useDispatch, useSelector } from "react-redux";

function Landing() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/login");
  }, []);
  return <div>Landing</div>;
}

export default Landing;
