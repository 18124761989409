import React from "react";
import InputBox from "../../components/InputBox";
import Button from "../../components/Button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createNewDocument } from "../../Firebase/Firebase.utils";
import ResiLeaseBillingFormComponent from "./ResiLeaseBillingFormComponent";

function ResiLeaseBilling() {
  const [formData, setFormData] = React.useState({});
  const [isProcessing, setIsProcessing] = React.useState(false);
  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const billingDoc = {
      ...formData,
      comments: [],
      billing_type: "residentialLease",
      created_at: new Date(),
      created_by: currentUser.email,
      status: "draft",
      files: {},
    };

    const submitBilling = async () => {
      try {
        const res = await createNewDocument("billing", billingDoc);
        const id = res.id;
        setIsProcessing(false);

        navigate(`/bill-support/${id}`);
      } catch (error) {
        alert(error.message);
        setIsProcessing(false);
      }
    };

    if (window.confirm("Are you sure you want to submit this billing?")) {
      submitBilling();
      setIsProcessing(false);
    } else {
      setIsProcessing(false);
      return;
    }
  };

  const handleSaveDraft = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const billingDoc = {
      ...formData,
      created_at: new Date(),
      created_by: currentUser.email,
      status: "draft",
      billing_type: "sale",
    };

    const saveDraft = async () => {
      try {
        await createNewDocument("billing", billingDoc);
        setIsProcessing(false);

        navigate(`/dashboard`);
      } catch (error) {
        alert(error.message);
        setIsProcessing(false);
      }
    };
    saveDraft();
  };

  return (
    <ResiLeaseBillingFormComponent
      formData={formData}
      handleChange={handleChange}
      isProcessing={isProcessing}
      handleSubmit={handleSubmit}
      handleSaveDraft={handleSaveDraft}
    />
  );
}

export default ResiLeaseBilling;
