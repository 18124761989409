import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import UploadFileInput from "../../components/UploadFileInput";
import { useState } from "react";
import Button from "../../components/Button";
import {
  updateDocById,
  getDocData,
  getDocDataSnap,
  createNewDocument,
} from "../../Firebase/Firebase.utils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccordionComponent from "../../components/AccordionComponent";
import { useSelector } from "react-redux";

function BillSupport({ disabled }) {
  const id = useParams();
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const [additionalFile, setAdditionalFile] = useState([]);
  const [billingType, setBillingType] = useState("");
  const [fileId, setFileId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [billingDoc, setBillingDoc] = useState({});

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const getDoc = async () => {
      const doc = await getDocDataSnap("billing", id.id, (data) => {
        setBillingType(data.billing_type);
        setBillingDoc(data);
        setFileId(id.id);
        setIsLoading(false);
      });
    };
    getDoc();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = "";

    if (billingDoc?.finance_status === "for fixing") {
      data = {
        status: "approved",
        finance_status: "finance checking",
      };
    } else {
      data = {
        status: "pending approval",
      };
    }

    const submitData = async () => {
      try {
        const res = await updateDocById("billing", id.id, data);

        //create a notification email object
        const mailObject = {
          to: billingDoc?.approver || "admin@leechiu.app",
          // to: "alvin.magat@leechiu.com",
          bcc: "alvin.magat@leechiu.com",
          message: {
            subject: "Billing for Approval",
            text: `A billing has been submitted for your approval with billing reference ${id.id} by ${billingDoc.created_by} for the client ${billingDoc.client_name} . Please check the billing list for more details.`,
          },
          createdAt: new Date().toLocaleString(),
          billingId: id.id,
        };

        //send email notification
        if (billingDoc.status !== "approved") {
          const sendMail = await createNewDocument("mail", mailObject);
        }
      } catch (error) {}
    };

    if (window.confirm("Are you sure you want to submit this billing?")) {
      submitData();
      toast.success("Billing submitted for approval");
      navigate("/billinglist/myBillings");
    } else {
      return;
    }
  };

  const handleAddFile = (e) => {
    e.preventDefault();
    setAdditionalFile([...additionalFile, "file"]);
  };

  const handleDeleteFile = (e, index) => {
    e.preventDefault();
    const newFile = additionalFile.filter((file, i) => i !== index);
    setAdditionalFile(newFile);
  };

  const myObject = billingDoc.files;

  let requiredFiles = [];
  if (billingDoc?.billing_type === "lease") {
    requiredFiles = [
      "Lease Contract",
      "Agency Agreement or Fee Confirmation",
      "Fee Sharing for Agents",
      "BIR 2303 or COR of entity to be billed",
      "Peza Certificate if applicable",
      "Notarized Sworn Affidavit if PEZA registered",
      "Certificate of Incorporation of entity to be billed",
      "Computation excel file for multi floor or multi unit lease",
    ];
  } else if (billingDoc?.billing_type === "residentialLease") {
    requiredFiles = [
      "Lease Contract",
      "Agency Agreement",
      "Fee Sharing for Agents",
      "BIR 2303 or COR of entity to be billed",
      "Peza Certificate if applicable",
      "Notarized Sworn Affidavit if PEZA registered",
      "Certificate of Incorporation of entity to be billed",
    ];
  } else if (billingDoc?.billing_type === "research") {
    requiredFiles = [
      "Research Contract",
      "BIR 2303 or COR of entity to be billed",
      "Peza Certificate if applicable",
      "Notarized Sworn Affidavit if PEZA registered",
      "Certificate of Incorporation of entity to be billed",
    ];
  } else {
    requiredFiles = [
      "Deed of Sale or CTS",
      "Agency Agreement",
      "Fee Sharing for Agents",
      "BIR 2303 or COR of entity to be billed",
      "Peza Certificate if applicable",
      "Notarized Sworn Affidavit if PEZA registered",
      "Certificate of Incorporation of entity to be billed",
    ];
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h4 className="mb-8">Upload Supporting Documents</h4>
      {requiredFiles.map((file) => (
        <div
          className="mb-4 shadow-md border border-gray-200 rounded "
          key={file}
        >
          <AccordionComponent
            label={
              <div className="flex gap-2">
                {myObject && myObject[file] ? (
                  <CheckCircleIcon className="text-green-500" />
                ) : (
                  <>
                    <ErrorIcon className="text-red-500" />
                  </>
                )}
                {myObject && myObject[file] ? (
                  <a
                    href={myObject[file]}
                    target="_blank"
                    className="text-gray-700 font-normal"
                  >
                    {file}
                  </a>
                ) : (
                  <div>{file}</div>
                )}
              </div>
            }
          >
            <div>
              {currentUser.roles.includes("finance") ? (
                <div>Click on the file name to view or download</div>
              ) : (
                <UploadFileInput
                  name={file}
                  placeholder={file}
                  value={file}
                  collName={"billing"}
                  id={id.id}
                  disabled={true}
                  label={file}
                  required
                />
              )}
            </div>
          </AccordionComponent>
        </div>
      ))}

      {currentUser.roles.includes("finance") ? null : (
        <button
          className="text-white bg-blue-800 mt-4 p-2 rounded
          disabled:opacity-50 disabled:cursor-not-allowed
          "
          type="submit"
          onClick={handleSubmit}
          disabled={disabled}
        >
          Submit For Approval
        </button>
      )}
    </div>
  );
}

export default BillSupport;
