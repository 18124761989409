import React from "react";
import OptionBox from "../../components/OptionBox";

function NewListingOptions() {
  return (
    <div className="">
      <h4 className="mb-4">Select Type of Listing below</h4>
      <div className="flex items-center justify-center flex-wrap gap-4 w-full">
        <OptionBox link="/sale-listing" label="Property for Sale" />
        <OptionBox link="/lease-listing" label="Property for Lease" />
      </div>
    </div>
  );
}

export default NewListingOptions;
