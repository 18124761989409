import React from "react";
import { useState, useEffect } from "react";
import { db } from "../Firebase/Firebase.utils";
import {
  collection,
  onSnapshot,
  query,
  orderBy,
  where,
} from "firebase/firestore";

function useFirestore(id) {
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    const q = query(
      collection(db, "photos"),
      where("listingId", "==", id),
      orderBy("createdAt", "desc")
    );
    const unsub = onSnapshot(q, (snap) => {
      let documents = [];
      snap.forEach((doc) => {
        documents.push({ ...doc.data(), id: doc.id });
      });
      setDocs(documents);
    });

    return () => unsub();
  }, [collection]);

  return { docs };
}

export default useFirestore;
