import React, { useEffect, useState } from "react";
import {
  getUserByEmail,
  getUserByEmailSnap,
  queryAllDocsByFieldSnap,
} from "../Firebase/Firebase.utils";

export default function DisplayNameOnly({ email }) {
  const [user, setUser] = React.useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getUserByEmailSnap("users", email, (data) => {
        setUser(data);
        setIsLoading(false);
      });
    };
    fetchUser();
  }, [email]);

  if (isLoading) {
    return <div></div>;
  }

  return (
    <div className="flex items-center justify-normal gap-4">
      <div className="text-xs font-bold">{user.displayName}</div>
    </div>
  );
}
