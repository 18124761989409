import {
  getDocById,
  queryAllDocsByField,
  getDocDataSnap,
} from "../../Firebase/Firebase.utils";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Modal from "../Top1k/Modal";
import AddCompanyTouchPoint from "./AddCompanyTouchPoint";
import { useSelector } from "react-redux";
import AddNewCompanyForm from "../Top1k/AddNewCompanyForm";
import InteractionData from "./InteractionData";

function CompanyDetails() {
  const { id } = useParams();
  const [company, setCompany] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [addMode, setAddMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [contactToEdit, setContactToEdit] = useState({});
  const [companyToEdit, setCompanyToEdit] = useState({});
  const [companyEditMode, setCompanyEditMode] = useState(false);
  const [activeContact, setActiveContact] = useState({});
  const [activeContactMode, setActiveContactMode] = useState(false);

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const getCompany = async () => {
      const company = await getDocDataSnap("companies", id, (data) => {
        setCompany(data);
        setIsLoading(false);
      });
    };
    getCompany();
  }, []);

  useEffect(() => {
    const getContacts = async () => {
      const results = await queryAllDocsByField(
        "touch_points",
        "company_ref",
        "==",
        id,
        (results) => {
          setContacts(results);
        }
      );
    };
    getContacts();
  }, [company]);

  if (isLoading) return <div>Loading...</div>;

  const handleAddMode = () => {
    setAddMode(!addMode);
    setEditMode(false);
  };

  const handleEditMode = (contact) => {
    setContactToEdit(contact);
    setAddMode(true);
    setEditMode(true);
  };

  const handleEditCompany = () => {
    setCompanyEditMode(true);
    setCompanyToEdit(company);
  };

  const handleInteraction = (contact) => {
    setActiveContactMode(true);
    setActiveContact(contact);
  };

  return (
    <div>
      <div className="p-2 bg-white rounded-xl text-sm text-gray-600">
        <div className="uppercase font-bold text-leechiuBlue space-x-4">
          {company.company_name}
          <button
            onClick={handleEditCompany}
            className="ml-8 underline uppercase text-xs"
          >
            Edit
          </button>
        </div>
        <div>{company.company_email_address}</div>
        <div>{company.company_address}</div>
        <div>{company.company_website_link}</div>
      </div>
      <div className="p-2 mt-4">
        <button
          onClick={handleAddMode}
          className="font-bold bg-leechiuOrange text-white p-2 shadow-sm text-xs"
        >
          Add a Contact Touch Point
        </button>
      </div>
      <div className="mt-4 bg-white rounded-xl p-2">
        <div className="uppercase text-leechiuBlue font-bold p-2">
          Contact Persons
        </div>
        <div className="flex items-center justify-start gap-4 p-2 flex-wrap">
          {contacts.map((contact, index) => (
            <div
              key={index}
              className="relative  h-36 w-80 text-sm bg-white rounded-xl px-2 py-1 flex flex-col items-start justify-start shadow-md flex-wrap"
            >
              <div className="uppercase font-bold">
                {contact.name_of_person}
              </div>
              <div>{contact.designation}</div>

              <div>{contact.email_address}</div>
              <div>{contact.contact_number}</div>
              <button
                onClick={() => handleInteraction(contact)}
                className="absolute  bottom-2 right-2 bg-leechiuOrange text-white p-2 shadow-sm text-xs"
              >
                Log an interaction
              </button>
              <div className="absolute top-2 right-2 space-x-2">
                {contact.created_by === currentUser.email && (
                  <button className="underline text-leechiuBlue text-xs">
                    Delete
                  </button>
                )}
                <button
                  onClick={() => handleEditMode(contact)}
                  className="underline text-leechiuBlue text-xs"
                >
                  Edit
                </button>
              </div>
            </div>
          ))}
          {contacts.length === 0 && (
            <div className="p-2">No contacts found</div>
          )}
        </div>
      </div>

      {addMode && (
        <Modal>
          <AddCompanyTouchPoint
            setAddMode={setAddMode}
            companyId={id}
            editMode={editMode}
            setEditMode={setEditMode}
            contact={contactToEdit}
          />
        </Modal>
      )}

      {companyEditMode && (
        <Modal>
          <AddNewCompanyForm
            companyEditMode={companyEditMode}
            setCompanyEditMode={setCompanyEditMode}
            companyToEdit={companyToEdit}
            setAddMode={setCompanyEditMode}
          />
        </Modal>
      )}

      <div className="mt-4 p-2">
        {activeContactMode && (
          <InteractionData
            contact={activeContact}
            setActiveContactMode={setActiveContactMode}
          />
        )}
      </div>
    </div>
  );
}

export default CompanyDetails;
