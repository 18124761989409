import React, { useEffect } from "react";
import { loadUser } from "../../store/userSlice";
import { useSelector } from "react-redux";
import {
  getAllDocsByEmail,
  getAllDocsByEmailAndStatus,
} from "../../Firebase/Firebase.utils";
import { Link, useNavigate } from "react-router-dom";
import BillingCard from "../../components/BillingCard";
import LeaseBillingCard from "./LeaseBillingCard";
import ResiLeaseBillingCard from "../Residential/ResiLeaseBillingCard";
import ResearchBillingCard from "../../Research/ResearchBillingCard";

function MyBillings() {
  const currentUser = useSelector((state) => state.userObject);
  const [billingList, setBillingList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const Navigate = useNavigate();

  //check if user is logged in
  useEffect(() => {
    if (!currentUser.email || currentUser.email === null) {
      Navigate("/login");
    }
  }, []);

  //fetch all billings created by the user
  useEffect(() => {
    const fetchMyBillings = async () => {
      try {
        setIsLoading(true);
        const myBillings = await getAllDocsByEmail(
          "billing",
          currentUser.email,
          "created_by"
        );

        setBillingList(myBillings);
        setIsLoading(false);
      } catch (error) {}
    };
    fetchMyBillings();
  }, []);

  return (
    <div>
      <div className="mt-8 text-leechiuBlue text-sm font-bold">
        REQUESTED BILLINGS
      </div>
      {isLoading && <p>Loading...</p>}

      {billingList.length === 0 && (
        <p className="italic">You dont have any pending request for billing</p>
      )}
      {billingList.map((billing) =>
        billing.billing_type === "lease" ? (
          <div>
            <Link to={`/leaseBillingView/${billing.id}`} key={billing.id}>
              <LeaseBillingCard billing={billing} />
            </Link>
          </div>
        ) : billing.billing_type === "sale" ? (
          <Link to={`/billingview/${billing.id}`} key={billing.id}>
            {/* Wrap BillingCard with Link */}
            <BillingCard billing={billing} />
          </Link>
        ) : billing.billing_type === "residentialLease" ? (
          <Link
            to={`/residentialLeaseBillingView/${billing.id}`}
            key={billing.id}
          >
            <ResiLeaseBillingCard billing={billing} />
          </Link>
        ) : billing.billing_type === "research" ? (
          <div key={billing.id}>
            <ResearchBillingCard billing={billing} />
          </div>
        ) : null
      )}
    </div>
  );
}

export default MyBillings;
