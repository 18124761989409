import React, { useEffect } from "react";
import NewTaskForm from "./NewTaskForm";
import Modal from "../components/Modal";
import { queryAllDocsByField } from "../Firebase/Firebase.utils";
import TaskCard from "./TaskCard";
import { Edit } from "@mui/icons-material";
import EditTaskForm from "./EditTaskForm";

function TaskManagement({ requirementData, id }) {
  const links = ["Open", "Completed", "All"];
  const [addMode, setAddMode] = React.useState(false);
  const [tasks, setTasks] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [activeLink, setActiveLink] = React.useState("Open");
  const [editMode, setEditMode] = React.useState(false);
  const [activeTask, setActiveTask] = React.useState({});

  useEffect(() => {
    const fetchData = async () => {
      const data = await queryAllDocsByField(
        "tasks",
        "listing_id",
        "==",
        id,
        (data) => {
          setTasks(data);
          setIsLoading(false);
        }
      );
    };
    fetchData();
  }, []);

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const filteredTasks = tasks.filter((task) => {
    if (activeLink === "All") {
      return task;
    }
    if (activeLink === "Open") {
      return task.status === "Open";
    }
    if (activeLink === "Completed") {
      return task.status === "Completed";
    }
  });

  const handleEditMode = (task) => {
    setActiveTask(task);
    setEditMode(!editMode);
  };

  return (
    <div>
      <div className="header">Task Management</div>
      <button className="blue-button" onClick={handleAddMode}>
        Add New Task
      </button>

      <div className="flex items-center gap-4 mt-4">
        {links.map((link, index) => (
          <div
            key={index}
            className={activeLink === link ? "active-pill" : "inactive-pill"}
            onClick={() => setActiveLink(link)}
          >
            {link}
          </div>
        ))}
      </div>
      {addMode && (
        <Modal>
          <NewTaskForm
            handleAddMode={handleAddMode}
            id={id}
            requirementData={requirementData}
          />
        </Modal>
      )}

      {editMode && (
        <Modal>
          <EditTaskForm
            handleEditMode={handleEditMode}
            task={activeTask}
            requirementData={requirementData}
          />
        </Modal>
      )}

      <div className="space-y-2 mt-4">
        {filteredTasks.length === 0 && (
          <div className="text-gray-600 text-sm">No tasks found</div>
        )}
        {filteredTasks.map((task, index) => (
          <TaskCard key={index} task={task} handleEditMode={handleEditMode} />
        ))}
      </div>
    </div>
  );
}

export default TaskManagement;
