import React from "react";
import { useState } from "react";
import InputBox from "../../components/InputBox";
import { addNewDoc, setDocData } from "../../Firebase/Firebase.utils";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";

function AddCompanyTouchPoint({
  editMode,
  setEditMode,
  contact,
  setAddMode,
  companyId,
}) {
  const [formData, setFormData] = useState(editMode ? contact : {});
  const [loading, setLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const currentUser = useSelector((state) => state.userObject);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //check if either email or phone number is present
  const isContactValid = () => {
    return formData.email_address || formData.contact_number;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isContactValid()) {
      toast.error("Please provide either an email address or contact number");
      return;
    }

    if (editMode) {
      setIsProcessing(true);
      await setDocData("touch_points", contact.id, {
        ...formData,
        company_ref: companyId,
        last_update: new Date().toISOString(),
        last_updated_by: currentUser.email,
      });
      setIsProcessing(false);
      toast.success("Touchpoint updated successfully");
      setAddMode(false);
      setEditMode(false);
      return;
    }

    await addNewDoc("touch_points", {
      ...formData,
      company_ref: companyId,
      date_created: new Date().toISOString(),
      created_by: currentUser.email,
    });
    setIsProcessing(false);
    toast.success("Touchpoint added successfully");
    setAddMode(false);
  };

  return (
    <div className="relative">
      <div className="font-bold text-center text-leechiuBlue pt-4">
        Add a Company TouchPoint
      </div>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-2 max-w-[400px] mx-auto text-sm"
      >
        <InputBox
          placeholder={"Contact Name"}
          name="name_of_person"
          onChange={handleChange}
          required
          label={"Name of Person"}
          value={formData.name_of_person}
        />
        <InputBox
          placeholder={"Contact Email"}
          name="email_address"
          type={"email"}
          onChange={handleChange}
          label={"Email"}
          value={formData.email_address}
        />
        <InputBox
          placeholder={"Contact Number"}
          name="contact_number"
          onChange={handleChange}
          label={"Contact Number"}
          value={formData.contact_number}
        />
        <InputBox
          placeholder={"Position"}
          name="designation"
          onChange={handleChange}
          required
          label={"Position"}
          value={formData.designation}
        />
        <InputBox
          label={"Notes"}
          placeholder={"Notes"}
          name="notes"
          onChange={handleChange}
          value={formData.notes}
        />

        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mt-4">
          {isProcessing ? "Please wait..." : "Add Touch Point"}
        </button>
      </form>
      <button
        onClick={() => {
          setAddMode(false);
          setEditMode(false);
        }}
        className="underline text-xs absolute top-0 right-0"
      >
        Close
      </button>
    </div>
  );
}

export default AddCompanyTouchPoint;
