import React, { useEffect } from "react";
import { getAllUserTasks } from "../../Firebase/Firebase.utils";
import { useSelector } from "react-redux";
import TaskCard from "./TaskCard";

function AllTasks() {
  const [tasks, setTasks] = React.useState([]);
  const [listings, setListings] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const fetchTasks = async () => {
      const tasks = getAllUserTasks("tasks", currentUser.email, (data) => {
        //arrange by due_date in ascending order
        data.sort((a, b) => {
          return new Date(a.due_date) - new Date(b.due_date);
        });
        setTasks(data);
        setIsLoading(false);
      });
    };
    fetchTasks();
  }, []);

  if (isLoading) {
    return (
      <div>
        <div className="bubble header text-left ">All Tasks</div>
        <div className="bubble text-left">Loading...</div>
      </div>
    );
  }

  return (
    <div>
      <div className="header text-left ">All Tasks</div>
      <div className="text-sm text-gray-600 text-left mt-4">
        You have {tasks.length} pending tasks
      </div>
      <div className="mt-4">
        {tasks.map((task) => (
          <div
            key={task.id}
            className="text-left mt-2"
          >
            <TaskCard task={task} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default AllTasks;
