import React from "react";
import { deleteDocById } from "../../Firebase/Firebase.utils";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Modal from "../../components/Modal";
import EditContactForm from "./EditContactForm";
import { useState } from "react";

function ContactCard({ contact }) {
  const [editMode, setEditMode] = useState(false);

  const currentUser = useSelector((state) => state.userObject);
  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this contact?")) {
      await deleteDocById("contacts", contact.id);
      toast.success("Contact deleted successfully");
    }
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  return (
    <div className="bubble relative">
      <div className="absolute top-2 right-2 flex items-center gap-2 ">
        <button
          className="underline text-xs text-gray-600"
          onClick={handleEditMode}
        >
          Edit
        </button>
        {contact.createdBy === currentUser.email ? (
          <button
            className="underline text-xs text-gray-600"
            onClick={handleDelete}
          >
            Delete
          </button>
        ) : null}
      </div>

      <div className="uppercase text-gray text-sm font-bold">
        {contact.contactName}
      </div>
      <div className="[&>*]:text-gray text-sm ">
        <div>{contact.contactPosition}</div>
        <div>{contact.contactPhone}</div>
        <div>{contact.contactEmail}</div>
      </div>
      <div className="text-gray-400 text-xs">
        Created By: {contact.createdBy}
      </div>

      {editMode && (
        <Modal>
          <EditContactForm contact={contact} handleEditMode={handleEditMode} />
        </Modal>
      )}
    </div>
  );
}

export default ContactCard;
