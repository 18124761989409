import React from "react";
import InputBox from "../../components/InputBox";
import Button from "../../components/Button";
import { useState } from "react";
import { addNewDoc } from "../../Firebase/Firebase.utils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { updateDocById } from "../../Firebase/Firebase.utils";
import LeaseBillingFormComponent from "./LeaseBillingFormComponent";

function LeaseBilling() {
  const [formData, setFormData] = useState({});

  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.userObject);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const docObject = {
      ...formData,
      created_at: new Date(),
      comments: [],
      created_by: currentUser.email,
      billing_type: "lease",
      uid: currentUser.uid,
      status: "draft",
      files: {},
    };
    const addNewDocData = async () => {
      try {
        const res = await addNewDoc("billing", docObject);
        const id = res.id;
        navigate(`/bill-support/${id}`);
      } catch (error) {}
    };
    addNewDocData();
  };

  const handleDraft = (e) => {
    e.preventDefault();
    const docObject = {
      ...formData,
      comments: [],
      created_at: new Date(),
      created_by: currentUser.email,
      uid: currentUser.uid,
      status: "draft",
      billing_type: "lease",
      files: {},
    };
    const addNewDocData = async () => {
      try {
        const res = await addNewDoc("billing", docObject);
        const id = res.id;
        navigate(`/billinglist/myBillings`);
      } catch (error) {}
    };
    addNewDocData();
  };

  return (
    <div>
      <LeaseBillingFormComponent
        handleChange={handleChange}
        formData={formData}
        handleSubmit={handleSubmit}
        handleDraft={handleDraft}
      />
    </div>
  );
}

export default LeaseBilling;
