import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getDocData } from "../../Firebase/Firebase.utils";

import InputBox from "../../components/InputBox";
import Button from "../../components/Button";

function Agency() {
  const { id } = useParams();
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({});

  useState(() => {
    const getListing = async () => {
      const listing = await getDocData("listings", id);
      setFormData(listing);
    };
    getListing();
  }, [id]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setEditMode(!editMode);
  };

  return (
    <div>
      <Link to={`/listing/${id}`}>Back</Link>

      <form action="" onSubmit={handleSubmit}>
        <InputBox
          label="Owner on Title"
          type="text"
          placeholder="e.g. SMDC Development Corporation."
          name="owner"
          onChange={handleChange}
          disabled={!editMode}
          value={formData.owner || ""}
        />
        <Button type="submit" label={editMode ? "Save" : "Edit"} />
      </form>
    </div>
  );
}

export default Agency;
