import React from "react";
import { setDocData } from "../Firebase/Firebase.utils";
import { collection } from "firebase/firestore";
import UseGetAllDoc from "../Hooks/UseGetAllDoc";
import toast from "react-hot-toast";
import ReactSelectBox from "../components/ReactSelectBox";

function RequirementDetails({ requirementData }) {
  const [editMode, setEditMode] = React.useState(false);
  const [formData, setFormData] = React.useState(requirementData);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [isSearching, setIsSearching] = React.useState(false);

  const { data: buildings, isLoading } = UseGetAllDoc({
    collection: "masterlist",
  });

  const buildingOptions = buildings.map((building) => ({
    label: building.buildingName,
    value: building.id,
  }));

  if (isLoading) {
    return <div className="">Loading...</div>;
  }

  const tableFields = [
    { label: "Tenant Name", value: "tenantName" },
    { label: "Type", value: "requirementType" },
    { label: "Size", value: "requirementSize" },
    { label: "Pipeline Status", value: "pipeline_status" },
    { label: "Building", value: "buildingName" },
    { label: "Location", value: "requirementLocation" },
    { label: "Budget", value: "requirementBudget" },
    { label: "Move-In Date", value: "requirementMoveInDate" },
    { label: "Lease Term", value: "requirementTerm" },
    { label: "Potential Fee", value: "potentialFee" },
    { label: "Source of Lead", value: "leadSource" },
    { label: "Notes", value: "requirementNotes" },
  ];

  const leadSource = [
    {
      label: "Referral",
      value: "Referral",
    },
    {
      label: "Website",
      value: "Website",
    },
    {
      label: "Social Media",
      value: "Social Media",
    },
    {
      label: "Email",
      value: "Email",
    },
    {
      label: "Call",
      value: "Call",
    },
    {
      label: "External Brokers/Agents",
      value: "External Brokers/Agents",
    },
    {
      label: "Networking",
      value: "Networking",
    },
    {
      label: "Others",
      value: "Others",
    },
  ];

  const pipelineStatusOptions = [
    { label: "0%", value: 0 },
    { label: "Requirement - 10%", value: "10%" },
    { label: "Signed Appointment - 30%", value: "30%" },
    { label: "Signed LOI - 50%", value: "50%" },
    { label: "Closed/For Billing - 90%", value: "90%" },
    { label: "Billed and Collection - 100%", value: "100%" },
  ];

  const handleEditMode = async () => {
    setEditMode(!editMode);
    setSearchTerm(formData.buildingName);

    if (editMode) {
      setIsProcessing(true);
      await setDocData("landlordRepRequirements", requirementData.id, formData);
      toast.success("Requirement updated");
      setIsProcessing(false);
    }
  };

  const handleSearch = (e) => {
    setIsSearching(true);
    setSearchTerm(e.target.value);
  };

  const handleSelect = (building) => {
    setFormData({
      ...formData,
      buildingName: building.label,
      building: building.value,
    });
    setSearchTerm(building.label);
    setIsSearching(false);
  };

  const handleCancelEdit = () => {
    setFormData(requirementData);
    setEditMode(false);
    setIsSearching(false);
  };

  return (
    <div className="bubble">
      <div className="header">Requirement Details</div>

      <div className="flex items-center gap-4 mb-8">
        <button className="blue-button" onClick={handleEditMode}>
          {editMode ? "Save" : "Edit"}
        </button>

        {editMode && (
          <button className="underline text-xs" onClick={handleCancelEdit}>
            Cancel Edit
          </button>
        )}
      </div>

      <div>
        {!editMode && (
          <div className="flex items-center flex-wrap max-w-5xl text-sm">
            <table className="w-full bg-white border border-gray-300">
              <tbody>
                {tableFields.map((field, index) => (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
                  >
                    <td className="border border-gray-300 px-2 py-1">
                      {field.label}
                    </td>
                    {field.value === "requirementSize" ? (
                      <td className="border border-gray-300 px-2 py-1">
                        {parseFloat(
                          requirementData[field.value]
                        ).toLocaleString()}{" "}
                        sqms
                      </td>
                    ) : (
                      <td className="border border-gray-300 px-2 py-1">
                        {requirementData[field.value]}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <div>
        {editMode && (
          <div className="flex items-center flex-wrap max-w-5xl text-sm">
            <table className="w-full bg-white border border-gray-300">
              <tbody>
                {tableFields.map((field, index) => (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
                  >
                    <td className="border border-gray-300 px-2 py-1">
                      {field.label}
                    </td>
                    {field.value === "requirementMoveInDate" ? (
                      <td className="border border-gray-300 px-2 py-1">
                        <input
                          className="px-2 py-1 border border-gray-300 w-96"
                          type="date"
                          name={field.value}
                          value={
                            formData[field.value] ||
                            requirementData[field.value]
                          }
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [field.value]: e.target.value,
                            })
                          }
                        />
                      </td>
                    ) : field.value === "buildingName" ? (
                      <td className="border border-gray-300 px-2 py-1">
                        <input
                          className="px-2 py-1 border border-gray-300 w-96"
                          type="search"
                          placeholder="Search for Building"
                          value={searchTerm}
                          onChange={handleSearch}
                        />
                        {isSearching && (
                          <div className="max-h-36 p-2 shadow-md rounded-lg w-[350px] overflow-hidden absolute bg-white border border-gray-100 ">
                            {buildingOptions
                              .filter((building) => {
                                return building.label
                                  ?.toString()
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase());
                              })
                              .map((building) => {
                                return (
                                  <div
                                    className="overflow-x-hidden text-clip border-b border-gray-100 p-2 cursor-pointer hover:bg-gray-100 capitalize"
                                    key={building.value}
                                    onClick={() => handleSelect(building)}
                                  >
                                    {building.label}
                                  </div>
                                );
                              })}
                          </div>
                        )}
                      </td>
                    ) : field.value === "leadSource" ? (
                      <td className="border border-gray-300 px-2 py-1">
                        <ReactSelectBox
                          options={leadSource}
                          value={leadSource.find(
                            (item) => item.value === formData.leadSource
                          )}
                          placeholder={"Select Lead Source"}
                          onChange={(e) =>
                            setFormData({ ...formData, leadSource: e.value })
                          }
                        />
                      </td>
                    ) : field.value === "pipeline_status" ? (
                      <td>
                        <ReactSelectBox
                          options={pipelineStatusOptions}
                          value={pipelineStatusOptions.find(
                            (item) => item.value === formData.pipeline_status
                          )}
                          placeholder={"Select Pipeline Status"}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              pipeline_status: e.value,
                            })
                          }
                        />
                      </td>
                    ) : (
                      <td className="border border-gray-300 px-2 py-1">
                        <input
                          className="px-2 py-1 border border-gray-300 w-96"
                          type="text"
                          name={field.value}
                          value={
                            formData[field.value] ||
                            requirementData[field.value]
                          }
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [field.value]: e.target.value,
                            })
                          }
                        />
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default RequirementDetails;
