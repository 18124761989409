import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getDocById } from "../../Firebase/Firebase.utils";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { updateDocById } from "../../Firebase/Firebase.utils";
import { useNavigate } from "react-router-dom";
import { deleteDocById } from "../../Firebase/Firebase.utils";
import { toast } from "react-toastify";
import Modal from "../../components/OldModal";
import Button from "../../components/Button";
import AvatarPhoto from "../../components/AvatarPhoto";
import AvatarName from "../../components/AvatarName";
import UserAvatar from "../../components/UserAvatar";

function BillingView() {
  const { billingid } = useParams();
  const [billingDoc, setBillingDoc] = React.useState({});
  const [forApproval, setForApproval] = React.useState(false);
  const currentUser = useSelector((state) => state.userObject);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const Navigate = useNavigate();
  const [clickEvent, setClickEvent] = React.useState("");
  const [comments, setComments] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const [showModal, setShowModal] = React.useState(false);

  //get billing doc details from firebase using params as id
  useEffect(() => {
    const getBillingDoc = async () => {
      try {
        const billingDoc = await getDocById("billing", billingid);
        setBillingDoc(billingDoc);
        setIsLoading(false);
      } catch (error) {}
    };
    getBillingDoc();
  }, []);

  //check if billing is for approval
  useEffect(() => {
    if (
      billingDoc.status === "pending approval" &&
      billingDoc.approver === currentUser.email
    ) {
      setForApproval(true);
    }
  }, [billingDoc]);

  //handle change for comments
  const handleChange = (event) => {
    const { name, value } = event.target;
    setComments({
      comment: value,
      commenter: currentUser.email,
      created_at: new Date(),
    });
  };
  const handleCancel = () => {
    setClickEvent("");
  };

  const handleConfirmation = (e) => {
    e.preventDefault();
    setShowModal(false);
    handleSubmit(e);
  };

  //handle submit for approval
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const newBillingInfo = {
      ...billingDoc,
      status: "approved",
      comments: [...billingDoc.comments, comments],
      approved_on: new Date(),
    };

    const updateBilling = async () => {
      try {
        await updateDocById("billing", billingid, newBillingInfo);
        setIsProcessing(false);
        toast.success("Billing approved successfully");
        Navigate("/billinglist/billingsApproved");
      } catch (error) {
        alert(error.message);
        setIsProcessing(false);
      }
    };
    updateBilling();
  };

  //handle reject for approval
  const handleReject = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const newBillingInfo = {
      ...billingDoc,
      status: "rejected",
      comments: [...billingDoc.comments, comments],
      return_date: new Date(),
    };
    const updateBilling = async () => {
      try {
        await updateDocById("billing", billingid, newBillingInfo);
        setIsProcessing(false);
        toast("Billing returned successfully");
        Navigate("/billinglist/mybillings");
      } catch (error) {
        alert(error.message);
        setIsProcessing(false);
      }
    };
    updateBilling();
  };

  //handle delete for draft
  const handleDelete = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const deleteBilling = async () => {
      try {
        await deleteDocById("billing", billingid);
        setIsProcessing(false);
        toast.success("Billing deleted successfully");
        Navigate("/billinglist/mybillings");
      } catch (error) {
        alert(error.message);
        setIsProcessing(false);
      }
    };

    if (
      window.confirm(
        "Are you sure you want to delete this billing? This action cannot be undone"
      )
    ) {
      deleteBilling();
    } else {
      setIsProcessing(false);
      return;
    }
  };

  const {
    amount,
    description,
    created_by,
    created_at,
    deed,
    fee,
    agency,
    billing_type,
    vat,
    transaction_date,
    seller,
    buyer,
    property_address,
    lot_area,
    floor_area,
    transaction_value,
    commission_rate,
  } = billingDoc;

  //convert string to number
  const amountNum = parseFloat(amount);
  const vatNum = parseFloat(vat);
  const totalBill = amountNum + vatNum;

  const handleClickEvent = (e) => {
    e.preventDefault();
    setClickEvent(e.target.innerText);
    setShowModal(true);
  };

  const handleResubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const newBillingInfo = {
      ...billingDoc,
      status: "pending approval",
      comments: [...billingDoc.comments, comments],
      return_date: new Date(),
    };
    const updateBilling = async () => {
      try {
        await updateDocById("billing", billingid, newBillingInfo);
        setIsProcessing(false);
        toast("Billing returned successfully");
        Navigate("/billinglist/mybillings");
      } catch (error) {
        alert(error.message);
        setIsProcessing(false);
      }
    };
    updateBilling();
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="bubble">
      <div className="mb-4 text-orange-500 uppercase font-bold text-sm">
        Billing Details
      </div>
      <div className="mb-4">
        <p>Requested by: {created_by}</p>
        <p>Date Requested: {created_at?.toDate().toLocaleString()}</p>
      </div>

      <h4>Fee Summary</h4>
      <div className="ml-4">
        <p className="font-bold text-blue-800 uppercase text-sm">
          Total Fee to be Billed: Php {parseFloat(amount).toLocaleString()}
        </p>
        <p>VAT: Php {parseFloat(vat).toLocaleString()}</p>
        <p>Total: {parseFloat(totalBill).toLocaleString()}</p>
      </div>

      <h4 className="mt-4">Transaction Summary</h4>
      <div className="ml-4">
        <p>Transaction Date: {transaction_date}</p>
        <p>Seller:{seller}</p>
        <p>Buyer: {buyer}</p>
        <p>Transaction Type: {billing_type}</p>
        <p>Description: {description}</p>
        <p>Property Address: {property_address}</p>
        <p>Lot Area: {parseFloat(lot_area).toLocaleString()} square meters</p>
        <p>Floor Area: {parseFloat(floor_area).toLocaleString()}</p>
        <p>
          Total Transaction Value: Php{" "}
          {parseFloat(transaction_value).toLocaleString()}
        </p>
        <p>Commission Rate: {commission_rate}</p>
      </div>

      <h4 className="mt-4 mb-4">Supporting Documents</h4>
      <div className="flex flex-col gap-2 flex-wrap ml-4 text-sm ">
        {billingDoc.files &&
          Object.keys(billingDoc.files).map((file) => (
            <div className="flex gap-2">
              <p className="font-bold">{file}</p>
              <a href={billingDoc.files[file]} target="_blank" rel="noreferrer">
                View
              </a>
            </div>
          ))}
      </div>

      {billingDoc.status === "rejected" && (
        <div className="flex gap-2 flex-col mt-4">
          <Button
            label="Edit"
            onClick={() => Navigate(`/continue-is/${billingid}`)}
          />
        </div>
      )}

      <h4 className="mt-4">Comments</h4>
      {billingDoc.comments?.map((comment) =>
        comment === null ||
        comment.comment === "" ||
        comment === undefined ? null : (
          <div className="flex flex-col gap-2 flex-wrap ml-4 text-sm mb-4 bg-white rounded-xl px-4 py-1 w-fit shadow-lg">
            <div className="flex items-center gap-2">
              <UserAvatar email={comment?.commenter} />
              <p>{comment.comment}</p>
            </div>
            {/* <p className="text-gray-300">
              {comment.created_at?.toDate().toLocaleString()}
            </p> */}
          </div>
        )
      )}

      {billingDoc.status === "rejected" && (
        <div className="flex gap-2 flex-col mt-4">
          <span className="mt-4 mb-4 font-bold">Resubmit Billing</span>
          <TextField
            multiline
            maxRows={6}
            label="Enter Comments"
            name="comments"
            onChange={handleChange}
          />
          <div className="flex gap-2 mt-8">
            <Button
              disabled={isProcessing}
              onClick={handleClickEvent}
              label={isProcessing ? "Processing..." : "Resubmit"}
            />
          </div>
        </div>
      )}

      {/* //show buttons approval and return to sender if billing is for approval */}
      {forApproval && (
        <div className="flex flex-col mt-4">
          <span className="mt-4 mb-4 font-bold">
            Approve or Return to Sender
          </span>
          <TextField
            multiline
            maxRows={6}
            label="Enter Comments"
            name="comments"
            onChange={handleChange}
            required
          />
          <div className="flex gap-2 mt-8">
            <Button
              disabled={isProcessing}
              onClick={handleClickEvent}
              label={isProcessing ? "Processing..." : "Approve"}
            />
            <Button
              disabled={isProcessing}
              onClick={handleClickEvent}
              label={isProcessing ? "Processing..." : "Return to Sender"}
            />
          </div>
        </div>
      )}

      {/* //show buttons continue this draft and delete if billing is draft */}
      {billingDoc.status === "draft" ||
      billingDoc?.finance_status === "for fixing" ? (
        <div className="flex gap-2 mt-4 ">
          <Button
            label="Continue this draft"
            onClick={() => Navigate(`/continue-is/${billingid}`)}
          />
          <Button label="Delete" onClick={handleDelete} />
        </div>
      ) : null}

      {/* //show buttons edit and delete if billing is rejected */}

      {/* //modal for the click event approve */}
      {clickEvent === "Approve" ? (
        <Modal
          header="Approval"
          text="Are you sure you want to approve this billing?"
        >
          <Button label="Yes" onClick={handleSubmit} />
          <Button label="No" onClick={handleCancel} />
        </Modal>
      ) : null}

      {/* //modal for the click event return to sender */}
      {clickEvent === "Return to Sender" ? (
        <Modal
          header="Return Billing"
          text="Are you sure you want to return this billing to requestor? Make sure you have provided your comments."
        >
          <Button label="Yes" onClick={handleReject} />
          <Button label="No" onClick={handleCancel} />
        </Modal>
      ) : null}

      {clickEvent === "Resubmit" ? (
        <Modal
          header="Resubmit Billing"
          text="Are you sure you want to resubmit this billing to approver? Make sure you have provided your comments."
        >
          <Button label="Yes" onClick={handleResubmit} />
          <Button label="No" onClick={handleCancel} />
        </Modal>
      ) : null}
    </div>
  );
}

export default BillingView;
